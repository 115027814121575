import styled from 'styled-components/macro';
import { components, CSSObjectWithLabel, OptionProps } from 'react-select';

import { Flag, FlagTextPositionMode } from '@components/Flag';
import { LANGUAGE_SHORT_NAMES, type LanguageV2 } from '@features/content/languages';
import { Theme } from '@features/theme';

import { CommonFilterOptionType } from '../../types';

const StyledFlag = styled(Flag)`
  display: inline-block;
  font-size: 0;
  line-height: 1.4rem;
  vertical-align: middle;
`;

export const getTranslationSelectorOptionStyles = (
  defaultStyles: CSSObjectWithLabel,
  props: OptionProps<CommonFilterOptionType>,
  theme: Theme,
) => ({
  ...defaultStyles,
  alignItems: 'center',
  backgroundColor: props.isSelected ? theme.colorV2.optionBackgroundActive : 'transparent',
  color: props.isSelected ? theme.colorV2.accentPrimary : theme.colorV2.textTertiary,
  cursor: props.isDisabled ? 'not-allowed' : 'pointer',
  borderRadius: '1.2rem',
  border: `0.1rem solid ${props.isSelected ? theme.colorV2.optionBackgroundActive : theme.color.darkBlue15}`,
  display: 'flex',
  opacity: props.isDisabled ? '0.5' : '1',
  padding: '0 0.6rem',
  width: 'auto',
  ':hover': {
    backgroundColor: theme.colorV2.optionBackgroundActive,
  },
});

export const TranslationSelectorOption = (props: OptionProps<CommonFilterOptionType>) => {
  const {
    data: { value },
  } = props;

  return (
    <components.Option {...props}>
      <StyledFlag
        countries={value as LanguageV2}
        showCountryName={false}
        tooltipEnabled={false}
        mode={FlagTextPositionMode.withoutText}
      />
      {LANGUAGE_SHORT_NAMES[value as LanguageV2]}
    </components.Option>
  );
};
