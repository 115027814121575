import { call, put, select } from 'redux-saga/effects';
import MultipleChoiceExerciseInterface from '@components/Exercises/MultipleChoice/interfaces/MultipleChoiceExerciseInterface';
import MultipleChoiceExerciseService from '@services/ExerciseRelatedServices/MultipleChoiceExerciseService';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import type { LanguageV2 } from '@features/content/languages';
import { MultipleChoiceExerciseActions } from '@actions/MultipleChoiceExerciseActions';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const MultipleChoiceExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: MultipleChoiceExerciseInterface;
        learningLanguage: LanguageV2;
        values: FormikValuesInterface;
      };
    },
  ) {
    let { values } = payload;

    yield put({
      type: MultipleChoiceExerciseActions.SET_MULTIPLE_CHOICE_ALL_FIELDS,
      payload: {
        values,
      },
    });
    let exercise: MultipleChoiceExerciseInterface = yield select(selectLoadedExerciseData);

    yield call(MultipleChoiceExerciseService.save, dispatch, exercise, values);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default MultipleChoiceExerciseSagas;
