import { FormikContextType, useFormikContext } from 'formik';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { LoadedComprehensionExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { ContentTypes } from '@common/enums/ContentTypes';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import BidimensionalEditor from '@components/BidimensionalEditor/BidimensionalEditor';
import { BidimensionalEditorModes } from '@components/BidimensionalEditor/enums/BidimensionalEditorModes';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { ImageUpload, VideoUpload } from '@components/MediaUpload';
import { PreviewModal } from '@features/content/preview';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { Sizes } from '@common/enums/Sizes';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { AccessWarning } from '@components/Warning';
import { WritableInputText } from '@components/WritableInputText';
import { useIsEditAvailable } from '@features/content/courses';
import { useAppDispatch, useAppSelector } from '@redux/store';
import helpersService from '@services/HelpersService';
import { useState } from 'react';
import { VideoUploadActionsCreator } from '@actionCreators/VideoUploadActionsCreator';
import { getResources } from '@helpers/getResourcesHelper';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { ExperimentSelector } from '@features/experiment';
import { constants as contentConstants } from '@features/content';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { ComprehensionExerciseActionsCreator } from '@actionCreators/ComprehensionExerciseActionsCreator';

import { Instructions, LexicalItemsMatched, removeMediaProcessingValidationError } from '@features/content/exercises';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';

import { ComprehensionOptions, type ComprehensionOptionsType } from './enums/ComprehensionOptions';
import readingIcon from './img/reading.svg';
import videoIcon from './img/video.svg';
import ComprehensionExerciseInterface from './interfaces/ComprehensionExerciseInterface';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { DBId } from '@common/types/DBId';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

const ComprehensionExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedComprehensionExerciseInterface>) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);
  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const { isEditorHidden } = useIsEditorHidden({ bundleName: 'mainBundle', visitedBranch: 'phrase' });

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as ComprehensionExerciseInterface;

  const textAudioLocalizations = loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations;
  const titleAudioLocalizations = loadedExercise?.content?.title?.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableTextAudioLocalizations = textAudioLocalizations
    ? textAudioLocalizations.filter((audioLocalization: LocalizationInterface) =>
        availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
      )
    : [];
  const availableTitleAudioLocalizations = titleAudioLocalizations
    ? titleAudioLocalizations.filter((audioLocalization: LocalizationInterface) =>
        availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
      )
    : [];

  // Image and Video only have EN localization
  const imageLocalization = loadedExercise?.content?.mainBundle?.image?.imageLocalizations.find(
    (imageLocalization) => imageLocalization.language === DEFAULT_LANGUAGE_V2,
  );
  const videoLocalization = loadedExercise?.content?.mainBundle?.video?.videoLocalizations.find(
    (videoLocalization) => videoLocalization.language === DEFAULT_LANGUAGE_V2,
  );

  let comprehensionExerciseOption: ComprehensionOptionsType = ComprehensionOptions.none;
  if (loadedExercise?.content?.mainVideoEnabled) {
    comprehensionExerciseOption = ComprehensionOptions.video;
  } else if (loadedExercise?.content?.mainTextEnabled) {
    comprehensionExerciseOption = ComprehensionOptions.reading;
  }
  const [comprehensionOption, setComprehensionOption] = useState<ComprehensionOptionsType>(comprehensionExerciseOption);

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const videoErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.video');
  const mainBundleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle');
  const imageErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.image');
  const titleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'title');
  const mainVideoEnabledErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainVideoEnabled');
  const textErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.phrase');

  if (videoErrors.length && videoLocalization?.processed && videoLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.VIDEO.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  if (imageErrors.length && imageLocalization?.processed && imageLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  if (titleErrors.length) {
    let updatedErrors = [...errors];

    availableTitleAudioLocalizations.forEach(({ language, processed, value }: LocalizationInterface) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          fieldName: 'title',
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  if (textErrors.length) {
    let updatedErrors = [...errors];

    availableTextAudioLocalizations.forEach(({ language, processed, value }: LocalizationInterface) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          fieldName: 'mainBundle.phrase',
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const isMainBundleChangeBlocked =
    loadedExercise.content.mainBundle?.mappings?.count &&
    loadedExercise.content.mainBundle?.mappings?.count > 1 &&
    !loadedExercise.content.mainBundle?.isReusingConfirmed;

  const setReusedData = (id: string, field: string, isBundle: boolean) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.exercise,
        field,
        undefined,
        undefined,
        'mainBundle',
        isBundle,
      ),
    );
  };

  const renderEditor = () => (
    <TranslationsTipV2
      visitedBranch={'phrase'}
      bundleName={'mainBundle'}
      showLanguageSwitcher={false}
      errors={textErrors}
      showErrorDisplayer={false}
      translationsPanelTranslationsMode={TranslationsPanelModes.wysiwyg}
      flipped={false}
    >
      <BidimensionalEditor
        mode={BidimensionalEditorModes.mono}
        storeBranch={'phrase'}
        bundleName={'mainBundle'}
        language={courseLearningLanguage}
        maxColums={1}
        ignoreLanguageForTips={false}
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
        }}
      />
    </TranslationsTipV2>
  );

  return (
    <div className="exercise-comprehension">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Comprehension</Title>
        <HelpDisplayer type="guideline" id="guideline-comprehension-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-comprehension__main-wrapper">
        <div className="exercise-comprehension__wrapper">
          <div className="exercise-comprehension__container">
            <h1 className="exercise-comprehension__box-title">{'Instruction'}</h1>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </div>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(ComprehensionExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
            }}
          />
        </div>

        <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(mainVideoEnabledErrors)} />
        {comprehensionOption === ComprehensionOptions.none && (
          <div className="exercise-comprehension__wrapper">
            <ul
              className={`multi-option-chooser ${
                mainVideoEnabledErrors.length && isIssuesShown ? 'multi-option-chooser--error' : ''
              }`}
            >
              <li className="multi-option-chooser__option">
                <div
                  className="multi-option-chooser__option-inner"
                  onClick={() => {
                    if (isEditAvailable) {
                      dispatch(
                        ComprehensionExerciseActionsCreator.setComprehensionOption(ComprehensionOptions.reading),
                      );
                      setComprehensionOption(ComprehensionOptions.reading);
                      dispatch(ExerciseCommonActions.setTextEnabled(true));
                    }
                  }}
                >
                  <img src={readingIcon} alt="" />
                  <span>Reading comprehension</span>
                </div>
              </li>
              <li className="multi-option-chooser__option">
                <div
                  className="multi-option-chooser__option-inner"
                  onClick={() => {
                    if (isEditAvailable) {
                      dispatch(ComprehensionExerciseActionsCreator.setComprehensionOption(ComprehensionOptions.video));
                      setComprehensionOption(ComprehensionOptions.video);
                      dispatch(ExerciseCommonActions.setVideoEnabled(true));
                    }
                  }}
                >
                  <img src={videoIcon} alt="" />
                  <span>Video comprehension</span>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>

      {isFeatureEnabled('vocabularyReview') && (
        <LexicalItemsMatched
          exerciseId={loadedExercise.content.id as DBId}
          language={courseLearningLanguage}
          values={loadedExercise.content.lexicalItems ?? []}
        />
      )}

      <ResourceBundleWrapper
        bundle={loadedExercise.content.mainBundle}
        bundleName={'mainBundle'}
        errors={mainBundleErrors}
        resources={getResources(loadedExercise, courseLearningLanguage)}
      >
        <>
          {comprehensionOption === ComprehensionOptions.video && (
            <div className="exercise-comprehension__wrapper">
              <ContentToggler
                text="Video comprehension"
                open={loadedExercise.content.mainVideoEnabled}
                onSwitch={() => {
                  dispatch(ExerciseCommonActions.setVideoEnabled(!loadedExercise.content.mainVideoEnabled));
                }}
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true);
                  } else {
                    setComprehensionOption(ComprehensionOptions.none);
                    dispatch(ComprehensionExerciseActionsCreator.removeVideo());
                    dispatch(ComprehensionExerciseActionsCreator.removeReadingImage());
                  }
                }}
                isChangeBlocked={!!isMainBundleChangeBlocked}
              >
                <div className="exercise-comprehension__video-wrapper">
                  <div className="exercise-comprehension__wrapper">
                    <div className="exercise-comprehension__container">
                      <h1 className="exercise-comprehension__box-title">Thumbnail</h1>
                      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(imageErrors)} />
                    </div>
                    <ImageUpload
                      width={ImageUploadDimensionDescriptors.comprehension.readingImage.width}
                      height={ImageUploadDimensionDescriptors.comprehension.readingImage.height}
                      size={Sizes.fullscreen}
                      mode={ImageUploadModes.normal}
                      onRemove={() => {
                        dispatch(ComprehensionExerciseActionsCreator.removeReadingImage());
                      }}
                      onChange={(file: File, progressHandler: Function) => {
                        if (file !== null && loadedExercise.content.id !== undefined) {
                          dispatch(
                            ComprehensionExerciseActionsCreator.setReadingImage(loadedExercise, file, progressHandler),
                          );
                        }
                      }}
                      imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                        loadedExercise,
                        'image',
                        undefined,
                        'mainBundle',
                      )}
                      onProcessingFinished={(url: string) => {
                        dispatch(
                          CommonExerciseActionsCreator.setValueAfterProcessing({
                            url,
                            mediaType: 'image',
                            type: ContentTypes.exercise,
                            field: 'image',
                            language: 'EN',
                            bundleName: 'mainBundle',
                          }),
                        );
                      }}
                      previewMode={false}
                      isForExercise
                      errors={imageErrors}
                      isChangeBlocked={!!isMainBundleChangeBlocked}
                      onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'video', true)}
                    />
                  </div>
                  <div className="exercise-comprehension__wrapper">
                    <div className="exercise-comprehension__container">
                      <h1 className="exercise-comprehension__box-title">Video</h1>
                      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(videoErrors)} />
                    </div>
                    <VideoUpload
                      videoData={ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')}
                      onProcessingFinished={(url) => {
                        dispatch(
                          CommonExerciseActionsCreator.setValueAfterProcessing({
                            url,
                            mediaType: 'video',
                            type: ContentTypes.exercise,
                            field: 'video',
                            language: 'EN',
                            bundleName: 'mainBundle',
                          }),
                        );
                      }}
                      onChange={(uploadedVideo, progressHandler) => {
                        dispatch(
                          VideoUploadActionsCreator.uploadVideo(
                            loadedExercise,
                            uploadedVideo,
                            'mainBundle',
                            progressHandler,
                          ),
                        );
                      }}
                      onRemove={() => {
                        dispatch(ComprehensionExerciseActionsCreator.removeVideo());
                      }}
                      fullScreen
                      errors={videoErrors}
                      fieldName="video"
                      isChangeBlocked={!!isMainBundleChangeBlocked}
                      onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'video', true)}
                    />
                  </div>
                </div>
              </ContentToggler>
            </div>
          )}

          {comprehensionOption === ComprehensionOptions.reading && (
            <div className="exercise-comprehension__wrapper">
              <ContentToggler
                text="Image"
                open={loadedExercise.content.feedback !== null}
                onSwitch={() => {
                  dispatch(ExerciseCommonActions.setTextEnabled(!loadedExercise.content.mainTextEnabled));
                }}
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true);
                  } else {
                    dispatch(ComprehensionExerciseActionsCreator.removeReadingImage());
                    setComprehensionOption(ComprehensionOptions.none);
                  }
                }}
                isChangeBlocked={!!isMainBundleChangeBlocked}
                errors={imageErrors}
              >
                <div className="exercise-comprehension__wrapper">
                  <ImageUpload
                    width={ImageUploadDimensionDescriptors.comprehension.readingImage.width}
                    height={ImageUploadDimensionDescriptors.comprehension.readingImage.height}
                    size={Sizes.fullscreen}
                    mode={ImageUploadModes.normal}
                    onChange={(file: File, progressHandler: Function) => {
                      if (file !== null && loadedExercise.content.id !== undefined) {
                        dispatch(
                          ComprehensionExerciseActionsCreator.setReadingImage(loadedExercise, file, progressHandler),
                        );
                      }
                    }}
                    onRemove={() => {
                      dispatch(ComprehensionExerciseActionsCreator.removeReadingImage());
                    }}
                    imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                      loadedExercise,
                      'image',
                      undefined,
                      'mainBundle',
                    )}
                    onProcessingFinished={(url: string) => {
                      dispatch(
                        CommonExerciseActionsCreator.setValueAfterProcessing({
                          url,
                          mediaType: 'image',
                          type: ContentTypes.exercise,
                          field: 'image',
                          language: 'EN',
                          bundleName: 'mainBundle',
                        }),
                      );
                    }}
                    previewMode={false}
                    isForExercise
                    errors={imageErrors}
                    isChangeBlocked={!!isMainBundleChangeBlocked}
                    onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'video', true)}
                  />
                </div>
              </ContentToggler>
            </div>
          )}

          <div className="exercise-comprehension__wrapper">
            <ContentToggler
              text="Text"
              open={!!loadedExercise.content.mainBundle?.phrase?._id}
              onRemove={() => {
                if (isMainBundleChangeBlocked) {
                  setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
                } else {
                  dispatch(ComprehensionExerciseActionsCreator.removeMainText());
                }
              }}
              errors={textErrors}
              isChangeBlocked={!!isMainBundleChangeBlocked}
            >
              {isEditorHidden ? <div>{renderEditor()}</div> : <>{renderEditor()}</>}
              <UserLanguageCheckbox
                {...((comprehensionOption === ComprehensionOptions.reading ||
                  comprehensionOption === ComprehensionOptions.video) && {
                  checkedOverride: true,
                  disabled: true,
                })}
                {...(comprehensionOption === ComprehensionOptions.reading && { checked: true })}
                {...(comprehensionOption === ComprehensionOptions.video && { checked: false })}
                checkedField={exerciseParam.exercise?.content.mainContentLanguage}
                onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
                  dispatch(ComprehensionExerciseActionsCreator.setMainContentLanguage(displayContentToUserMode));
                }}
              />
            </ContentToggler>
          </div>
        </>
      </ResourceBundleWrapper>

      <div className="exercise-comprehension__wrapper">
        <div className="exercise-comprehension__examples-wrapper">
          <ContentToggler
            text="Title"
            open={!!loadedExercise.content.title?._id}
            onRemove={() => {
              dispatch(ComprehensionExerciseActionsCreator.nullifyTitle());
            }}
            errors={titleErrors}
          >
            <TranslationsTipV2 visitedBranch={'title'} errors={titleErrors} showErrorDisplayer={false}>
              <WritableInputText bold fontSize="24" id="comprehension-title-input" />
            </TranslationsTipV2>
            <UserLanguageCheckbox
              {...(comprehensionOption === ComprehensionOptions.reading && {
                checkedOverride: true,
                checked: true,
                disabled: true,
              })}
              checkedField={exerciseParam.exercise?.content.titleLanguage}
              onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
                dispatch(
                  ComprehensionExerciseActionsCreator.setTitleLanguage(
                    displayContentToUserMode,
                    comprehensionOption === ComprehensionOptions.reading,
                  ),
                );
              }}
            />
          </ContentToggler>
        </div>
      </div>

      <div className="exercise-comprehension__wrapper">
        <ContentToggler
          text="Content provider"
          open={!!loadedExercise.content.provider}
          onRemove={() => {
            setFieldValue('provider', null);
            dispatch(ComprehensionExerciseActionsCreator.nullifyProvider());
          }}
        >
          <WritableInputText
            id="comprehension-provider-input"
            value={values.provider}
            onChange={(availableText: string) => {
              setFieldValue('provider', availableText);
            }}
          />
        </ContentToggler>
      </div>
    </div>
  );
};

export default ComprehensionExercise;
