import { ReactNode, useEffect, useState } from 'react';

import { Modal, useDialogModal } from '@features/modal';
import { Loader } from '@features/theme';

import {
  ConfirmBulkModal,
  ContentCreatorItems,
  ContentCreatorSearch,
  QuantityHandler,
  type QuantityHandlerActionType,
} from './_components';
import {
  CreatorButtonsContainer,
  CreatorContainer,
  CreatorMenu,
  CreatorSeparator,
  CreatorDescription,
  CreatorTitle,
  QuantityHandlerWrapper,
} from './styles';

const DEFAULT_MIN_ITEMS_TO_CREATE = 1;
const DEFAULT_MAX_ITEMS_TO_CREATE = 10;

export type ContentCreatorWidgetItemType<T extends string> = {
  disabled?: boolean;
  label: string;
  iconSrc: string;
  showByDefault: boolean;
  showOnlyIcon?: boolean;
  title?: string;
  type: T;
};

export type ContentCreatorWidgetProps<T extends string> = {
  items: ContentCreatorWidgetItemType<T>[];
  allowBulk?: boolean;
  isCreateInProgress: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  position: number;
  title: string | ReactNode;
  description?: string;
  getItemLabel?: (itemType: ContentCreatorWidgetItemType<T>['type']) => string | undefined;
  onItemSelected: (item: ContentCreatorWidgetItemType<T>, numItemsToCreate?: number) => void;
  onSearch?: () => void;
};

export const ContentCreatorWidget = <T extends string>({
  items,
  allowBulk = false,
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  title,
  description,
  getItemLabel,
  onItemSelected,
  onSearch,
}: ContentCreatorWidgetProps<T>) => {
  let [numItemsToCreate, setNumItemsToCreate] = useState(DEFAULT_MIN_ITEMS_TO_CREATE);
  const [currentItem, setCurrentItem] = useState<ContentCreatorWidgetItemType<T> | null>(null);
  const [shouldResetMoreMenu, setShouldResetMoreMenu] = useState(false);

  const shouldRenderItems = isEditAvailable && items.length > 0;
  const shouldShowSearch = isEditAvailable && onSearch;

  const { open: openConfirmBulkModal, modal: confirmBulkModal } = useDialogModal((modalControls) => (
    <Modal {...modalControls} onClickOutside={modalControls.close}>
      <ConfirmBulkModal
        itemLabel={getItemLabel && getItemLabel(currentItem?.type as ContentCreatorWidgetItemType<T>['type'])}
        numItems={numItemsToCreate}
        onClose={() => {
          setNumItemsToCreate(DEFAULT_MIN_ITEMS_TO_CREATE);
          modalControls.close();
        }}
        onConfirm={() => {
          onItemSelected(currentItem as ContentCreatorWidgetItemType<T>, numItemsToCreate);
          modalControls.close();
        }}
      />
    </Modal>
  ));

  const handleOnChangeQuantity = (action: QuantityHandlerActionType) =>
    action === 'decrease' ? setNumItemsToCreate(--numItemsToCreate) : setNumItemsToCreate(++numItemsToCreate);

  const handleOnItemSelectedWithBulk = (item: ContentCreatorWidgetItemType<T>) => {
    if (numItemsToCreate > 1) {
      setCurrentItem(item);
      openConfirmBulkModal();
    } else {
      onItemSelected(item, numItemsToCreate);
    }
  };

  useEffect(() => {
    if (isPreEnabled) {
      setShouldResetMoreMenu(true);
    }
  }, [isPreEnabled]);

  return (
    <CreatorContainer
      isPreEnabled={isPreEnabled}
      onMouseEnter={() => {
        if (!isPreEnabled) {
          setShouldResetMoreMenu(true);
        }
      }}
      onMouseLeave={() => {
        if (!isPreEnabled) {
          setShouldResetMoreMenu(true);
        }
      }}
    >
      {isPreEnabled && (
        <>
          <CreatorTitle>{title}</CreatorTitle>
          {!!description && <CreatorDescription>{description}</CreatorDescription>}
        </>
      )}
      {isCreateInProgress ? (
        <Loader />
      ) : (
        <>
          <CreatorMenu
            data-testid="contentCreatorMenu"
            allowBulk={allowBulk}
            isPreenabled={isPreEnabled}
            position={position}
          >
            {allowBulk && shouldRenderItems ? (
              <QuantityHandlerWrapper>
                <QuantityHandler
                  minValue={DEFAULT_MIN_ITEMS_TO_CREATE}
                  maxValue={DEFAULT_MAX_ITEMS_TO_CREATE}
                  value={numItemsToCreate}
                  onChange={handleOnChangeQuantity}
                />
                <div>of</div>
                <CreatorButtonsContainer>
                  <ContentCreatorItems
                    items={items}
                    isCreateInProgress={isCreateInProgress}
                    resetMoreMenu={shouldResetMoreMenu}
                    setResetMoreMenu={setShouldResetMoreMenu}
                    onItemSelected={handleOnItemSelectedWithBulk}
                  />
                </CreatorButtonsContainer>
              </QuantityHandlerWrapper>
            ) : (
              <CreatorButtonsContainer>
                {shouldRenderItems && (
                  <ContentCreatorItems
                    items={items}
                    isCreateInProgress={isCreateInProgress}
                    resetMoreMenu={shouldResetMoreMenu}
                    setResetMoreMenu={setShouldResetMoreMenu}
                    onItemSelected={(item) => onItemSelected(item, DEFAULT_MIN_ITEMS_TO_CREATE)}
                  />
                )}
              </CreatorButtonsContainer>
            )}
            {shouldShowSearch && (
              <ContentCreatorSearch allowBulk={allowBulk} isPreEnabled={isPreEnabled} onSearchClick={onSearch} />
            )}
            {!isPreEnabled && <CreatorSeparator />}
          </CreatorMenu>
          {allowBulk && confirmBulkModal}
        </>
      )}
    </CreatorContainer>
  );
};
