import { FormikContextType, useFormikContext } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { DBId } from '@common/types/DBId';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { GrammarCategoryFormikValues } from '../types';

export const Breadcrumbs = () => {
  const history = useHistory();
  const { courseId } = useParams<{ courseId: DBId; categoryId: DBId }>();
  const formikContext: FormikContextType<GrammarCategoryFormikValues> = useFormikContext();

  const englishCategoryName =
    formikContext?.values.title?.find((loc) => loc.language === DEFAULT_LANGUAGE_V2)?.value || 'Grammar Category';

  return (
    <BreadcrumbNavigation
      breadcrumbItems={[
        <span
          className="breadcrumb-navigation__node breadcrumb-navigation__node_link"
          onClick={() => history.push(`/course/${courseId}/grammar-review`)}
        >
          Grammar Review
        </span>,
        <span>{englishCategoryName}</span>,
      ]}
    />
  );
};
