import { FC } from 'react';

import { userTracking } from '@features/app/tracking';

import { AvailableSearchType } from '../../../types';
import { IdSearch } from './IdSearch';
import { StringSearch } from './StringSearch';
import { BundleSearch } from './BundleSearch';
import { ExerciseSearch } from './ExerciseSearch';
import { ActivitySearch } from './ActivitySearch';
import { LessonSearch } from './LessonSearch';
import { ChapterSearch } from './ChapterSearch';
import { LexicalItemSearch } from './LexicalItemSearch';

export type CommonSearchComponentProps = {
  trackSearch: () => void;
  onBack: (() => void) | null;
};

const searchContentByType: Record<AvailableSearchType, FC<CommonSearchComponentProps>> = {
  id: (props: CommonSearchComponentProps) => <IdSearch {...props} />,
  string: (props: CommonSearchComponentProps) => <StringSearch {...props} />,
  bundle: (props: CommonSearchComponentProps) => <BundleSearch {...props} />,
  exercise: (props: CommonSearchComponentProps) => <ExerciseSearch {...props} />,
  activity: (props: CommonSearchComponentProps) => <ActivitySearch {...props} />,
  lesson: (props: CommonSearchComponentProps) => <LessonSearch {...props} />,
  chapter: (props: CommonSearchComponentProps) => <ChapterSearch {...props} />,
  /** TODO: Implement LexicalItemSearch component */
  lexicalItem: (props: CommonSearchComponentProps) => <LexicalItemSearch {...props} />,
};

export const SearchContent = ({
  searchType,
  onBack,
}: {
  searchType: AvailableSearchType;
  onBack: (() => void) | null;
}) => {
  const TypeSpecificContent = searchContentByType[searchType];

  const trackSearchTriggered = () => {
    userTracking.logosSearchTriggered({
      type: searchType,
    });
  };

  return TypeSpecificContent ? <TypeSpecificContent trackSearch={trackSearchTriggered} onBack={onBack} /> : null;
};
