import { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { SearchModal } from '@features/search';
import { ContentOwner } from '@features/content/contentOwnership';
import { NAVIGATION_PANEL_WIDTH } from '@features/content/navigation';

import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { Header } from '../Header';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { PlacementTestData } from './PlacementTestData';
import { useAppSelector, useAppDispatch } from '@redux/store';
import { selectCourse } from '@selectors/CoursesSelectors';
import { selectPlacementTest } from '@selectors/PlacementTestSelectors';

const ContentWrapper = styled.div`
  left: ${NAVIGATION_PANEL_WIDTH};
  margin-top: 10.5rem;
  padding: 0 6rem 5rem 6rem;
  position: relative;
  width: calc(100% - ${NAVIGATION_PANEL_WIDTH});
`;

const PlacementTest = () => {
  const dispatch = useAppDispatch();
  const { courseId } = useParams<{ courseId: string }>();
  const currentCourse = useAppSelector(selectCourse);
  const placementTest = useAppSelector(selectPlacementTest);

  useEffect(() => {
    dispatch(PlacementTestActionCreators.setInitialPlacementTest());
  }, [dispatch, courseId]);

  useEffect(() => {
    if (
      placementTest.loaded === LoadingStage.loaded &&
      placementTest.entrypointsData.loaded === LoadingStage.notLoaded &&
      courseId
    ) {
      dispatch(PlacementTestActionCreators.getEntrypoints(courseId, placementTest.content.id));
    }
  }, [dispatch, courseId, placementTest]);

  return (
    <ContentWrapper>
      <SearchModal />
      {currentCourse && (
        <Header
          contentName="Placement test"
          learningLanguage={currentCourse.learningLanguage}
          interfaceLanguages={currentCourse.interfaceLanguages}
        />
      )}
      <BreadcrumbsAndOwnershipWrapper>
        <BreadcrumbNavigation breadcrumbItems={[<span>Placement Test</span>]} />
        <ContentOwner
          componentId={courseId}
          contentType={ContentTypes.placementTest}
          dates={placementTest.content.dates}
          owner={placementTest.content.owner}
        />
      </BreadcrumbsAndOwnershipWrapper>
      <PlacementTestData />
    </ContentWrapper>
  );
};

export const PlacementTestContent = withRouter(PlacementTest);
