import { useEffect, useState } from 'react';

import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { useIsPublishAvailable } from '@features/auth/roles';

import { RoleplayCategoryContentType, RoleplayContentType, RoleplayScenarioContentType } from '../types';
import { CommonPublishButton } from '@features/content/header';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsPublishingInProgress } from '@selectors/CoursesSelectors';

import { useRoleplay } from '../useRoleplay';

export const PublishButton = ({
  contentToPublish,
}: {
  contentToPublish: RoleplayContentType | RoleplayCategoryContentType | RoleplayScenarioContentType;
}) => {
  const dispatch = useAppDispatch();
  const [tooltipText, setTooltipText] = useState('');

  const isPublishingInProgress = useAppSelector(selectIsPublishingInProgress);
  const { publish: isPublishAvailable, unpublish: isUnpublishAvailable } = useIsPublishAvailable();

  const { childrenArray, languageId, parentId } = useRoleplay();

  const isContentInvalid = !contentToPublish?.validationStatus?.valid;

  const getTypeName = (type: ContentTypesType) => {
    if (type === ContentTypes.roleplay) {
      return 'roleplay';
    } else if (type === ContentTypes.roleplayCategory) {
      return 'roleplay category';
    } else if (type === ContentTypes.roleplayScenario) {
      return 'roleplay scenario';
    } else {
      return '';
    }
  };

  const getParentType = (type: ContentTypesType) => {
    if (type === ContentTypes.roleplayCategory) {
      return ContentTypes.roleplay;
    }

    if (type === ContentTypes.roleplayScenario) {
      return ContentTypes.roleplayCategory;
    }

    return;
  };

  const isButtonDisabled = () => {
    if (contentToPublish.ready) {
      return false;
    }

    if (
      contentToPublish.type !== ContentTypes.roleplayScenario &&
      !childrenArray.filter((group) => group.ready).length
    ) {
      return true;
    }

    return isContentInvalid;
  };

  useEffect(() => {
    const getChildName = (type: ContentTypesType) => {
      switch (type) {
        case ContentTypes.roleplay: {
          return ContentTypes.roleplayCategory;
        }
        case ContentTypes.roleplayCategory: {
          return ContentTypes.roleplayScenario;
        }
        default:
          return ContentTypes.roleplayCategory;
      }
    };

    if (contentToPublish?.ready) {
      setTooltipText('');
    } else {
      if (
        contentToPublish.type !== ContentTypes.roleplayScenario &&
        !childrenArray.filter((group) => group.ready).length
      ) {
        setTooltipText(
          `To publish this ${getTypeName(contentToPublish.type)}, it must contain at least 1 published ${getTypeName(
            getChildName(contentToPublish.type),
          )}.`,
        );
      } else {
        setTooltipText('');
      }
    }
  }, [contentToPublish, childrenArray]);

  const handleButtonClick = (forUpdate: boolean) => {
    dispatch(
      PublishingActionsCreator.setContentToPublish(
        contentToPublish.id || '',
        contentToPublish.type,
        contentToPublish.ready,
        forUpdate,
        parentId,
        getParentType(contentToPublish.type),
        languageId,
      ),
    );
  };

  return (
    <CommonPublishButton
      contentType={getTypeName(contentToPublish.type)}
      isButtonDisabled={isButtonDisabled()}
      isPublishAvailable={isPublishAvailable}
      isPublishedContent={contentToPublish?.ready}
      isPublishingInProgress={isPublishingInProgress}
      isUpdateButtonDisabled={isContentInvalid}
      isUpdateVisible={contentToPublish?.ready && contentToPublish?.changeStatus.hasNewChanges}
      isUnpublishVisible={isUnpublishAvailable && contentToPublish?.ready}
      tooltipText={tooltipText}
      onPublishClick={handleButtonClick}
    />
  );
};
