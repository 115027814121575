import type { DBId } from '@common/types/DBId';
import type { ExperimentType } from '@features/experiment';
import { CommonActions } from '@actions/CommonActions';
import { SetExerciseNewLexicalItemPayload } from '@features/content/exercises';

// @TODO ExerciseCommonActionCreators are often imported as ExerciseCommonActions, rename this import
export const ExerciseCommonActionCreators = {
  setImageEnabled: (value: boolean) => ({
    type: CommonActions.SET_IMAGE_ENABLED,
    payload: value,
  }),
  setExperimentValue: (experiment: ExperimentType | null) => ({
    type: CommonActions.SET_EXPERIMENT_VALUE,
    payload: experiment,
  }),
  setImageLoadingStarted: (imageField: string) => ({
    type: CommonActions.IMAGE_UPLOADING_STARTED,
    payload: { imageField },
  }),
  setImageLoadingFinished: (imageField: string) => ({
    type: CommonActions.IMAGE_UPLOADING_FINISHED,
    payload: { imageField },
  }),
  setExerciseNewContentId: (payload: any) => ({
    type: CommonActions.SET_EXERCISE_NEW_CONTENT_ID,
    payload: payload,
  }),
  setExerciseLexicalItem: (payload: SetExerciseNewLexicalItemPayload) => ({
    type: CommonActions.SET_EXERCISE_NEW_LEXICAL_ITEM,
    payload: payload,
  }),
  setCommonNewContentId: (payload: any) => ({
    type: CommonActions.SET_COMMON_NEW_CONTENT_ID,
    payload: payload,
  }),
  setSaveProgress: (payload: any) => ({
    type: CommonActions.SET_IS_SAVE_LOADING,
    payload: payload,
  }),
  toggleVocabulary: (payload: any) => ({
    type: CommonActions.TOGGLE_VOCABULARY,
    payload: payload,
  }),
  setAudioEnabled: (value: boolean) => ({
    type: CommonActions.SET_AUDIO_ENABLED,
    payload: value,
  }),
  setTextEnabled: (value: boolean) => ({
    type: CommonActions.SET_TEXT_ENABLED,
    payload: value,
  }),
  setVideoEnabled: (value: boolean) => ({
    type: CommonActions.SET_VIDEO_ENABLED,
    payload: value,
  }),
  setRecapId: (exerciseId: DBId | null) => ({
    type: CommonActions.SET_RECAP_ID,
    payload: exerciseId,
  }),
  removeBundle: (bundleName: string) => ({
    type: CommonActions.REMOVE_BUNDLE,
    payload: bundleName,
  }),
};
