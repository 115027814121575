import { ContentTypes, ContentType } from '@common/enums/ContentTypes';
import { PublishingQueueItemInterface } from '@common/interfaces/contentTypes/PublishingInterface';
import { apiClient } from '@features/api';
import type { LanguageV2 } from '@features/content/languages';
import { RoleplayService } from '@features/content/roleplay';
import { GrammarReviewService } from '@services/GrammarReviewService';
import { PlacementTestService } from './PlacementTestService';

const PublishingService = {
  async publishContent(
    id: string,
    type: string,
    parentId: string,
    courseId: string,
    parentType?: string,
    language?: LanguageV2,
  ) {
    if (id) {
      switch (type) {
        case ContentTypes.course: {
          return apiClient.noErrorsV2.post(`content/courses/${id}/publish`);
        }

        case ContentTypes.exercise: {
          return apiClient.noErrorsV2.post(`content/exercises/${id}/publish`, { parentId });
        }

        case ContentType.group: {
          return apiClient.noErrorsV2.post(`content/groups/${id}/publish`, { parentId });
        }

        case ContentTypes.grammarReview: {
          return GrammarReviewService.publishReview(courseId);
        }

        case ContentTypes.grammarCategory: {
          return GrammarReviewService.publishCategory(courseId, id, parentId);
        }

        case ContentTypes.grammarTopic: {
          return GrammarReviewService.publishTopic(courseId, id, parentId);
        }

        case ContentTypes.placementTest: {
          return PlacementTestService.publishPlacementTest(courseId);
        }
        case ContentTypes.entrypoint: {
          return PlacementTestService.publishEntrypoint(courseId, id, parentId);
        }

        case ContentTypes.roleplay: {
          return RoleplayService.publishRoleplay(language as LanguageV2);
        }

        case ContentTypes.roleplayCategory: {
          return RoleplayService.publishCategory(id, parentId);
        }

        case ContentTypes.roleplayScenario: {
          return RoleplayService.publishScenario(id, parentId);
        }

        default: {
          throw new TypeError(`${type} not supported yet`);
        }
      }
    } else {
      throw new TypeError('Publish: Id is not provided');
    }
  },
  async unpublishContent(
    id: string,
    type: string,
    parentId: string,
    courseId: string,
    parentType?: string,
    language?: LanguageV2,
  ) {
    if (id) {
      switch (type) {
        case ContentTypes.course: {
          return apiClient.v2.post(`content/courses/${id}/unpublish`);
        }

        case ContentTypes.exercise: {
          return apiClient.v2.post(`content/exercises/${id}/unpublish`, { parentId, parentType });
        }

        case ContentType.group: {
          return apiClient.v2.post(`content/groups/${id}/unpublish`, { parentId });
        }

        case ContentTypes.grammarReview: {
          return GrammarReviewService.unpublishReview(courseId);
        }

        case ContentTypes.grammarCategory: {
          return GrammarReviewService.unpublishCategory(courseId, id, parentId);
        }

        case ContentTypes.grammarTopic: {
          return GrammarReviewService.unpublishTopic(courseId, id, parentId);
        }

        case ContentTypes.placementTest: {
          return PlacementTestService.unpublishPlacementTest(courseId);
        }

        case ContentTypes.entrypoint: {
          return PlacementTestService.unpublishEntrypoint(courseId, id, parentId);
        }

        case ContentTypes.roleplay: {
          return RoleplayService.unpublishRoleplay(language as LanguageV2);
        }

        case ContentTypes.roleplayCategory: {
          return RoleplayService.unpublishCategory(id, parentId);
        }

        case ContentTypes.roleplayScenario: {
          return RoleplayService.unpublishScenario(id, parentId);
        }

        default: {
          throw new TypeError(`${type} not supported yet`);
        }
      }
    } else {
      throw new TypeError('Unpublish: Id is not provided');
    }
  },
  async getQueue(page: number, pageSize: number) {
    const { data } = await apiClient.v2.get('/publication/pending-changes', {
      params: { limit: pageSize + 1, offset: (page - 1) * pageSize },
    });
    const changes: PublishingQueueItemInterface[] = data.changes;
    const hasNextPage = changes.length > pageSize;
    const items = hasNextPage ? changes.slice(0, pageSize) : changes;
    return { hasNextPage, items };
  },
  async isDeploymentFinished() {
    const { data } = await apiClient.v2.get('/deployments/latest');
    return data.deployment.status === 'finished';
  },
  async startDeployment(commitId: string) {
    const { data } = await apiClient.v2.post(`/deployments`, null, { params: { commitId } });
    return data;
  },
};

export default PublishingService;
