import { SimpleLanguageSelector } from '@components/SelectorCommonComponents';
import type { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';

import type { StateConfig } from '../Common/useFiltersState';
import type { ActivityQueryParamsType } from '../../service/types';
import { FiltersContainer } from '../Common/SearchHeader/FiltersContainer';
import {
  FilterField,
  SimpleCourseSelector,
  SimpleRangeSelector,
  SimpleTypeSelector,
  SimpleYesNoSelector,
} from '../Common';
import { ACTIVITIES, ACTIVITY_TITLES } from '@features/content/activities';
import { EXERCISE_TITLES, EXERCISES } from '@common/enums/ExerciseTypes';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { ContentTypes } from '@common/enums/ContentTypes';

let activityTypes = ACTIVITIES;
let exerciseTypes = EXERCISES;

if (!isFeatureEnabled('speakingBites')) {
  activityTypes = activityTypes.filter((type) => type !== ContentTypes.speakingActivity);
  exerciseTypes = exerciseTypes.filter((type) => type !== ContentTypes.listenRepeat);
}

type ActivitySearchFiltersProps = {
  filtersState: StateConfig<ActivityQueryParamsType>;
  availableCourses: CourseInfoInterface[];
  onSaveFilters?: (name: string) => Promise<void>;
};
export const ActivitySearchFilters = ({
  filtersState,
  availableCourses,
  onSaveFilters,
}: ActivitySearchFiltersProps) => {
  const filters = [
    <FilterField
      state={filtersState}
      name="languages"
      render={(value, onChange) => <SimpleLanguageSelector value={value} onChange={onChange} />}
    />,
    <FilterField
      state={filtersState}
      name="courses"
      render={(value, onChange) => (
        <SimpleCourseSelector
          courses={availableCourses}
          courseFilter={(course) =>
            filtersState.state.languages === null || filtersState.state.languages.includes(course.learningLanguage)
          }
          value={value}
          onChange={onChange}
        />
      )}
    />,
    <FilterField
      state={filtersState}
      name="activityTypes"
      render={(value, onChange) => (
        <SimpleTypeSelector
          types={activityTypes}
          labelGenerator={(type) => ACTIVITY_TITLES[type]}
          value={value}
          onChange={onChange}
        />
      )}
    />,
  ];
  const secondaryFilters = [
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isReused"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Is Reused" rule="Is Reused" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Is Reused',
      onRemove: () => filtersState.resetField('isReused'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="exerciseTypes"
          render={(value, onChange) => (
            <SimpleTypeSelector
              types={exerciseTypes}
              labelGenerator={(type) => EXERCISE_TITLES[type]}
              rule="Exercise Type"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Exercise Type',
      onRemove: () => filtersState.resetField('exerciseTypes'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="numExercises"
          render={(value, onChange) => (
            <SimpleRangeSelector rule="Num. exercises" maxLimit={50} value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Number of exercises',
      onRemove: () => filtersState.resetField('numExercises'),
    },
  ];

  return (
    <FiltersContainer
      filters={filters.filter((filter) => Boolean(filter)) as NonNullable<(typeof filters)[number]>[]}
      secondaryFilters={
        (secondaryFilters || []).filter((filter) => Boolean(filter)) as NonNullable<(typeof secondaryFilters)[number]>[]
      }
      emptyFilters={filtersState.isEmpty}
      onSaveFilters={onSaveFilters}
    />
  );
};
