import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { DBId } from '@common/types/DBId';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import BidimensionalEditorUtils from '@components/BidimensionalEditor/BidimensionalEditorUtils';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { findLocalizationInSearchedLanguage } from '@features/content';
import { updateTextField } from '@helpers/updateTextField';
import { brTag } from '@helpers/htmlTagsHelper';
import { BidimensionalEditorActions } from '@actions/BidimensionalEditorActions';
import { TipExerciseActions } from '@actions/TipExerciseActions';
import TipExerciseInterface from '@components/Exercises/Tip/interfaces/TipExerciseInterface';
import TipExerciseContent from '@components/Exercises/Tip/interfaces/TipExerciseContent';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import type { LanguageV2 } from '@features/content/languages';
import { BidimensionalEditorActionsCreator } from '@actionCreators/BidimensionalEditorActionsCreator';

const TipExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_TIP_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: TipExerciseInterface = payload;

    ExerciseDataModelAssembler.fillAbsentLocalizations(content.paragraph.textLocalizations);

    if (content.examples?.[0].length === 0) {
      content.examples[0] = [
        ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
      ];
    } else {
      content.examples = content.examples.map((example) => {
        if (example?.length) {
          return example.map((exampleItem) => {
            const newTextLocalizations =
              exampleItem?.textLocalizations && exampleItem.textLocalizations.length
                ? ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                    ['textLocalizations'],
                    exampleItem?.textLocalizations,
                  )
                : ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations']);
            const newAudioLocalizations =
              exampleItem?.audioLocalizations && exampleItem.audioLocalizations.length
                ? ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                    ['audioLocalizations'],
                    exampleItem?.audioLocalizations,
                  )
                : ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['audioLocalizations']);

            return {
              ...exampleItem,
              ...newTextLocalizations,
              ...newAudioLocalizations,
            };
          });
        } else {
          return [ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations()];
        }
      });
    }

    let exercise: TipExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.tip,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [TipExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckbox('instructionsLanguage', state, payload);
  },
  [TipExerciseActions.SET_PARAGRAPH_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckboxWithoutAffectingSaveButton('paragraphLanguage', state, payload);
  },
  [TipExerciseActions.SET_EXAMPLES_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckbox('examplesLanguage', state, payload);
  },
  [TipExerciseActions.SET_EXAMPLES_CHANGED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    let content = clonedCourseSlice.loadedExercise.exercise.content as TipExerciseContent;

    content.examplesChanged = true;

    if (payload.removeExamples) {
      // @ts-ignore
      content.examples = null;
    }

    let exercise: TipExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.tip,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        ...clonedCourseSlice.loadedExercise,
        exercise,
      },
    };
  },
  [TipExerciseActions.SAVE_SUCCEEDED]: (state: CourseSliceInterface) => {
    state.loadedExercise.exercise.content.paragraphChanged = false;
    state.loadedExercise.exercise.content.examplesChanged = false;

    return state;
  },
  [TipExerciseActions.SET_TIP_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'paragraph');

    return clonedCourseSlice;
  },
  [BidimensionalEditorActions.ADD_ROW]: (
    state: CourseSliceInterface,
    { payload }: ReturnType<typeof BidimensionalEditorActionsCreator.addRow>,
  ) => {
    let { branch, insertAt } = payload;
    let newRow = [];

    let cells = clone(
      (state.loadedExercise.exercise as TipExerciseInterface).content[branch],
    ) as TranslationsPanelContentInterface[][];

    for (let column = 0; column < cells[0].length; column++) {
      let translationsPanelContentPopulatedWithEmptyLocalizations: TranslationsPanelContentInterface =
        ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations('Tip example.');

      newRow.push(translationsPanelContentPopulatedWithEmptyLocalizations);
    }

    if (insertAt !== undefined) {
      cells.splice(insertAt, 0, newRow);
    } else {
      cells[cells.length] = newRow;
    }

    (state.loadedExercise.exercise as TipExerciseInterface).content[branch] = cells as any;

    return state;
  },
  [BidimensionalEditorActions.REMOVE_COLUMN]: (
    state: CourseSliceInterface,
    { payload }: ReturnType<typeof BidimensionalEditorActionsCreator.removeColumn>,
  ) => {
    let { columnToRemove, branch } = payload;

    (state.loadedExercise.exercise as TipExerciseInterface).content[branch] =
      BidimensionalEditorUtils.removeColumnFromBidimensional(
        clone((state.loadedExercise.exercise as TipExerciseInterface).content[branch]),
        columnToRemove,
      );
    if (branch === 'examples') {
      state.loadedExercise.exercise.content.examplesChanged = true;
    }

    return state;
  },

  [BidimensionalEditorActions.REMOVE_ROW]: (
    state: CourseSliceInterface,
    { payload }: ReturnType<typeof BidimensionalEditorActionsCreator.removeRow>,
  ) => {
    let { rowToRemove, branch } = payload;

    let cells = clone(
      (state.loadedExercise.exercise as TipExerciseInterface).content[branch],
    ) as TranslationsPanelContentInterface[][];

    let rows = cells.length;
    let outputCells: any = [];

    for (let row = 0; row < rows; row++) {
      if (row !== rowToRemove) {
        outputCells.push(cells[row]);
      }
    }

    (state.loadedExercise.exercise as TipExerciseInterface).content[branch] = outputCells;
    if (branch === 'examples') {
      state.loadedExercise.exercise.content.examplesChanged = true;
    }

    return state;
  },
  [BidimensionalEditorActions.UPDATE_REDUX_STORE_CELL]: (
    state: CourseSliceInterface,
    { payload }: ReturnType<typeof BidimensionalEditorActionsCreator.updateReduxStoreCell>,
  ): any => {
    let {
      newText,
      storeRow,
      storeColumn,
      storeBranch,
      localizationId,
      bundleName,
      language,
    }: {
      newText: string;
      storeRow: number;
      storeColumn: number;
      storeBranch: string;
      localizationId: DBId | undefined;
      bundleName?: string;
      language: string;
    } = payload;
    let clonedState = clone(state);

    let loadedExercise: AnyLoadedExercise = clonedState?.loadedExercise as AnyLoadedExercise;
    let loadedContent = clonedState?.selectedGroupsOfParent.parentContents;

    let translationsPanelContent: TranslationsPanelContentInterface | TranslationsPanelContentInterface[][] = bundleName
      ? ((clonedState.loadedExercise.exercise as TipExerciseInterface).content as any)[bundleName][storeBranch]
      : storeBranch === 'completeMessage'
        ? (loadedContent as any)[storeBranch]
        : ((clonedState.loadedExercise.exercise as TipExerciseInterface).content as any)[storeBranch];

    let updatedLocalization;

    if (storeRow !== undefined && storeColumn !== undefined) {
      translationsPanelContent = translationsPanelContent as TranslationsPanelContentInterface[][];

      if (localizationId) {
        updatedLocalization = translationsPanelContent[storeRow][storeColumn].textLocalizations.find(
          (localization) => localization._id === localizationId,
        );
      } else {
        updatedLocalization = findLocalizationInSearchedLanguage(
          translationsPanelContent[storeRow][storeColumn].textLocalizations,
          language as LanguageV2,
        );
      }
      translationsPanelContent[storeRow][storeColumn].changed = true;
    } else {
      translationsPanelContent = translationsPanelContent as TranslationsPanelContentInterface;

      if (localizationId) {
        updatedLocalization = translationsPanelContent.textLocalizations.find(
          (localization) => localization._id === localizationId,
        );
      } else {
        updatedLocalization = findLocalizationInSearchedLanguage(
          translationsPanelContent.textLocalizations,
          language as LanguageV2,
        );
      }
    }
    if (bundleName) {
      (loadedExercise.exercise as any).content[bundleName][`${storeBranch}Changed`] = true;
    } else {
      if (storeBranch === 'completeMessage') {
        (loadedContent as any)[`${storeBranch}Changed`] = true;
      } else {
        (loadedExercise.exercise as any).content[`${storeBranch}Changed`] = true;
      }
    }

    if (updatedLocalization) {
      updatedLocalization.value = newText === brTag ? '' : newText;
    }

    return clonedState;
  },
  [BidimensionalEditorActions.ADD_COLUMN]: (
    state: CourseSliceInterface,
    { payload }: ReturnType<typeof BidimensionalEditorActionsCreator.addColumn>,
  ) => {
    let { branch, insertAt } = payload;

    let cells = clone(
      (state.loadedExercise.exercise as TipExerciseInterface).content[branch],
    ) as TranslationsPanelContentInterface[][];

    for (let row = 0; row < cells.length; row++) {
      let translationsPanelContentPopulatedWithEmptyLocalizations: TranslationsPanelContentInterface =
        ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations('Tip example.');
      if (insertAt !== undefined) {
        cells[row].splice(insertAt, 0, translationsPanelContentPopulatedWithEmptyLocalizations);
      } else {
        cells[row].push(translationsPanelContentPopulatedWithEmptyLocalizations);
      }
    }

    (state.loadedExercise.exercise as TipExerciseInterface).content[branch] = cells as any;

    return state;
  },
};

export default TipExerciseCourseReducers;
