import { useCallback } from 'react';
import classnames from 'classnames';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { Flag, FlagTextPositionMode } from '@components/Flag';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { AudioUpload } from '@components/MediaUpload';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { selectGrammarTranslationsPanel } from '@selectors/UiSelectors';

export type CommonAudioProps = {
  textLocalizations: LocalizationInterface[];
  validationErrors?: ValidationErrorInterface[];
};

type AudioInstanceProps = CommonAudioProps & {
  localization: LocalizationInterface;
  idx: number;
};

export const AudioInstance = ({ validationErrors, localization, idx, textLocalizations }: AudioInstanceProps) => {
  const dispatch = useAppDispatch();

  const translations = useAppSelector(selectGrammarTranslationsPanel);

  const { visitedContentId: contentId, fieldName, isEditBlocked, type, isEditDisabled } = translations;

  const validationError = validationErrors
    ?.filter((error) => error.message.includes('audio'))
    .find((error) => error.message.includes(localization.language));

  const requestOrCancelAudioPayload = {
    field: fieldName,
    language: localization.language,
    type: type || '',
  };

  const onProcessingFinished = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: type || '',
          field: fieldName,
          language: localization.language,
        }),
      );
    },
    [dispatch, type, fieldName, localization.language],
  );

  return (
    <li key={idx} className="translations-panel-modal__audio" style={{ height: 'unset' }}>
      <div
        className={classnames(
          'translations-panel-modal__translation-data',
          'translations-panel-modal__translation-data--for-audio',
          {
            'translations-panel-modal__translation-data--with-error': validationError,
          },
        )}
      >
        <Flag mode={FlagTextPositionMode.before} countries={localization.language} />

        <AudioUpload
          fullScreen
          audioData={{
            mediaValue: localization?.value,
            mediaId: localization?._id || '',
            contentId: contentId || '',
            processed: localization?.processed || false,
            phraseText: textLocalizations.find((loc) => loc.language === localization.language)?.value,
            audioRequest: localization?.audioRequest,
          }}
          onAudioRequestSuccess={(audioRequestId: string) =>
            dispatch(
              CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                ...requestOrCancelAudioPayload,
                audioRequestData: {
                  id: audioRequestId,
                  status: 'new',
                },
              }),
            )
          }
          onCancelAudioRequestSuccess={() => {
            dispatch(CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload));
          }}
          onProcessingFinished={onProcessingFinished}
          disabled={isEditDisabled}
          onChange={(uploadedMedia, progressHandler) => {
            dispatch(GrammarReviewActionCreators.uploadAudio(localization, uploadedMedia, progressHandler));
          }}
          onRemove={() => {
            dispatch(GrammarReviewActionCreators.removeAudio(localization, fieldName, type));
          }}
          onChangeInstant={() =>
            dispatch(TranslationTipActionsCreator.setCurrentContentId(contentId, type || '', fieldName))
          }
          isChangeBlocked={isEditBlocked}
          errors={validationError ? [validationError] : undefined}
          fieldName={fieldName}
          currentLanguage={localization.language}
        />
      </div>
      <ValidationErrorDisplayer text={validationError?.message} />
    </li>
  );
};
