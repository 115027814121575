/* @TODO Move to @features/content/_utils/localization */

import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { brTag, removeBrTag } from '@helpers/htmlTagsHelper';
import { FormikValueInterface } from '@helpers/formikInitialValuesHelper';
import { type LanguageV2, DEFAULT_LANGUAGE_V2 } from '@features/content/languages';

import {} from './constants';

export const openKTag = '[k]';
export const closedKTag = '[/k]';

export const getValueByLanguage = (
  localizedContent: (LocalizationInterface | FormikValueInterface)[] | undefined,
  language?: string,
): string => {
  if (localizedContent && localizedContent.length) {
    if (language) {
      return (
        localizedContent
          .find((localization) => localization.language === language)
          // Need to replace '\n' to brTag to display string the same like for clients
          ?.value?.replace(/\n/g, brTag)
          ?.replace(/&amp;/gi, '&') || ''
      );
    }

    return (
      localizedContent
        .find((localization) => localization.language === DEFAULT_LANGUAGE_V2)
        // Need to replace '\n' to brTag to display string the same like for clients
        ?.value?.replace(/\n/g, brTag)
        ?.replace(/&amp;/gi, '&') || ''
    );
  }
  return '';
};

export const getIdByLanguage = (localizedContent: LocalizationInterface[] | undefined, language?: string): string => {
  if (localizedContent && localizedContent.length) {
    if (language) {
      return (
        localizedContent.find((localization: LocalizationInterface) => localization.language === language)?._id || ''
      );
    }
    return (
      localizedContent.find((localization: LocalizationInterface) => localization.language === DEFAULT_LANGUAGE_V2)
        ?._id || ''
    );
  }
  return '';
};

export const getProcessedByLanguage = (localizedContent?: LocalizationInterface[], language?: string): boolean => {
  if (localizedContent && localizedContent.length) {
    if (language) {
      return localizedContent.find((localization) => localization.language === language)?.processed || false;
    }
    return localizedContent.find((localization) => localization.language === DEFAULT_LANGUAGE_V2)?.processed || false;
  }
  return false;
};

export const getCorrectPhrase = (phrase: string): string => {
  if (phrase && phrase.length) {
    return phrase
      .replaceAll(openKTag, '')
      .replaceAll(closedKTag, '')
      .replaceAll(openKTag.toUpperCase(), '')
      .replaceAll(closedKTag.toUpperCase(), '');
  }
  return '';
};

export const findLocalizationInSearchedLanguage = (
  localizations: LocalizationInterface[],
  language: LanguageV2,
): LocalizationInterface | undefined => {
  return (localizations || []).find((localization) => {
    return localization.language === language;
  });
};

export const createGhostLocalization = (language: LanguageV2): LocalizationInterface => {
  return {
    _id: '',
    language,
    value: '',
    processed: true,
  };
};

export const isLocalizationNeedsToBeCounted = (
  localization: LocalizationInterface,
  interfaceLanguages: LanguageV2[],
  learningLanguage: LanguageV2,
): boolean => {
  const allCountedLanguages = new Set<LanguageV2>([...interfaceLanguages, learningLanguage, DEFAULT_LANGUAGE_V2]);

  return allCountedLanguages.has(localization.language as LanguageV2);
};

export const isLocalizationFilled = (localization: LocalizationInterface): boolean => {
  return Boolean(removeBrTag(localization.value));
};

export const formatCounterOfFilledLocalizations = (
  localizations: LocalizationInterface[],
  interfaceLanguages: LanguageV2[],
  learningLanguage: LanguageV2,
): string => {
  const allRequiredLanguages = new Set<LanguageV2>([...interfaceLanguages, learningLanguage, DEFAULT_LANGUAGE_V2]);

  const numberOfFilledLocalizations = localizations.filter(
    (localization: LocalizationInterface) =>
      isLocalizationFilled(localization) &&
      isLocalizationNeedsToBeCounted(localization, interfaceLanguages, learningLanguage),
  ).length;

  return `${numberOfFilledLocalizations}/${allRequiredLanguages.size}`;
};
