import styled from 'styled-components';

import { ALL_LANGUAGES_V2 } from '@features/content/languages';
import { ReactComponent as TranslationIcon } from './img/translation.svg';
import { ReactComponent as AudioIcon } from './img/audio.svg';

const TranslationsWrapper = styled.div`
  display: flex;
  font-size: 1.2rem;
  gap: 0.8rem;
  color: ${({ theme }) => theme.color.deepBlue65};
`;

const AddedTranslation = styled.span`
  font-weight: 800;
`;

type CardTranslationProps = {
  className?: string;
  translations: number;
  type: 'audio' | 'text';
};

export const CardTranslation = ({ className, translations, type }: CardTranslationProps) => (
  <TranslationsWrapper className={className}>
    {type === 'audio' ? <AudioIcon /> : <TranslationIcon />}
    <div>
      <AddedTranslation>{translations}</AddedTranslation>/{ALL_LANGUAGES_V2.length}
    </div>
  </TranslationsWrapper>
);
