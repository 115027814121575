import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { CardPendingStatus } from '@components/ContentTypes/BaseContent/CardPendingStatus';
import {
  type ActivityType,
  ActivityTypeIcon,
  ACTIVITY_DIALOGUE,
  ACTIVITY_SPEAKING,
  ACTIVITY_TITLES,
} from '@features/content/activities';
import { ActivityTypeSelector } from './ActivityTypeSelector';
import { type LanguageV2, LANGUAGE_NAMES_V2 } from '@features/content/languages';
import { CardValidationMark } from '@components/ContentTypes/BaseContent/CardValidationMark';
import { isLegacyActivity } from '@features/content/activities';

import { Data, Title, Description } from '../card.styles';
import { HelpDisplayer } from '@features/help';
import { useAppSelector } from '@redux/store';
import { selectSelectedGroupsOfParentParentContents } from '@selectors/CoursesSelectors';

type ActivityCardDataProps = {
  content: ActivityInterface;
  activityType: ActivityType;
  instructions: string;
  language: LanguageV2;
};

export const ActivityCardData = ({ content, activityType, instructions, language }: ActivityCardDataProps) => {
  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents);

  const isChildInvalid = Object.values(loadedContent?.validation?.invalidChildEntities || {})?.includes(content.id);

  return (
    <>
      {activityType !== undefined && <ActivityTypeIcon type={activityType} isLegacy={isLegacyActivity(content)} />}
      <Data>
        <CardPendingStatus content={content} />

        <Title>
          {isLegacyActivity(content) && <span>Logos doesn't display autogenerated exercises.</span>}
          {activityType === ACTIVITY_DIALOGUE || activityType === ACTIVITY_SPEAKING ? (
            <span>{ACTIVITY_TITLES[activityType]}</span>
          ) : (
            <>
              <ActivityTypeSelector activityType={activityType} id={content?.id} />
              <HelpDisplayer type="guideline" id="guideline-activity-type" />
            </>
          )}
          <CardValidationMark isInvalid={isChildInvalid} />
        </Title>
        <Description>{instructions?.replace('[COURSE LANGUAGE]', LANGUAGE_NAMES_V2[language])}</Description>
      </Data>
    </>
  );
};
