import * as Sentry from '@sentry/react';
import { Extras } from '@sentry/types';
import { CanceledError } from 'axios';
import { useRef } from 'react';

import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { DBId } from '@common/types/DBId';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useToast } from '@features/app/toast';
import { getStringCount } from '@features/search';
import { LanguageV2 } from '@features/content/languages';
import { MatchLexicalItemCreatorOption, VocabularyReviewService } from '@features/content/vocabularyReview';
import { useAppDispatch } from '@redux/store';

import type { MatchUnmatchAction, MatchUnmatchFunctionParams, OnMatchCallback } from './types';
import { updateValuesWithNewLexicalItemId } from './_util/updateValuesWithNewLexicalItemId';

type UseLexicalItemsMatchedParams = {
  language: LanguageV2;
  exerciseId: DBId;
};

export const useLexicalItemsMatched = ({ language, exerciseId }: UseLexicalItemsMatchedParams) => {
  const controller = useRef<AbortController | undefined>();
  const dispatch = useAppDispatch();
  const showToast = useToast();

  const checkLexicalItemsDuplicates = async (phrase: string) => {
    // set some number > 0 by default to be returned in case of error and then reflect it in the UI
    let countDuplicates = 1;

    controller.current = new AbortController();

    await getStringCount({
      filtersPreset: {},
      language,
      query: phrase,
      signal: (controller.current as AbortController).signal,
      type: ContentTypes.lexicalItem,
    })
      .then((count) => {
        countDuplicates = count;
      })
      .catch((error) => {
        // request cancelation is technically an error so we need this check to handle actual errors
        if (!(error instanceof CanceledError)) {
          Sentry.captureException(error, (scope) => {
            scope.setTag('logosAction', 'checkLexicalItemsDuplicates');
            scope.setExtras({
              exerciseId,
              phrase,
            });

            return scope;
          });

          showToast({
            type: 'error',
            title: 'An error occurred when checking duplicates on Lexical Items',
            description: `Please check manually if ${phrase} might already exists`,
          });
        }
      });

    return countDuplicates;
  };

  const onError = ({
    action,
    details,
    error,
    phrase,
  }: {
    action: MatchUnmatchAction;
    details: Extras;
    error: Error;
    phrase: string;
  }) => {
    const toastTitle = `An error ocurred on ${action}ing Lexical Item ${phrase}`;

    showToast({
      type: 'error',
      title: toastTitle,
    });

    Sentry.captureException(error, (scope) => {
      scope.setTag('logosAction', `Lexical item ${action}`);
      scope.setExtras(details);

      return scope;
    });
  };

  const onSuccess = (action: MatchUnmatchAction, phrase: string) => {
    const toastTitle = `Lexical Item ${phrase} was ${action}ed succesfully`;

    showToast({
      type: 'success',
      title: toastTitle,
    });
  };

  const createAndMatchLexicalItem = ({
    nextCurrentValues,
    phrase,
  }: {
    nextCurrentValues: MatchLexicalItemCreatorOption[];
    phrase: string;
  }) => {
    VocabularyReviewService.createLexicalItem({ language, exerciseId, phrase, dispatch })
      .then((newLexicalItemId) => {
        updateValuesWithNewLexicalItemId(nextCurrentValues, newLexicalItemId as DBId);
        onSuccess('match', phrase);
      })
      .catch((error) => {
        onError({
          action: 'match',
          details: {
            exerciseId,
            phrase,
          },
          error,
          phrase,
        });
      });
  };

  const matchLexicalItem = ({ exerciseId, lexicalItemId, phrase }: MatchUnmatchFunctionParams) => {
    VocabularyReviewService.attachLexicalItemToExercise(lexicalItemId, { exerciseId })
      .then(() => {
        onSuccess('match', phrase);
      })
      .catch((error) => {
        onError({
          action: 'match',
          details: {
            exerciseId,
            lexicalItemId,
          },
          error,
          phrase,
        });
      });
  };

  const unmatchLexicalItem = ({ exerciseId, lexicalItemId, phrase }: MatchUnmatchFunctionParams) => {
    VocabularyReviewService.dettachLexicalItemToExercise(lexicalItemId, { exerciseId })
      .then(() => {
        onSuccess('unmatch', phrase);
      })
      .catch((error) => {
        onError({
          action: 'unmatch',
          details: {
            exerciseId,
            lexicalItemId,
          },
          error,
          phrase,
        });
      });
  };

  const onLexicalItemClick = (lexicalItemId: DBId) => {
    const url = `/vocabulary-review/${language}/lexical-item/${lexicalItemId}`;
    window.open(url, '_blank')?.focus();
  };

  const onSearch = (query = '', onMatchCallback: OnMatchCallback) => {
    dispatch(
      SearchModalActionsCreator.showSearchV2Panel({
        callbacks: {
          onMatch: onMatchCallback,
        },
        filtersPreset: {
          lexicalItem: {
            language,
          },
        },
        forReusing: true,
        predefinedType: 'lexicalItem',
        query,
      }),
    );
  };

  return {
    checkLexicalItemsDuplicates,
    createAndMatchLexicalItem,
    matchLexicalItem,
    onLexicalItemClick,
    onSearch,
    unmatchLexicalItem,
  };
};
