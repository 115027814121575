import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { LoadedHighlighterExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { LocalizationTransformer } from '@common/types/LocalizationTransformer';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { findLocalizationInSearchedLanguage } from '@features/content';
import { updateTextField } from '@helpers/updateTextField';
import { HighlighterExerciseActions } from '@actions/HighlighterExerciseActions';
import { FormikValueInterface, FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import HighlighterExerciseInterface from '@components/Exercises/Highlighter/interfaces/HighlighterExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import type { LanguageV2 } from '@features/content/languages';

function updatePhrase(phraseNumber: number, state: CourseSliceInterface, payload: LocalizationTransformer) {
  let clonedState: CourseSliceInterface = clone(state);
  let loadedExercise: LoadedHighlighterExerciseInterface =
    clonedState?.loadedExercise as LoadedHighlighterExerciseInterface;

  let content = (loadedExercise.exercise as HighlighterExerciseInterface).content;

  if (content.phrases === null) {
    content.phrases = [];
    content.phrases[phraseNumber] =
      ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations();
  }

  if (content.phrases[phraseNumber] === undefined || content.phrases[phraseNumber] === null) {
    content.phrases[phraseNumber] =
      ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations();
  }

  (
    findLocalizationInSearchedLanguage(
      content.phrases[phraseNumber].textLocalizations,
      payload.language as LanguageV2,
    ) as LocalizationInterface
  ).value = payload.value;

  content.phrasesChanged = true;

  return {
    ...clonedState,
    loadedExercise,
  };
}

const updatePhraseValue = (
  state: CourseSliceInterface,
  values: FormikValuesInterface,
  pharaseNumber: number,
  fieldName: string,
) => {
  if (
    (values as any)[`${fieldName}Changed`] &&
    state.loadedExercise.exercise.content.phrases[pharaseNumber]?.textLocalizations
  ) {
    state.loadedExercise.exercise.content.phrases[pharaseNumber].textLocalizations =
      state.loadedExercise.exercise.content.phrases[pharaseNumber]?.textLocalizations.map(
        (loc: LocalizationInterface) => {
          return {
            ...loc,
            value: (values as any)[fieldName]
              .find((value: FormikValueInterface) => value.language === loc.language && !value.isPhonetic)
              .value.replaceAll('&nbsp;', ' '),
            phoneticValue:
              (values as any)[fieldName].find(
                (value: FormikValueInterface) => value.language === loc.language && value.isPhonetic,
              ).value || '',
          };
        },
      );
  }
  return state;
};

const HighlighterExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_HIGHLIGHTER_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: HighlighterExerciseInterface = payload;
    if (content.phrases) {
      if (content.phrases.length === 0) {
        content.phrases = [
          ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
        ];
      } else {
        content.phrases = content.phrases.map((phrase) => {
          if (phrase) {
            const newTextLocalizations =
              phrase?.textLocalizations && phrase.textLocalizations.length
                ? ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                    ['textLocalizations'],
                    phrase?.textLocalizations,
                  )
                : ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations']);
            const newAudioLocalizations =
              phrase?.audioLocalizations && phrase.audioLocalizations.length
                ? ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                    ['audioLocalizations'],
                    phrase?.audioLocalizations,
                  )
                : ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['audioLocalizations']);

            return {
              ...phrase,
              ...newTextLocalizations,
              ...newAudioLocalizations,
            };
          } else {
            return ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations();
          }
        });
      }
    }
    let exercise: HighlighterExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.highlighter,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [HighlighterExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedHighlighterExerciseInterface =
      clonedState?.loadedExercise as LoadedHighlighterExerciseInterface;

    if ((loadedExercise.exercise as HighlighterExerciseInterface).content !== undefined) {
      (loadedExercise.exercise as HighlighterExerciseInterface).content.instructionsLanguage =
        payload.instructionsLanguage;
    }

    (loadedExercise.exercise as HighlighterExerciseInterface).content.instructionsLanguageChanged = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [HighlighterExerciseActions.SET_PHRASE0_TEXT]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return updatePhrase(0, state, payload);
  },
  [HighlighterExerciseActions.SET_PHRASE1_TEXT]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return updatePhrase(1, state, payload);
  },
  [HighlighterExerciseActions.SET_PHRASE2_TEXT]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return updatePhrase(2, state, payload);
  },
  [HighlighterExerciseActions.SET_PHRASE3_TEXT]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return updatePhrase(3, state, payload);
  },
  [HighlighterExerciseActions.SET_HIGHLIGHTER_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'feedback');
    clonedCourseSlice = updatePhraseValue(clonedCourseSlice, values, 0, 'phrase0');
    clonedCourseSlice = updatePhraseValue(clonedCourseSlice, values, 1, 'phrase1');
    clonedCourseSlice = updatePhraseValue(clonedCourseSlice, values, 2, 'phrase2');
    clonedCourseSlice = updatePhraseValue(clonedCourseSlice, values, 3, 'phrase3');

    return clonedCourseSlice;
  },
};

export default HighlighterExerciseCourseReducers;
