import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { UploadTranslationActionsCreator } from '@actionCreators/UploadTranslationActionsCreator';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { BaseContentActionsCreator } from '@actionCreators/BaseContentActionsCreator';
import { UploadImageToExerciseAvailableSections } from '@sagas/courses/definitions/CourseSagasDefinition';
import { ComprehensionExerciseActions } from '@actions/ComprehensionExerciseActions';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { SlidePptxExerciseActions } from '@actions/SlidePptxExerciseActions';
import { SlidePdfExerciseActions } from '@actions/SlidePdfExerciseActions';
import { AnyExerciseContentBranch } from '@common/types/AnyExerciseContentBranch';
import { UploadTranslationActions } from '@actions/UploadTranslationActions';
import { FillGapExerciseActions } from '@actions/FillGapExerciseActions';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { UploadAudioResponse, UploadMediaResponse, UploadSlideResponse } from '@services/HelpersService';
import { CommonActions } from '@actions/CommonActions';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import ContentsService from '@services/ContentsService';
import GroupsService from '@services/GroupsService';
import { MediaTypes } from '@common/enums/MediaTypes';
import MediaService from '@services/MediaService';
import { FlashcardExerciseActions } from '@actions/FlashcardExerciseActions';
import { MultipleChoiceExerciseActions } from '@actions/MultipleChoiceExerciseActions';
import { ConversationExerciseActions } from '@actions/ConversationExerciseActions';
import { SpeechRecognitionExerciseActions } from '@actions/SpeechRecognitionExerciseActions';
import { apiClient } from '@features/api';
import { AttachUploadedAudiosPayload, UploadAudiosPayload } from '@features/audioManagement';
import { UploadAudiosActions } from '@actions/UploadAudiosActions';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import {
  selectCourse,
  selectLoadedExerciseContent,
  selectSelectedGroupsOfParentParentContents,
} from '@selectors/CoursesSelectors';
import { VideoUploadActionsCreator } from '@actionCreators/VideoUploadActionsCreator';
import { ExerciseCommonActionCreators } from '@actionCreators/ExerciseCommonActionCreator';
import { UploadAudiosActionsCreator } from '@actionCreators/UploadAudiosActionsCreator';
import { ListenRepeatExerciseActions } from '@actions/ListenRepeatExerciseActions';
import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { RoleplayService } from '@features/content/roleplay';
import { RoleplayActions } from '@actions/RoleplayActions';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { VocabularyReviewActionsCreator } from '@actionCreators/VocabularyReviewActionsCreator';
import { ContentTypes } from '@common/enums/ContentTypes';
import { selectLexicalItemContent } from '@selectors/VocabularyReviewSelectors';
import {
  LexicalItemContentBranches,
  LexicalItemFieldWithLocalizationNames,
  VocabularyReviewService,
} from '@features/content/vocabularyReview';
import { VocabularyReviewActions } from '@actions/VocabularyReviewActions';

export interface UploadCommonActions_UPLOAD_FAIL {
  payloadText: string;
  errors: string[];
}

const getImageField = (exerciseType: string, type: string) => {
  let imageField = '';

  switch (exerciseType) {
    case ExerciseTypes.multipleChoice: {
      if (type === MultipleChoiceExerciseActions.UPLOAD_MAIN_CONTENT_IMAGE) {
        imageField = 'answerImage';
      }
      if (type === MultipleChoiceExerciseActions.UPLOAD_ANSWER_IMAGE) {
        imageField = 'answerImage';
      }
      if (type === MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR1_IMAGE) {
        imageField = 'distractor1Image';
      }
      if (type === MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR2_IMAGE) {
        imageField = 'distractor2Image';
      }

      break;
    }

    case ExerciseTypes.conversation: {
      if (type === ConversationExerciseActions.UPLOAD_IMAGE1) {
        imageField = 'image1';
      }

      if (type === ConversationExerciseActions.UPLOAD_IMAGE2) {
        imageField = 'image2';
      }

      if (type === ConversationExerciseActions.UPLOAD_IMAGE3) {
        imageField = 'image3';
      }

      break;
    }
  }

  return imageField;
};

const MediaUploadSagas = {
  *uploadTranslations({ payload: { translationsFile } }: ReturnType<typeof UploadTranslationActionsCreator.upload>) {
    try {
      const uploadResponse: Awaited<ReturnType<typeof MediaService.upload>> = yield call(
        MediaService.upload,
        translationsFile,
      );

      const errors = uploadResponse.data.errors ?? [];

      if (errors.length > 0) {
        yield put({
          type: UploadTranslationActions.UPLOAD_FAIL,
          payload: { payloadText: '', errors },
        });
      } else {
        yield put({
          type: UploadTranslationActions.UPLOAD_SUCCESS,
        });
      }
    } catch (e: any) {
      const payload: UploadCommonActions_UPLOAD_FAIL = {
        payloadText: e.response?.data?.detail || 'Unknown error',
        errors: [],
      };

      yield put({
        type: UploadTranslationActions.UPLOAD_FAIL,
        payload,
      });
    }
  },

  *uploadAudios({ payload: { audioFiles } }: ReturnType<typeof UploadAudiosActionsCreator.upload>) {
    try {
      const files: UploadAudiosPayload['files'] = [];

      for (let index = 0; index < audioFiles.length; index++) {
        const uploadMediaResponse: UploadAudioResponse = yield call(MediaService.uploadAudio, audioFiles[index]);
        const { fileId } = uploadMediaResponse;

        files.push({
          fileId,
          fileName: audioFiles[index].name,
        });
      }

      const attachUploadedAudiosResult: AxiosResponse<AttachUploadedAudiosPayload> = yield call(() =>
        apiClient.noErrorsV2.post('audio-requests/upload-audios', { files }),
      );

      const { results } = attachUploadedAudiosResult.data;
      const notMatchedAudioFiles = results ? results.filter((result) => result.status === 'not-matched') : [];

      yield put({
        type: UploadAudiosActions.UPLOAD_SUCCESS,
        payload: {
          notMatchedAudioFiles,
        },
      });
    } catch (e: any) {
      let payloadText = 'Unknown error';

      if (e.response?.data?.detail) {
        payloadText = e.response?.data?.detail;
      } else if (e.message) {
        payloadText = e.message;
      }

      yield put({
        type: UploadAudiosActions.UPLOAD_FAIL,
        payload: {
          payloadText,
          errors: [],
        },
      });
    }
  },

  *uploadImageToExercise({
    type,
    payload: { exercise, image, exerciseType, section, progressHandler },
  }: {
    type: string;
    payload: {
      exercise: AnyExerciseInterface;
      image: File;
      exerciseType: ExerciseTypesType;
      section: UploadImageToExerciseAvailableSections;
      courseLearningLanguage?: string;
      progressHandler: (progress: number) => void;
    };
  }) {
    try {
      const imageField = getImageField(exerciseType, type);

      const loadedCourse: CourseInterface = yield select(selectCourse);

      yield put({
        type: CommonActions.IMAGE_UPLOADING_STARTED,
        payload: { imageField },
      });

      const uploadMediaResponse: UploadMediaResponse = yield call(
        MediaService.uploadImageToExercise,
        exercise,
        image,
        exerciseType,
        section,
        loadedCourse.id,
        progressHandler,
      );

      let updatedContent = uploadMediaResponse.content;

      const createAction = (
        exerciseType: ExerciseTypesType,
        mediaURL: UploadMediaResponse['mediaURL'],
        type: string,
        updatedContent: TranslationsPanelContentInterface,
      ) => {
        let imageField: AnyExerciseContentBranch | string = '';
        let bundleName: string = '';

        switch (exerciseType) {
          case ExerciseTypes.flashcard: {
            imageField = '';
            bundleName = 'learningWordBundle';
            break;
          }

          case ExerciseTypes.trueFalse: {
            imageField = '';
            bundleName = 'mainBundle';
            break;
          }

          case ExerciseTypes.multipleChoice: {
            if (type === MultipleChoiceExerciseActions.UPLOAD_MAIN_CONTENT_IMAGE) {
              imageField = 'answerImage';
              bundleName = 'answerBundle';
            }
            if (type === MultipleChoiceExerciseActions.UPLOAD_ANSWER_IMAGE) {
              imageField = 'answerImage';
              bundleName = 'answerBundle';
            }
            if (type === MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR1_IMAGE) {
              imageField = 'distractor1Image';
              bundleName = 'distractor1';
            }
            if (type === MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR2_IMAGE) {
              imageField = 'distractor2Image';
              bundleName = 'distractor2';
            }

            break;
          }

          case ExerciseTypes.conversation: {
            if (type === ConversationExerciseActions.UPLOAD_IMAGE1) {
              imageField = 'image1';
            }

            if (type === ConversationExerciseActions.UPLOAD_IMAGE2) {
              imageField = 'image2';
            }

            if (type === ConversationExerciseActions.UPLOAD_IMAGE3) {
              imageField = 'image3';
            }

            break;
          }

          case ExerciseTypes.speechRecognition: {
            imageField = '';
            bundleName = 'mainBundle';

            break;
          }

          case ExerciseTypes.spelling: {
            imageField = '';
            bundleName = 'mainBundle';

            break;
          }

          case ExerciseTypes.typing: {
            imageField = '';
            bundleName = 'mainBundle';

            break;
          }

          case ExerciseTypes.fillgap: {
            imageField = '';
            bundleName = 'mainBundle';

            break;
          }

          case ExerciseTypes.comprehension: {
            if (type === ComprehensionExerciseActions.UPLOAD_READING_IMAGE) {
              imageField = '';
              bundleName = 'mainBundle';
            }

            break;
          }

          case ExerciseTypes.listenRepeat: {
            imageField = '';
            bundleName = 'mainBundle';

            break;
          }

          default: {
            throw new TypeError(`${exerciseType} not supported yet`);
          }
        }

        return {
          type: CommonActions.REFRESH_IMAGE,
          payload: {
            mediaURL,
            imageField,
            bundleName,
            updatedContent,
          },
        };
      };

      yield put(
        createAction(exerciseType, uploadMediaResponse.mediaURL, type, {
          ...updatedContent,
          _id: uploadMediaResponse.mediaId,
        }),
      );
    } catch (e: any) {
      progressHandler(0);
      const imageField = getImageField(exerciseType, type);

      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField,
          details: e?.message,
        },
      });

      console.error(e);
    }
  },

  *uploadPdfToExercise({
    type,
    payload: { pdf },
  }: {
    type: string;
    payload: {
      pdf: File;
    };
  }) {
    try {
      yield put({
        type: CommonActions.IMAGE_UPLOADING_STARTED,
        payload: { imageField: 'pdfUrl' },
      });

      const uploadedPdfUrl: UploadSlideResponse = yield call(MediaService.uploadPdf, pdf);

      if (!uploadedPdfUrl) {
        throw new Error(`URL for file ${pdf.name} was not retrieved`);
      }

      yield put({
        type: SlidePdfExerciseActions.SET_PDF,
        payload: {
          pdfFile: uploadedPdfUrl.url,
          pdfFileId: uploadedPdfUrl.fileId,
        },
      });
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: { imageField: 'pdfUrl' },
      });
    } catch (e: any) {
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField: 'pdfUrl',
          details: e?.message,
        },
      });

      console.error(e);
    }
  },

  *uploadPptxToExercise({
    type,
    payload: { pptx },
  }: {
    type: string;
    payload: {
      pptx: File;
    };
  }) {
    try {
      yield put({
        type: CommonActions.IMAGE_UPLOADING_STARTED,
        payload: { imageField: 'pptxUrl' },
      });

      const uploadedPptxUrl: UploadSlideResponse = yield call(MediaService.uploadPptx, pptx);

      if (!uploadedPptxUrl) {
        throw new Error(`URL for file ${pptx.name} was not retrieved`);
      }

      yield put({
        type: SlidePptxExerciseActions.SET_PPTX,
        payload: {
          pptxFile: uploadedPptxUrl.url,
          pptxFileId: uploadedPptxUrl.fileId,
        },
      });
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: { imageField: 'pptxUrl' },
      });
    } catch (e: any) {
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField: 'pptxUrl',
          details: e?.message,
        },
      });

      console.error(e);
    }
  },
  *uploadImageToLesson({
    payload: { contentId, contentIdBeingUpdated, file, progressHandler },
  }: ReturnType<typeof BaseContentActionsCreator.uploadImageToLesson>) {
    try {
      yield put({
        type: CommonActions.IMAGE_UPLOADING_STARTED,
        payload: { imageField: contentId },
      });

      const uploadMediaResponse: UploadMediaResponse = yield call(
        MediaService.uploadMedia,
        MediaTypes.image,
        file,
        'lesson',
        undefined,
        contentIdBeingUpdated,
        progressHandler,
      );

      if (contentIdBeingUpdated === undefined) {
        contentIdBeingUpdated = uploadMediaResponse.mediaId;
      }

      yield call(GroupsService.lessons.uploadImage, contentId, contentIdBeingUpdated);

      yield put({
        type: ContentTypesActions.LESSON_SET_IMAGE,
        payload: { value: uploadMediaResponse.mediaURL, groupId: contentId, contentId: uploadMediaResponse.mediaId },
      });
    } catch (e: any) {
      progressHandler(0);
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField: contentId,
          details: e?.message,
        },
      });
      console.error(e);
    }
  },
  *uploadUnitImageToLesson({
    payload: { contentId, contentIdBeingUpdated, file, progressHandler },
  }: ReturnType<typeof BaseContentActionsCreator.uploadUnitImageToLesson>) {
    try {
      yield put({
        type: CommonActions.IMAGE_UPLOADING_STARTED,
        payload: { imageField: 'thumbnailImage' },
      });

      const uploadMediaResponse: UploadMediaResponse = yield call(
        MediaService.uploadMedia,
        MediaTypes.image,
        file,
        'lesson',
        undefined,
        contentIdBeingUpdated,
        progressHandler,
      );

      if (contentIdBeingUpdated === undefined) {
        contentIdBeingUpdated = uploadMediaResponse.mediaId;
      }

      yield call(GroupsService.lessons.uploadUnitImage, contentId, contentIdBeingUpdated);

      yield put({
        type: ContentTypesActions.LESSON_SET_UNIT_IMAGE,
        payload: { value: uploadMediaResponse.mediaURL, contentId: uploadMediaResponse.mediaId },
      });
    } catch (e: any) {
      progressHandler(0);
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField: 'thumbnailImage',
          details: e?.message,
        },
      });
      console.error(e);
    }
  },
  *removeImageFromLesson({ payload }: ReturnType<typeof BaseContentActionsCreator.removeImageFromLesson>) {
    yield put({
      type: ContentTypesActions.LESSON_CLEAR_IMAGE_LOCALIZATIONS,
      payload,
    });

    yield call(GroupsService.lessons.uploadImage, payload.contentId, null);
  },
  *removeUnitImageFromLesson({ payload }: ReturnType<typeof BaseContentActionsCreator.removeUnitImageFromLesson>) {
    yield put({
      type: ContentTypesActions.LESSON_CLEAR_UNIT_IMAGE_LOCALIZATIONS,
      payload,
    });

    const parentContents: ReturnType<typeof selectSelectedGroupsOfParentParentContents> = yield select(
      selectSelectedGroupsOfParentParentContents,
    );

    ContentsService.contents.removeImageFromLesson(payload.contentIdBeingUpdated || '', parentContents?.thumbnailImage);

    yield call(GroupsService.lessons.uploadUnitImage, payload.contentId, null);
  },
  *uploadVideoToExercise({ payload }: ReturnType<typeof VideoUploadActionsCreator.uploadVideo>) {
    try {
      yield put(ExerciseCommonActionCreators.setImageLoadingStarted('video'));

      const { exercise, uploadedVideo, visitedBranch, progressHandler } = payload;

      // waiting for backend normalisation https://busuucom.atlassian.net/browse/INTO-690
      const contentIdBeingUpdated =
        (exercise.content[visitedBranch].video as TranslationsPanelContentInterface)?._id ||
        (exercise.content[visitedBranch].video as TranslationsPanelContentInterface)?.id;

      const mergedContentWithVideo: UploadMediaResponse = yield call(
        MediaService.uploadMedia,
        MediaTypes.video,
        uploadedVideo,
        undefined,
        undefined,
        contentIdBeingUpdated,
        progressHandler,
      );

      switch (exercise.type) {
        case ExerciseTypes.comprehension: {
          yield put({
            type: ComprehensionExerciseActions.UPLOAD_VIDEO_SUCCESS,
            payload: mergedContentWithVideo,
          });
          break;
        }
        case ExerciseTypes.flashcard: {
          yield put({
            type: FlashcardExerciseActions.UPLOAD_VIDEO_SUCCESS,
            payload: mergedContentWithVideo,
          });
          break;
        }
        case ExerciseTypes.fillgap: {
          yield put({
            type: FillGapExerciseActions.UPLOAD_VIDEO_SUCCESS,
            payload: mergedContentWithVideo,
          });
          break;
        }
        case ExerciseTypes.listenRepeat: {
          yield put({
            type: ListenRepeatExerciseActions.UPLOAD_VIDEO_SUCCESS,
            payload: mergedContentWithVideo,
          });
          break;
        }
        case ExerciseTypes.speechRecognition: {
          yield put({
            type: SpeechRecognitionExerciseActions.UPLOAD_VIDEO_SUCCESS,
            payload: mergedContentWithVideo,
          });
          break;
        }
      }
    } catch (e: any) {
      payload.progressHandler(0);
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField: 'video',
          details: e?.message,
        },
      });
      console.error(e);
    }
  },
  *uploadSoundToExerciseOrLexicaItem({ payload }: ReturnType<typeof AudioUploadActionsCreator.uploadSound>) {
    const { contentType } = payload;
    const isLexicalItem = contentType === ContentTypes.lexicalItem;

    try {
      const {
        contentId,
        localization,
        uploadedSound,
        visitedBranch,
        row,
        column,
        forLearningLang,
        bundleName,
        progressHandler,
      } = payload;

      const loadedCourse: CourseInterface = yield select(selectCourse);
      const loadedExerciseContent: ReturnType<typeof selectLoadedExerciseContent> =
        yield select(selectLoadedExerciseContent);
      const loadedLexicalItemContent: ReturnType<typeof selectLexicalItemContent> =
        yield select(selectLexicalItemContent);

      if (uploadedSound) {
        yield put({
          type: CommonActions.AUDIO_UPLOADING_STARTED,
          payload: {
            audioField: visitedBranch,
            language:
              forLearningLang && localization && !isLexicalItem
                ? loadedCourse.learningLanguage
                : localization?.language,
            row,
          },
        });
      }

      let contentData;

      if (isLexicalItem) {
        contentData = loadedLexicalItemContent[visitedBranch as LexicalItemContentBranches];
      } else {
        if (visitedBranch === 'script') {
          contentData = (loadedExerciseContent as any)[visitedBranch][row || 0].line;
        } else {
          contentData = bundleName
            ? (loadedExerciseContent as any)[bundleName][visitedBranch]
            : (loadedExerciseContent as any)[visitedBranch];
        }
      }

      const { mediaURL, content, mediaId }: UploadMediaResponse = yield MediaService.uploadMedia(
        MediaTypes.audio,
        uploadedSound,
        undefined,
        undefined,
        contentId,
        progressHandler,
        contentData,
        localization?.language,
      );

      if (isLexicalItem) {
        yield put(
          VocabularyReviewActionsCreator.uploadAudioToLexicalItemSuccedeed({
            content: { ...content, id: mediaId },
            localization,
            mediaURL,
            visitedBranch: visitedBranch as LexicalItemFieldWithLocalizationNames,
          }),
        );
      } else {
        yield put(
          AudioUploadActionsCreator.uploadAudioSucceeded({
            localization,
            visitedBranch,
            row,
            column,
            content: { ...content, id: mediaId },
            mediaURL,
            forLearningLang,
            bundleName,
          }),
        );
      }
    } catch (e: any) {
      const loadedCourse: CourseInterface = yield select(selectCourse);

      let { localization, visitedBranch, row, forLearningLang } = payload;

      yield put({
        type: CommonActions.AUDIO_UPLOADING_FINISHED,
        payload: {
          audioField: visitedBranch,
          language: forLearningLang && !isLexicalItem ? loadedCourse.learningLanguage : localization?.language,
          row,
          details: e?.message,
        },
      });
      console.error(e);
    }
  },
  *uploadImageToScenario({
    payload: { scenarioId, contentIdBeingUpdated, file, progressHandler },
  }: ReturnType<typeof RoleplayActionCreators.uploadImageToScenario>) {
    try {
      yield put({
        type: CommonActions.IMAGE_UPLOADING_STARTED,
        payload: { imageField: 'scenarioImage' },
      });

      const uploadMediaResponse: UploadMediaResponse = yield call(
        MediaService.uploadMedia,
        MediaTypes.image,
        file,
        'lesson', // no specific ImageType for scenario image in BE
        undefined,
        contentIdBeingUpdated,
        progressHandler,
      );

      if (contentIdBeingUpdated === undefined) {
        contentIdBeingUpdated = uploadMediaResponse.mediaId;
      }

      const imageId = uploadMediaResponse.content.imageLocalizations.find(
        (localization) => localization.language === DEFAULT_LANGUAGE_V2,
      )?._id;

      yield call(RoleplayService.saveScenario, scenarioId, { image: contentIdBeingUpdated });

      yield put({
        type: RoleplayActions.SET_SCENARIO_IMAGE,
        payload: { id: imageId, localizationId: contentIdBeingUpdated, value: uploadMediaResponse.mediaURL },
      });
    } catch (error: any) {
      progressHandler(0);

      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField: 'scenarioImage',
          details: error?.message,
        },
      });

      console.error(error);
    }
  },
  *removeImageFromScenario({ payload }: ReturnType<typeof RoleplayActionCreators.removeImageFromScenario>) {
    try {
      yield put({
        type: RoleplayActions.SET_SCENARIO_IMAGE,
        payload: { id: null },
      });

      yield call(RoleplayService.saveScenario, payload, { image: null });
    } catch (error) {
      console.error(error);
    }
  },

  *uploadImageToLexicalItem({
    payload: { lexicalItemId, contentIdBeingUpdated, file, progressHandler },
  }: ReturnType<typeof VocabularyReviewActionsCreator.uploadImageToLexicalItem>) {
    try {
      yield put({
        type: CommonActions.IMAGE_UPLOADING_STARTED,
        payload: { imageField: 'image' },
      });

      const uploadMediaResponse: UploadMediaResponse = yield call(
        MediaService.uploadMedia,
        MediaTypes.image,
        file,
        ContentTypes.exercise, // no specific ImageType for lexical item image in BE
        undefined,
        contentIdBeingUpdated,
        progressHandler,
      );

      if (contentIdBeingUpdated === undefined) {
        contentIdBeingUpdated = uploadMediaResponse.mediaId;
      }

      const imageId = uploadMediaResponse.content.imageLocalizations.find(
        (localization) => localization.language === DEFAULT_LANGUAGE_V2,
      )?._id;

      yield call(VocabularyReviewService.saveLexicalItem, lexicalItemId, { image: contentIdBeingUpdated });

      yield put({
        type: VocabularyReviewActions.SET_IMAGE,
        payload: { id: imageId, localizationId: contentIdBeingUpdated, value: uploadMediaResponse.mediaURL },
      });
    } catch (error: any) {
      progressHandler(0);

      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField: 'image',
          details: error?.message,
        },
      });

      console.error(error);
    }
  },
  *removeImageFromLexicalItem({
    payload,
  }: ReturnType<typeof VocabularyReviewActionsCreator.removeImageFromLexicalItem>) {
    try {
      yield put({
        type: VocabularyReviewActions.SET_IMAGE,
        payload: { id: null },
      });

      yield call(VocabularyReviewService.saveLexicalItem, payload, { image: null });
    } catch (error) {
      console.error(error);
    }
  },
};

export default MediaUploadSagas;
