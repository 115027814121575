import { Flag } from '@components/Flag';
import { LANGUAGE_NAMES_V2, type LanguageV2 } from '@features/content/languages';
import { LanguageSelector } from '@components/SelectorCommonComponents';

export const LanguagesContainer = ({
  languages,
  isEditMode,
  setLanguages,
}: {
  languages: LanguageV2[];
  isEditMode: boolean;
  setLanguages: (languages: LanguageV2[]) => void;
}) => (
  <>
    {isEditMode ? (
      <LanguageSelector
        value={languages.map((language) => ({ value: language, label: LANGUAGE_NAMES_V2[language] }))}
        onChange={(languages) => setLanguages(languages?.map((language) => language.value as LanguageV2) || [])}
      />
    ) : (
      <Flag countries={languages} />
    )}
  </>
);
