import { Character } from '@components/Exercises/Dialogue/types/Character';
import { SearchPanelSliceType } from '@features/search';
import type { TranslationsPanelModesType } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { AnyExerciseContentBranch, CourseContentBranches } from '@common/types/AnyExerciseContentBranch';
import { UploadTranslationsPhases } from '@components/UploadTranslations';
import { GrammarCategoryEditableFieldNames } from '@features/content/grammar';
import { type ContentTypesType } from '@common/enums/ContentTypes';
import { UploadAudiosPhase, AttachUploadedAudiosPayload } from '@features/audioManagement';
import { RoleplayCategoryTranslationsPanelType } from '@features/content/roleplay';
import { LexicalItemTranslationsPanelType } from '@features/content/vocabularyReview';

// @TODO Replace enum-like object with constants and a type
export const DialogueCharactersPanelModes = {
  changeCharacter: 'change character',
  assignCharacters: 'assign characters',
  none: '',
} as const;
export type DialogueCharactersPanelModesType =
  (typeof DialogueCharactersPanelModes)[keyof typeof DialogueCharactersPanelModes];

export type GrammarTranslationsPanelType = {
  visible: boolean;
  preopenedTab: 'translations' | 'audios' | 'other';
  visitedContentId: string;
  fieldName: GrammarCategoryEditableFieldNames;
  isEditDisabled?: boolean;
  isEditBlocked?: boolean;
  type: ContentTypesType;
  defaultContextForTranslators?: string;
};

export interface UISliceInterface {
  panels: {
    searchV2: SearchPanelSliceType;
    dialogueCharacters: {
      visible: boolean;
      characters: Character[];
      title: string;
      MAX_CHARACTERS: number;
      mode: DialogueCharactersPanelModesType;
      characterIndex: number | null;
    };
    translations: {
      visible: boolean;
      visibleForCourse: boolean;
      visibleForLevelAndLesson: boolean;
      preopenedTab: 'translations' | 'audios' | 'other';
      visitedKey: string;
      visitedContentId: string;
      visitedBranch: AnyExerciseContentBranch | CourseContentBranches;
      bundleName: string;
      isStringChangeBlocked?: boolean;
      isBundleChangeBlocked?: boolean;
      translationsPanelTranslationsMode: TranslationsPanelModesType;
      localizationIdUserView: string;
      row: number | undefined;
      column: number | undefined;
      cells: [][] | undefined;
      showOtherLocations: boolean;
      disableLearningLanguage: boolean;
      defaultContextForTranslators?: string;
    };
    grammarTranslations: GrammarTranslationsPanelType;
    roleplayTranslations: RoleplayCategoryTranslationsPanelType;
    lexicalItemTranslations: LexicalItemTranslationsPanelType;
    publishing: {
      visible: boolean;
    };
    uploadAudios: {
      phase: UploadAudiosPhase;
      payloadText?: string;
      errors?: string[];
      notMatchedAudioFiles?: AttachUploadedAudiosPayload['results'];
    };
    uploadTranslations: {
      phase: UploadTranslationsPhases;
      payloadText?: string;
      errors?: string[];
    };
  };
  isPreviewOpened: boolean;
  isPreviewForExercisesListOpened: boolean;
  isIssuesShown: boolean;
}
