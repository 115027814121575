import { LanguageV2 } from '@features/content/languages';
import { VocabularyReviewListItem } from '@features/content/vocabularyReview';

import { LexicalItemResultCard } from './SearchResultItemCard';
import { DBId } from '@common/types/DBId';

type LexicalItemSearchResultItemProps = {
  content: VocabularyReviewListItem;
  language: LanguageV2;
  onClick?: (lexicalItemId: DBId) => void;
  onMatch?: (lexicalItemId: DBId, phrase: string) => void;
};

export const LexicalItemSearchResultItem = ({
  content,
  language,
  onClick,
  onMatch,
}: LexicalItemSearchResultItemProps) => {
  return (
    <>
      <LexicalItemResultCard
        content={content}
        language={language}
        onClick={onClick}
        onMatch={(lexicalItemId, localizedPhrase) => onMatch && onMatch(lexicalItemId, localizedPhrase)}
      />
    </>
  );
};
