import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { updateCourseState } from './CourseLoadCourseCourseReducers';
import { CourseEditionActions } from '@actions/CourseEditionActions';
import { type LanguageV2, AVAILABLE_INTERFACE_LANGUAGES_V2 } from '@features/content/languages';
import { NewCoursePanelActions } from '@actions/NewCoursePanelActions';
import { loadedExerciseInitialData, recapOptionsInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';

const NewCoursePanelActionsCourseCourseReducers = {
  [NewCoursePanelActions.COURSE_CREATE_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return updateCourseState(state, payload.course, true, true);
  },
  [CourseEditionActions.CLEAR_CURRENT_EXERCISE]: (state: CourseSliceInterface): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      loadedExercise: loadedExerciseInitialData,
      mediaUploading: {
        ...clonedState.mediaUploading,
        imageOrVideoUploadingInProcess: [],
        audioUploadingInProcess: [],
      },
      recapOptions: recapOptionsInitialData,
    };
  },
  [NewCoursePanelActions.COURSE_SET_MAIN_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload: { courseLanguage } }: PayloadAction<any>,
  ): CourseSliceInterface => ({
    ...state,
    course: {
      ...state.course,
      learningLanguage: courseLanguage,
    },
  }),
  [NewCoursePanelActions.COURSE_TOGGLE_UI_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload: { toggledInterfaceLanguage } }: PayloadAction<{ toggledInterfaceLanguage: LanguageV2 }>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);
    let interfaceLanguages = clonedState.course.interfaceLanguages;

    if (interfaceLanguages.lastIndexOf(toggledInterfaceLanguage) > -1) {
      interfaceLanguages = interfaceLanguages.filter(
        (interfaceLanguage: string) => interfaceLanguage !== toggledInterfaceLanguage,
      );
    } else {
      interfaceLanguages.push(toggledInterfaceLanguage);
    }

    return {
      ...clonedState,
      course: {
        ...clonedState.course,
        interfaceLanguages,
      },
    };
  },
  [NewCoursePanelActions.SET_ALL_COURSE_UI_LANGUAGES_AS_TOGGLED]: (
    state: CourseSliceInterface,
    { payload: { toggled } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let interfaceLanguages: LanguageV2[] = [];

    if (toggled) {
      interfaceLanguages = AVAILABLE_INTERFACE_LANGUAGES_V2;
    }

    return {
      ...state,
      course: {
        ...state.course,
        interfaceLanguages,
      },
    };
  },
};

export default NewCoursePanelActionsCourseCourseReducers;
