import type { AppDispatch } from '@redux/store';
import type { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import type { LanguageV2 } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { FlashcardExerciseActionsCreator } from '@actionCreators/FlashcardExerciseActionsCreator';
import type FlashcardExerciseInterface from '@components/Exercises/Flashcard/interfaces/FlashCardExerciseInterface';
import { TipExerciseActionsCreator } from '@actionCreators/TipExerciseActionsCreator';
import type TipExerciseInterface from '@components/Exercises/Tip/interfaces/TipExerciseInterface';
import { TrueFalseExerciseActionsCreator } from '@actionCreators/TrueFalseExerciseActionsCreator';
import type TrueFalseExerciseInterface from '@components/Exercises/TrueFalse/interfaces/TrueFalseExerciseInterface';
import { MultipleChoiceExerciseActionsCreator } from '@actionCreators/MultipleChoiceExerciseActionsCreator';
import type MultipleChoiceExerciseInterface from '@components/Exercises/MultipleChoice/interfaces/MultipleChoiceExerciseInterface';
import { ConversationExerciseActionsCreator } from '@actionCreators/ConversationExerciseActionsCreator';
import type ConversationExerciseInterface from '@components/Exercises/Conversation/interfaces/ConversationExerciseInterface';
import { PhraseBuilderExerciseActionsCreator } from '@actionCreators/PhraseBuilderExerciseActionsCreator';
import type PhraseBuilderExerciseInterface from '@components/Exercises/PhraseBuilder/interfaces/PhraseBuilderExerciseInterface';
import { DialogueExerciseActionsCreator } from '@actionCreators/DialogueExerciseActionsCreator';
import type DialogueExerciseInterface from '@components/Exercises/Dialogue/interfaces/DialogueExerciseInterface';
import { SpeechRecognitionExerciseActionsCreator } from '@actionCreators/SpeechRecognitionExerciseActionsCreator';
import type SpeechRecognitionExerciseInterface from '@components/Exercises/SpeechRecognition/interfaces/SpeechRecognitionExerciseInterface';
import { TypingExerciseActionsCreator } from '@actionCreators/TypingExerciseActionsCreator';
import type TypingExerciseInterface from '@components/Exercises/Typing/interfaces/TypingExerciseInterface';
import { SpellingExerciseActionsCreator } from '@actionCreators/SpellingExerciseActionsCreator';
import type SpellingExerciseInterface from '@components/Exercises/Spelling/interfaces/SpellingExerciseInterface';
import { MatchUpExerciseActionsCreator } from '@actionCreators/MatchUpExerciseActionsCreator';
import type MatchUpExerciseInterface from '@components/Exercises/MatchUp/interfaces/MatchUpExerciseInterface';
import { HighlighterExerciseActionsCreator } from '@actionCreators/HighlighterExerciseActionsCreator';
import type HighlighterExerciseInterface from '@components/Exercises/Highlighter/interfaces/HighlighterExerciseInterface';
import { FillGapExerciseActionsCreator } from '@actionCreators/FillGapExerciseActionsCreator';
import type FillGapExerciseInterface from '@components/Exercises/FillGap/interfaces/FillGapExerciseInterface';
import { ComprehensionExerciseActionsCreator } from '@actionCreators/ComprehensionExerciseActionsCreator';
import type ComprehensionExerciseInterface from '@components/Exercises/Comprehension/interfaces/ComprehensionExerciseInterface';
import { SlidePdfExerciseActionsCreator } from '@actionCreators/SlidePdfExerciseActionsCreator';
import type SlidePdfExerciseInterface from '@components/Exercises/SlidePdf/interfaces/SlidePdfExerciseInterface';
import { SlidePptxExerciseActionsCreator } from '@actionCreators/SlidePptxExerciseActionsCreator';
import type SlidePptxExerciseInterface from '@components/Exercises/SlidePptx/interfaces/SlidePptxExerciseInterface';
import ListenRepeatExerciseInterface from '@components/Exercises/ListenRepeat/interfaces/ListenRepeatExerciseInterface';
import { ListenRepeatExerciseActionsCreator } from '@actionCreators/ListenRepeatExerciseActionsCreator';

export const saveContent = async (
  dispatch: AppDispatch,
  loadedExercise: AnyLoadedExercise,
  learningLanguage: LanguageV2,
  values: FormikValuesInterface,
) => {
  if (
    loadedExercise?.exercise === null ||
    loadedExercise?.exercise === undefined ||
    loadedExercise?.exercise.content.id === undefined
  )
    return;

  dispatch(ExerciseCommonActions.setSaveProgress({ value: true }));
  switch (loadedExercise?.exercise.type) {
    case ExerciseTypes.flashcard: {
      dispatch(
        FlashcardExerciseActionsCreator.save(
          loadedExercise?.exercise as FlashcardExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.tip: {
      dispatch(
        TipExerciseActionsCreator.save(loadedExercise?.exercise as TipExerciseInterface, learningLanguage, values),
      );
      break;
    }

    case ExerciseTypes.trueFalse: {
      dispatch(
        TrueFalseExerciseActionsCreator.save(
          loadedExercise?.exercise as TrueFalseExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.multipleChoice: {
      dispatch(
        MultipleChoiceExerciseActionsCreator.save(
          loadedExercise?.exercise as MultipleChoiceExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.conversation: {
      dispatch(
        ConversationExerciseActionsCreator.save(
          loadedExercise?.exercise as ConversationExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.phraseBuilder: {
      dispatch(
        PhraseBuilderExerciseActionsCreator.save(
          loadedExercise?.exercise as PhraseBuilderExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.dialogue: {
      dispatch(
        DialogueExerciseActionsCreator.save(
          loadedExercise?.exercise as DialogueExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.speechRecognition: {
      dispatch(
        SpeechRecognitionExerciseActionsCreator.save(
          loadedExercise?.exercise as SpeechRecognitionExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.typing: {
      dispatch(
        TypingExerciseActionsCreator.save(
          loadedExercise?.exercise as TypingExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.spelling: {
      dispatch(
        SpellingExerciseActionsCreator.save(
          loadedExercise?.exercise as SpellingExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.matchup: {
      dispatch(
        MatchUpExerciseActionsCreator.save(
          loadedExercise?.exercise as MatchUpExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.highlighter: {
      dispatch(
        HighlighterExerciseActionsCreator.save(
          loadedExercise?.exercise as HighlighterExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.fillgap: {
      dispatch(
        FillGapExerciseActionsCreator.save(
          loadedExercise?.exercise as FillGapExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.comprehension: {
      dispatch(
        ComprehensionExerciseActionsCreator.save(
          loadedExercise?.exercise as ComprehensionExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.listenRepeat: {
      dispatch(
        ListenRepeatExerciseActionsCreator.save(
          loadedExercise?.exercise as ListenRepeatExerciseInterface,
          learningLanguage,
          values,
        ),
      );
      break;
    }

    case ExerciseTypes.slidePdf: {
      dispatch(SlidePdfExerciseActionsCreator.save(loadedExercise?.exercise as SlidePdfExerciseInterface));
      break;
    }

    case ExerciseTypes.slidePptx: {
      dispatch(SlidePptxExerciseActionsCreator.save(loadedExercise?.exercise as SlidePptxExerciseInterface));
      break;
    }

    default: {
      dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: false }));
      throw new TypeError('Not found');
    }
  }
};
