import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components/macro';

import {
  Dropdown,
  DropdownInterface,
  dropdown_defaultCode,
  generateLanguageDropdownOptions,
} from '@components/Dropdown';
import { LoadingStage, type LoadingStageType } from '@common/enums/LoadingStage';
import { SearchBox } from '@components/Search';
import { AVAILABLE_LEARNING_LANGUAGES_V2, LanguageV2 } from '@features/content/languages';
import { isFeatureEnabled } from '@helpers/featuresHelper';

import { DashboardTableItemsInterface } from '../../types';

const FiltersRow = styled(Row)`
  margin: 0 0 1.5rem 0;
`;

const CoursesFilterDropdown = styled(Dropdown)`
  min-width: 20rem;
  button {
    margin: 0;
  }
`;

const LearningLanguageDropdown = styled(CoursesFilterDropdown)`
  margin-right: 2.6rem;
`;

const SearchCol = styled(Col)`
  display: flex;
  padding: 0;
  justify-content: flex-end;
  .search-box {
    width: 24rem;
  }
`;

type FilterControllersProps = {
  items: DashboardTableItemsInterface[];
  loaded: LoadingStageType;
  setFilteredItems: (items: DashboardTableItemsInterface[]) => void;
};

export const FilterControllers = ({ items, loaded, setFilteredItems }: FilterControllersProps) => {
  const [filterTerm, setFilterTerm] = useState('');
  const [filterContentType, setFilterContentType] = useState<DropdownInterface['code']>(dropdown_defaultCode);
  const [filterCourseLanguage, setFilterCourseLanguage] = useState<DropdownInterface['code']>(dropdown_defaultCode);

  useEffect(() => {
    let filteredItems = items;
    if (filterContentType !== dropdown_defaultCode) {
      filteredItems = filteredItems.filter((content) => content.itemType === filterContentType);
    }
    if (filterCourseLanguage !== dropdown_defaultCode) {
      filteredItems = filteredItems.filter((content) => content.learningLanguage === filterCourseLanguage);
    }
    if (filterTerm.length > 0) {
      filteredItems = filteredItems.filter(
        (content) =>
          content.title.toLocaleLowerCase().lastIndexOf(filterTerm.toLocaleLowerCase()) > -1 ||
          content.id.indexOf(filterTerm) > -1,
      );
    }
    if (loaded === LoadingStage.loaded) {
      setFilteredItems(filteredItems);
    }
  }, [filterContentType, filterCourseLanguage, filterTerm, loaded, items, setFilteredItems]);

  const courseLanguagesDropdownFilters = generateLanguageDropdownOptions(
    AVAILABLE_LEARNING_LANGUAGES_V2,
    'All course languages',
    'items',
  );

  const ContentTypeDropdownValues = [
    {
      name: 'All content',
      code: dropdown_defaultCode,
    },
    {
      name: 'Courses',
      code: 'course',
    },
    {
      name: 'Grammar Review',
      code: 'grammar',
    },
    {
      name: 'Placement Test',
      code: 'placementTest',
    },
  ];

  if (isFeatureEnabled('aiRoleplay')) {
    ContentTypeDropdownValues.push({
      name: 'Roleplay',
      code: 'roleplay',
    });
  }

  if (isFeatureEnabled('vocabularyReview')) {
    ContentTypeDropdownValues.push({
      name: 'Vocabulary Review',
      code: 'vocabularyReview',
    });
  }

  return (
    <FiltersRow>
      <LearningLanguageDropdown
        id="course-language"
        values={courseLanguagesDropdownFilters}
        value={filterCourseLanguage as LanguageV2}
        title="Course language"
        onChange={(selectedValue) => {
          setFilterCourseLanguage(selectedValue.code);
        }}
      />

      <CoursesFilterDropdown
        id="content-type"
        values={ContentTypeDropdownValues}
        value={filterContentType}
        title="Content type"
        onChange={(selectedValue) => {
          setFilterContentType(selectedValue.code);
        }}
      />

      <SearchCol>
        <SearchBox
          filterTerm={filterTerm}
          onSearchChange={(value: string) => setFilterTerm(value)}
          placeholder="Filter by title"
        />
      </SearchCol>
    </FiltersRow>
  );
};
