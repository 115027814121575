import { AnyExerciseContentBranch } from '@common/types/AnyExerciseContentBranch';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { getIdByLanguage, getValueByLanguage, getProcessedByLanguage } from '@features/content';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { MediaDataInterface } from '@common/interfaces/contentTypes/MediaDataInterface';
import { findLocalizationInSearchedLanguage } from '@features/content';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { LexicalItem } from '@features/content/vocabularyReview';

const ImageUploadUtils = {
  getDisplayImageForFileUpload(
    loadedExercise: AnyExerciseInterface | LexicalItem,
    field: AnyExerciseContentBranch,
    courseLearningLanguage = DEFAULT_LANGUAGE_V2,
    bundleName?: string,
  ): MediaDataInterface | undefined {
    const exerciseContents = loadedExercise?.content as any;
    let imageLocalizations = null;
    let contentId;
    if (bundleName) {
      imageLocalizations = exerciseContents?.[bundleName]?.image?.imageLocalizations;
      contentId = exerciseContents?.[bundleName]?.[field]?.id || exerciseContents?.[bundleName]?.[field]?._id;
    } else {
      imageLocalizations = exerciseContents?.[field]?.imageLocalizations;
      contentId = exerciseContents?.[field]?.id || exerciseContents?.[field]?._id;
    }

    if (imageLocalizations === undefined || imageLocalizations === null) return undefined;

    const localizationContent = findLocalizationInSearchedLanguage(imageLocalizations, courseLearningLanguage);
    const mediaId = localizationContent?._id || '';
    const mediaValue = localizationContent?.value || '';

    return { mediaId, contentId, mediaValue, processed: localizationContent?.processed || false };
  },
  getImageDataOutsideExercise(content?: TranslationsPanelContentInterface): MediaDataInterface | undefined {
    return {
      mediaId: getIdByLanguage(content?.imageLocalizations) || '',
      contentId: content?._id || content?.id || '',
      mediaValue: getValueByLanguage(content?.imageLocalizations) || '',
      processed: getProcessedByLanguage(content?.imageLocalizations),
    };
  },
  getAudioForFileUpload(
    loadedContent: AnyExerciseInterface | LexicalItem,
    field: AnyExerciseContentBranch,
    learningLanguage = DEFAULT_LANGUAGE_V2,
    bundleName?: string,
  ): MediaDataInterface | undefined {
    const exerciseOrLexicalItemContent = loadedContent?.content as AnyExerciseInterface['content'] &
      LexicalItem['content'];
    const targetContentField = bundleName
      ? exerciseOrLexicalItemContent?.[bundleName]?.[field]
      : exerciseOrLexicalItemContent?.[field];
    const audioLocalizations = targetContentField?.audioLocalizations ?? undefined;
    const textLocalizations = targetContentField?.textLocalizations ?? undefined;
    const contentId = targetContentField?.id || targetContentField?._id;

    if (!audioLocalizations) return undefined;

    const localizationContent = findLocalizationInSearchedLanguage(audioLocalizations, learningLanguage);
    const mediaId = localizationContent?._id || localizationContent?.id || '';
    const mediaValue = localizationContent?.value || '';
    const audioRequest = localizationContent?.audioRequest;
    const phraseText = findLocalizationInSearchedLanguage(textLocalizations, learningLanguage)?.value || '';

    return {
      mediaId,
      contentId,
      mediaValue,
      processed: localizationContent?.processed || false,
      phraseText,
      audioRequest,
    };
  },
  getDisplayVideoForFileUpload(
    loadedExercise: AnyExerciseInterface,
    bundleName: string,
    courseLearningLanguage = DEFAULT_LANGUAGE_V2,
  ): MediaDataInterface | undefined {
    const exerciseContents = loadedExercise?.content as AnyExerciseContentInterface;
    const videoLocalizations = exerciseContents?.[bundleName]?.video?.videoLocalizations;

    if (videoLocalizations === undefined || videoLocalizations === null) return undefined;

    const localizationContent = findLocalizationInSearchedLanguage(videoLocalizations, courseLearningLanguage);
    const mediaId = localizationContent?._id || '';
    const mediaValue = localizationContent?.value || '';
    const contentId = exerciseContents?.[bundleName]?.video?._id || exerciseContents?.[bundleName]?.video?.id;

    return { mediaId, contentId, mediaValue, processed: localizationContent?.processed || false };
  },
};

export default ImageUploadUtils;
