import { ALL_LANGUAGES_V2 } from '@features/content/languages';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { AudioAndTranslationBlock } from './AudioAndTranslationBlock';

export type AudiosAndTranslationsContentProps = {
  audioLocalizations: LocalizationInterface[];
  textLocalizations: LocalizationInterface[];
};

export const AudiosAndTranslationsContent = ({
  audioLocalizations,
  textLocalizations,
}: AudiosAndTranslationsContentProps) => (
  <>
    {ALL_LANGUAGES_V2.map((language) => (
      <AudioAndTranslationBlock
        key={language}
        audio={audioLocalizations.find((audio) => audio.language === language)?.value || ''}
        language={language}
        text={textLocalizations.find((text) => text.language === language)?.value || ''}
      />
    ))}
  </>
);
