import styled, { css } from 'styled-components/macro';

import { HeaderWrapper } from './HeaderWrapper';

const LexicalItemHeaderWrapper = styled(HeaderWrapper)<{ forReusing: boolean }>`
  ${({ forReusing }) => css`
    grid-template-columns: calc(5.6rem + 50%) minmax(8.5rem, 1fr) minmax(8.5rem, 1fr) ${forReusing
        ? 'minmax(12rem, 1fr)'
        : '5%'};
  `}
`;

export const LexicalItemSearchResultTypeHeader = ({ forReusing = false }: { forReusing?: boolean }) => (
  <LexicalItemHeaderWrapper forReusing={forReusing}>
    <span>Phrase</span>
    <span>Translations</span>
    <span>Audio</span>
    <span>&nbsp;</span>
  </LexicalItemHeaderWrapper>
);
