import { useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components/macro';
import Tooltip from 'react-tooltip';

import { DBId } from '@common/types/DBId';
import { Dropdown } from '@components/Dropdown';
import { WritableInputText } from '@components/WritableInputText';
import { Flag } from '@components/Flag';
import type { LanguageV2 } from '@features/content/languages';
import { Button } from '@features/theme';

import { VoiceArtistFilter } from '../Common/VoiceArtistFilter';
import { sendAudioRequest } from '../_service';
import { useToast } from '@features/app/toast';

const StyledModal = styled(Modal)`
  z-index: 8100;

  .modal-content {
    border: none;
    border-radius: 1.6rem;
    box-shadow: 0 0.8rem 1.2rem rgba(0, 0, 0, 0.1);
    padding: 4rem 2.5rem;
    transition: height 0.3s ease-out;
    width: 100%;
  }

  .modal-header {
    border: none;
    display: flex;
    padding: 0;
    position: relative;

    .modal-title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 2.4rem;
      width: 100%;
    }

    .close {
      color: ${({ theme }) => theme.colorV2.textTertiary};
      font-size: 3.2rem;
      font-weight: normal;
      margin: 0;
      margin-top: 0;
      opacity: 1;
      padding: 0;
      padding-top: 0;
      position: absolute;
      right: 0;
      top: -2.4rem;
      z-index: 100;
    }
  }

  .modal-dialog {
    max-width: 94.8rem;
  }

  .modal-body {
    background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
    padding: 0;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 2.4rem;
`;

const StyledVoiceArtistFilter = styled(VoiceArtistFilter)`
  width: 100% !important;

  .voice-artist-selector__control {
    font-size: 1.8rem !important;
    min-height: 4.4rem;
  }
`;

const StyledDropdown = styled(Dropdown)`
  .dropdown-toggle {
    border-color: ${({ theme }) => theme.colorV2.filterInputBaseBorder};
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .dropdown-item {
    font-size: 1.8rem;
  }
`;

const StyledFlag = styled(Flag)`
  background-color: ${({ theme }) => theme.colorV2.utilityInfoBackground};
  border: solid 0.1rem ${({ theme }) => theme.colorV2.filterInputBaseBorder};
  border-radius: 0.8rem;
  font-size: 1.6rem;
  height: 4.4rem;
  padding: 0.4rem 0.8rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
`;

const Textarea = styled.textarea`
  display: block;
  border: solid 0.1rem ${({ theme }) => theme.colorV2.filterInputBaseBorder};
  border-radius: 0.8rem;
  font-size: 1.8rem;
  height: 8.6rem;
  padding: 0.9rem 1.4rem;
  width: 100%;

  &:focus {
    border: solid 0.1rem ${({ theme }) => theme.colorV2.accentPrimary};
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: flex-end;
  padding-top: 1.6rem;
`;

const DEFAULT_LEVEL_DROPDOWN_VALUE = '1';
const LEVEL_TYPES = ['a1', 'a2', 'b1', 'b2', 'c1'];

type AudioRequestModalProps = {
  language: LanguageV2;
  level: string | null;
  resourceId: DBId;
  phrase: string;
  show: boolean;
  onHide: () => void;
  onSuccess: (requestId: string) => void;
};

export const AudioRequestModal = ({
  language,
  level,
  resourceId,
  phrase,
  show,
  onHide,
  onSuccess,
}: AudioRequestModalProps) => {
  const [inResponseTo, setInResponseTo] = useState('');
  const [notes, setNotes] = useState('');
  const [phraseToUpdate, setPhraseToUpdate] = useState(phrase);
  const [selectedLevel, setSelectedLevel] = useState<string | null>(level);
  const [selectedVoiceArtist, setSelectedVoiceArtist] = useState<{ value: string; label: string } | null>(null);

  const showToast = useToast();

  const levelTypes = LEVEL_TYPES.map((item) => ({ code: item, name: item }));

  const sendButtonTooltipText =
    !selectedLevel || !selectedVoiceArtist || !phraseToUpdate
      ? 'You must select a Level and Voice Artist and set Phrase to create an audio request!'
      : '';

  const handleRequestAudio = () => {
    sendAudioRequest({
      language,
      level: selectedLevel as string,
      resourceId,
      phrase: phraseToUpdate,
      inResponseTo,
      notes,
      voiceArtist: selectedVoiceArtist?.value as string,
    })
      .then((result) => {
        showToast({
          type: 'info',
          title: 'Audio request sent',
        });
        onSuccess(result.data.id);
      })
      .catch(() => {
        showToast({
          type: 'error',
          title: 'Audio request failed',
        });
      })
      .finally(() => {
        onHide();
      });
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Audio Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <StyledRow>
            <Col xs={6}>
              <Label>Resource Id</Label>
              <WritableInputText disabled fontSize="18" value={resourceId} />
            </Col>
            <Col>
              <Label>Level {!selectedLevel && '*'}</Label>
              <StyledDropdown
                title="Level"
                value={levelTypes.find((type) => type.name === selectedLevel)?.code || DEFAULT_LEVEL_DROPDOWN_VALUE}
                values={levelTypes}
                onChange={(value) => setSelectedLevel(value.name as string)}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <Label>Language</Label>
              <StyledFlag countries={[language]} />
            </Col>
            <Col>
              <Label>Voice Artist {!selectedVoiceArtist && '*'}</Label>
              <StyledVoiceArtistFilter
                selectedVoiceArtist={selectedVoiceArtist}
                selectedLanguage={{
                  value: language,
                  label: language,
                }}
                setSelectedVoiceArtist={setSelectedVoiceArtist}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <Label>Phrase {!phraseToUpdate && '*'}</Label>
              <WritableInputText
                fontSize="18"
                value={phraseToUpdate}
                onChange={(newPhrase) => setPhraseToUpdate(newPhrase.trim())}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <Label>Notes</Label>
              <Textarea onChange={(evt) => setNotes(evt.target.value)} />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <Label>In response to</Label>
              <Textarea onChange={(evt) => setInResponseTo(evt.target.value)} />
            </Col>
          </StyledRow>
          <ActionsWrapper>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>

            <div data-tip={sendButtonTooltipText} data-for="send-button-tooltip">
              <Button
                disabled={!selectedLevel || !selectedVoiceArtist || !phraseToUpdate}
                variant="primary"
                onClick={handleRequestAudio}
              >
                Send
                <Tooltip id="send-button-tooltip" effect="solid" />
              </Button>
            </div>
          </ActionsWrapper>
        </Container>
      </Modal.Body>
    </StyledModal>
  );
};
