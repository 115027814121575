import axios from 'axios';
import { apiClient } from '@features/api';
import { AxiosResponse } from 'axios';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { MediaTypes, type MediaTypesType } from '@common/enums/MediaTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { AnyExerciseContentBranch } from '@common/types/AnyExerciseContentBranch';
import { DBId } from '@common/types/DBId';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { ConversationExerciseActions } from '@actions/ConversationExerciseActions';
import ConversationExerciseInterface from '@components/Exercises/Conversation/interfaces/ConversationExerciseInterface';
import { MultipleChoiceExerciseActions } from '@actions/MultipleChoiceExerciseActions';
import { isMp4, checkIsImageMoreThanLimit } from '@helpers/mediaHelper';
import { UploadImageToExerciseAvailableSections } from '@sagas/courses/definitions/CourseSagasDefinition';
import ContentsService from './ContentsService';
import ExercisesService from './ExercisesService';
import { UploadAudioResponse, UploadMediaResponse } from './HelpersService';
import { editConversationExercise } from '@services/exercises/editExerciseService';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';

const MAX_VIDEO_FILE_SIZE = 262144000; // 250 MB

const MediaService = {
  uploadMedia: async (
    mediaType: MediaTypesType,
    media: File | null,
    imageCategory: string | undefined = 'exercise',
    existingLocalizationsToMerge?: TranslationsPanelContentInterface | undefined,
    contentIdBeingUpdated?: DBId | undefined,
    progressHandler?: (progress: number) => void,
    contentData?: TranslationsPanelContentInterface,
    language?: string,
  ): Promise<UploadMediaResponse> => {
    let mediaUploadAndContentAssociationKey: string;
    let mediaUploadHttpCallData;

    switch (mediaType) {
      case MediaTypes.image:
        mediaUploadAndContentAssociationKey = 'imageLocalizations';
        mediaUploadHttpCallData = await MediaService.uploadImage(media, progressHandler);

        break;

      case MediaTypes.audio:
        mediaUploadAndContentAssociationKey = 'audioLocalizations';
        mediaUploadHttpCallData = await MediaService.uploadAudio(media, progressHandler);

        break;

      case MediaTypes.video:
        mediaUploadAndContentAssociationKey = 'videoLocalizations';
        mediaUploadHttpCallData = await MediaService.uploadVideo(media, progressHandler);

        break;
    }

    let uploadedMediaData = mediaUploadHttpCallData;

    if (!contentIdBeingUpdated) {
      return ContentsService.contents.createAndAddUploadedMedia(
        { ...uploadedMediaData, type: imageCategory, language: language || DEFAULT_LANGUAGE_V2 },
        mediaUploadAndContentAssociationKey,
      );
    } else {
      return ContentsService.contents.updateExistingContentWithUploadedMedia(
        contentIdBeingUpdated,
        { ...uploadedMediaData, type: imageCategory, language: language || DEFAULT_LANGUAGE_V2 },
        mediaUploadAndContentAssociationKey,
        contentData,
      );
    }
  },
  uploadAudio: async (
    audio: File | null,
    progressHandler?: (progress: number) => void,
  ): Promise<UploadAudioResponse | null> => {
    let url = '/media/audio';
    if (audio) {
      if (audio.type === 'audio/wav') {
        url = '/media/wav';
      } else if (audio.type === 'audio/xwav') {
        url = '/media/xwav';
      } else {
        throw new Error('Incorrect audio extension');
      }
    }

    const audioUploadHttpCall: AxiosResponse = await apiClient.v2.get(url);

    if (audioUploadHttpCall.status === 200 && audio) {
      const audioUploadUrl = audioUploadHttpCall.data.url;
      const fileId = audioUploadHttpCall.data.fileId;

      const uploadAudioResult: AxiosResponse = await axios.put(audioUploadUrl, audio, {
        headers: {
          'Content-Type': audio.type,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent && progressEvent.total) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            progressHandler && progressHandler(progress);
          }
        },
      });

      if (uploadAudioResult.status === 200) {
        return { fileId };
      }
      return null;
    } else {
      return null;
    }
  },
  uploadImage: async (image: File | null, progressHandler?: (progress: number) => void): Promise<any> => {
    let url = '/media/image';
    if (image) {
      if (image.type === 'image/jpg' || image.type === 'image/jpeg') {
        const isImageMoreThanLimit = await checkIsImageMoreThanLimit(image);
        if (isImageMoreThanLimit) {
          throw new Error('Image resolution too high. Max is 16.8 MP.');
        } else {
          url = '/media/jpg';
        }
      } else {
        throw new Error('Incorrect image extension');
      }
    }

    const imageUploadHttpCall: AxiosResponse = await apiClient.v2.get(url);

    if (imageUploadHttpCall.status === 200 && image) {
      const imageUploadUrl = imageUploadHttpCall.data.url;
      const fileId = imageUploadHttpCall.data.fileId;

      const uploadImageResult: AxiosResponse = await axios.put(imageUploadUrl, image, {
        headers: {
          'Content-Type': image.type,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent && progressEvent.total) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            progressHandler && progressHandler(progress);
          }
        },
      });

      if (uploadImageResult.status === 200) {
        return { fileId };
      }
      return null;
    } else {
      return null;
    }
  },
  uploadVideo: async (video: File | null, progressHandler?: (progress: number) => void): Promise<any> => {
    let url = '/media/video';
    if (video) {
      const isMP4 = await isMp4(video);
      if (video.type === 'video/mp4') {
        if (isMP4) {
          if (video.size > MAX_VIDEO_FILE_SIZE) {
            throw new Error('Too big file');
          }
          url = '/media/mp4';
        } else {
          throw new Error('It is not an mp4 file');
        }
      } else {
        throw new Error('Incorrect video extension');
      }
    }

    const videoUploadHttpCall: AxiosResponse = await apiClient.v2.get(url);

    if (videoUploadHttpCall.status === 200 && video) {
      const videoUploadUrl = videoUploadHttpCall.data.url;
      const fileId = videoUploadHttpCall.data.fileId;

      const uploadVideoResult: AxiosResponse = await axios.put(videoUploadUrl, video, {
        headers: {
          'Content-Type': video.type,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent && progressEvent.total) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            progressHandler && progressHandler(progress);
          }
        },
      });

      if (uploadVideoResult.status === 200) {
        return { fileId };
      }
      return null;
    } else {
      return null;
    }
  },
  uploadPdf: async (pdf: File | null): Promise<any> => {
    const pdfUploadHttpCall: AxiosResponse = await apiClient.v2.get('/media/pdf');

    if (pdfUploadHttpCall.status === 200) {
      const { fileId, url } = pdfUploadHttpCall.data;
      const uploadPdfResult: AxiosResponse = await axios.put(url, pdf, {
        headers: {
          'Content-Type': 'application/pdf',
        },
      });

      if (uploadPdfResult.status === 200) {
        return {
          fileId,
          url: url.split('?')[0],
        };
      }
    }

    return null;
  },
  uploadPptx: async (pptx: File | null): Promise<any> => {
    const pptxUploadHttpCall: AxiosResponse = await apiClient.v2.get('/media/pptx');

    if (pptxUploadHttpCall.status === 200) {
      const { fileId, url } = pptxUploadHttpCall.data;
      const uploadPptxResult: AxiosResponse = await axios.put(url, pptx, {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        },
      });

      if (uploadPptxResult.status === 200) {
        return {
          fileId,
          url: url.split('?')[0],
        };
      }
    }

    return null;
  },
  async upload(translationsFile: File) {
    const formData = new FormData();
    formData.append('file', translationsFile);

    const response: AxiosResponse = await apiClient.v2.post('content/resources/translations-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  },
  async uploadMediaAndUpdateContent(
    image: File,
    exercise: AnyExerciseInterface,
    exerciseType: ExerciseTypesType,
    branchBeingUpdated: AnyExerciseContentBranch,
    mediaType: MediaTypesType = MediaTypes.image,
    bundleName?: string,
    progressHandler?: (progress: number) => void,
  ) {
    const contentID: DBId | undefined = bundleName
      ? exercise?.content?.[bundleName][branchBeingUpdated]?._id ||
        exercise?.content?.[bundleName][branchBeingUpdated]?.id
      : // waiting for backend normalisation https://busuucom.atlassian.net/browse/INTO-690
        exercise?.content?.[branchBeingUpdated]?._id || exercise?.content?.[branchBeingUpdated]?.id;

    let uploadMediaResponse = await MediaService.uploadMedia(
      mediaType,
      image,
      undefined,
      exercise.content[branchBeingUpdated],
      contentID,
      progressHandler,
    );

    return Promise.resolve(uploadMediaResponse);
  },
  async uploadImageToExercise(
    exercise: AnyExerciseInterface,
    image: File,
    exerciseType: ExerciseTypesType,
    section?: UploadImageToExerciseAvailableSections,
    courseId?: DBId,
    progressHandler?: (progress: number) => void,
  ): Promise<UploadMediaResponse> {
    switch (exerciseType) {
      case ExerciseTypes.flashcard: {
        return MediaService.uploadMediaAndUpdateContent(
          image,
          exercise,
          exerciseType,
          'image',
          MediaTypes.image,
          'learningWordBundle',
          progressHandler,
        );
      }

      case ExerciseTypes.trueFalse: {
        return MediaService.uploadMediaAndUpdateContent(
          image,
          exercise,
          exerciseType,
          'image',
          MediaTypes.image,
          'mainBundle',
          progressHandler,
        );
      }

      case ExerciseTypes.multipleChoice: {
        switch (section) {
          case MultipleChoiceExerciseActions.UPLOAD_MAIN_CONTENT_IMAGE: {
            return MediaService.uploadMediaAndUpdateContent(
              image,
              exercise,
              exerciseType,
              'image',
              MediaTypes.image,
              'answerBundle',
              progressHandler,
            );
          }

          case MultipleChoiceExerciseActions.UPLOAD_ANSWER_IMAGE: {
            return MediaService.uploadMediaAndUpdateContent(
              image,
              exercise,
              exerciseType,
              'image',
              MediaTypes.image,
              'answerBundle',
              progressHandler,
            );
          }

          case MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR1_IMAGE: {
            return MediaService.uploadMediaAndUpdateContent(
              image,
              exercise,
              exerciseType,
              'image',
              MediaTypes.image,
              'distractor1',
              progressHandler,
            );
          }

          case MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR2_IMAGE: {
            return MediaService.uploadMediaAndUpdateContent(
              image,
              exercise,
              exerciseType,
              'image',
              MediaTypes.image,
              'distractor2',
              progressHandler,
            );
          }

          default: {
            throw new Error(`Unknown section for Multiple Choice exercise`);
          }
        }
      }

      case ExerciseTypes.conversation: {
        let images: string[];
        let uploadMediaResponse: UploadMediaResponse;

        switch (section) {
          case ConversationExerciseActions.UPLOAD_IMAGE1: {
            exercise = exercise as ConversationExerciseInterface;
            let image1 = exercise.content.image1;

            uploadMediaResponse = await MediaService.uploadMedia(
              MediaTypes.image,
              image,
              undefined,
              image1 as any,
              undefined,
              progressHandler,
            );

            const exerciseUpdated: any = await ExercisesService.misc.getExercise(exercise.content.id, courseId);

            images = [
              uploadMediaResponse.mediaId,
              exerciseUpdated.content.image2?._id,
              exerciseUpdated.content.image3?._id,
            ];

            break;
          }
          case ConversationExerciseActions.UPLOAD_IMAGE2: {
            exercise = exercise as ConversationExerciseInterface;
            let image2 = exercise.content.image2;

            uploadMediaResponse = await MediaService.uploadMedia(
              MediaTypes.image,
              image,
              undefined,
              image2 as any,
              undefined,
              progressHandler,
            );

            const exerciseUpdated: any = await ExercisesService.misc.getExercise(exercise.content.id, courseId);

            images = [
              exerciseUpdated.content.image1?._id,
              uploadMediaResponse.mediaId,
              exerciseUpdated.content.image3?._id,
            ];

            break;
          }
          case ConversationExerciseActions.UPLOAD_IMAGE3: {
            exercise = exercise as ConversationExerciseInterface;
            let image3 = exercise.content.image3;

            uploadMediaResponse = await MediaService.uploadMedia(
              MediaTypes.image,
              image,
              undefined,
              image3 as any,
              undefined,
              progressHandler,
            );

            const exerciseUpdated: any = await ExercisesService.misc.getExercise(exercise.content.id, courseId);

            images = [
              exerciseUpdated.content.image1?._id,
              exerciseUpdated.content.image2?._id,
              uploadMediaResponse.mediaId,
            ];

            break;
          }
          default: {
            throw new Error(`Unknown section for Conversation exercise`);
          }
        }

        await editConversationExercise(exercise.content.id as string, {
          images,
        });

        return Promise.resolve(uploadMediaResponse);
      }

      case ExerciseTypes.speechRecognition: {
        return MediaService.uploadMediaAndUpdateContent(
          image,
          exercise,
          exerciseType,
          'image',
          MediaTypes.image,
          'mainBundle',
          progressHandler,
        );
      }

      case ExerciseTypes.spelling: {
        return MediaService.uploadMediaAndUpdateContent(
          image,
          exercise,
          exerciseType,
          'image',
          MediaTypes.image,
          'mainBundle',
          progressHandler,
        );
      }

      case ExerciseTypes.typing: {
        return MediaService.uploadMediaAndUpdateContent(
          image,
          exercise,
          exerciseType,
          'image',
          MediaTypes.image,
          'mainBundle',
          progressHandler,
        );
      }

      case ExerciseTypes.fillgap: {
        return MediaService.uploadMediaAndUpdateContent(
          image,
          exercise,
          exerciseType,
          'image',
          MediaTypes.image,
          'mainBundle',
          progressHandler,
        );
      }

      case ExerciseTypes.comprehension: {
        return MediaService.uploadMediaAndUpdateContent(
          image,
          exercise,
          exerciseType,
          'image',
          MediaTypes.image,
          'mainBundle',
          progressHandler,
        );
      }

      case ExerciseTypes.listenRepeat: {
        return MediaService.uploadMediaAndUpdateContent(
          image,
          exercise,
          exerciseType,
          'image',
          MediaTypes.image,
          'mainBundle',
          progressHandler,
        );
      }

      default: {
        throw new Error(
          `The type of exercise "${exerciseType}" is not yet a type of exercise capable of uploading images`,
        );
      }
    }
  },
};

export default MediaService;
