import 'draft-js/dist/Draft.css';
import { ChangeEvent } from 'react';
import classnames from 'classnames';
import { Form } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import { Sizes } from '@common/enums/Sizes';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadedConversationExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import helpersService from '@services/HelpersService';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { PreviewModal } from '@features/content/preview';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import BidimensionalEditor from '@components/BidimensionalEditor/BidimensionalEditor';
import { BidimensionalEditorModes } from '@components/BidimensionalEditor/enums/BidimensionalEditorModes';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { useIsEditAvailable } from '@features/content/courses';
import { ImageUpload } from '@components/MediaUpload';
import { ExperimentSelector } from '@features/experiment';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { WritableInputText } from '@components/WritableInputText';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { AccessWarning } from '@components/Warning';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { constants as contentConstants } from '@features/content';
import { removeMediaProcessingValidationError } from '@features/content/exercises';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { ConversationExerciseActionsCreator } from '@actionCreators/ConversationExerciseActionsCreator';
import ConversationExerciseInterface from './interfaces/ConversationExerciseInterface';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;
const MAX_NUMBER_OF_WORDS = 40;

const ConversationExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedConversationExerciseInterface>) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const { isEditorHidden } = useIsEditorHidden({ visitedBranch: 'instructions' });
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as ConversationExerciseInterface;

  const instructionsAudioLocalizations = loadedExercise?.content?.instructions.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableInstructionsAudioLocalizations = instructionsAudioLocalizations.filter(
    (audioLocalization: LocalizationInterface) =>
      availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  // Images only have EN localization
  const image1Localization = loadedExercise?.content?.image1?.imageLocalizations.find(
    (imageLocalization: LocalizationInterface) => imageLocalization.language === DEFAULT_LANGUAGE_V2,
  );
  const image2Localization = loadedExercise?.content?.image2?.imageLocalizations.find(
    (imageLocalization: LocalizationInterface) => imageLocalization.language === DEFAULT_LANGUAGE_V2,
  );
  const image3Localization = loadedExercise?.content?.image3?.imageLocalizations.find(
    (imageLocalization: LocalizationInterface) => imageLocalization.language === DEFAULT_LANGUAGE_V2,
  );

  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const hintErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'hint');
  const imagesErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'images');
  const minimumNumberOfWordsErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'minimumNumberOfWords',
  );
  const image1Errors = errors.filter((error: ValidationErrorInterface) => error.field === 'images[0]');
  const image2Errors = errors.filter((error: ValidationErrorInterface) => error.field === 'images[1]');
  const image3Errors = errors.filter((error: ValidationErrorInterface) => error.field === 'images[2]');

  if (instructionsErrors.length) {
    let updatedErrors = [...errors];

    availableInstructionsAudioLocalizations.forEach(({ language, processed, value }: LocalizationInterface) => {
      if (processed && value) {
        updatedErrors = updatedErrors.filter(
          (error) => !error.message.includes(MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language)),
        );
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  if (image1Errors.length && image1Localization?.processed && image1Localization?.value) {
    let updatedErrors = removeMediaProcessingValidationError({
      errors,
      fieldName: 'images[0]',
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', image1Localization.language),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  if (image2Errors.length && image2Localization?.processed && image2Localization?.value) {
    let updatedErrors = removeMediaProcessingValidationError({
      errors,
      fieldName: 'images[1]',
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', image2Localization.language),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  if (image3Errors.length && image3Localization?.processed && image3Localization?.value) {
    let updatedErrors = removeMediaProcessingValidationError({
      errors,
      fieldName: 'images[2]',
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', image3Localization.language),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const renderEditor = () => (
    <TranslationsTipV2
      visitedBranch={'instructions'}
      showLanguageSwitcher={false}
      errors={instructionsErrors}
      showErrorDisplayer={false}
      flipped={false}
    >
      <BidimensionalEditor
        mode={BidimensionalEditorModes.mono}
        storeBranch="instructions"
        language={courseLearningLanguage}
        maxColums={1}
        dataSourceMode={'editorState'}
        ignoreLanguageForTips={true}
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
        }}
      />
    </TranslationsTipV2>
  );

  return (
    <div className="exercise-conversation">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Conversation</Title>
        <HelpDisplayer type="guideline" id="guideline-conversation-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-conversation__wrapper">
        <div className="exercise-conversation__container">
          <h1 className="exercise-conversation__box-title">Conversation question</h1>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
        </div>
        {isEditorHidden ? <div>{renderEditor()}</div> : <>{renderEditor()}</>}

        <UserLanguageCheckbox
          checkedField={exerciseParam.exercise?.content.instructionsLanguage}
          onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
            dispatch(ConversationExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
          }}
        />
      </div>
      <div className="exercise-conversation__wrapper">
        <ContentToggler
          text="Hint"
          open={!!loadedExercise.content.hint?._id}
          errors={hintErrors}
          onRemove={() => {
            dispatch(ConversationExerciseActionsCreator.nullifyHint());
          }}
        >
          <TranslationsTipV2 visitedBranch={'hint'} errors={hintErrors} showErrorDisplayer={false}>
            <WritableInputText id="conversation-hint-input" />
          </TranslationsTipV2>
        </ContentToggler>
      </div>

      <div className="exercise-conversation__wrapper">
        <div className="exercise-conversation__container">
          <h1 className="exercise-conversation__box-title">Minimum number of words (1-{MAX_NUMBER_OF_WORDS})</h1>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(minimumNumberOfWordsErrors)} />
        </div>
        <Form.Control
          type="number"
          min={1}
          disabled={!isEditAvailable}
          max={MAX_NUMBER_OF_WORDS}
          className={classnames('exercise-conversation__min-number-of-words', {
            'exercise-conversation__min-number-of-words--with-errors':
              minimumNumberOfWordsErrors.length && isIssuesShown,
          })}
          value={loadedExercise.content.minimumNumberOfWords}
          onChange={(minimunNumberOWordsEvent: ChangeEvent<HTMLInputElement>) => {
            dispatch(
              ConversationExerciseActionsCreator.updateMinimumNumberOfWords(+minimunNumberOWordsEvent.target.value),
            );
          }}
        />
      </div>

      <div className="exercise-conversation__wrapper">
        <div className="exercise-conversation__container">
          <h1 className="exercise-conversation__box-title">Images</h1>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(imagesErrors)} />
        </div>
        <ul className="exercise-conversation__images">
          <li className="exercise-conversation__image">
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(image1Errors)} />
            <ImageUpload
              width={ImageUploadDimensionDescriptors.conversation.image.width}
              height={ImageUploadDimensionDescriptors.conversation.image.height}
              size={Sizes.large}
              mode={ImageUploadModes.normal}
              onChange={(file: File, progressHandler: Function) => {
                if (file !== null && loadedExercise.content.id !== undefined) {
                  dispatch(ConversationExerciseActionsCreator.setImage1(loadedExercise, file, progressHandler));
                }
              }}
              onRemove={() => {
                dispatch(ConversationExerciseActionsCreator.removeImage1());
              }}
              imageData={ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image1')}
              onProcessingFinished={(url: string) => {
                dispatch(
                  CommonExerciseActionsCreator.setValueAfterProcessing({
                    url,
                    mediaType: 'image',
                    type: ContentTypes.exercise,
                    field: 'image1',
                    language: 'EN',
                  }),
                );
              }}
              previewMode={false}
              errors={[...imagesErrors, ...image1Errors]}
              fieldName="image1"
            />
          </li>
          <li className="exercise-conversation__image">
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(image2Errors)} />
            <ImageUpload
              width={ImageUploadDimensionDescriptors.conversation.image.width}
              height={ImageUploadDimensionDescriptors.conversation.image.height}
              size={Sizes.large}
              mode={ImageUploadModes.normal}
              onChange={(file: File, progressHandler: Function) => {
                if (file !== null && loadedExercise.content.id !== undefined) {
                  dispatch(ConversationExerciseActionsCreator.setImage2(loadedExercise, file, progressHandler));
                }
              }}
              onRemove={() => {
                dispatch(ConversationExerciseActionsCreator.removeImage2());
              }}
              imageData={ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image2')}
              onProcessingFinished={(url: string) => {
                dispatch(
                  CommonExerciseActionsCreator.setValueAfterProcessing({
                    url,
                    mediaType: 'image',
                    type: ContentTypes.exercise,
                    field: 'image2',
                    language: 'EN',
                  }),
                );
              }}
              previewMode={false}
              errors={[...imagesErrors, ...image2Errors]}
              fieldName="image2"
            />
          </li>
          <li className="exercise-conversation__image">
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(image3Errors)} />
            <ImageUpload
              width={ImageUploadDimensionDescriptors.conversation.image.width}
              height={ImageUploadDimensionDescriptors.conversation.image.height}
              size={Sizes.large}
              mode={ImageUploadModes.normal}
              onChange={(file: File, progressHandler: Function) => {
                if (file !== null && loadedExercise.content.id !== undefined) {
                  dispatch(ConversationExerciseActionsCreator.setImage3(loadedExercise, file, progressHandler));
                }
              }}
              onRemove={() => {
                dispatch(ConversationExerciseActionsCreator.removeImage3());
              }}
              imageData={ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image3')}
              onProcessingFinished={(url: string) => {
                dispatch(
                  CommonExerciseActionsCreator.setValueAfterProcessing({
                    url,
                    mediaType: 'image',
                    type: ContentTypes.exercise,
                    field: 'image3',
                    language: 'EN',
                  }),
                );
              }}
              previewMode={false}
              errors={[...imagesErrors, ...image3Errors]}
              fieldName="image3"
            />
          </li>
        </ul>
        <RecapSelector exerciseId={loadedExercise?.content?.id} />
      </div>
    </div>
  );
};

export default ConversationExercise;
