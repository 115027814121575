import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import type { Theme } from '@features/theme';
import type { ValueOf } from '../../types';
import type { TextLevel, TextVariant } from './types';

type TextProps = {
  as?: React.ElementType;
  bold?: boolean;
  children: ReactNode;
  className?: string;
  color?: ValueOf<Theme['colorV2']> | 'inherit';
  level: 1 | 2 | 3 | 4 | 5;
  spaceBottom?: keyof Theme['space'];
  spaceTop?: keyof Theme['space'];
  variant?: TextVariant;
};

const cssPropertiesValuesMap: Record<TextVariant, TextLevel> = {
  body: {
    level1: {
      fontSize: '1.8rem',
      fontWeight: 400,
      lineHeight: '150%',
    },
    level2: {
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '150%',
    },
    level3: {
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '150%',
    },
    level4: {
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '150%',
    },
    level5: {
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '150%',
    },
  },
  header: {
    level1: {
      fontSize: '4rem',
      fontWeight: 800,
      lineHeight: '130%',
    },
    level2: {
      fontSize: '3.2rem',
      fontWeight: 800,
      lineHeight: '130%',
    },
    level3: {
      fontSize: '2.4rem',
      fontWeight: 800,
      lineHeight: '130%',
    },
  },
  subheader: {
    level1: {
      fontSize: '1.4rem',
      fontWeight: 800,
      lineHeight: '130%',
    },
    level2: {
      fontSize: '1.2rem',
      fontWeight: 700,
      lineHeight: '150%',
    },
    level3: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '150%',
    },
  },
};

const StyledText = styled.div<Omit<TextProps, 'children'>>`
  ${({ bold, color, level, spaceBottom, spaceTop, theme, variant = 'body' }) => {
    const levelKey: keyof TextLevel = `level${level}`;
    const { fontSize, fontWeight, lineHeight } = cssPropertiesValuesMap[variant]?.[levelKey] || {};

    return css`
      color: ${color};
      font-size: ${fontSize};
      font-weight: ${bold ? (variant === 'body' ? 700 : 800) : fontWeight};
      line-height: ${lineHeight};
      margin-bottom: ${theme.space[spaceBottom as keyof Theme['space']] ?? '0'};
      margin-top: ${theme.space[spaceTop as keyof Theme['space']] ?? '0'};
      text-transform: ${variant === 'subheader' ? 'uppercase' : 'none'};
    `;
  }};
`;

export const Text = ({
  as = 'p',
  bold = false,
  className,
  children,
  color = 'inherit',
  level,
  spaceBottom = 'M',
  spaceTop,
  variant = 'body',
}: TextProps) => (
  <StyledText
    as={as}
    bold={bold}
    className={className}
    color={color}
    level={level}
    spaceBottom={spaceBottom}
    spaceTop={spaceTop}
    variant={variant}
  >
    {children}
  </StyledText>
);
