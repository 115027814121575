import { SelectedGroupsOfParentInterface } from '@common/interfaces/groups/SelectedGroupsOfParentInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer/ValidationErrorDisplayer';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { useIsEditAvailable } from '@features/content/courses';
import { Loader } from '@features/theme';
import { WritableInputText } from '@components/WritableInputText';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { ContentType, ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { DraggableList } from '@components/DraggableList/DraggableList';
import { LoadingStage } from '@common/enums/LoadingStage';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import {
  selectCourse,
  selectSelectedGroupsOfParent,
  selectSelectedGroupsOfParentParentContents,
} from '@selectors/CoursesSelectors';
import { arrayMoveImmutable } from 'array-move';
import { clone } from '@helpers/clone';

export const ChapterMainData = () => {
  const dispatch = useAppDispatch();
  const selectedGroupsOfParent: SelectedGroupsOfParentInterface = useAppSelector(selectSelectedGroupsOfParent);
  const { isEditAvailable } = useIsEditAvailable();

  const loadedCourse = useAppSelector(selectCourse);
  const loadedContent: any = useAppSelector(selectSelectedGroupsOfParentParentContents);

  const titleValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'title',
  );
  const childrenValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'children',
  );

  const groupValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'groups',
  );

  const filteredLessons = selectedGroupsOfParent.groups.filter((group) => {
    const lessonTypesToExclude: (ContentTypesType | string)[] = [ContentTypes.checkpoint];

    return !lessonTypesToExclude.includes(group.lessonType);
  });

  return (
    <div className="course-composer">
      <div className="course-edition__first-top-element">
        <PublishingStatus ready={loadedContent.ready} changeStatus={loadedContent.changeStatus} />
        <div id={`chapter-${loadedContent.id}`}>
          <IDDisplayer id={loadedContent.id} mode="normal" />
          <TranslationsTipV2
            visitedBranch={'titleWithLocalizations'}
            type={ContentType.levelOrLesson}
            content={loadedContent}
            errors={titleValidationErrors}
            isExercise={false}
            isLesson={false}
            className="content-name"
          >
            <WritableInputText
              fontSize="30"
              bold
              withoutBorder
              placeholder={'Untitled chapter'}
              id="chapter-title-input"
            />
          </TranslationsTipV2>
        </div>
        {!isEditAvailable && <AccessWarning />}
      </div>
      {selectedGroupsOfParent.groupsLoaded !== LoadingStage.loaded ? (
        <Loader size="L" />
      ) : (
        <>
          <DraggableList
            arrayOfChildren={selectedGroupsOfParent.groups}
            onDragEnd={(result) => {
              result.destination &&
                dispatch(
                  ContentTypesActionsCreator.setOrder(
                    arrayMoveImmutable(clone(filteredLessons), result.source.index, result.destination.index),
                    result.source.index,
                    result.destination.index,
                    ContentTypes.chapter,
                    loadedContent.id,
                    loadedCourse.id,
                  ),
                );
            }}
            onContentCreate={(contentTypeToCreate, contentCategory, position) => {
              dispatch(
                ContentTypesActionsCreator.onCreateContent(
                  loadedCourse.id,
                  contentTypeToCreate,
                  ContentTypes.chapter,
                  loadedContent.id,
                  contentCategory,
                  position,
                ),
              );
            }}
            creatorAcceptableButtons={{
              [ContentTypes.certificate]: true,
              [ContentTypes.checkpoint]: true,
              [ContentTypes.lesson]: true,
              [ContentTypes.liveLesson]: true,
              [ContentTypes.review]: true,
              [ContentTypes.speakingLesson]: isFeatureEnabled('speakingBites'),
            }}
          />
        </>
      )}
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(childrenValidationErrors)} />
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(groupValidationErrors)} />
    </div>
  );
};
