import { DBId } from '@common/types/DBId';
import { LanguageV2 } from '@features/content/languages';
import { getPhrasesToDisplay, VocabularyReviewListItem } from '@features/content/vocabularyReview';

import { CardTitle } from '../Common';
import { CardImage } from './CardImage';
import { NavigateLink } from './NavigateLink';
import { LexicalItemCardWrapper, StyledCardAudio, StyledCardTranslation } from './styles';
import { Button } from '@features/theme';

type LexicalItemResultCardProps = {
  content: VocabularyReviewListItem;
  language: LanguageV2;
  onClick?: (lexicalItemId: DBId) => void;
  onMatch?: (lexicalItemId: DBId, phrase: string) => void;
};

export const LexicalItemResultCard = ({ content, language, onClick, onMatch }: LexicalItemResultCardProps) => {
  const { id, image, hasAudio, phrase, translationsCount } = content;
  const localizedPhrase =
    phrase.textLocalizations?.find((localization) => localization.language === language)?.value || '';
  const title = getPhrasesToDisplay(phrase, language);
  const path = `/vocabulary-review/${language}/lexical-item/${id}`;

  return (
    <LexicalItemCardWrapper forReusing={Boolean(onMatch)} isExpanded={false} onClick={() => onClick && onClick(id)}>
      <CardImage image={image} />
      <CardTitle id={id} title={title} />
      <StyledCardTranslation translations={translationsCount} type="text"></StyledCardTranslation>
      <StyledCardAudio hasAudio={hasAudio}></StyledCardAudio>
      {onMatch ? (
        <Button
          variant="secondary"
          size="S"
          onClick={(evt) => {
            evt.stopPropagation();
            onMatch(id, localizedPhrase);
          }}
        >
          Match
        </Button>
      ) : (
        <NavigateLink path={path} />
      )}
    </LexicalItemCardWrapper>
  );
};
