import { SimpleRangeSelector } from '@features/search';

import { FILTER_WORD_RANGE_MAX } from '../constants';
import { FiltersState } from '../types';

type WordRangeFilterProps = {
  rule: string;
  value: FiltersState['wordsRange'];
  onChange: (value: FiltersState['wordsRange']) => void;
};

export const WordsRangeFilter = ({ value, onChange }: WordRangeFilterProps) => {
  const handleChange = (value: FiltersState['wordsRange']) => {
    const { min, max } = value || {};

    if (min === 0 && max === 0) {
      onChange(null);
    } else {
      onChange(value);
    }
  };

  return (
    <SimpleRangeSelector maxLimit={FILTER_WORD_RANGE_MAX} rule="Words range" value={value} onChange={handleChange} />
  );
};
