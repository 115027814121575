import {
  AnyExerciseContentBranch,
  CourseContentBranches,
  ContentBranches,
} from '@common/types/AnyExerciseContentBranch';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { DEFAULT_LANGUAGE_V2, LANGUAGE_NAMES_V2, type LanguageV2 } from '@features/content/languages';
import { camelCaseSeparateWords } from '@helpers/camel-case-separator';
import { isLocalizationFilled, isLocalizationNeedsToBeCounted } from '@features/content';
import * as _ from 'lodash';

const TranslationTipV2Utils = {
  getTranslationAndAudioElements(
    interfaceLanguages: LanguageV2[],
    content: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {},
    visitedBranch: AnyExerciseContentBranch | CourseContentBranches | ContentBranches,
    row: number | undefined,
    column: number | undefined,
    courseLearningLang: LanguageV2,
    bundleName?: string,
  ) {
    let totalNeededLanguages = interfaceLanguages.length;

    if (!interfaceLanguages.includes(courseLearningLang)) {
      totalNeededLanguages = totalNeededLanguages + 1;
    }

    if (!interfaceLanguages.includes(DEFAULT_LANGUAGE_V2) && courseLearningLang !== DEFAULT_LANGUAGE_V2) {
      totalNeededLanguages = totalNeededLanguages + 1;
    }

    let chosenBranch = bundleName ? (content as any)[bundleName][visitedBranch] : (content as any)[visitedBranch];

    if (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)) {
      chosenBranch = (content as any).phrases;
    }
    const bundleId = bundleName ? (content as any)[bundleName]?._id : 'N/A';

    if (row !== undefined) {
      if (column === undefined) {
        chosenBranch = chosenBranch[row];
      } else {
        chosenBranch = chosenBranch[row][column];
      }
    }

    const isHiglighterPhrasesEmpty =
      chosenBranch === null ||
      (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch) && (content as any).phrases.length === 0);

    if (chosenBranch === undefined && !isHiglighterPhrasesEmpty) {
      throw new Error(`Field "${visitedBranch}" doesn't appear in the exercise payload`);
    }

    let localizationIDForLangExperts = isHiglighterPhrasesEmpty || !chosenBranch._id ? 'N/A' : chosenBranch._id;

    if (isHiglighterPhrasesEmpty) {
      return {
        translationElements: `0/${totalNeededLanguages}`,
        audioElements: `0/${totalNeededLanguages}`,
        localizationIDForLangExperts,
        chosenBranch,
        bundleId,
      };
    } else {
      const numberOfTranslations = chosenBranch.textLocalizations.filter(
        (localization: LocalizationInterface) =>
          isLocalizationFilled(localization) &&
          isLocalizationNeedsToBeCounted(
            localization,
            interfaceLanguages as LanguageV2[],
            courseLearningLang as LanguageV2,
          ),
      ).length;

      const numberOfAudio = chosenBranch.audioLocalizations.filter(
        (localization: LocalizationInterface) =>
          localization.value &&
          isLocalizationNeedsToBeCounted(
            localization,
            interfaceLanguages as LanguageV2[],
            courseLearningLang as LanguageV2,
          ),
      ).length;

      return {
        translationElements: `${numberOfTranslations}/${totalNeededLanguages}`,
        audioElements: `${numberOfAudio}/${totalNeededLanguages}`,
        localizationIDForLangExperts,
        chosenBranch,
        bundleId,
      };
    }
  },
  formatPlaceholder(
    childrenPlaceholder: string | undefined,
    visitedBranch: AnyExerciseContentBranch | CourseContentBranches | ContentBranches,
    languageForTips: LanguageV2,
  ) {
    return childrenPlaceholder === undefined
      ? `${camelCaseSeparateWords(_.upperFirst(visitedBranch))} in ${LANGUAGE_NAMES_V2[languageForTips]}`
      : childrenPlaceholder;
  },
};

export default TranslationTipV2Utils;
