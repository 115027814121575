import { startCase } from 'lodash';

import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { ContentBranches } from '@common/types/AnyExerciseContentBranch';

import { LESSON_FIELD_NAMES } from './constants';
import type { LessonDefaultContextForTranslators } from './types';

/**
 * @param lessonContent - The field localization object.
 * @returns {array} - The field names with empty context for translators.
 */
export const getFieldsWithEmptyContextForTranslators = (lessonContent: GenericContentInterface) => {
  const fieldsWithEmptyContextForTranslators: ContentBranches[] = [];

  for (const fieldName in LESSON_FIELD_NAMES) {
    if (!lessonContent[fieldName as keyof GenericContentInterface].description) {
      fieldsWithEmptyContextForTranslators.push(fieldName as ContentBranches);
    }
  }

  return fieldsWithEmptyContextForTranslators;
};

/**
 * @return {string} - The default context for translators based on the field (aka 'visitedBranch').
 */
export const getLessonDefaultContextForTranslators = (lessonFocus: string, fieldName: ContentBranches) => {
  if (lessonFocus) {
    return `${startCase(lessonFocus)} lesson ${LESSON_FIELD_NAMES[fieldName]}`;
  }
};

/**
 *
 * @returns {object} - The default context for translators values of every passed field.
 */
export const getAllLessonDefaultContextForTranslators = (lessonFocus: string, fieldNames: ContentBranches[]) => {
  const defaultContextForTranslators: Partial<LessonDefaultContextForTranslators> = {};

  for (const fieldName in fieldNames) {
    const targetFieldName = fieldName as ContentBranches;
    defaultContextForTranslators[targetFieldName] = getLessonDefaultContextForTranslators(
      lessonFocus,
      fieldName as ContentBranches,
    );
  }

  return defaultContextForTranslators;
};
