import styled, { css } from 'styled-components/macro';

export const ValuePillContainer = styled.div<{ isToBeRemoved: boolean }>`
  ${({ isToBeRemoved, theme }) => css`
    align-items: center;
    border: solid 0.1rem ${isToBeRemoved ? theme.colorV2.utilityError : theme.colorV2.accentPrimary};
    border-radius: 1.2rem;
    color: ${isToBeRemoved ? theme.colorV2.utilityError : theme.colorV2.accentPrimary};
    display: inline-flex;
    font-size: 1.4rem;
    gap: ${theme.space.XS};
    line-height: 2.4rem;
    padding: 0 ${theme.space.S};
    transition:
      border 0.3s ease-in,
      color 0.3s ease-in;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }

    svg {
      vertical-align: middle;

      path {
        fill: ${theme.colorV2.textTertiary};
      }
    }
  `}
`;
