import type { LanguageV2 } from '@features/content/languages';

import { AudioRequestStatusType } from '../types';

export type AudioRequestParamsType = {
  selectedLanguage?: LanguageV2;
  selectedVoiceArtist?: string;
  selectedRequesters?: string[];
  selectedStatus?: AudioRequestStatusType;
  exportId?: string;
};

export const getAudioRequestParams = (params: AudioRequestParamsType): string => {
  const languageQuery = params.selectedLanguage ? `&language=${params.selectedLanguage}` : '';
  const voiceArtistQuery = params.selectedVoiceArtist ? `&voiceArtist=${params.selectedVoiceArtist}` : '';
  const statusQuery = params.selectedStatus ? `&status=${params.selectedStatus}` : '';
  const exportIdQuery = params.exportId ? `&exportId=${params.exportId}` : '';
  const requesterQuery = params.selectedRequesters?.reduce((acc, curr) => {
    return acc + `&requesters[]=${curr}`;
  }, '');

  const queryParams = [languageQuery, voiceArtistQuery, requesterQuery, statusQuery, exportIdQuery].join('');

  return queryParams;
};
