import { type FormikContextType, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import type { LoadingStageType } from '@common/enums/LoadingStage';
import type { DBId } from '@common/types/DBId';
import { userTracking } from '@features/app/tracking';
import { AVAILABLE_INTERFACE_LANGUAGES_V2, type LanguageV2 } from '@features/content/languages';
import type { OnOpenTranslationPanelParams } from '@features/content/translations';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsSaveButtonEnabled } from '@selectors/CoursesSelectors';
import { selectRoleplay, selectRoleplayCategory, selectRoleplayScenario } from '@selectors/roleplaySelectors';

import type {
  RoleplayCategoryFieldWithLocalizationNames,
  RoleplayCategoryFormikValues,
  RoleplayCategoryListItemType,
  RoleplayListItemType,
  RoleplayScenarioFieldWithLocalizationNames,
  RoleplayScenarioFormikValues,
} from './types';
import { FormikValueInterface } from '@helpers/formikInitialValuesHelper';

export const useRoleplay = () => {
  const dispatch = useAppDispatch();
  const formikContext: FormikContextType<RoleplayCategoryFormikValues | RoleplayScenarioFormikValues> =
    useFormikContext();

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);

  const { languageId, categoryId, scenarioId } = useParams<{
    languageId: LanguageV2;
    categoryId: DBId;
    scenarioId: DBId;
  }>();

  const roleplay = useAppSelector(selectRoleplay);
  const roleplayCategory = useAppSelector(selectRoleplayCategory);
  const roleplayScenario = useAppSelector(selectRoleplayScenario);

  const interfaceLanguages = AVAILABLE_INTERFACE_LANGUAGES_V2;

  let childrenArray: RoleplayListItemType[] | RoleplayCategoryListItemType = roleplay.categoriesData.categories;
  let isRoleplayLoaded: LoadingStageType = roleplay.loaded;
  let roleplayType: ContentTypesType = ContentTypes.roleplay;
  let roleplayContent = roleplay.content;
  let parentId;
  let onSave = () => {};

  if (languageId && categoryId && scenarioId) {
    roleplayType = ContentTypes.roleplayScenario;
    roleplayContent = roleplayScenario.content;
    isRoleplayLoaded = roleplayScenario.loaded;
    childrenArray = [];
    parentId = categoryId;

    onSave = () => {
      dispatch(
        RoleplayActionCreators.saveScenario({
          values: formikContext?.values as RoleplayScenarioFormikValues,
          languageId,
          roleplayId: roleplay.content.id,
          categoryId,
          scenarioId,
        }),
      );
      formikContext?.resetForm({ values: formikContext?.values });
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    };
  } else if (languageId && categoryId && !scenarioId) {
    roleplayType = ContentTypes.roleplayCategory;
    roleplayContent = roleplayCategory.content;
    isRoleplayLoaded = roleplayCategory.loaded;
    childrenArray = roleplayCategory.scenariosData.scenarios;
    parentId = roleplay.content.id;

    onSave = () => {
      dispatch(
        RoleplayActionCreators.saveCategory({
          values: formikContext?.values,
          languageId,
          roleplayId: roleplay.content.id,
          categoryId,
        }),
      );
      formikContext?.resetForm({ values: formikContext?.values });
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    };
  }

  // Update save button availability on content change
  useEffect(() => {
    const isRoleplayCategoryChanged =
      categoryId && !scenarioId && (roleplayCategory.content.iconChanged || formikContext?.dirty);

    const isRoleplayCategoryNotChanged =
      categoryId && !scenarioId && !(roleplayCategory.content.iconChanged || formikContext?.dirty);

    if (isRoleplayCategoryChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    } else if (isRoleplayCategoryNotChanged && isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    }

    const isRoleplayScenarioChanged = scenarioId && formikContext?.dirty;
    const isRoleplayScenarioNotChanged = scenarioId && !formikContext?.dirty;

    if (isRoleplayScenarioChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    } else if (isRoleplayScenarioNotChanged && isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    }
  }, [categoryId, dispatch, formikContext, isSaveButtonEnabled, roleplayCategory, scenarioId]);

  const onOpenTranslationPanel = ({
    defaultContextForTranslators,
    fieldName,
    isChangeBlocked,
    isEditDisabled,
    tab,
    type,
    visitedContentId,
  }: OnOpenTranslationPanelParams<
    RoleplayCategoryFieldWithLocalizationNames | RoleplayScenarioFieldWithLocalizationNames
  >) => {
    dispatch(
      RoleplayActionCreators.openTranslationsPanel(
        type,
        tab,
        visitedContentId,
        fieldName,
        isChangeBlocked,
        isEditDisabled,
        defaultContextForTranslators,
      ),
    );
  };

  const onRemoveString = (
    type: ContentTypesType,
    fieldName: RoleplayCategoryFieldWithLocalizationNames | RoleplayScenarioFieldWithLocalizationNames,
  ) => {
    dispatch(RoleplayActionCreators.removeString(type, fieldName));

    const { values, setFieldValue } = formikContext;

    const newValues = (
      values?.[
        fieldName as keyof (RoleplayCategoryFormikValues | RoleplayScenarioFormikValues)
      ] as FormikValueInterface[]
    )?.map((item) => {
      return {
        ...item,
        value: '',
      };
    });

    setFieldValue(fieldName, newValues);
    setFieldValue(`${fieldName}Changed`, true);
    setFieldValue(`${fieldName}Context`, '');
  };

  const onContentSuggestionsClick = (count: number, query: string) => {
    dispatch(
      SearchModalActionsCreator.showSearchV2Panel({
        contentType: roleplayType,
        filtersPreset: {
          string: {
            languages: [languageId],
          },
        },
        forReusing: true,
        predefinedType: 'string',
        language: languageId,
        query,
      }),
    );

    userTracking.logosSearchOpened({
      source: 'resource',
    });
  };

  return {
    childrenArray,
    interfaceLanguages,
    isRoleplayLoaded,
    languageId,
    parentId,
    roleplayContent,
    roleplayType,
    onContentSuggestionsClick,
    onOpenTranslationPanel,
    onRemoveString,
    onSave,
  };
};
