import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import type { LanguageV2 } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { CommonActions } from '@actions/CommonActions';
import { FillGapExerciseActions } from '@actions/FillGapExerciseActions';
import FillGapExerciseInterface from '@components/Exercises/FillGap/interfaces/FillGapExerciseInterface';
import { DBId } from '@common/types/DBId';

export const FillGapExerciseActionsCreator = {
  save: (exercise: FillGapExerciseInterface, learningLanguage: LanguageV2, values: FormikValuesInterface) => ({
    type: FillGapExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: FillGapExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setImage: (exercise: AnyExerciseInterface, image: File, progressHandler: Function) => ({
    type: FillGapExerciseActions.UPLOAD_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ExerciseTypes.fillgap,
      progressHandler,
    },
  }),
  nullifyDistractor: (field: string) => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: { field },
  }),
  removeImage: () => ({
    type: FillGapExerciseActions.REMOVE_IMAGE,
  }),
  removeAudio: ({ contentId, language }: { contentId: DBId | undefined; language: string }) => ({
    type: FillGapExerciseActions.REMOVE_AUDIO,
    payload: { contentId, language },
  }),
  removeVideo: () => ({
    type: FillGapExerciseActions.REMOVE_VIDEO,
  }),
};
