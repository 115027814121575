import { useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components, MultiValue, MultiValueGenericProps } from 'react-select';
import { useTheme } from 'styled-components';

import { DBId } from '@common/types/DBId';
import { LanguageV2 } from '@features/content/languages';

import { Modal, useDialogModal } from '@features/modal';
import { Text } from '@features/theme';
import { ReactComponent as PlusOutlinedIcon } from '@static/svg/plusOutlined.svg';
import { ReactComponent as SearchIcon } from '@static/svg/search.svg';

import { ValuePill } from '@features/content/exercises';

import { MatchLexicalItemCreateModal, MatchLexicalItemUnmatchModal } from './_modals';
import {
  getMatchLexicalItemCreatorSelectorStyles,
  MatchLexicalItemButton,
  MatchLexicalItemCreatorButtons,
  MatchLexicalItemCreatorWrapper,
} from './styles';
import type { MatchLexicalItemCreatorOption } from './types';

type ValueContainerCustomProps = MultiValueGenericProps<MultiValue<MatchLexicalItemCreatorOption>> & {
  onClick: (lexicalItemId: DBId) => void;
  onRemove: (value: MatchLexicalItemCreatorOption) => void;
};

const ValueContainerCustom = (props: ValueContainerCustomProps) => {
  const { data, onClick, onRemove } = props;

  return (
    <components.MultiValueContainer {...props}>
      <ValuePill label={data.label} onClick={() => onClick(data.value)} onRemove={() => onRemove(data)} />
    </components.MultiValueContainer>
  );
};

type MatchLexicalItemCreatorProps = {
  exerciseId: DBId;
  language: LanguageV2;
  values: MatchLexicalItemCreatorOption[];
  checkDuplicates: (query: string) => Promise<number>;
  onChange: (values: MatchLexicalItemCreatorOption[]) => void;
  onClick: (lexicalItemId: DBId) => void;
  onSearch: (query?: string) => void;
};

export const MatchLexicalItemCreator = ({
  language,
  values = [],
  checkDuplicates,
  onChange,
  onClick,
  onSearch,
}: MatchLexicalItemCreatorProps) => {
  const valueToRemove = useRef<MatchLexicalItemCreatorOption | null>(null);

  const theme = useTheme();
  const matchLexicalItemCreatorSelectorStyles = getMatchLexicalItemCreatorSelectorStyles(theme);

  const handleOnCreate = (value: MatchLexicalItemCreatorOption) => {
    const newValues = [...values, value];

    onChange(newValues);
  };

  const handleOnUnmatch = (value: MatchLexicalItemCreatorOption | null) => {
    if (!value) return;

    const newValues = [...values].filter(({ label }) => value.label !== label);

    onChange(newValues);
  };

  const { open: openCreateModal, modal: createModal } = useDialogModal((modalControls) => (
    <Modal lockScroll size="S" {...modalControls}>
      <MatchLexicalItemCreateModal
        language={language}
        close={() => {
          modalControls.close();
        }}
        checkDuplicates={checkDuplicates}
        onCreate={handleOnCreate}
        onSearch={onSearch}
      />
    </Modal>
  ));

  const { open: openUnmatchModal, modal: unmatchModal } = useDialogModal((modalControls) => (
    <Modal lockScroll size="S" {...modalControls}>
      <MatchLexicalItemUnmatchModal
        valueToRemove={valueToRemove.current}
        close={() => {
          modalControls.close();
        }}
        onCancel={() => {
          valueToRemove.current = null;
        }}
        onUnmatch={handleOnUnmatch}
      />
    </Modal>
  ));

  return (
    <>
      <MatchLexicalItemCreatorWrapper>
        <MatchLexicalItemCreatorButtons>
          <MatchLexicalItemButton icon={<PlusOutlinedIcon />} variant="text" onClick={openCreateModal}>
            Create
          </MatchLexicalItemButton>
          <Text as="span" level={3} variant="body">
            or
          </Text>
          <MatchLexicalItemButton icon={<SearchIcon />} variant="text" onClick={() => onSearch()}>
            Match
          </MatchLexicalItemButton>
        </MatchLexicalItemCreatorButtons>
        <CreatableSelect
          components={{
            MultiValueContainer: (props: MultiValueGenericProps<MultiValue<MatchLexicalItemCreatorOption>>) => (
              <ValueContainerCustom
                {...props}
                onClick={onClick}
                onRemove={(value) => {
                  valueToRemove.current = value;
                  openUnmatchModal();
                }}
              />
            ),
          }}
          isClearable
          isMulti
          placeholder="No lexical items attached"
          styles={matchLexicalItemCreatorSelectorStyles}
          value={values}
          onKeyDown={(evt) => {
            // entering text not allowed
            evt.preventDefault();
          }}
        />
      </MatchLexicalItemCreatorWrapper>
      {createModal}
      {unmatchModal}
    </>
  );
};
