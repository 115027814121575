import { DBId } from '@common/types/DBId';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { CommonActions } from '@actions/CommonActions';
import { ContentTypesType } from '@common/enums/ContentTypes';
import { CertificateLessonType } from '@common/enums/LessonTypes';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';

type BaseContentActionsCreator_uploadImageToLesson = {
  lessonId: DBId;
  contentIdBeingUpdated: DBId | undefined;
  file: File;
  courseId: DBId;
  contentId: DBId;
  progressHandler: (progress: number) => void;
};

type BaseContentActionsCreator_removeImageFromLesson = {
  lessonId: DBId;
  contentIdBeingUpdated: DBId | undefined;
  courseId: DBId;
  contentId: DBId;
};

export const BaseContentActionsCreator = {
  uploadImageToLesson: ({
    lessonId,
    contentIdBeingUpdated,
    file,
    courseId,
    contentId,
    progressHandler,
  }: BaseContentActionsCreator_uploadImageToLesson) => ({
    type: ContentTypesActions.LESSON_UPLOAD_IMAGE,
    payload: {
      lessonId,
      contentIdBeingUpdated,
      file,
      courseId,
      contentId,
      progressHandler,
    },
  }),
  uploadUnitImageToLesson: ({
    lessonId,
    contentIdBeingUpdated,
    file,
    courseId,
    contentId,
    progressHandler,
  }: BaseContentActionsCreator_uploadImageToLesson) => ({
    type: ContentTypesActions.LESSON_UPLOAD_UNIT_IMAGE,
    payload: {
      lessonId,
      contentIdBeingUpdated,
      file,
      courseId,
      contentId,
      progressHandler,
    },
  }),
  removeImageFromLesson: ({
    lessonId,
    courseId,
    contentId,
    contentIdBeingUpdated,
  }: BaseContentActionsCreator_removeImageFromLesson) => ({
    type: ContentTypesActions.LESSON_REMOVE_IMAGE,
    payload: {
      lessonId,
      courseId,
      contentId,
      contentIdBeingUpdated,
    },
  }),
  removeUnitImageFromLesson: ({
    lessonId,
    courseId,
    contentId,
    contentIdBeingUpdated,
  }: BaseContentActionsCreator_removeImageFromLesson) => ({
    type: ContentTypesActions.LESSON_REMOVE_UNIT_IMAGE,
    payload: {
      lessonId,
      courseId,
      contentId,
      contentIdBeingUpdated,
    },
  }),
  changeIdentityGroup: (identityGroup: { title: string; id: DBId } | null) => ({
    type: ContentTypesActions.CHANGE_IDENTITY_GROUP,
    payload: identityGroup,
  }),
  changeCertificateLessonType: (certificateType: CertificateLessonType) => ({
    type: ContentTypesActions.CHANGE_LESSON_CERTIFICATE_TYPE,
    payload: certificateType,
  }),
  setDefaultContextForTranslators: ({
    contentType,
    defaultContextValues,
  }: {
    contentType: ContentTypesType;
    defaultContextValues: Partial<Record<keyof GenericContentInterface, string>>;
  }) => ({
    type: CommonActions.SET_DEFAULT_CONTEXT_FOR_TRANSLATORS,
    payload: { contentType, defaultContextValues },
  }),
};
