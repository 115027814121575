import styled from 'styled-components/macro';

import { DBId } from '@common/types/DBId';
import { HelpDisplayer } from '@features/help';
import { isFeatureEnabled } from '@helpers/featuresHelper';

import type { AvailableSearchType } from '../../../types';

import type { FavouriteSearch } from '../SearchContent/Common/favouriteSearches';
import { ReactComponent as ActivityImage } from '../img/activity.svg';
import { ReactComponent as BundleImage } from '../img/bundle.svg';
import { ReactComponent as ChapterImage } from '../img/chapter.svg';
import { ReactComponent as ExerciseImage } from '../img/exercise.svg';
import { ReactComponent as IdImage } from '../img/id.svg';
import { ReactComponent as LessonImage } from '../img/lesson.svg';
import { ReactComponent as StringImage } from '../img/string.svg';

import { FavouriteSearches } from './FavouriteSearches';
import { SearchTypeCard } from './SearchTypeCard';

const SearchTypeSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justifiy-content: flex-start;
  gap: 3.2rem;
`;

const SearchTypeCardsTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
`;

const SearchTypeCardsWrapper = styled.div`
  display: grid;
  grid-column-gap: 2.4rem;
  grid-template-columns: repeat(4, 1fr);
`;

const Divider = styled.hr`
  border-color: ${({ theme }) => theme.colorV2.uiDivider};
  margin: 0;
  width: 100%;
`;

type SearchTypeSelectorProps = {
  favouriteSearches: FavouriteSearch<any>[];
  removeFavouriteSearch: (favouriteSearchId: DBId) => void;
  selectSearchType: (type: AvailableSearchType) => void;
  selectFavouriteSearch: (favouriteSearch: FavouriteSearch<any>) => void;
  updateFavouriteSearch: (favouriteSearchId: DBId, name: string) => void;
};
export const SearchTypeSelector = ({
  favouriteSearches,
  removeFavouriteSearch,
  selectSearchType,
  selectFavouriteSearch,
  updateFavouriteSearch,
}: SearchTypeSelectorProps) => {
  return (
    <SearchTypeSelectorWrapper>
      {favouriteSearches.length > 0 && (
        <>
          <SearchTypeCardsTitle>Use favourite search</SearchTypeCardsTitle>
          <FavouriteSearches
            favouriteSearches={favouriteSearches}
            onRemove={removeFavouriteSearch}
            onSearch={(favouriteSearch) => selectFavouriteSearch(favouriteSearch)}
            onUpdate={updateFavouriteSearch}
          />
          <Divider />
        </>
      )}
      <SearchTypeCardsTitle>{favouriteSearches.length > 0 ? 'or ' : ''}Select a search type</SearchTypeCardsTitle>
      <SearchTypeCardsWrapper>
        <SearchTypeCard
          icon={<IdImage />}
          title="ID-based Search"
          howToHelp={<HelpDisplayer id="how-to-search-id" type="how-to" />}
          onClick={() => selectSearchType('id')}
        />
        <SearchTypeCard
          icon={<StringImage />}
          title="String Search"
          howToHelp={<HelpDisplayer id="how-to-search-string" type="how-to" />}
          onClick={() => selectSearchType('string')}
        />
        <SearchTypeCard
          icon={<BundleImage />}
          title="Bundle Search"
          howToHelp={<HelpDisplayer id="how-to-search-bundle" type="how-to" />}
          onClick={() => selectSearchType('bundle')}
        />
        <SearchTypeCard
          icon={<ExerciseImage />}
          title="Exercise Search"
          howToHelp={<HelpDisplayer contentPosition="bottom-right" id="how-to-search-exercise" type="how-to" />}
          onClick={() => selectSearchType('exercise')}
        />
        <SearchTypeCard
          icon={<ActivityImage />}
          title="Activity Search"
          howToHelp={<HelpDisplayer type="how-to" id="how-to-search-activity" />}
          onClick={() => selectSearchType('activity')}
        />
        <SearchTypeCard
          icon={<LessonImage />}
          title="Lesson Search"
          howToHelp={<HelpDisplayer type="how-to" id="how-to-search-lesson" />}
          onClick={() => selectSearchType('lesson')}
        />
        <SearchTypeCard
          icon={<ChapterImage />}
          title="Chapter Search"
          howToHelp={<HelpDisplayer type="how-to" id="how-to-search-chapter" />}
          onClick={() => selectSearchType('chapter')}
        />
      </SearchTypeCardsWrapper>
    </SearchTypeSelectorWrapper>
  );
};
