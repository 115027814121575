import filterIcon from '../_img/filter.svg';
import { areFiltersSelected } from './util';
import { FilterActionButton, FiltersCol, FiltersContainer, StyledSearchBox } from './styles';
import type { FiltersState, FilterValue } from './types';
import { WordsRangeFilter } from './FilterSelectors';

type VocabularyReviewFiltersProps = {
  filters: FiltersState;
  onApply: () => void;
  onClear: () => void;
  onFilter: (filterType: keyof FiltersState, value: FilterValue) => void;
};

export const VocabularyReviewFilters = ({ filters, onApply, onClear, onFilter }: VocabularyReviewFiltersProps) => {
  const filterButtonsDisabled = !areFiltersSelected(filters);

  return (
    <FiltersContainer data-testid="vocabulary-review-filters-container">
      <FiltersCol>
        <WordsRangeFilter
          rule={''}
          value={filters.wordsRange}
          onChange={(selectedWordsRange) => {
            onFilter('wordsRange', selectedWordsRange);
          }}
        />
      </FiltersCol>
      <FiltersCol>
        <StyledSearchBox
          filterTerm={filters.query || ''}
          inputIcon={filterIcon}
          onClear={() => onFilter('query', '')}
          onSearchChange={(value: string) => onFilter('query', value)}
          placeholder="Filter by phrase"
        />
        <FilterActionButton disabled={filterButtonsDisabled} size="S" variant="secondary" onClick={onApply}>
          Apply Filters
        </FilterActionButton>
        <FilterActionButton disabled={filterButtonsDisabled} size="S" variant="tertiary" onClick={onClear}>
          Clear All
        </FilterActionButton>
      </FiltersCol>
    </FiltersContainer>
  );
};
