import styled, { css } from 'styled-components/macro';

export const CharsLimitWrapper = styled.div<{ errored: boolean }>`
  ${({ errored, theme }) => css`
    color: ${errored ? theme.colorV2.utilityError : theme.colorV2.textQuaternary};
    font-size: 1rem;
    height: 1.2rem;
    line-height: 1.2rem;
    text-align: left;
    position: absolute;
    right: 0;
    top: -1.8rem;
    width: fit-content;
  `}
`;

export const WritableInputTextWrapper = styled.div`
  position: relative;
`;
