import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { LoadedHighlighterExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import { HighlighterContentBranches } from '@components/Exercises/Highlighter/types/HighlighterContentBranches';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { PreviewModal } from '@features/content/preview';
import { Explanation, Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { HighlighterTool } from './HighlighterTool/HighlighterTool';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExperimentSelector } from '@features/experiment';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { constants as contentConstants } from '@features/content';
import { Instructions, removeMediaProcessingValidationError } from '@features/content/exercises';

import { HighlighterExerciseActionsCreator } from '@actionCreators/HighlighterExerciseActionsCreator';
import HighlighterExerciseInterface from './interfaces/HighlighterExerciseInterface';
import { type LanguageV2, LANGUAGE_NAMES_V2 } from '@features/content/languages';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

export const HighlighterExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedHighlighterExerciseInterface>) => {
  const dispatch = useAppDispatch();
  const { isEditorHidden: isEditorHiddenPhrase0 } = useIsEditorHidden({ visitedBranch: 'phrase0' });
  const { isEditorHidden: isEditorHiddenPhrase1 } = useIsEditorHidden({ visitedBranch: 'phrase1' });
  const { isEditorHidden: isEditorHiddenPhrase2 } = useIsEditorHidden({ visitedBranch: 'phrase2' });
  const { isEditorHidden: isEditorHiddenPhrase3 } = useIsEditorHidden({ visitedBranch: 'phrase3' });
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as HighlighterExerciseInterface;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));

  const [phrase0AudioLocalizations, phrase1AudioLocalizations, phrase2AudioLocalizations, phrase3AudioLocalizations] =
    loadedExercise?.content?.phrases
      ? loadedExercise?.content?.phrases.map((phrase) => {
          if (phrase) {
            return phrase.audioLocalizations.filter((audioLocalization) =>
              availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
            );
          }

          return undefined;
        })
      : [];

  const { isEditAvailable } = useIsEditAvailable();
  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const phrasesErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'phrases' || error.field.includes('phrases'),
  );

  if (phrasesErrors.length) {
    let updatedErrors = [...errors];

    [
      phrase0AudioLocalizations,
      phrase1AudioLocalizations,
      phrase2AudioLocalizations,
      phrase3AudioLocalizations,
    ].forEach((phraseAudioLocalization, phraseIndex) => {
      if (phraseAudioLocalization) {
        phraseAudioLocalization.forEach(({ language, processed, value }: LocalizationInterface) => {
          if (processed && value) {
            updatedErrors = removeMediaProcessingValidationError({
              errors,
              fieldName: `phrases[${phraseIndex}]`,
              message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
            });
          }
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const renderPhraseEditor = (
    fieldName: Extract<HighlighterContentBranches, 'phrase0' | 'phrase1' | 'phrase2' | 'phrase3'>,
    row: number,
  ) => (
    <HighlighterTool
      fieldName={fieldName}
      row={row}
      dataSourceMode={true ? 'editorState' : 'defaultEditorState'}
      placeholder={`Write in ${LANGUAGE_NAMES_V2[courseLearningLanguage as LanguageV2]}`}
      errors={phrasesErrors.filter((error: ValidationErrorInterface) => error.field === `phrases[${row}]`)}
    />
  );

  return (
    <div className="exercise-highlighter">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Highlighter</Title>
        <HelpDisplayer type="guideline" id="guideline-highlighter-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-highlighter__main-wrapper">
        <div className="exercise-highlighter__wrapper">
          <div className="exercise-highlighter__container">
            <h1 className="exercise-highlighter__box-title">{'Instruction'}</h1>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </div>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(HighlighterExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
            }}
          />
        </div>

        <div className="exercise-highlighter__wrapper">
          <main className="exercise-highlighter__main">
            <div className="exercise-highlighter__wrapper">
              <div className="exercise-highlighter__container">
                <h3 className="exercise-highlighter__box-title">Phrase</h3>
                <ValidationErrorDisplayer
                  text={helpersService.getValidationErrorMessageText(
                    phrasesErrors.filter(
                      (error: ValidationErrorInterface) => error.field === 'phrases[0]' || error.field === 'phrases',
                    ),
                  )}
                />
              </div>
              {isEditorHiddenPhrase0 ? <div>{renderPhraseEditor('phrase0', 0)}</div> : renderPhraseEditor('phrase0', 0)}
            </div>

            <div className="exercise-highlighter__wrapper">
              <ContentToggler
                text="Phrase"
                open={loadedExercise.content.phrases?.[1] !== undefined}
                onSwitch={(switched: boolean) =>
                  switched && dispatch(HighlighterExerciseActionsCreator.setPhrase1Text('', courseLearningLanguage))
                }
                onRemove={() => dispatch(HighlighterExerciseActionsCreator.nullifyPhrase(1))}
                errors={phrasesErrors.filter((error: ValidationErrorInterface) => error.field === 'phrases[1]')}
              >
                {isEditorHiddenPhrase1 ? (
                  <div>{renderPhraseEditor('phrase1', 1)}</div>
                ) : (
                  renderPhraseEditor('phrase1', 1)
                )}
              </ContentToggler>
            </div>

            <div className="exercise-highlighter__wrapper">
              <ContentToggler
                text="Phrase"
                open={loadedExercise.content.phrases?.[2] !== undefined}
                onSwitch={(switched: boolean) =>
                  switched && dispatch(HighlighterExerciseActionsCreator.setPhrase2Text('', courseLearningLanguage))
                }
                onRemove={() => dispatch(HighlighterExerciseActionsCreator.nullifyPhrase(2))}
                errors={phrasesErrors.filter((error: ValidationErrorInterface) => error.field === 'phrases[2]')}
              >
                {isEditorHiddenPhrase2 ? (
                  <div>{renderPhraseEditor('phrase2', 2)}</div>
                ) : (
                  renderPhraseEditor('phrase2', 2)
                )}
              </ContentToggler>
            </div>

            <div className="exercise-highlighter__wrapper">
              <ContentToggler
                text="Phrase"
                open={loadedExercise.content.phrases?.[3] !== undefined}
                onSwitch={(switched: boolean) =>
                  switched && dispatch(HighlighterExerciseActionsCreator.setPhrase3Text('', courseLearningLanguage))
                }
                onRemove={() => dispatch(HighlighterExerciseActionsCreator.nullifyPhrase(3))}
                errors={phrasesErrors.filter((error: ValidationErrorInterface) => error.field === 'phrases[3]')}
              >
                {isEditorHiddenPhrase3 ? (
                  <div>{renderPhraseEditor('phrase3', 3)}</div>
                ) : (
                  renderPhraseEditor('phrase3', 3)
                )}
              </ContentToggler>
            </div>
          </main>
        </div>

        <div className="exercise-highlighter__wrapper">
          <Explanation />
        </div>
        <RecapSelector exerciseId={loadedExercise?.content?.id} />
      </div>
    </div>
  );
};
