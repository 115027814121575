import { Fragment, useCallback, useEffect, useState } from 'react';
import { Alert, Button, Tab, Tabs } from 'react-bootstrap';
import { FormikContextType, useFormikContext } from 'formik';
import lodash from 'lodash';
import styled from 'styled-components/macro';

import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { AnyExerciseContentBranch, CourseContentBranches } from '@common/types/AnyExerciseContentBranch';
import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { PreloaderFullscreen } from '@components/Loaders/PreloaderFullscreen';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import TranslationsPanelService from '@services/TranslationsPanelService';
import { ContentType, ContentTypes } from '@common/enums/ContentTypes';
import { constants as contentConstants, formatCounterOfFilledLocalizations } from '@features/content';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { useIsAdmin } from '@features/auth/roles';
import { useIsEditAvailable } from '@features/content/courses';
import { getLink } from '@helpers/urlLinkHelper';
import ChevronRightIcon from '@static/svg/translationsPanel/Chevron Right.svg';
import ExternalLinkIcon from '@static/svg/translationsPanel/External link.svg';
import ArrowLeftIcon from '@static/svg/translationsPanel/Arrow left.svg';
import WarningIcon from '@static/svg/translationsPanel/Warning.svg';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ReactComponent as ExclamationIcon } from '@components/ValidationErrorDisplayer/exclamation.svg';

import { TranslationsPanelActionsCreators } from '@actionCreators/TranslationsPanelActionsCreators';
import { TranslationsBlock } from './TranslationsBlock';
import { AudiosBlock } from './AudiosBlock';
import { ALL_LANGUAGES_V2, DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';
import { TranslationPanelProps } from './TranslationsPanelProps';
import { InactiveTranslations } from './InactiveTranslations';
import { InactiveAudios } from './InactiveAudios';
import TranslationsPanelUtils from './TranslationsPanelUtils';
import { SidePanelActionsCreator } from '@actionCreators/SidePanelActionsCreator';
import { SidePanel, SidePanelBody, SidePanelHeader } from '@features/theme';
import {
  selectIsIssuesShown,
  selectTranslationsPanelBundleName,
  selectTranslationsPanelColumn,
  selectTranslationsPanelDefaultContextForTranslators,
  selectTranslationsPanelDisableLearningLanguage,
  selectTranslationsPanelIsBundleBlocked,
  selectTranslationsPanelIsStringBlocked,
  selectTranslationsPanelPreopenedTab,
  selectTranslationsPanelRow,
  selectTranslationsPanelShowOtherLocations,
  selectTranslationsPanelTranslationsMode,
  selectTranslationsPanelVisitedBranch,
  selectTranslationsPanelVisitedContentId,
} from '@selectors/UiSelectors';
import { selectCourse, selectInterfaceLanguages, selectLearningLanguage } from '@selectors/CoursesSelectors';

const ContextWarning = styled.div`
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.colorV2.utilityAlertBackground};
  border-radius: 0.6rem;
  font-size: 1.4rem;
  gap: 0.8rem;
  padding: 0.8rem;
  margin: 1.6rem 0;
  width: fit-content;

  svg {
    display: inline-block;
    vertical-align: text-bottom;

    path {
      fill: ${({ theme }) => theme.colorV2.utilityError};
    }
  }
`;

const HIGHLIGHTER_EXERCISE_PHRASE_VALIDATION_REGEXP = /^phrase(\d)/;
const MATCHUP_EXERCISE_FIXED_ITEM_VALIDATION_REGEXP = /^(fixedItem)(\d)/;
const MATCHUP_EXERCISE_MATCHING_ITEM_VALIDATION_REGEXP = /^(matchingItem)(\d)/;

const TranslationsPanel = ({ content, type, exerciseType = ContentTypes.flashcard }: TranslationPanelProps) => {
  const { values, resetForm }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  //TODO: when instructions is null from the API the translations panel appears empty
  const selectedCourse: CourseInterface = useAppSelector(selectCourse);
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const row = useAppSelector(selectTranslationsPanelRow);
  const column = useAppSelector(selectTranslationsPanelColumn);
  const showOtherLocations = useAppSelector(selectTranslationsPanelShowOtherLocations);

  const rawVisitedContentId = useAppSelector(selectTranslationsPanelVisitedContentId);
  const visitedContentId: string = rawVisitedContentId?.length ? rawVisitedContentId : 'undefined';

  const visitedBranch: AnyExerciseContentBranch | CourseContentBranches = useAppSelector(
    selectTranslationsPanelVisitedBranch,
  );
  const bundleName: string = useAppSelector(selectTranslationsPanelBundleName);
  const isStringChangeBlocked: boolean | undefined = useAppSelector(selectTranslationsPanelIsStringBlocked);
  const isBundleChangeBlocked: boolean | undefined = useAppSelector(selectTranslationsPanelIsBundleBlocked);

  const translationsPanelMode = useAppSelector(selectTranslationsPanelTranslationsMode);
  const preopenedTab = useAppSelector(selectTranslationsPanelPreopenedTab);
  const disableLearningLanguage = useAppSelector(selectTranslationsPanelDisableLearningLanguage);
  const defaultContextForTranslators = useAppSelector(selectTranslationsPanelDefaultContextForTranslators);

  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const isAdmin = useIsAdmin();

  let validationErrors = bundleName
    ? content?.validation?.errors.filter((error: any) => error.field === `${bundleName}.${visitedBranch}`) || []
    : content?.validation?.errors.filter((error: any) => error.field === visitedBranch) || [];

  if (visitedBranch === 'titleWithLocalizations') {
    validationErrors = content.validation.errors.filter((error: ValidationErrorInterface) => error.field === 'title');
  } else if (visitedBranch === 'descriptionWithLocalizations') {
    validationErrors = content.validation.errors.filter(
      (error: ValidationErrorInterface) => error.field === 'description',
    );
  } else if (visitedBranch === 'script') {
    validationErrors = content.validation.errors.filter(
      (error: ValidationErrorInterface) => error.field === `scriptContents[${row}]`,
    );
  } else if (visitedBranch === 'examples') {
    validationErrors = content.validation.errors.filter((error: ValidationErrorInterface) =>
      error.field.includes('exampleValues'),
    );
  } else if (HIGHLIGHTER_EXERCISE_PHRASE_VALIDATION_REGEXP.test(visitedBranch)) {
    const errorFieldName = visitedBranch.replace(HIGHLIGHTER_EXERCISE_PHRASE_VALIDATION_REGEXP, 'phrases[$1]');

    validationErrors = content.validation.errors.filter(
      (error: ValidationErrorInterface) => error.field === errorFieldName,
    );
  } else if (MATCHUP_EXERCISE_FIXED_ITEM_VALIDATION_REGEXP.test(bundleName)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, bundleRootName, bundlePairIndex] = bundleName.match(MATCHUP_EXERCISE_FIXED_ITEM_VALIDATION_REGEXP) || [];

    if (bundleRootName && bundlePairIndex) {
      const validationErrorPairIndex = parseInt(bundlePairIndex) - 1;
      const errorFieldName = `pairs[${validationErrorPairIndex}].${bundleRootName}`;

      validationErrors = content.validation.errors.filter(
        (error: ValidationErrorInterface) => error.field === errorFieldName,
      );
    }
  } else if (MATCHUP_EXERCISE_MATCHING_ITEM_VALIDATION_REGEXP.test(bundleName)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, bundleRootName, bundlePairIndex] =
      bundleName.match(MATCHUP_EXERCISE_MATCHING_ITEM_VALIDATION_REGEXP) || [];

    if (bundleRootName && bundlePairIndex) {
      const validationErrorPairIndex = parseInt(bundlePairIndex) - 1;
      const errorFieldName = `pairs[${validationErrorPairIndex}].${bundleRootName}`;

      validationErrors = content.validation.errors.filter(
        (error: ValidationErrorInterface) => error.field === errorFieldName,
      );
    }
  }

  const generatePanelTitle = () => {
    let panelTitleName: string = bundleName
      ? (content as any)[bundleName][visitedBranch]?._id || ContentType.levelOrLesson
      : (content as any)[visitedBranch]?._id || ContentType.levelOrLesson;

    if (
      type === ContentTypes.exercise &&
      exerciseType === ExerciseTypes.dialogue &&
      visitedBranch === contentConstants.DIALOGUE_EXERCISE_SCRIPT_STATE_BRANCH_NAME
    ) {
      panelTitleName = !(content as any)[visitedBranch]?.[row as number].line._id
        ? ContentType.levelOrLesson
        : (content as any)[visitedBranch]?.[row as number].line._id;
    }

    if (type === ContentTypes.exercise && exerciseType === ExerciseTypes.tip && visitedBranch === 'examples') {
      panelTitleName = (content as any)[visitedBranch][row as number][column as number]?._id;
    }

    return panelTitleName === '' ? 'Unsaved string' : `Edit ${panelTitleName}`;
  };

  const panelTitle: string = generatePanelTitle();

  let [selectedTab, setSelectedTab] = useState(preopenedTab);

  let visitedContentBranch: TranslationsPanelContentInterface = bundleName
    ? (content as any)[bundleName][visitedBranch]
    : (content as any)[visitedBranch];

  if (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)) {
    visitedContentBranch = (content as any).phrases;
  }

  let [otherLocationsNumberOfItems, setOtherLocationsNumberOfItems] = useState('');
  let [otherLocations, setOtherLocations] = useState<OtherLocationsInterface[][]>([]);

  let getTotalOtherLocations = async () => {
    let otherLocationsPayload = await TranslationsPanelService.getOtherLocations(visitedContentId);
    const realOtherLocations = otherLocationsPayload.data.filter((mappings: OtherLocationsInterface[]) =>
      mappings[mappings.length - 1].type === 'resourceBundle'
        ? mappings[mappings.length - 2].id !== content.id
        : mappings[mappings.length - 1].id !== content.id,
    );
    setOtherLocations(realOtherLocations);
    setOtherLocationsNumberOfItems(`${realOtherLocations.length}`);
  };

  const footer = (
    <footer className="translations-panel-modal__save-cancel">
      <Button
        className="translations-panel-modal__cancel"
        onClick={() => {
          dispatch(SidePanelActionsCreator.hideSidePanel());
        }}
      >
        Cancel
      </Button>
      <Button
        className="translations-panel-modal__save"
        disabled={!isEditAvailable}
        onClick={async () => {
          switch (type) {
            case ContentTypes.exercise:
              dispatch(TranslationsPanelActionsCreators.save(values));
              break;
            case ContentTypes.course:
              dispatch(TranslationsPanelActionsCreators.saveCourse(values));
              break;
            case ContentType.levelOrLesson:
              dispatch(TranslationsPanelActionsCreators.saveLevelAndLesson(values));
              break;
            default:
              console.log('Unsupported type');
          }

          dispatch(SidePanelActionsCreator.hideSidePanel());
          resetForm({ values });
        }}
      >
        Save
      </Button>
    </footer>
  );

  useEffect(() => {
    getTotalOtherLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const textLocalizations = TranslationsPanelUtils.discoverLocalizationBlock(
    visitedContentBranch,
    visitedBranch,
    row,
    column,
    'textLocalizations',
    content,
    bundleName,
  );

  const audioLocalizations = TranslationsPanelUtils.discoverLocalizationBlock(
    visitedContentBranch,
    visitedBranch,
    row,
    column,
    'audioLocalizations',
    content,
    bundleName,
  );
  const fieldName = bundleName ? `${bundleName}_${visitedBranch}` : visitedBranch;

  const totalTextLocalizations = formatCounterOfFilledLocalizations(
    (values as any)[fieldName]
      ? (values as any)[fieldName].filter((item: any) => item.value && !item.isPhonetic)
      : textLocalizations,
    interfaceLanguages as LanguageV2[],
    courseLearningLanguage as LanguageV2,
  );
  const totalAudioLocalizations = formatCounterOfFilledLocalizations(
    audioLocalizations || [],
    interfaceLanguages as LanguageV2[],
    courseLearningLanguage as LanguageV2,
  );

  const missingContext = TranslationsPanelUtils.getMissingContext(textLocalizations);

  const getValue = () => {
    return TranslationsPanelUtils.extractDescription(
      content,
      visitedBranch,
      exerciseType as ExerciseTypesType,
      row,
      column,
      bundleName,
    );
  };

  let [context, setContext] = useState(getValue() || defaultContextForTranslators);

  let onChangeHandler = (e: any) => {
    if (isStringChangeBlocked || isBundleChangeBlocked) {
      dispatch(
        TranslationTipActionsCreator.setCurrentContentId(
          isBundleChangeBlocked ? (bundleName ? (content as any)[bundleName]?._id : '') : visitedContentId || '',
          type || '',
          visitedBranch || '',
          row,
          column,
          bundleName,
          isBundleChangeBlocked,
        ),
      );
    } else {
      setContext(e.target.value);
      debounceHandler(e.target.value);
    }
  };

  const customChangeHandler = (val: any) => {
    dispatch(
      TranslationsPanelActionsCreators.updateTranslatorContext(val, visitedBranch, type, row, column, bundleName),
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandler = useCallback(
    lodash.debounce((val) => customChangeHandler(val), 500),
    [],
  );

  if (content === null) {
    return <PreloaderFullscreen />;
  }

  const extractedAudioLocalizations = TranslationsPanelUtils.getAudioLocalizations(
    content,
    exerciseType as ExerciseTypesType,
    visitedBranch,
    row,
    column,
    bundleName,
  );
  const notAllLanguagesSelected = selectedCourse.interfaceLanguages.length < ALL_LANGUAGES_V2.length - 1;

  const isDefaultLanguage = (localization: LocalizationInterface) => {
    return localization.language === DEFAULT_LANGUAGE_V2;
  };

  const isCourseLearningLanguage = (localization: LocalizationInterface) => {
    return localization.language === selectedCourse.learningLanguage;
  };

  const isInterfaceLanguage = (localization: LocalizationInterface) => {
    return selectedCourse.interfaceLanguages.includes(localization.language);
  };

  const defaultLanguagesText = textLocalizations.filter(
    (loc: LocalizationInterface) => isInterfaceLanguage(loc) || isDefaultLanguage(loc) || isCourseLearningLanguage(loc),
  );
  const hiddenLanguagesText = textLocalizations.filter(
    (loc: LocalizationInterface) =>
      !isInterfaceLanguage(loc) && !isDefaultLanguage(loc) && !isCourseLearningLanguage(loc),
  );
  const defaultLanguagesAudio = extractedAudioLocalizations.filter(
    (loc: LocalizationInterface) => isInterfaceLanguage(loc) || isDefaultLanguage(loc) || isCourseLearningLanguage(loc),
  );
  const hiddenLanguagesAudio = extractedAudioLocalizations.filter(
    (loc: LocalizationInterface) =>
      !isInterfaceLanguage(loc) && !isDefaultLanguage(loc) && !isCourseLearningLanguage(loc),
  );

  const onTranslationChangeHandler = (
    updatedLocalizationString: string,
    localizationLanguageCode: string,
    isPhonetic?: boolean,
  ) => {
    switch (type) {
      case ContentTypes.exercise:
        dispatch(
          TranslationsPanelActionsCreators.updateLocalization(
            localizationLanguageCode,
            updatedLocalizationString,
            visitedBranch,
            row,
            column,
            isPhonetic,
            bundleName,
          ),
        );
        break;
      default:
        console.log('Unsupported type');
    }
  };

  return (
    <SidePanel
      className="translations-panel-modal"
      onClose={() => dispatch(SidePanelActionsCreator.hideSidePanel())}
      header={<SidePanelHeader>{panelTitle}</SidePanelHeader>}
      body={
        <SidePanelBody>
          <Tabs activeKey={selectedTab} onSelect={(selectedTabString: any) => setSelectedTab(selectedTabString)}>
            <Tab eventKey="translations" title={`Translations (${totalTextLocalizations})`}>
              {+otherLocationsNumberOfItems > 0 && showOtherLocations && (
                <Alert
                  variant="warning"
                  className="translations-panel-modal__alert"
                  onClick={() => setSelectedTab('other')}
                >
                  <img src={WarningIcon} alt="Warning" className="translations-panel-modal__alert-icon" />
                  <span className="translations-panel-modal__other-locations-text">
                    This string lives in {otherLocationsNumberOfItems} other locations
                  </span>
                  <img
                    src={ArrowLeftIcon}
                    alt="Go to other locations"
                    className="translations-panel-modal__other-locations-icon"
                  />
                </Alert>
              )}
              <div className="translations-panel-modal__translation-context-label-wrapper">
                <span className="translations-panel-modal__translation-context-label">Context for translators</span>

                <textarea
                  placeholder="Add context for translators"
                  className="translations-panel-modal__translator-context"
                  value={context || ''}
                  onChange={onChangeHandler}
                  disabled={(visitedContentId?.includes('default_') && !isAdmin) || !isEditAvailable}
                />

                {!!missingContext.length && (
                  <ContextWarning>
                    <ExclamationIcon />
                    Check if context describe the meaning word{missingContext.length > 1 ? 's' : ''}:{' '}
                    {missingContext.map((part) => `${part}= `).join(', ')}.
                  </ContextWarning>
                )}
              </div>
              <TranslationsBlock
                bundleId={bundleName ? (content as any)[bundleName]?._id : ''}
                bundleName={bundleName}
                column={column}
                contentId={visitedContentId}
                courseLearningLanguage={courseLearningLanguage}
                disabled={visitedContentId?.includes('default_') && !isAdmin}
                disableLearningLanguage={disableLearningLanguage}
                exerciseType={exerciseType}
                isBundleChangeBlocked={isBundleChangeBlocked}
                isChangeBlocked={isStringChangeBlocked || isBundleChangeBlocked}
                otherPlacesCount={+otherLocationsNumberOfItems || 0}
                row={row}
                show={true}
                storeBranch={visitedBranch}
                translationsBlockValuesArray={defaultLanguagesText || []}
                translationsPanelMode={translationsPanelMode}
                type={type}
                validationErrors={isIssuesShown ? validationErrors : []}
                onTranslationChange={onTranslationChangeHandler}
              />
              {notAllLanguagesSelected ? (
                <InactiveTranslations
                  bundleId={bundleName ? (content as any)[bundleName]?._id : ''}
                  bundleName={bundleName}
                  column={column}
                  contentId={visitedContentId}
                  courseLearningLanguage={courseLearningLanguage}
                  disableLearningLanguage={disableLearningLanguage}
                  exerciseType={exerciseType}
                  isBundleChangeBlocked={isBundleChangeBlocked}
                  isChangeBlocked={isStringChangeBlocked || isBundleChangeBlocked}
                  otherLocationsNumber={+otherLocationsNumberOfItems}
                  row={row}
                  storeBranch={visitedBranch}
                  translationsBlockValuesArray={hiddenLanguagesText || []}
                  translationsPanelMode={translationsPanelMode}
                  type={type}
                  validationErrors={isIssuesShown ? validationErrors : []}
                  onTranslationChange={onTranslationChangeHandler}
                />
              ) : null}
              {footer}
            </Tab>
            <Tab eventKey="audios" title={`Audios (${totalAudioLocalizations})`}>
              <AudiosBlock
                content={content}
                exerciseId={content.id}
                exerciseType={exerciseType as ExerciseTypesType}
                courseLearningLanguage={courseLearningLanguage}
                contentId={visitedContentId}
                disabled={visitedContentId?.includes('default_') && !isAdmin}
                visitedBranch={visitedBranch}
                row={row}
                column={column}
                validationErrors={isIssuesShown ? validationErrors : []}
                translationsBlockValuesArray={defaultLanguagesAudio || []}
                show={true}
                bundleName={bundleName}
                isBundleChangeBlocked={isBundleChangeBlocked}
                type={type}
              />
              {notAllLanguagesSelected ? (
                <InactiveAudios
                  content={content}
                  exerciseId={content.id}
                  exerciseType={exerciseType as ExerciseTypesType}
                  courseLearningLanguage={courseLearningLanguage}
                  contentId={visitedContentId}
                  visitedBranch={visitedBranch}
                  bundleName={bundleName}
                  isBundleChangeBlocked={isBundleChangeBlocked}
                  row={row}
                  column={column}
                  validationErrors={isIssuesShown ? validationErrors : []}
                  translationsBlockValuesArray={hiddenLanguagesAudio || []}
                  type={type}
                />
              ) : null}
              {footer}
            </Tab>
            {showOtherLocations && (
              <Tab
                eventKey="other"
                title={`Other locations (${otherLocationsNumberOfItems})`}
                disabled={otherLocationsNumberOfItems === '0'}
              >
                <ul className="translations-panel-modal__other-locations">
                  {otherLocations?.map((otherLocation: OtherLocationsInterface[], idx: number) => {
                    return (
                      <li className="translations-panel-modal__other-location" key={idx}>
                        <a
                          href={getLink(otherLocation)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="translations-panel-modal__other-location-link"
                        >
                          <span className="translations-panel-modal__other-location-text">
                            {otherLocation.map((location) => (
                              <Fragment key={`${idx}-${location.id}`}>
                                {location.label || 'Resource Bundle'} <img src={ChevronRightIcon} alt="" />{' '}
                              </Fragment>
                            ))}
                            <img
                              src={ExternalLinkIcon}
                              alt="Open in a new tab"
                              className="translations-panel-modal__other-location-external-link-icon"
                            />
                          </span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Tab>
            )}
          </Tabs>
        </SidePanelBody>
      }
    />
  );
};

export default TranslationsPanel;
