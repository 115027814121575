import { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { GrammarReviewData } from './GrammarReviewData';
import { SearchModal } from '@features/search';
import { ContentOwner } from '@features/content/contentOwnership';

import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { Header } from '../Header';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse } from '@selectors/CoursesSelectors';
import { selectGrammarReview } from '@selectors/GrammarSelectors';

const GrammarReview = () => {
  const dispatch = useAppDispatch();
  const { courseId } = useParams<{ courseId: DBId }>();

  const currentCourse = useAppSelector(selectCourse);
  const grammarReview = useAppSelector(selectGrammarReview);

  useEffect(() => {
    dispatch(GrammarReviewActionCreators.setInitialGrammarReview());
  }, [dispatch, courseId]);

  useEffect(() => {
    if (
      grammarReview.loaded === LoadingStage.loaded &&
      grammarReview.categoriesData.loaded === LoadingStage.notLoaded &&
      courseId
    ) {
      dispatch(GrammarReviewActionCreators.getGrammarCategories(courseId, grammarReview.content.id));
    }
  }, [dispatch, courseId, grammarReview]);

  return (
    <>
      <div className="course-edition__course-data-composer">
        <SearchModal />
        {currentCourse && (
          <Header
            contentName="Grammar review"
            learningLanguage={currentCourse.learningLanguage}
            interfaceLanguages={currentCourse.interfaceLanguages}
          />
        )}
        <BreadcrumbsAndOwnershipWrapper>
          <BreadcrumbNavigation breadcrumbItems={[<span>Grammar Review</span>]} />
          <ContentOwner
            contentType={ContentTypes.grammarReview}
            componentId={grammarReview.content.id}
            dates={grammarReview.content.dates}
            owner={grammarReview.content.owner}
          />
        </BreadcrumbsAndOwnershipWrapper>
        <GrammarReviewData />
      </div>
    </>
  );
};

export const GrammarReviewContent = withRouter(GrammarReview);
