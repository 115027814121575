import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { DBId } from '@common/types/DBId';
import { CommonActions } from '@actions/CommonActions';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import type { LanguageV2 } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { TypingExerciseActions } from '@actions/TypingExerciseActions';
import TypingExerciseInterface from '@components/Exercises/Typing/interfaces/TypingExerciseInterface';

export const TypingExerciseActionsCreator = {
  save: (exercise: TypingExerciseInterface, learningLanguage: LanguageV2, values: FormikValuesInterface) => ({
    type: TypingExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setPhraseTextAlt: (values: any, language: string) => ({
    type: TypingExerciseActions.SET_PHRASE_TEXT_ALT,
    payload: { values, language },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: TypingExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setImage: (exercise: AnyExerciseInterface, image: File, progressHandler: Function) => ({
    type: TypingExerciseActions.UPLOAD_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ExerciseTypes.typing,
      progressHandler,
    },
  }),
  removeImage: () => ({
    type: TypingExerciseActions.REMOVE_IMAGE,
  }),
  removeAudio: ({ contentId, language }: { contentId: DBId | undefined; language: string }) => ({
    type: TypingExerciseActions.REMOVE_AUDIO,
    payload: { contentId, language },
  }),
  nullifyHint: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'hint',
    },
  }),
};
