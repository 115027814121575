import { OptionProps, ValueContainerProps, components } from 'react-select';
import styled from 'styled-components/macro';

import { Flag } from '@components/Flag';

import { CommonFilterSelector, CommonFilterOptionType } from '@components/SelectorCommonComponents';

import { ALL_LANGUAGES_V2, LANGUAGE_FLAGS, LANGUAGE_NAMES_V2, type LanguageV2 } from '@features/content/languages';
import { ComponentType } from 'react';

const FlagContainer = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FlagsContainer = styled(Flag)<{ isMenuOpen: boolean }>`
  max-width: 12rem;

  .flag__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    color: ${({ theme, isMenuOpen }) => (isMenuOpen ? theme.color.brandWhite : theme.color.deepBlue)};
  }

  .flag__more-countries-circle {
    color: ${({ theme }) => theme.color.deepBlue};
  }
`;

const CLASSNAME_PREFIX = 'language-selector';

type LanguageOptionType = { value: LanguageV2; label: string };

const languageOptions = ALL_LANGUAGES_V2.map((language) => ({
  value: language,
  label: LANGUAGE_NAMES_V2[language],
}));

const CustomOption = (props: OptionProps<LanguageOptionType>) => (
  <components.Option {...props}>
    <OptionContainer>
      <FlagContainer src={LANGUAGE_FLAGS[props.data.value]} alt="Country flag" />
      {LANGUAGE_NAMES_V2[props.data.value]}
    </OptionContainer>
  </components.Option>
);

const CustomValueContainer = (props: ValueContainerProps<LanguageOptionType>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, input] = props.children as any;

  return (
    <components.ValueContainer {...props}>
      {props.selectProps.value ? (
        <FlagsContainer
          isMenuOpen={props.selectProps.menuIsOpen}
          countries={(props.selectProps.value as LanguageOptionType).value || ''}
          tooltipEnabled={false}
        />
      ) : (
        props.children
      )}
      {input}
    </components.ValueContainer>
  );
};

export const LanguageFilter = ({
  selectedLanguage,
  setSelectedLanguage,
}: {
  selectedLanguage: LanguageOptionType | null;
  setSelectedLanguage: (language: LanguageOptionType | null) => void;
}) => (
  <CommonFilterSelector
    placeholder="Language"
    withSearchIcon
    classNamePrefix={CLASSNAME_PREFIX}
    value={selectedLanguage}
    options={languageOptions}
    optionComponent={CustomOption as ComponentType<OptionProps<CommonFilterOptionType, false>>}
    valueContainerComponent={CustomValueContainer as ComponentType<ValueContainerProps<CommonFilterOptionType, false>>}
    onChange={(language) => setSelectedLanguage(language as LanguageOptionType)}
  />
);
