import { call, put, select } from 'redux-saga/effects';
import type { LanguageV2 } from '@features/content/languages';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import ComprehensionExerciseInterface from '@components/Exercises/Comprehension/interfaces/ComprehensionExerciseInterface';
import ComprehensionExerciseService from '@services/ExerciseRelatedServices/ComprehensionExerciseService';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ComprehensionExerciseActions } from '@actions/ComprehensionExerciseActions';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const ComprehensionExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: ComprehensionExerciseInterface;
        learningLanguage: LanguageV2;
        values: FormikValuesInterface;
      };
    },
  ) {
    let { values } = payload;

    yield put({
      type: ComprehensionExerciseActions.SET_COMPREHENSION_ALL_FIELDS,
      payload: {
        values,
      },
    });
    let exercise: ComprehensionExerciseInterface = yield select(selectLoadedExerciseData);

    yield call(ComprehensionExerciseService.save, dispatch, exercise, values);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default ComprehensionExerciseSagas;
