import React from 'react';
import { SimpleLanguageSelector } from '@components/SelectorCommonComponents';
import type { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';

import type { StateConfig } from '../Common/useFiltersState';
import type { ChapterQueryParamsType } from '../../service/types';
import { FiltersContainer } from '../Common/SearchHeader/FiltersContainer';
import {
  FilterField,
  SimpleCourseSelector,
  SimpleRangeSelector,
  SimpleTypeSelector,
  SimpleYesNoSelector,
} from '../Common';
import { LESSON_TITLES, LESSONS } from '@common/enums/LessonTypes';

type ChapterSearchFiltersProps = {
  filtersState: StateConfig<ChapterQueryParamsType>;
  availableCourses: CourseInfoInterface[];
  onSaveFilters?: (name: string) => Promise<void>;
};
export const ChapterSearchFilters = ({ filtersState, availableCourses, onSaveFilters }: ChapterSearchFiltersProps) => {
  const filters = [
    <FilterField
      state={filtersState}
      name="languages"
      render={(value, onChange) => <SimpleLanguageSelector value={value} onChange={onChange} />}
    />,
    <FilterField
      state={filtersState}
      name="courses"
      render={(value, onChange) => (
        <SimpleCourseSelector
          courses={availableCourses}
          courseFilter={(course) =>
            filtersState.state.languages === null || filtersState.state.languages.includes(course.learningLanguage)
          }
          value={value}
          onChange={onChange}
        />
      )}
    />,
  ];
  const secondaryFilters = [
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isPublished"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Is Published" rule="Is Published" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Is Published',
      onRemove: () => filtersState.resetField('isPublished'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="numLessons"
          render={(value, onChange) => (
            <SimpleRangeSelector rule="Num. lessons" maxLimit={50} value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Number of lessons',
      onRemove: () => filtersState.resetField('numLessons'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="lessonTypes"
          render={(value, onChange) => (
            <SimpleTypeSelector
              types={LESSONS}
              labelGenerator={(type) => LESSON_TITLES[type]}
              rule="Lesson Type"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Has specific Lesson',
      onRemove: () => filtersState.resetField('lessonTypes'),
    },
  ];

  return (
    <FiltersContainer
      filters={filters.filter((filter) => Boolean(filter)) as NonNullable<(typeof filters)[number]>[]}
      secondaryFilters={
        (secondaryFilters || []).filter((filter) => Boolean(filter)) as NonNullable<(typeof secondaryFilters)[number]>[]
      }
      emptyFilters={filtersState.isEmpty}
      onSaveFilters={onSaveFilters}
    />
  );
};
