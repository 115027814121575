import React from 'react';
import { SimpleLanguageSelector } from '@components/SelectorCommonComponents';
import type { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';

import type { StateConfig } from '../Common/useFiltersState';
import type { BundleQueryParamsType } from '../../service/types';
import { FiltersContainer } from '../Common/SearchHeader/FiltersContainer';
import { FilterField, SimpleCourseSelector, SimpleYesNoSelector } from '../Common';

type BundleSearchFiltersProps = {
  filtersState: StateConfig<BundleQueryParamsType>;
  availableCourses: CourseInfoInterface[];
  onSaveFilters?: (name: string) => Promise<void>;
};
export const BundleSearchFilters = ({ filtersState, availableCourses, onSaveFilters }: BundleSearchFiltersProps) => {
  const filters = [
    <FilterField
      state={filtersState}
      name="languages"
      render={(value, onChange) => <SimpleLanguageSelector value={value} onChange={onChange} />}
    />,
    <FilterField
      state={filtersState}
      name="courses"
      render={(value, onChange) => (
        <SimpleCourseSelector
          courses={availableCourses}
          courseFilter={(course) =>
            filtersState.state.languages === null || filtersState.state.languages.includes(course.learningLanguage)
          }
          value={value}
          onChange={onChange}
        />
      )}
    />,
    <FilterField
      state={filtersState}
      name="isVocabulary"
      render={(value, onChange) => (
        <SimpleYesNoSelector placeholder="Vocab Item" rule="Vocab Item" value={value} onChange={onChange} />
      )}
    />,
  ];
  const secondaryFilters = [
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isReused"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Is Reused" rule="Is Reused" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Is Reused',
      onRemove: () => filtersState.resetField('isReused'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="hasImage"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Has Image" rule="Has Image" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Has Image',
      onRemove: () => filtersState.resetField('hasImage'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="hasVideo"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Has Video" rule="Has Video" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Has Video',
      onRemove: () => filtersState.resetField('hasImage'),
    },
  ];

  return (
    <FiltersContainer
      filters={filters.filter((filter) => Boolean(filter)) as NonNullable<(typeof filters)[number]>[]}
      secondaryFilters={
        (secondaryFilters || []).filter((filter) => Boolean(filter)) as NonNullable<(typeof secondaryFilters)[number]>[]
      }
      emptyFilters={filtersState.isEmpty}
      onSaveFilters={onSaveFilters}
    />
  );
};
