import { call, put, select } from 'redux-saga/effects';
import TipExerciseInterface from '@components/Exercises/Tip/interfaces/TipExerciseInterface';
import TipExerciseService from '@services/ExerciseRelatedServices/TipExerciseService';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import { TipExerciseActions } from '@actions/TipExerciseActions';
import type { LanguageV2 } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const TipExerciseSagas = {
  *updateTipExercise(dispatch: AppDispatch, { payload }: PayloadAction<any>) {
    try {
      let {
        values,
      }: {
        learningLanguage: LanguageV2;
        values: FormikValuesInterface;
      } = payload;

      yield put({
        type: TipExerciseActions.SET_TIP_ALL_FIELDS,
        payload: {
          values,
        },
      });
      let exercise: TipExerciseInterface = yield select(selectLoadedExerciseData);

      yield call(TipExerciseService.save, dispatch, exercise, values);

      yield put({
        type: TipExerciseActions.SAVE_SUCCEEDED,
        payload: { exercise },
      });

      yield call(ExerciseSagas.requestOneExerciseCommon);
    } catch (e: any) {
      console.error(e);
    }
  },
};

export default TipExerciseSagas;
