import { ReactComponent as RemoveIcon } from '@static/svg/remove.svg';

import { ValuePillContainer } from './styles';

type ValuePillProps = {
  isToBeRemoved?: boolean;
  label: string;
  onClick?: () => void;
  onRemove: () => void;
};

export const ValuePill = ({ isToBeRemoved = false, label, onClick, onRemove }: ValuePillProps) => {
  return (
    <ValuePillContainer isToBeRemoved={isToBeRemoved}>
      <span onClick={onClick}>{label}</span>
      <RemoveIcon onClick={onRemove} />
    </ValuePillContainer>
  );
};
