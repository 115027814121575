import { useEffect, useState } from 'react';

import type { LanguageV2 } from '@features/content/languages';
import { Button, Loader, Text } from '@features/theme';
import {
  LexicalItemSearchResultItem,
  LexicalItemSearchResultTypeHeader,
  SearchResults,
  searchByLexicalItem,
} from '@features/search';

import type { VocabularyReviewListItem } from '../types';
import {
  MatchedLexicalItemsModalButtonArea,
  MatchedLexicalItemsModalDescription,
  MatchedLexicalItemsModalHeader,
  MatchedLexicalItemsModalResultsContainer,
  MatchedLexicalItemsModalWrapper,
} from './styles';

type MatchedLexicalItemsModalPropsType = {
  count: number;
  isNewLexicalItem: boolean;
  isOpen: boolean;
  language: LanguageV2;
  query: string;
  close: () => void;
  onCancel: () => void;
};

export const MatchedLexicalItemsModal = ({
  count,
  isNewLexicalItem,
  isOpen,
  language,
  query,
  close,
  onCancel,
}: MatchedLexicalItemsModalPropsType) => {
  const [results, setResults] = useState<VocabularyReviewListItem[]>([]);

  useEffect(() => {
    if (isOpen) {
      (async () => {
        const {
          data: { results },
        } = await searchByLexicalItem(query, {
          exactMatch: true,
          language,
        });

        if (results?.length) {
          setResults(results);
        }
      })();
    }
  }, [isOpen, language, query]);

  return (
    <MatchedLexicalItemsModalWrapper>
      <MatchedLexicalItemsModalHeader>
        <Text variant="header" level={3}>
          {count} similar lexical items found!
        </Text>
        <MatchedLexicalItemsModalDescription variant="body" level={1}>
          The lexical item you're creating might be a duplicate.
        </MatchedLexicalItemsModalDescription>
      </MatchedLexicalItemsModalHeader>
      <MatchedLexicalItemsModalResultsContainer>
        {!!results.length ? (
          <SearchResults
            results={results}
            header={<LexicalItemSearchResultTypeHeader />}
            renderItem={(content) => <LexicalItemSearchResultItem content={content} language="en" />}
          />
        ) : (
          <Loader />
        )}
      </MatchedLexicalItemsModalResultsContainer>
      <MatchedLexicalItemsModalButtonArea>
        <Button fullWidth size="M" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        {isNewLexicalItem && (
          <Button fullWidth size="M" onClick={close}>
            Create Anyway
          </Button>
        )}
      </MatchedLexicalItemsModalButtonArea>
    </MatchedLexicalItemsModalWrapper>
  );
};
