import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FormikContextType, useFormikContext } from 'formik';

import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import TranslationsPanelService from '@services/TranslationsPanelService';
import { formatCounterOfFilledLocalizations } from '@features/content';
import { ALL_LANGUAGES_V2, DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';
import { DBId } from '@common/types/DBId';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { GrammarCategoryContentType, GrammarCategoryFormikValues } from '../GrammarCategory';
import { GrammarTopicContentType } from '../GrammarTopic';
import { TranslationsTab } from './TranslationsTab/TranslationsTab';
import { AudiosTab } from './AudiosTab/AudiosTab';
import { OtherLocationsTab } from './OtherLocationsTab';
import { SidePanel, SidePanelBody, SidePanelHeader } from '@features/theme';
import { SidePanelActionsCreator } from '@actionCreators/SidePanelActionsCreator';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectGrammarTranslationsPanel,
  selectGrammarTranslationsPanelDefaultContextForTranslators,
} from '@selectors/UiSelectors';
import { selectCourse } from '@selectors/CoursesSelectors';

type TranslationsPanelProps = {
  content: GrammarCategoryContentType | GrammarTopicContentType;
};

export const TranslationsPanel = ({ content }: TranslationsPanelProps) => {
  const { values }: FormikContextType<GrammarCategoryFormikValues> = useFormikContext();
  const { courseId, categoryId, topicId, exerciseId } = useParams<{
    courseId: DBId;
    categoryId: DBId;
    topicId: DBId;
    exerciseId: DBId;
  }>();
  const translations = useAppSelector(selectGrammarTranslationsPanel);
  const selectedCourse = useAppSelector(selectCourse);
  const defaultContextForTranslators = useAppSelector(selectGrammarTranslationsPanelDefaultContextForTranslators);

  const dispatch = useAppDispatch();

  const { interfaceLanguages, learningLanguage } = selectedCourse;
  const { preopenedTab, fieldName } = translations;
  const visitedContentId = translations.visitedContentId?.length ? translations.visitedContentId : 'undefined';

  const validationErrors = content?.validationStatus?.errors.filter((error) => error.field === fieldName) || [];

  const panelTitle = `Edit ${visitedContentId !== 'undefined' ? visitedContentId : 'content'}`;

  const [selectedTab, setSelectedTab] = useState(preopenedTab);

  const [otherLocationsNumberOfItems, setOtherLocationsNumberOfItems] = useState('');
  const [otherLocations, setOtherLocations] = useState<OtherLocationsInterface[][]>([]);

  useEffect(() => {
    const getTotalOtherLocations = async () => {
      const otherLocationsPayload = await TranslationsPanelService.getOtherLocations(visitedContentId);

      const realOtherLocations = otherLocationsPayload.data.filter((mappings: OtherLocationsInterface[]) => {
        const idsInMapping = mappings.map((mapping) => mapping.id);
        if (courseId && categoryId && topicId && exerciseId) {
          return (
            !idsInMapping.includes(courseId) ||
            !idsInMapping.includes(categoryId) ||
            !idsInMapping.includes(topicId) ||
            !idsInMapping.includes(exerciseId)
          );
        } else if (courseId && categoryId && topicId) {
          return (
            !idsInMapping.includes(courseId) || !idsInMapping.includes(categoryId) || !idsInMapping.includes(topicId)
          );
        } else if (courseId && categoryId) {
          return !idsInMapping.includes(courseId) || !idsInMapping.includes(categoryId);
        } else {
          return !idsInMapping.includes(courseId);
        }
      });

      setOtherLocations(realOtherLocations);
      setOtherLocationsNumberOfItems(`${realOtherLocations.length}`);
    };

    getTotalOtherLocations();
  }, [visitedContentId, courseId, categoryId, topicId, exerciseId]);

  const notAllLanguagesSelected = interfaceLanguages.length < ALL_LANGUAGES_V2.length - 1;

  const textLocalizations = (fieldName && content[fieldName]?.textLocalizations) || [];

  const audioLocalizations = (fieldName && content[fieldName]?.audioLocalizations) || [];

  const isDefaultLanguage = (localization: LocalizationInterface) => {
    return localization.language === DEFAULT_LANGUAGE_V2;
  };

  const isCourseLearningLanguage = (localization: LocalizationInterface) => {
    return localization.language === learningLanguage;
  };

  const isInterfaceLanguage = (localization: LocalizationInterface) => {
    return interfaceLanguages.includes(localization.language);
  };

  const defaultTranslations = textLocalizations.filter(
    (loc) => isInterfaceLanguage(loc) || isDefaultLanguage(loc) || isCourseLearningLanguage(loc),
  );
  const hiddenTranslations = textLocalizations.filter(
    (loc) => !isInterfaceLanguage(loc) && !isDefaultLanguage(loc) && !isCourseLearningLanguage(loc),
  );

  const defaultAudios = audioLocalizations.filter(
    (loc) => isInterfaceLanguage(loc) || isDefaultLanguage(loc) || isCourseLearningLanguage(loc),
  );
  const hiddenAudios = audioLocalizations.filter(
    (loc) => !isInterfaceLanguage(loc) && !isDefaultLanguage(loc) && !isCourseLearningLanguage(loc),
  );

  const totalTextLocalizations = formatCounterOfFilledLocalizations(
    fieldName && values?.[fieldName]
      ? (values[fieldName]?.filter((item) => item.value && !item.isPhonetic) as unknown as LocalizationInterface[])
      : textLocalizations,
    interfaceLanguages as LanguageV2[],
    learningLanguage as LanguageV2,
  );
  const totalAudioLocalizations = formatCounterOfFilledLocalizations(
    audioLocalizations || [],
    interfaceLanguages as LanguageV2[],
    learningLanguage as LanguageV2,
  );

  return (
    <SidePanel
      className="translations-panel-modal"
      onClose={() => dispatch(SidePanelActionsCreator.hideSidePanel())}
      header={<SidePanelHeader>{panelTitle}</SidePanelHeader>}
      body={
        <SidePanelBody>
          <Tabs
            activeKey={selectedTab}
            onSelect={(selectedTabString: string | null) => {
              if (
                selectedTabString === 'translations' ||
                selectedTabString === 'audios' ||
                selectedTabString === 'other'
              ) {
                setSelectedTab(selectedTabString);
              }
            }}
          >
            <Tab eventKey="translations" title={`Translations (${totalTextLocalizations})`}>
              <TranslationsTab
                otherLocationsNumberOfItems={+otherLocationsNumberOfItems}
                validationErrors={validationErrors}
                defaultContext={defaultContextForTranslators || ''}
                defaultTranslations={defaultTranslations}
                hiddenTranslations={hiddenTranslations}
                notAllLanguagesSelected={notAllLanguagesSelected}
                navigateToOtherTab={() => setSelectedTab('other')}
              />
            </Tab>
            <Tab eventKey="audios" title={`Audios (${totalAudioLocalizations})`}>
              <AudiosTab
                validationErrors={validationErrors}
                defaultAudios={defaultAudios}
                textLocalizations={textLocalizations}
                hiddenAudios={hiddenAudios}
                notAllLanguagesSelected={notAllLanguagesSelected}
              />
            </Tab>
            <Tab
              eventKey="other"
              title={`Other locations (${otherLocationsNumberOfItems || 0})`}
              disabled={otherLocationsNumberOfItems === '0' || !visitedContentId}
            >
              <OtherLocationsTab otherLocations={otherLocations} />
            </Tab>
          </Tabs>
        </SidePanelBody>
      }
    />
  );
};
