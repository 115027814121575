import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { ProfileImage } from '@components/RolesAndPermissions/ProfileImage/ProfileImage';
import { Loader } from '@features/theme';
import { getLink } from '@helpers/urlLinkHelper';
import { DBId } from '@common/types/DBId';
import { Logo } from '@features/app/logo';
import { LocationsList } from './LocationsList';
import { getLocations } from './IsolatedComponentService';
import type { LanguageV2 } from '@features/content/languages';

const ContentMappingsWrapper = styled.div`
  padding: 4rem;
  width: 100%;
`;

const ContentMappingsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 5.5rem;
  margin-bottom: 5.6rem;

  .dropdown-menu.show {
    transform: translate3d(-22rem, 4.5rem, 0) !important;
  }
`;

const ContentMappingsData = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5.6rem;
`;

const ContentMappingsTitle = styled.span`
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 2.4rem;
`;

const ContentMappingsDescription = styled.span`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colorV2.contentMappingsDescriptionText};
`;

export const ContentMappingsPage = () => {
  const { contentId } = useParams<{ contentId: DBId }>();
  const history = useHistory();
  const [locations, setLocations] = useState<(OtherLocationsInterface & { language: LanguageV2 })[][]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [locationsLoading, setLocationsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!locationsLoading && contentId && !locations.length && !errorMessage) {
      setLocationsLoading(true);

      getLocations(contentId)
        .then((result) => {
          if (result.data.mappings.length === 1) {
            history.replace(getLink(result.data.mappings[0]));
          } else if (result.data.mappings.length === 0) {
            setErrorMessage('This content is not used anywhere!');
          } else {
            setLocations(result.data.mappings);
            setLocationsLoading(false);
          }
        })
        .catch((e) => {
          setLocationsLoading(false);
          if (e.response.data.detail) {
            setErrorMessage(e.response.data.detail);
          } else if (e.response.status === 500) {
            setErrorMessage('Looks like content is broken!');
          } else {
            setErrorMessage(e.response.data.message || 'Unknown error');
          }
        });
    }
  }, [contentId, locationsLoading, locations, history, errorMessage]);

  return (
    <ContentMappingsWrapper>
      <ContentMappingsHeader>
        <Logo size="large" withLink />
        <ProfileImage />
      </ContentMappingsHeader>
      {!errorMessage ? (
        <>
          {locationsLoading ? (
            <Loader size="L" />
          ) : (
            <>
              <ContentMappingsData>
                <ContentMappingsTitle>Reused content</ContentMappingsTitle>
                <ContentMappingsDescription>
                  This content is reused, so you need to open exact place, which you need:
                </ContentMappingsDescription>
              </ContentMappingsData>
              <LocationsList locations={locations} />
            </>
          )}
        </>
      ) : (
        <ContentMappingsData>
          <ContentMappingsTitle>Error</ContentMappingsTitle>
          <ContentMappingsDescription>{errorMessage}</ContentMappingsDescription>
        </ContentMappingsData>
      )}
    </ContentMappingsWrapper>
  );
};
