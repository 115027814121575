import { DBId } from '@common/types/DBId';
import { LanguageV2 } from '@features/content/languages';

import { RoleplayScenarioContentType } from '../types';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';

/**
 * @returns Array containing the ids of every PhraseBookPhraseN field which has a value
 */
export const getPhrasebookValue = (content: RoleplayScenarioContentType, language: LanguageV2) => {
  const phraseBookPhrases = Object.entries(content).filter(([fieldName]) => fieldName.startsWith('phraseBookPhrase'));

  const phraseBookPhraseIds = phraseBookPhrases.reduce(
    (acc: (DBId | null)[], [phraseBookPhraseFieldName, localization]) => {
      const currentLocalization = localization as TranslationsPanelContentInterface;

      // store both id or null to preserve phrase order in the array
      acc.push(currentLocalization?.id ?? null);

      return acc;
    },
    [],
  );

  return phraseBookPhraseIds;
};
