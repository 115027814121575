import { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';

import { DBId } from '@common/types/DBId';
import { CommonFilterOptionType } from '@components/SelectorCommonComponents';
import type { LanguageV2 } from '@features/content/languages';
import { Modal, useDialogModal } from '@features/modal';
import { Loader } from '@features/theme';

import { AudioRequestStatusType, AudioRequestType } from '../../types';
import { cancelAudioRequest, getAudioRequests } from '../../_service';
import { MessageItem } from '../../commonStyles';
import { CancelAudioRequestConfirmModal } from './CancelAudioRequestConfirmModal';
import { FiltersArea } from './FiltersArea';
import { TableContent } from './TableContent';
import { useToast } from '@features/app/toast';

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_TOTAL_PAGES = 0;

export const AudioRequestsTable = () => {
  const [audioRequests, setAudioRequests] = useState<AudioRequestType[]>([]);
  const [audioRequestIdToCancel, setAudioRequestIdToCancel] = useState<DBId | null>(null);
  const [audioRequestsLoading, setAudioRequestsLoading] = useState<boolean>(false);
  const [audioRequestsLoaded, setAudioRequestsLoaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Filters
  const [selectedLanguage, setSelectedLanguage] = useState<{ value: LanguageV2; label: string } | null>(null);
  const [selectedVoiceArtist, setSelectedVoiceArtist] = useState<CommonFilterOptionType | null>(null);
  const [selectedRequesters, setSelectedRequesters] = useState<MultiValue<CommonFilterOptionType> | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<{ value: AudioRequestStatusType; label: string } | null>({
    value: 'new',
    label: 'New',
  });
  const [exportId, setExportId] = useState<string>('');

  // Pagination
  const [currentPage, setCurrentPage] = useState(DEFAULT_CURRENT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);

  const showToast = useToast();

  // Cancel Audio Request Confirm Modal
  const removeAudioRequestFromList = (audioRequestId: DBId) => {
    const newAudioRequestsValue = [...audioRequests].filter(({ id }) => id !== audioRequestId);
    setAudioRequests(newAudioRequestsValue);
  };

  const handleCancelAudioRequestConfirm = (audioRequestId: DBId, close: () => void) => {
    cancelAudioRequest(audioRequestIdToCancel as string)
      .then(() => {
        showToast({
          type: 'success',
          title: 'Audio request was succesfully removed',
        });

        removeAudioRequestFromList(audioRequestId);
        setAudioRequestIdToCancel(null);
        close();
      })
      .catch(() => {
        showToast({
          type: 'error',
          title: 'An error occurred on removing an audio request.',
        });
      });
  };

  const { open: openCancelAudioRequestConfirmModal, modal: cancelAudioRequestConfirmModal } = useDialogModal(
    (modalControls) => (
      <Modal {...modalControls} onClickOutside={modalControls.close}>
        <CancelAudioRequestConfirmModal
          onConfirm={() => handleCancelAudioRequestConfirm(audioRequestIdToCancel as DBId, modalControls.close)}
          onHide={() => {
            setAudioRequestIdToCancel(null);
            modalControls.close();
          }}
        />
      </Modal>
    ),
  );

  const requestAudioRequests = async () => {
    setAudioRequestsLoaded(false);
    setAudioRequestsLoading(true);

    getAudioRequests(currentPage, {
      selectedLanguage: selectedLanguage?.value,
      selectedVoiceArtist: selectedVoiceArtist?.value,
      selectedRequesters: selectedRequesters?.map((requester) => requester.value),
      selectedStatus: selectedStatus?.value,
      exportId,
    })
      .then(({ data }) => {
        setAudioRequests(data.audioRequests);
        setTotalPages(Math.ceil(data.count / DEFAULT_PAGE_SIZE));
      })
      .catch((error) => {
        setAudioRequests([]);
        setTotalPages(0);
        setErrorMessage(error.response.data.detail);
      })
      .finally(() => {
        setAudioRequestsLoaded(true);
        setAudioRequestsLoading(false);
      });
  };

  useEffect(() => {
    requestAudioRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectedLanguage, selectedVoiceArtist, selectedRequesters, selectedStatus, exportId]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedLanguage, selectedVoiceArtist, selectedRequesters, selectedStatus, exportId]);

  return (
    <>
      <FiltersArea
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        selectedVoiceArtist={selectedVoiceArtist}
        setSelectedVoiceArtist={setSelectedVoiceArtist}
        selectedRequesters={selectedRequesters}
        setSelectedRequesters={setSelectedRequesters}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        exportId={exportId}
        setExportId={setExportId}
        onExportSuccess={(exportId: string) => {
          setAudioRequests((audioRequests) =>
            audioRequests.map((audioRequest) => ({ ...audioRequest, exportId, requestStatus: 'exported' })),
          );
        }}
      />
      {audioRequestsLoading && !audioRequestsLoaded ? (
        <Loader size="L" />
      ) : (
        <>
          {errorMessage ? (
            <MessageItem>{errorMessage}</MessageItem>
          ) : (
            <>
              <TableContent
                audioRequests={audioRequests}
                currentPage={currentPage}
                totalPages={totalPages}
                onCancelAudioRequest={(audioRequestId: DBId) => {
                  setAudioRequestIdToCancel(audioRequestId);
                  openCancelAudioRequestConfirmModal();
                }}
                onPageChange={setCurrentPage}
              />
              {cancelAudioRequestConfirmModal}
            </>
          )}
        </>
      )}
    </>
  );
};
