import { Button, Text } from '@features/theme';

import { MatchLexicalItemModalButtonsArea, MatchLexicalItemModalWrapper } from '../styles';
import type { MatchLexicalItemCreatorOption } from '../types';

type MatchLexicalItemUnmatchModalProps = {
  valueToRemove: MatchLexicalItemCreatorOption | null;
  close: () => void;
  onCancel: () => void;
  onUnmatch: (valueToRemove: MatchLexicalItemCreatorOption | null) => void;
};

export const MatchLexicalItemUnmatchModal = ({
  valueToRemove,
  close,
  onCancel,
  onUnmatch,
}: MatchLexicalItemUnmatchModalProps) => {
  return (
    <MatchLexicalItemModalWrapper>
      <Text variant="header" level={3}>
        Are you sure you want to unmatch "{valueToRemove?.label}"?
      </Text>
      <MatchLexicalItemModalButtonsArea>
        <Button
          fullWidth
          onClick={() => {
            close();
            onUnmatch(valueToRemove);
          }}
        >
          Unmatch
        </Button>
        <Button
          fullWidth
          variant="secondary"
          onClick={() => {
            close();
            onCancel();
          }}
        >
          Cancel
        </Button>
      </MatchLexicalItemModalButtonsArea>
    </MatchLexicalItemModalWrapper>
  );
};
