import { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';
import styled from 'styled-components';
import placeholderImage from '@static/png/placeholder-image.png';
import { LANGUAGE_NAMES_V2 } from '@features/content/languages';
import { clearCourseCounter } from './counters';
import TrashIcon from '@static/svg/Trash.svg';
import { Flag } from '@components/Flag';
import { Link } from 'react-router-dom';

const RecentCourseWrapper = styled.div<{ isDeleteModeOn: boolean }>`
  height: 100%;
  position: relative;
  padding: 8px;
  ${(props) =>
    props.isDeleteModeOn
      ? `&:hover {
    background: ${props.theme.color.darkBlue15};
    border-radius: 8px;
  }`
      : ''}
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 300px;
  height: 180px;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.color.brandBlack};
  margin-top: 1.6rem;
  font-weight: 400;
`;

const Languages = styled.div(({ theme }) => ({
  color: theme.color.brandGreyscale800,
  fontSize: '1.4rem',
  display: 'flex',
  alignItems: 'inline-block',
}));

const InlineFlagWrapper = styled.span`
  padding-left: 3px;
  display: inline-block;
`;
const RemoveCourseButton = styled.button`
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.color.brandWhite};
  border: none;
  border-radius: 8px;
`;

type RecentCourseProps = {
  course: CourseInfoInterface;
  isDeleteModeOn: boolean;
};

/* eslint-disable jsx-a11y/img-redundant-alt */
const RecentCourse = ({ course, isDeleteModeOn }: RecentCourseProps) => (
  <RecentCourseWrapper isDeleteModeOn={isDeleteModeOn}>
    {isDeleteModeOn && (
      <RemoveCourseButton onClick={() => clearCourseCounter(course.id)}>
        <img src={TrashIcon} alt="Remove course" />
      </RemoveCourseButton>
    )}
    <Link to={`/course/${course.id}`}>
      <ImageWrapper>
        <img src={course.thumbnailImage || placeholderImage} alt={`${course.title} image`} />
      </ImageWrapper>
      <Title>{course.title}</Title>
      <Languages>
        {LANGUAGE_NAMES_V2[course.learningLanguage]} ·{' '}
        <InlineFlagWrapper>
          <Flag countries={course.interfaceLanguages} forCoursesList />
        </InlineFlagWrapper>
      </Languages>
    </Link>
  </RecentCourseWrapper>
);
/* eslint-enable jsx-a11y/img-redundant-alt */

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4.6rem;
`;

type RecentCoursesListProps = {
  recentCourses: CourseInfoInterface[];
  isDeleteModeOn: boolean;
};

export const RecentCoursesList = ({ recentCourses, isDeleteModeOn }: RecentCoursesListProps) => (
  <ListWrapper>
    {recentCourses.map((course) => (
      <RecentCourse course={course} key={course.id} isDeleteModeOn={isDeleteModeOn} />
    ))}
  </ListWrapper>
);
