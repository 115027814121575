import { Fragment } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { ContentTypes } from '@common/enums/ContentTypes';
import { getLink } from '@helpers/urlLinkHelper';
import grammarIcon from './img/grammar.svg';
import placementIcon from './img/placement.svg';
import { ReactComponent as NavigateIcon } from './img/navigate.svg';

import { Flag, FlagTextPositionMode } from '@components/Flag';
import { DEFAULT_LANGUAGE_V2, LanguageV2 } from '@features/content/languages';

const OtherLocationItem = styled.div`
  min-height: 5.6rem;
  padding: 0.8rem 1.6rem;
  color: ${({ theme }) => theme.color.deepBlue};
  border-radius: 0.8rem;
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 20% 15% 15% 20% 8% 12% 4%;
  align-items: center;
  font-size: 1.6rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.darkBlue15};

  &:hover {
    background: ${({ theme }) => theme.color.brandBlue5};
  }
`;

const LocationItem = styled.div`
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
`;

const FlagStyled = styled(Flag)`
  font-size: 1rem;
  margin-right: 2.4rem;
  img {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;
  }
`;

const NotCourseImage = styled.img`
  margin-right: 1rem;
`;

const NavigateImage = styled(Link)`
  grid-column: 7;
`;

export const LocationsContent = ({
  locations,
}: {
  locations: (OtherLocationsInterface & { language?: LanguageV2 })[][];
}) => (
  <>
    {locations.map((location, index) => (
      <OtherLocationItem data-testid={`location-item-${index}`} key={`location-item-${index}`}>
        {location.map((locationItem, locationIndex) => (
          <Fragment key={locationIndex}>
            {locationItem.type !== 'resourceBundle' && (
              <LocationItem key={`${location}-${locationIndex}-${index}`}>
                {locationItem.type === ContentTypes.course && (
                  <FlagStyled
                    countries={locationItem.language || DEFAULT_LANGUAGE_V2}
                    mode={FlagTextPositionMode.withoutText}
                  />
                )}
                {(locationItem.type === ContentTypes.grammarReview ||
                  locationItem.type === ContentTypes.placementTest) && (
                  <NotCourseImage
                    src={locationItem.type === ContentTypes.grammarReview ? grammarIcon : placementIcon}
                  />
                )}
                {locationItem.label}
              </LocationItem>
            )}
          </Fragment>
        ))}
        <NavigateImage to={getLink(location)} target="_blank">
          <NavigateIcon />
        </NavigateImage>
      </OtherLocationItem>
    ))}
  </>
);
