import { call, put, select } from 'redux-saga/effects';
import TypingExerciseInterface from '@components/Exercises/Typing/interfaces/TypingExerciseInterface';
import TypingExerciseService from '@services/ExerciseRelatedServices/TypingExerciseService';
import type { LanguageV2 } from '@features/content/languages';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import { TypingExerciseActions } from '@actions/TypingExerciseActions';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';
import { selectLearningLanguage } from '@selectors/CoursesSelectors';

const TypingExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: TypingExerciseInterface;
        learningLanguage: LanguageV2;
        values: FormikValuesInterface;
      };
    },
  ) {
    let { values } = payload;
    yield put({
      type: TypingExerciseActions.SET_TYPING_ALL_FIELDS,
      payload: {
        values,
      },
    });
    let exercise: TypingExerciseInterface = yield select(selectLoadedExerciseData);
    const learningLanguage: string = yield select(selectLearningLanguage);

    yield call(TypingExerciseService.save, dispatch, exercise, values, learningLanguage);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default TypingExerciseSagas;
