import 'draft-js/dist/Draft.css';

import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import KTagsCreator from '@components/Exercises/Common/KTagsCreator/KTagsCreator';
import { AudioUpload } from '@components/MediaUpload';
import KTagsCreatorProps from '@components/Exercises/Common/KTagsCreator/KTagsCreatorProps';
import { MediaDataInterface } from '@common/interfaces/contentTypes/MediaDataInterface';
import { EditorDataSourceMode } from '@common/types/EditorDataSourceMode';
import { DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';

interface KTagsWithAudioCreatorProps extends KTagsCreatorProps {
  audio?: MediaDataInterface;
  currentLanguage?: LanguageV2;
  dataSourceMode?: EditorDataSourceMode;
  fieldName?: string;
  isChangeBlocked?: boolean;
  row?: number;
  showAudio?: boolean;
  onAudioRemove?: Function;
  onAudioRequestSuccess: (audioRequestId: string) => void;
  onAudioUpload?: (file: File, progressHandler: (progress: number) => void) => void;
  onCancelAudioRequestSuccess: () => void;
  onChangeInstant?: Function;
  onProcessingFinished?: Function;
}

const KTagsWithAudioCreator = ({
  value,
  placeholder,
  className,
  onChange,
  showKTags = false,
  audio,
  dataSourceMode = 'editorState',
  showAudio = true,
  errors = [],
  onChangeInstant,
  isChangeBlocked = false,
  currentLanguage = DEFAULT_LANGUAGE_V2,
  fieldName = '',
  row = 0,
  onAudioRequestSuccess,
  onAudioUpload,
  onAudioRemove,
  onCancelAudioRequestSuccess,
  onProcessingFinished,
}: KTagsWithAudioCreatorProps) => {
  const errorsForText = errors.filter((error: ValidationErrorInterface) => error.message.includes('text'));
  const errorsForAudio = errors.filter((error: ValidationErrorInterface) =>
    error.message.toLowerCase().includes('audio'),
  );

  if (audio?.mediaValue.length || (!audio?.mediaValue.length && audio?.processed)) {
    errorsForAudio.splice(
      errorsForAudio.findIndex((audioError) => audioError.message.toLowerCase().includes('Audio is not processed for')),
    );
  }

  return (
    <div className={`ktags-with-audio-creator ${className !== undefined ? className : ''}`}>
      <KTagsCreator
        value={value}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        showKTags={showKTags}
        gapMode
        dataSourceMode={dataSourceMode}
        errors={errorsForText}
        onChangeInstant={onChangeInstant}
        isChangeBlocked={isChangeBlocked}
      />
      {showAudio && (
        <AudioUpload
          audioData={audio}
          currentLanguage={currentLanguage}
          errors={errorsForAudio}
          fieldName={fieldName}
          fullScreen={true}
          isChangeBlocked={isChangeBlocked}
          row={row}
          onChangeInstant={onChangeInstant}
          onChange={onAudioUpload && onAudioUpload}
          onProcessingFinished={onProcessingFinished}
          onRemove={onAudioRemove && onAudioRemove}
          onAudioRequestSuccess={onAudioRequestSuccess}
          onCancelAudioRequestSuccess={onCancelAudioRequestSuccess}
        />
      )}
    </div>
  );
};

export default KTagsWithAudioCreator;
