import { FormikContextType, useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { VocabularyReviewActionsCreator } from '@actionCreators/VocabularyReviewActionsCreator';
import { ContentTypes } from '@common/enums/ContentTypes';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { Sizes } from '@common/enums/Sizes';
import { MediaDataInterface } from '@common/interfaces/contentTypes/MediaDataInterface';
import { DBId } from '@common/types/DBId';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { AudioUpload, ImageUpload } from '@components/MediaUpload';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { AlternativeValueCreator } from '@components/Exercises/Common/AlternativeValueCreator/AlternativeValueCreator';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { AccessWarning } from '@components/Warning';
import { WritableInputText } from '@components/WritableInputText';
import { apiClient } from '@features/api';
import { constants as contentConstants } from '@features/content';
import { useIsEditAvailable } from '@features/content/courses';
import { removeMediaProcessingValidationError } from '@features/content/exercises';
import { DEFAULT_LANGUAGE_V2, LanguageV2 } from '@features/content/languages';
import { ImageDataType } from '@features/content/media';
import { TranslationsTipWrapper } from '@features/content/translations';
import { Modal, useDialogModal } from '@features/modal';
import { FormikValuesInterface, processLocalizationsForFormikValues } from '@helpers/formikInitialValuesHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectLexicalItem, selectLexicalItemContent } from '@selectors/VocabularyReviewSelectors';

import { LexicalItemFieldWithLocalizationNames, LexicalItemFormikValues } from '../types';
import { useVocabularyReview } from '../useVocabularyReview';
import { MatchedLexicalItemsModal } from './MatchedLexicalItemsModal';
import { FieldLabel, LexicalItemDataWrapper, LexicalItemSubheader, StyledCol, StyledRow } from './styles';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

/**
 * @TODO Move this to @features/content/media to be reused
 * This maps new API media data to the object currently used in the related media components
 * */

const mapImageDataToMediaData = (imageData: ImageDataType | null): MediaDataInterface | undefined => {
  if (imageData) {
    const { id, localizationId, processed, value } = imageData;

    return {
      contentId: localizationId,
      mediaId: id,
      mediaValue: value,
      processed,
    };
  }
};

/** @TODO Move this to a service to be reused */
const getAlternativeValueSuggestions = async (language: LanguageV2, phrase: string): Promise<string[]> => {
  const response = await apiClient.noErrorsV2.post('ai/alternative-values', {
    phrase,
    language,
  });

  return response.data.alternativeValues as string[];
};

export const LexicalItemData = ({ isNewLexicalItem }: { isNewLexicalItem: boolean }) => {
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  const lexicalItem = useAppSelector(selectLexicalItem);
  const lexicalItemContent = useAppSelector(selectLexicalItemContent);

  const {
    interfaceLanguages,
    languageId: learningLanguage,
    onOpenTranslationPanel,
    onRemoveString,
  } = useVocabularyReview();

  useEffect(() => {
    if (isNewLexicalItem) {
      // clear form context values to prevent getting outdated localization data
      setFieldValue('phrase', processLocalizationsForFormikValues(lexicalItemContent?.phrase?.textLocalizations));
      setFieldValue('example', processLocalizationsForFormikValues(lexicalItemContent?.example?.textLocalizations));
    }
  }, [isNewLexicalItem, lexicalItemContent, setFieldValue]);

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, learningLanguage]));

  const { errors } = lexicalItemContent?.validationStatus || { errors: [] };
  /** Phrase validation errors */
  const phraseValidationErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'phrase');
  const phraseAudioErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'phrase' && error.message.toLowerCase().includes('audio'),
  );
  const phraseAudioLocalizations = lexicalItemContent?.phrase?.audioLocalizations;
  const availablePhraseAudioLocalizations = phraseAudioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  /** Update Phrase audio validation errors on audio processing */
  if (phraseAudioErrors.length) {
    let updatedErrors = [...errors];

    // Phrase audio localizations might not exist if the example field has previously removed
    availablePhraseAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          fieldName: 'phrase',
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  /** Example validation errors */
  const exampleValidationErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'example');
  const exampleAudioErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'example' && error.message.toLowerCase().includes('audio'),
  );
  const exampleAudioLocalizations = lexicalItemContent?.example?.audioLocalizations;
  const availableExampleAudioLocalizations = exampleAudioLocalizations?.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  /** Update Example audio validation errors on audio processing */
  if (exampleAudioErrors.length) {
    let updatedErrors = [...errors];

    // Example audio localizations might not exist if the example field has previously removed
    (availableExampleAudioLocalizations ?? []).forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          fieldName: 'example',
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const imageErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'image');
  const image = lexicalItemContent?.image;

  if (imageErrors.length && image?.processed && image?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const requestOrCancelAudioPayload = {
    field: 'phrase',
    language: learningLanguage as string,
    type: ContentTypes.lexicalItem,
  };

  const learningLanguagePhrase = (values.phrase || []).find((loc) => loc.language === learningLanguage);
  const learningLanguageAudioLocalization = lexicalItemContent.phrase.audioLocalizations.find(
    (audioLocalization) => audioLocalization.language === learningLanguage,
  );

  const isPhraseChangeBlocked =
    (lexicalItemContent.phrase?.mappings?.count > 1 || lexicalItemContent.phrase?.mappings?.length > 1) &&
    !lexicalItemContent.phrase?.isReusingConfirmed;

  const loadAlternativeValuesSuggestions = useCallback(async () => {
    if (!learningLanguagePhrase?.value) {
      return [];
    }

    return getAlternativeValueSuggestions(learningLanguage, learningLanguagePhrase.value);
  }, [learningLanguagePhrase, learningLanguage]);

  const onProcessingFinished = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: ContentTypes.lexicalItem,
          field: 'phrase',
          language: learningLanguage as string,
        }),
      );
    },
    [dispatch, learningLanguage],
  );

  const setReusedData = (id: DBId, field: string) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.lexicalItem,
        field,
        undefined,
        undefined,
        undefined,
        false,
      ),
    );
  };

  const matchedLexicalItemsCount = useRef<number | null>(null);
  const matchedLexicalItemsQuery = useRef<string | null>(null);

  const { open: openMatchedLexicalItemsModal, modal: matchedLexicalItemsModal } = useDialogModal((modalControls) => (
    <Modal lockScroll size="M" {...modalControls}>
      <MatchedLexicalItemsModal
        close={() => {
          modalControls.close();
        }}
        count={matchedLexicalItemsCount.current ?? 0}
        isNewLexicalItem={isNewLexicalItem}
        isOpen={modalControls.isOpen}
        language={learningLanguage}
        query={matchedLexicalItemsQuery.current ?? ''}
        onCancel={() => {
          onRemoveString(ContentTypes.lexicalItem, 'phrase');
          modalControls.close();
        }}
      />
    </Modal>
  ));

  return (
    <LexicalItemDataWrapper>
      <LexicalItemSubheader>
        <IDDisplayer id={lexicalItemContent.id} truncateAt={24} />
        <PublishingStatus ready={lexicalItemContent.ready} changeStatus={lexicalItemContent.changeStatus} />
        <TranslationsTipWrapper<LexicalItemFieldWithLocalizationNames, LexicalItemFormikValues>
          content={lexicalItemContent.phrase}
          defaultContextForTranslators="Lexical item phrase"
          interfaceLanguages={interfaceLanguages}
          errors={phraseValidationErrors}
          fieldName="phrase"
          learningLanguage={learningLanguage}
          showSearch={false}
          type={ContentTypes.lexicalItem}
          onContentSuggestionsClick={(count, query) => {
            matchedLexicalItemsCount.current = count;
            matchedLexicalItemsQuery.current = query;
            openMatchedLexicalItemsModal();
          }}
          onOpenTranslationsPanel={onOpenTranslationPanel}
          onRemoveString={onRemoveString}
        >
          <WritableInputText bold fontSize="30" id="lexical-item-phrase-input" placeholder="Phrase" withoutBorder />
        </TranslationsTipWrapper>
      </LexicalItemSubheader>

      {!isEditAvailable && <AccessWarning />}

      <StyledRow>
        <StyledCol>
          <FieldLabel level={3} spaceBottom="XS">
            Audio
          </FieldLabel>
          <AudioUpload
            audioData={ImageUploadUtils.getAudioForFileUpload(lexicalItem, 'phrase', lexicalItem.language)}
            currentLanguage={learningLanguage}
            errors={phraseAudioErrors}
            fieldName="phrase"
            fullScreen
            onAudioRequestSuccess={(audioRequestId: string) => {
              dispatch(
                CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                  ...requestOrCancelAudioPayload,
                  audioRequestData: {
                    id: audioRequestId,
                    status: 'new',
                  },
                }),
              );
            }}
            onCancelAudioRequestSuccess={() => {
              dispatch(
                CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
              );
            }}
            onProcessingFinished={onProcessingFinished}
            onChange={(uploadedSound, progressHandler) => {
              dispatch(
                AudioUploadActionsCreator.uploadSound(
                  lexicalItemContent.id,
                  ContentTypes.lexicalItem,
                  learningLanguage,
                  lexicalItemContent.phrase.id,
                  learningLanguageAudioLocalization,
                  uploadedSound,
                  'phrase',
                  undefined,
                  undefined,
                  true,
                  undefined,
                  progressHandler,
                ),
              );
            }}
            onChangeInstant={() => {
              setReusedData(lexicalItemContent.phrase?.id || '', 'phrase');
            }}
            isChangeBlocked={isPhraseChangeBlocked}
            onRemove={() => {
              dispatch(
                VocabularyReviewActionsCreator.removeAudio({
                  contentId: lexicalItemContent.phrase?.id,
                  fieldName: 'phrase',
                  language: learningLanguage,
                }),
              );
            }}
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          <AlternativeValueCreator
            content={lexicalItemContent}
            language={learningLanguage}
            loadSuggestions={loadAlternativeValuesSuggestions}
            visitedBranch="phrase"
            onChange={(values) => {
              dispatch(VocabularyReviewActionsCreator.setPhraseAltValues(values, learningLanguage));
              setFieldValue('phraseChanged', true);
            }}
            onChangeInstant={() => {
              setReusedData(lexicalItemContent.phrase?.id || '', 'phrase');
            }}
            isChangeBlocked={isPhraseChangeBlocked}
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          <FieldLabel level={3} spaceBottom="XS">
            Example
          </FieldLabel>
          <TranslationsTipWrapper<LexicalItemFieldWithLocalizationNames, LexicalItemFormikValues>
            content={lexicalItemContent.example}
            defaultContextForTranslators="Lexical item example"
            errors={exampleValidationErrors}
            fieldName="example"
            interfaceLanguages={interfaceLanguages}
            learningLanguage={learningLanguage}
            showSearch
            type={ContentTypes.lexicalItem}
            onOpenTranslationsPanel={(params) => onOpenTranslationPanel(params)}
            onRemoveString={onRemoveString}
          >
            <WritableInputText fontSize="16" id="lexical-item-example-input" placeholder="" />
          </TranslationsTipWrapper>
        </StyledCol>
        <StyledCol>
          <FieldLabel level={3} spaceBottom="XS">
            Example audio
          </FieldLabel>
          <AudioUpload
            audioData={ImageUploadUtils.getAudioForFileUpload(lexicalItem, 'example', lexicalItem.language)}
            currentLanguage={learningLanguage}
            errors={exampleAudioErrors}
            fieldName="example"
            fullScreen
            onAudioRequestSuccess={(audioRequestId: string) => {
              dispatch(
                CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                  ...requestOrCancelAudioPayload,
                  audioRequestData: {
                    id: audioRequestId,
                    status: 'new',
                  },
                }),
              );
            }}
            onCancelAudioRequestSuccess={() => {
              dispatch(
                CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
              );
            }}
            onProcessingFinished={onProcessingFinished}
            onChange={(uploadedSound, progressHandler) => {
              dispatch(
                AudioUploadActionsCreator.uploadSound(
                  lexicalItemContent.id,
                  ContentTypes.lexicalItem,
                  learningLanguage,
                  lexicalItemContent.phrase.id,
                  learningLanguageAudioLocalization,
                  uploadedSound,
                  'example',
                  undefined,
                  undefined,
                  true,
                  undefined,
                  progressHandler,
                ),
              );
            }}
            onChangeInstant={() => {
              setReusedData(lexicalItemContent.phrase?.id || '', 'example');
            }}
            isChangeBlocked={isPhraseChangeBlocked}
            onRemove={() => {
              dispatch(
                VocabularyReviewActionsCreator.removeAudio({
                  contentId: lexicalItemContent.phrase?.id,
                  fieldName: 'example',
                  language: learningLanguage,
                }),
              );
            }}
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol xs={6}>
          <FieldLabel level={3} spaceBottom="XS">
            Image
          </FieldLabel>
          <ImageUpload
            size={Sizes.fullscreen}
            errors={imageErrors}
            fieldName="image"
            height={ImageUploadDimensionDescriptors.lexicalItem.image.height}
            imageData={mapImageDataToMediaData(lexicalItemContent.image)}
            isForExercise
            mode={ImageUploadModes.normal}
            previewMode={false}
            width={ImageUploadDimensionDescriptors.lexicalItem.image.width}
            withHelp={false}
            onChange={(file, progressHandler) => {
              dispatch(
                VocabularyReviewActionsCreator.uploadImageToLexicalItem({
                  lexicalItemId: lexicalItemContent.id,
                  contentIdBeingUpdated: lexicalItemContent.image?.id,
                  file,
                  progressHandler,
                }),
              );
            }}
            onRemove={() => {
              dispatch(VocabularyReviewActionsCreator.removeImageFromLexicalItem(lexicalItemContent.id));
            }}
            onProcessingFinished={(url: string) => {
              dispatch(
                VocabularyReviewActionsCreator.setMediaValueAfterProcessing(ContentTypes.lexicalItem, 'image', url),
              );
            }}
            onChangeInstant={() => setReusedData(lexicalItemContent.id || '', 'image')}
          />
        </StyledCol>
      </StyledRow>
      {matchedLexicalItemsModal}
    </LexicalItemDataWrapper>
  );
};
