import type { DBId } from '@common/types/DBId';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { type LanguageV2, AVAILABLE_INTERFACE_LANGUAGES_V2 } from '@features/content/languages';

import type { VocabularyReviewListItem } from '../../types';
import { getPhrasesToDisplay } from '../util';

import { LexicalItemAudioLabel } from './LexicalItemAudioLabel';
import { LexicalItemMediaDisplay } from './LexicalItemMediaDisplay';
import {
  ExercisesMatchedContainer,
  ExercisesMatchedCount,
  StyledExercisesMatchedIcon,
  PhraseWrapper,
  TableCell,
  TableRow,
  EllipsedPhrase,
} from './styles';
import { MouseEvent } from 'react';

const TOTAL_TRANSLATIONS = AVAILABLE_INTERFACE_LANGUAGES_V2.length;

export type LexicalItemsRowProps = {
  learningLanguage: LanguageV2;
  lexicalItem: VocabularyReviewListItem;
  onClick: (lexicalItemId: DBId) => void;
  openMatchedExercisesModal: () => void;
};

export const LexicalItemRow = ({
  learningLanguage,
  lexicalItem,
  onClick,
  openMatchedExercisesModal,
}: LexicalItemsRowProps) => {
  const { id, changeStatus, hasAudio, hasVideo, image, matchedExercisesCount, phrase, ready, translationsCount } =
    lexicalItem;

  const handleOnClickOnExercisesMatched = (evt: MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();

    matchedExercisesCount && openMatchedExercisesModal();
  };

  return (
    <TableRow data-testid="lexical-item-row" onClick={() => onClick(id)}>
      <TableCell>
        <CardPublishingStatus hasPendingChanges={changeStatus.hasPendingChanges} ready={ready} />
        <PhraseWrapper>
          <IDDisplayer id={id} mode="small" />
          <EllipsedPhrase>{getPhrasesToDisplay(phrase, learningLanguage)}</EllipsedPhrase>
        </PhraseWrapper>
      </TableCell>
      <TableCell>
        {translationsCount} / {TOTAL_TRANSLATIONS}
      </TableCell>
      <TableCell>
        <LexicalItemAudioLabel hasAudio={hasAudio} />
      </TableCell>
      <TableCell>
        <LexicalItemMediaDisplay hasVideo={hasVideo} image={image} />
      </TableCell>
      <TableCell align="right" fontSize="1.4rem">
        <ExercisesMatchedContainer disabled={matchedExercisesCount === 0} onClick={handleOnClickOnExercisesMatched}>
          <ExercisesMatchedCount>{matchedExercisesCount}</ExercisesMatchedCount>
          {!!matchedExercisesCount && <StyledExercisesMatchedIcon />}
        </ExercisesMatchedContainer>
      </TableCell>
    </TableRow>
  );
};
