import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';
import type { LanguageV2 } from '@features/content/languages';

import {
  GroupsService_updateLessonFocus,
  GroupsService_updateLessonType,
  GroupsService_updateLevelType,
} from './types/GroupsServiceTypes';

const GroupsService = {
  lessons: {
    async uploadImage(lessonId: DBId, contentIdBeingUpdated: DBId | null) {
      return await apiClient.noErrorsV2.put(`content/groups/${lessonId}`, { image: contentIdBeingUpdated });
    },
    async uploadUnitImage(lessonId: DBId, contentIdBeingUpdated: DBId | null) {
      return await apiClient.noErrorsV2.put(`content/groups/${lessonId}`, { thumbnailImage: contentIdBeingUpdated });
    },
    async updateFocus(
      lessonId: GroupsService_updateLessonFocus['lessonId'],
      focus: GroupsService_updateLessonFocus['focus'],
    ) {
      return await apiClient.noErrorsV2.put(`content/groups/${lessonId}`, { focus });
    },
    async updateType(
      lessonId: GroupsService_updateLessonType['lessonId'],
      lessonType: GroupsService_updateLessonType['lessonType'],
    ) {
      return await apiClient.noErrorsV2.post(`content/lessons/${lessonId}/change-class`, { class: lessonType });
    },
    async getVocabularyReviewBundles(
      lessonId: DBId,
      language: LanguageV2,
    ): Promise<{ data: { bundles: []; count: number } }> {
      return await apiClient.noErrorsV2.get(`/content/lessons/${lessonId}/vocabulary-review`, {
        params: { language },
      });
    },
  },
  levels: {
    async updateType(
      levelId: GroupsService_updateLevelType['levelId'],
      levelType: GroupsService_updateLevelType['levelType'],
    ) {
      return await apiClient.noErrorsV2.put(`content/groups/${levelId}`, { class: levelType });
    },
  },
};

export default GroupsService;
