import { useCallback, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { ContentTypes } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { ReactComponent as TrashIcon } from '@components/ContentTypes/BaseContent/img/trashNew.svg';
// @TODO move these styles to this feature
import {
  SuggestionsSpinnerAnimated,
  SuggestionWrapper,
  TranslationTipOption,
  TranslationTipOptions,
  TranslationTipOptionSuggestions,
  TruncatedContentId,
} from '@components/TranslationTip/styles';
import { useIsAdmin } from '@features/auth/roles';
import { useIsEditAvailable } from '@features/content/courses';
import type { LanguageV2 } from '@features/content/languages';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { useToast } from '@features/app/toast';
import { useAppDispatch } from '@redux/store';
import { userTracking } from '@features/app/tracking';

import type { OnOpenTranslationPanelParams, TranslationsTipCommonProps } from '../types';
import { useContentSuggestions } from '../useContentSuggestions';

import { ReactComponent as AudioIcon } from './img/Audio.svg';
import { ReactComponent as InfoIcon } from './img/info.svg';
import { ReactComponent as LockIcon } from './img/Lock.svg';
import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as TranslationIcon } from './img/Translation.svg';

type WidgetOptionsProps<T> = TranslationsTipCommonProps<T> & {
  audioElements: string;
  compact: boolean;
  currentLanguage: LanguageV2;
  isChangeBlocked: boolean;
  isEditDisabled: boolean;
  isWidgetActive: boolean;
  suggestionsQuery: string;
  translationElements: string;
  visitedContentId: DBId | undefined;
};

export const WidgetOptions = <T extends string>({
  audioElements,
  compact,
  currentLanguage,
  defaultContextForTranslators,
  fieldName,
  isChangeBlocked,
  isEditDisabled,
  isWidgetActive,
  showSearch,
  suggestionsQuery,
  translationElements,
  type,
  visitedContentId,
  onContentSuggestionsClick,
  onOpenTranslationsPanel,
  onRemoveString,
}: WidgetOptionsProps<T>) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const isAdmin = useIsAdmin();
  const localizationId = visitedContentId || 'N/A';
  const showToast = useToast();
  const { showContentSuggestions, suggestionsCount, suggestionQueryLoading } = useContentSuggestions({
    currentLanguage,
    fieldName,
    isWidgetActive,
    localizationId,
    suggestionsQuery,
    type,
  });

  const contentSuggestionsMessagePrefix = useMemo(() => {
    if (type === ContentTypes.lexicalItem && fieldName === 'phrase') {
      return <InfoIcon />;
    }

    return '~';
  }, [fieldName, type]);

  /** Widget Options handlers */

  const onCopyLocalizationId = useCallback(() => {
    showToast({
      type: 'info',
      title: 'Content ID copied to clipboard',
    });
  }, [showToast]);

  const onLocalizationIdOptionClick = useCallback(() => {
    if (localizationId?.includes('default_') && !isAdmin) {
      showToast({
        type: 'info',
        title: 'Default instructions cannot be edited',
        description: 'To remove the default instruction, clear it using the trash can',
      });
    }
  }, [isAdmin, localizationId, showToast]);

  const onOpenTranslationsPanelOptionClick = useCallback(
    (activeTab: OnOpenTranslationPanelParams<T>['tab']) => {
      onOpenTranslationsPanel({
        defaultContextForTranslators,
        fieldName,
        isChangeBlocked,
        isEditDisabled,
        tab: activeTab,
        type,
        visitedContentId,
      });
    },
    [
      defaultContextForTranslators,
      fieldName,
      isChangeBlocked,
      isEditDisabled,
      onOpenTranslationsPanel,
      type,
      visitedContentId,
    ],
  );

  const onRemoveStringOptionClick = useCallback(() => {
    onRemoveString(type, fieldName);
  }, [fieldName, onRemoveString, type]);

  const onSearchStringOptionClick = useCallback(() => {
    dispatch(
      SearchModalActionsCreator.showSearchV2Panel({
        contentType: 'string',
        filtersPreset: {
          string: {
            languages: [currentLanguage],
          },
        },
        forReusing: true,
        predefinedType: 'string',
        query: suggestionsQuery,
        language: currentLanguage,
        visitedBranch: fieldName,
      }),
    );
    userTracking.logosSearchOpened({
      source: 'resource',
    });
  }, [currentLanguage, dispatch, fieldName, suggestionsQuery]);

  const onContentSuggestionsOptionClick = useCallback(() => {
    if (suggestionsCount === 0) return;

    if (onContentSuggestionsClick) {
      onContentSuggestionsClick(suggestionsCount, suggestionsQuery);
    } else {
      // default handler
      onSearchStringOptionClick();
    }
  }, [onContentSuggestionsClick, onSearchStringOptionClick, suggestionsCount, suggestionsQuery]);

  return (
    <TranslationTipOptions
      compact={compact}
      hideContentSuggestions={!showContentSuggestions}
      hideSearch={isEditDisabled}
    >
      {showContentSuggestions && (
        <TranslationTipOptionSuggestions>
          <SuggestionWrapper showMatches={suggestionsCount > 0} onClick={onContentSuggestionsOptionClick}>
            {suggestionQueryLoading ? (
              <SuggestionsSpinnerAnimated />
            ) : (
              <>
                {contentSuggestionsMessagePrefix}
                {suggestionsCount} matches with&nbsp;<strong>"{suggestionsQuery}"</strong>
              </>
            )}
          </SuggestionWrapper>
        </TranslationTipOptionSuggestions>
      )}
      <TranslationTipOption onClick={() => onOpenTranslationsPanelOptionClick('translations')}>
        <TranslationIcon title="Translation" />
        <span>trns: {translationElements}</span>
      </TranslationTipOption>
      <TranslationTipOption onClick={() => onOpenTranslationsPanelOptionClick('audios')}>
        <AudioIcon title="Audio" />
        <span>audio: {audioElements}</span>
      </TranslationTipOption>
      <TranslationTipOption title={localizationId} onClick={onLocalizationIdOptionClick}>
        {localizationId && (
          <CopyToClipboard text={localizationId}>
            {localizationId.includes('default_') && !isAdmin ? (
              <LockIcon title="Lock" />
            ) : (
              <TruncatedContentId onClick={onCopyLocalizationId}>{localizationId}</TruncatedContentId>
            )}
          </CopyToClipboard>
        )}
      </TranslationTipOption>
      {!translationElements?.includes('0/') && isEditAvailable ? (
        <TranslationTipOption onlyIcon onClick={onRemoveStringOptionClick}>
          <TrashIcon title="Remove" />
        </TranslationTipOption>
      ) : null}
      {!isEditDisabled && showSearch && (
        <TranslationTipOption onlyIcon onClick={onSearchStringOptionClick}>
          <SearchIcon title="Search" />
        </TranslationTipOption>
      )}
    </TranslationTipOptions>
  );
};
