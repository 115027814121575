import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import type { LanguageV2 } from '@features/content/languages';
import { NavigationContainer, NavigationItemType } from '@features/content/navigation';
import { RoleplayService } from '@features/content/roleplay';
import { Loader } from '@features/theme';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectRoleplay,
  selectRoleplayNavigationLoadingParentId,
  selectRoleplayNavigationStructure,
} from '@selectors/roleplaySelectors';

import { RoleplayListItemType } from '../types';
import { NavigationItem } from './NavigationItem';

export const Navigation = () => {
  const dispatch = useAppDispatch();
  const { languageId, categoryId, scenarioId } = useParams<{
    languageId: LanguageV2;
    categoryId: DBId;
    scenarioId: DBId;
  }>();
  const currentPathIds = [`roleplay_${languageId}`, categoryId, scenarioId];

  const loadingParentId = useAppSelector(selectRoleplayNavigationLoadingParentId);
  const navigationStructure = useAppSelector(selectRoleplayNavigationStructure);

  const roleplay = useAppSelector(selectRoleplay);

  const getChildrenArray = (type: ContentTypesType, id: DBId) => {
    if (!navigationStructure.map((content) => content.parentId).includes(id)) {
      dispatch(RoleplayActionCreators.setLoadingParentId(id));

      switch (type) {
        case ContentTypes.roleplay:
          return RoleplayService.getRoleplayNavigation(languageId).then((result) =>
            result.data.categories.map((category: RoleplayListItemType) => ({
              ...category,
              parentId: id,
              type: ContentTypes.roleplayCategory,
              expanded: categoryId === category.id,
            })),
          );
        case ContentTypes.roleplayCategory:
          return RoleplayService.getRoleplayCategoryNavigation(id).then((result) =>
            result.data.roleplayScenarios.map((scenario: RoleplayListItemType) => ({
              ...scenario,
              parentId: id,
              type: ContentTypes.roleplayScenario,
            })),
          );

        default:
          return [];
      }
    }
    return [];
  };

  const getChildren = async (type: ContentTypesType, id: DBId) => {
    const childrenArray = await getChildrenArray(type, id);
    dispatch(
      RoleplayActionCreators.updateNavigation([...navigationStructure, ...(childrenArray as NavigationItemType[])]),
    );
    dispatch(RoleplayActionCreators.setLoadingParentId(''));
  };

  useEffect(() => {
    if (roleplay.content.id) {
      dispatch(RoleplayActionCreators.getAllNavigation(languageId, roleplay.content.id, categoryId, scenarioId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, roleplay.content.id]);

  const roleplayStructureItem = navigationStructure.find((content) => content.type === ContentTypes.roleplay);

  if (!roleplayStructureItem) {
    return <Loader />;
  }

  return (
    <NavigationContainer isDisabled={!!loadingParentId} buttonsAmount={1}>
      <NavigationItem
        content={roleplayStructureItem}
        currentPathIds={currentPathIds}
        structure={navigationStructure}
        loadingParentId={loadingParentId}
        onExpand={getChildren}
      />
    </NavigationContainer>
  );
};
