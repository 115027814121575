import { FunctionComponent, useCallback } from 'react';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { LoadedSpellingExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { Explanation, Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import { getResources } from '@helpers/getResourcesHelper';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { PreviewModal } from '@features/content/preview';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { constants as contentConstants, findLocalizationInSearchedLanguage } from '@features/content';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { AudioUpload, ImageUpload } from '@components/MediaUpload';
import GappingTool from './GappingTool/GappingTool';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExperimentSelector } from '@features/experiment';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { Sizes } from '@common/enums/Sizes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { SpellingExerciseActionsCreator } from '@actionCreators/SpellingExerciseActionsCreator';
import SpellingExerciseInterface from './interfaces/SpellingExerciseInterface';
import { DEFAULT_LANGUAGE_V2, LANGUAGE_NAMES_V2 } from '@features/content/languages';
import { Instructions, LexicalItemsMatched, removeMediaProcessingValidationError } from '@features/content/exercises';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';
import { DBId } from '@common/types/DBId';
import { isFeatureEnabled } from '@helpers/featuresHelper';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

const SpellingExercise: FunctionComponent<ExerciseProps<LoadedSpellingExerciseInterface>> = ({
  exercise: exerciseParam,
}: ExerciseProps<LoadedSpellingExerciseInterface>) => {
  const { isEditorHidden } = useIsEditorHidden({ bundleName: 'mainBundle', visitedBranch: 'phrase' });
  const dispatch = useAppDispatch();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as SpellingExerciseInterface;

  const audioLocalizations = loadedExercise?.content?.mainBundle?.phrase.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableAudioLocalizations = audioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  // Image only has EN localization
  const imageLocalization = loadedExercise?.content?.mainBundle?.image?.imageLocalizations?.find(
    (imageLocalization) => imageLocalization.language === DEFAULT_LANGUAGE_V2,
  );

  const { isEditAvailable } = useIsEditAvailable();

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const mainBundleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle');
  const phraseErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.phrase');
  const imageErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.image');

  if (imageErrors.length && imageLocalization?.processed && imageLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE_V2),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const phraseTextErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      (error.field === 'mainBundle.phrase' || error.field === 'phrase') &&
      ['text', 'add translations', 'tags'].some((t) => error.message.includes(t)),
  );
  const phraseAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'mainBundle.phrase' && error.message.toLowerCase().includes('audio'),
  );

  if (phraseAudioErrors.length) {
    let updatedErrors = [...errors];

    availableAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const isPhraseChangeBlocked =
    (loadedExercise.content?.mainBundle?.phrase?.mappings?.count > 1 ||
      loadedExercise.content?.mainBundle?.phrase?.mappings?.length > 1) &&
    !loadedExercise.content?.mainBundle?.phrase?.isReusingConfirmed;

  const isMainBundleChangeBlocked =
    loadedExercise.content.mainBundle?.mappings?.count &&
    loadedExercise.content.mainBundle?.mappings?.count > 1 &&
    !loadedExercise.content.mainBundle?.isReusingConfirmed;

  const requestOrCancelAudioPayload = {
    bundleName: 'mainBundle',
    field: 'phrase',
    language: courseLearningLanguage,
    type: ContentTypes.exercise,
  };

  const renderEditor = () => (
    <TranslationsTipV2
      bundleName={'mainBundle'}
      visitedBranch={'phrase'}
      showLanguageSwitcher={false}
      errors={phraseAudioErrors.length || phraseTextErrors.length ? phraseTextErrors : phraseErrors}
      showErrorDisplayer={false}
      flipped={false}
    >
      <GappingTool
        dataSourceMode={true ? 'editorState' : 'defaultEditorState'}
        placeholder={`Write in ${LANGUAGE_NAMES_V2[courseLearningLanguage]}`}
      />
    </TranslationsTipV2>
  );

  const onProcessingFinished = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: ContentTypes.exercise,
          field: 'phrase',
          language: courseLearningLanguage,
          bundleName: 'mainBundle',
        }),
      );
    },
    [dispatch, courseLearningLanguage],
  );

  return (
    <div className="exercise-spelling">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Spelling</Title>
        <HelpDisplayer type="guideline" id="guideline-spelling-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-spelling__main-wrapper">
        <div className="exercise-spelling__wrapper">
          <div className="exercise-spelling__container">
            <h1 className="exercise-spelling__box-title">{'Instruction'}</h1>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </div>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(SpellingExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
            }}
          />
        </div>

        {isFeatureEnabled('vocabularyReview') && (
          <LexicalItemsMatched
            exerciseId={loadedExercise.content.id as DBId}
            language={courseLearningLanguage}
            values={loadedExercise.content.lexicalItems ?? []}
          />
        )}

        <ResourceBundleWrapper
          bundle={loadedExercise.content.mainBundle}
          bundleName={'mainBundle'}
          errors={mainBundleErrors}
          resources={getResources(loadedExercise, courseLearningLanguage)}
        >
          <>
            <div className="exercise-spelling__wrapper">
              <div className="exercise-spelling__container">
                <h1 className="exercise-spelling__box-title">Phrase</h1>
                <ValidationErrorDisplayer
                  type={phraseTextErrors.some((e) => !e.isWarning) ? 'error' : 'warning'}
                  text={
                    phraseAudioErrors.length || phraseTextErrors.length
                      ? helpersService.getValidationErrorMessageText(phraseTextErrors)
                      : helpersService.getValidationErrorMessageText(phraseErrors)
                  }
                />
              </div>
              {isEditorHidden ? <div>{renderEditor()}</div> : <>{renderEditor()}</>}
            </div>
            <div className="exercise-spelling__wrapper">
              <div className="exercise-spelling__container">
                <h3 className="exercise-spelling__box-title">Audio</h3>
                <ValidationErrorDisplayer
                  text={
                    phraseAudioErrors.length || phraseTextErrors.length
                      ? helpersService.getValidationErrorMessageText(phraseAudioErrors)
                      : helpersService.getValidationErrorMessageText(
                          phraseErrors.filter((error: ValidationErrorInterface) => !error.message.includes('tags')),
                        )
                  }
                />
              </div>
              <AudioUpload
                audioData={ImageUploadUtils.getAudioForFileUpload(
                  loadedExercise,
                  'phrase',
                  courseLearningLanguage,
                  'mainBundle',
                )}
                onAudioRequestSuccess={(audioRequestId: string) => {
                  dispatch(
                    CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                      ...requestOrCancelAudioPayload,
                      audioRequestData: {
                        id: audioRequestId,
                        status: 'new',
                      },
                    }),
                  );
                }}
                onCancelAudioRequestSuccess={() => {
                  dispatch(
                    CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
                  );
                }}
                onProcessingFinished={onProcessingFinished}
                onChange={(uploadedSound, progressHandler) => {
                  dispatch(
                    AudioUploadActionsCreator.uploadSound(
                      loadedExercise.content.id,
                      ExerciseTypes.spelling,
                      courseLearningLanguage,
                      loadedExercise.content?.mainBundle?.phrase?._id,
                      findLocalizationInSearchedLanguage(
                        loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations as any,
                        courseLearningLanguage,
                      ),
                      uploadedSound,
                      'phrase',
                      undefined,
                      undefined,
                      true,
                      'mainBundle',
                      progressHandler,
                    ),
                  );
                }}
                onRemove={() => {
                  dispatch(SpellingExerciseActionsCreator.removeAudio());
                }}
                onChangeInstant={() => {
                  if (isMainBundleChangeBlocked) {
                    dispatch(
                      TranslationTipActionsCreator.setCurrentContentId(
                        loadedExercise.content.mainBundle?._id || '',
                        ContentTypes.exercise,
                        'phrase',
                        undefined,
                        undefined,
                        'mainBundle',
                        true,
                      ),
                    );
                  } else {
                    dispatch(
                      TranslationTipActionsCreator.setCurrentContentId(
                        loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id ||
                          '',
                        ContentTypes.exercise,
                        'phrase',
                        undefined,
                        undefined,
                        'mainBundle',
                      ),
                    );
                  }
                }}
                isChangeBlocked={isMainBundleChangeBlocked || isPhraseChangeBlocked}
                fullScreen
                errors={
                  phraseAudioErrors.length || phraseTextErrors.length
                    ? phraseAudioErrors
                    : phraseErrors.filter((error: ValidationErrorInterface) => !error.message.includes('tags'))
                }
                currentLanguage={courseLearningLanguage}
                fieldName={'phrase'}
              />
            </div>
            <div className="exercise-spelling__wrapper">
              <div className="exercise-spelling__container">
                <h3 className="exercise-spelling__box-title">Image</h3>
                <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(imageErrors)} />
              </div>
              <ImageUpload
                width={ImageUploadDimensionDescriptors.spelling.image.width}
                height={ImageUploadDimensionDescriptors.spelling.image.height}
                size={Sizes.fullscreen}
                mode={ImageUploadModes.normal}
                onChange={(file: File, progressHandler: Function) => {
                  if (file !== null && loadedExercise.content.id !== undefined) {
                    dispatch(SpellingExerciseActionsCreator.setImage(loadedExercise, file, progressHandler));
                  }
                }}
                onRemove={() => {
                  dispatch(SpellingExerciseActionsCreator.removeImage());
                }}
                imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                  loadedExercise,
                  'image',
                  undefined,
                  'mainBundle',
                )}
                onProcessingFinished={(url: string) => {
                  dispatch(
                    CommonExerciseActionsCreator.setValueAfterProcessing({
                      url,
                      mediaType: 'image',
                      type: ContentTypes.exercise,
                      field: 'image',
                      language: 'EN',
                      bundleName: 'mainBundle',
                    }),
                  );
                }}
                previewMode={false}
                isForExercise
                isChangeBlocked={!!isMainBundleChangeBlocked}
                onChangeInstant={() => {
                  dispatch(
                    TranslationTipActionsCreator.setCurrentContentId(
                      loadedExercise.content.mainBundle?._id || '',
                      ContentTypes.exercise,
                      'image',
                      undefined,
                      undefined,
                      'mainBundle',
                      true,
                    ),
                  );
                }}
                errors={imageErrors}
              />
            </div>
          </>
        </ResourceBundleWrapper>
        <Explanation />
        <RecapSelector exerciseId={loadedExercise?.content?.id} />
      </div>
    </div>
  );
};

export default SpellingExercise;
