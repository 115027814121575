import React, { useState } from 'react';
import { FormikContextType, useFormikContext } from 'formik';

import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import TranslationsTip from '@components/TranslationTip/TranslationTip';
import { formatCounterOfFilledLocalizations } from '@features/content';
import { ContentTypes } from '@common/enums/ContentTypes';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { getReuseData } from '@helpers/reuseHelper';
import TranslationTipV2Utils from './TranslationTipV2Utils';
import TranslationsTipV2Props from './TranslationsTipV2Props';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';

const getPlainSuggestionQuery = (str: string) => str.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, '');

const TranslationsTipV2 = ({
  children,
  visitedBranch,
  bundleName,
  row,
  column,
  showLanguageSwitcher = true,
  flipped = true,
  content = {},
  type = ContentTypes.exercise,
  errors = [],
  showErrorDisplayer = true,
  withImage = false,
  forTrueFalse = false,
  translationsPanelTranslationsMode: providedTranslationsPanelTranslationsMode,
  hideRemove = false,
  hideSearch = false,
  className,
  isLesson,
  isExercise = true,
  compact = false,
  defaultContextForTranslators,
}: TranslationsTipV2Props) => {
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const deducedTranslationsPanelTranslationsMode =
    visitedBranch === 'paragraph' ? TranslationsPanelModes.wysiwyg : TranslationsPanelModes.normal;
  const translationsPanelTranslationsMode =
    providedTranslationsPanelTranslationsMode || deducedTranslationsPanelTranslationsMode;
  const { isEditAvailable } = useIsEditAvailable();
  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const isErrorsShown = isIssuesShown && errors?.length;
  const hasErrors = errors?.some((e) => !e.isWarning);
  const hasWarnings = errors?.some((e) => e.isWarning);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as AnyExerciseInterface;
  let contentForTranslation = content;
  if (type === ContentTypes.exercise) {
    contentForTranslation = loadedExercise.content;
  }

  let { audioElements, localizationIDForLangExperts, chosenBranch, bundleId } =
    TranslationTipV2Utils.getTranslationAndAudioElements(
      interfaceLanguages,
      contentForTranslation,
      visitedBranch,
      row,
      column,
      courseLearningLanguage,
      bundleName,
    );

  let [languageForTips, setLanguageForTips] = useState(flipped ? DEFAULT_LANGUAGE_V2 : courseLearningLanguage);
  let [isPhonetic, setIsPhonetic] = useState(false);

  const fieldName = bundleName ? `${bundleName}_${visitedBranch}` : visitedBranch;
  let displayedValueInChildren =
    (values as any)[fieldName]?.find((item: any) => item.language === languageForTips && !item.isPhonetic)?.value || '';

  const translationElements = formatCounterOfFilledLocalizations(
    (values as any)[fieldName]?.filter((item: any) => item.value && !item.isPhonetic) || [],
    interfaceLanguages,
    courseLearningLanguage,
  );

  let placeholder = TranslationTipV2Utils.formatPlaceholder(
    children?.props?.placeholder,
    visitedBranch,
    languageForTips,
  );

  const { isStringChangeBlocked, isBundleChangeBlocked } = getReuseData({
    contentForTranslation,
    bundleName: bundleName || '',
    visitedBranch,
    isEditAvailable,
    localizationIDForLangExperts,
  });

  return (
    <>
      <TranslationsTip
        localizationIDForLangExperts={localizationIDForLangExperts}
        bundleId={bundleId}
        isExercise={isExercise}
        isLesson={isLesson}
        isBundleChangeBlocked={isBundleChangeBlocked}
        translationElements={translationElements}
        audioElements={audioElements}
        visitedContentId={chosenBranch?._id}
        visitedBranch={visitedBranch}
        bundleName={bundleName}
        isStringChangeBlocked={isStringChangeBlocked}
        showLanguageSwitcher={showLanguageSwitcher}
        row={row && row}
        column={column && column}
        flipped={flipped}
        onLanguageSwitchToggled={(switchedToEnglishLanguage: boolean, isPhoneticSelected: boolean) => {
          setLanguageForTips(switchedToEnglishLanguage ? DEFAULT_LANGUAGE_V2 : courseLearningLanguage);
          setIsPhonetic(isPhoneticSelected);
        }}
        type={type}
        languageForTips={languageForTips}
        translationsPanelTranslationsMode={translationsPanelTranslationsMode}
        withImage={withImage}
        forTrueFalse={forTrueFalse}
        hideRemove={hideRemove}
        hideSearch={hideSearch}
        className={className}
        suggestionsQuery={getPlainSuggestionQuery(displayedValueInChildren)}
        compact={compact}
        defaultContextForTranslators={defaultContextForTranslators}
      >
        {React.cloneElement(children, {
          placeholder: placeholder,
          value: displayedValueInChildren,
          currentLanguage: languageForTips,
          onChange: (availableText: string) => {
            const newValues = (values as any)?.[fieldName]?.map((item: any) => {
              if (item.language === languageForTips && item.isPhonetic === isPhonetic) {
                return {
                  ...item,
                  value: availableText,
                };
              }
              return item;
            });

            setFieldValue(fieldName, newValues);
            setFieldValue(`${fieldName}Changed`, true);
          },
          errorsShown: isErrorsShown,
          errorType: hasErrors ? 'error' : hasWarnings ? 'warning' : undefined,
        })}
      </TranslationsTip>
      {showErrorDisplayer ? (
        <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(errors)} />
      ) : null}
    </>
  );
};

export default TranslationsTipV2;
