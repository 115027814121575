import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import type { LanguageV2 } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { TipExerciseActions } from '@actions/TipExerciseActions';
import TipExerciseInterface from '@components/Exercises/Tip/interfaces/TipExerciseInterface';

export const TipExerciseActionsCreator = {
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: TipExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setParagraphLanguage: (paragraphLanguage: DisplayContentToUserModeType) => ({
    type: TipExerciseActions.SET_PARAGRAPH_LANGUAGE,
    payload: {
      paragraphLanguage,
    },
  }),
  setExampleChanged: (examplesLanguage?: DisplayContentToUserModeType, removeExamples?: boolean) => ({
    type: TipExerciseActions.SET_EXAMPLES_CHANGED,
    payload: {
      examplesLanguage,
      removeExamples,
    },
  }),
  save: (exercise: TipExerciseInterface, learningLanguage: LanguageV2, values: FormikValuesInterface) => ({
    type: TipExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
};
