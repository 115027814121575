import { call, put, select } from 'redux-saga/effects';

import { ListenRepeatExerciseActions } from '@actions/ListenRepeatExerciseActions';
import type { LanguageV2 } from '@features/content/languages';
import ListenRepeatExerciseInterface from '@components/Exercises/ListenRepeat/interfaces/ListenRepeatExerciseInterface';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';
import ListenRepeatExerciseService from '@services/ExerciseRelatedServices/ListenRepeatExerciseService';

const ListenRepeatExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: ListenRepeatExerciseInterface;
        learningLanguage: LanguageV2;
        values: FormikValuesInterface;
      };
    },
  ) {
    const { values } = payload;

    yield put({
      type: ListenRepeatExerciseActions.SET_LISTEN_REPEAT_ALL_FIELDS,
      payload: {
        values,
      },
    });
    const exercise: ListenRepeatExerciseInterface = yield select(selectLoadedExerciseData);

    yield call(ListenRepeatExerciseService.save, dispatch, exercise, values);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default ListenRepeatExerciseSagas;
