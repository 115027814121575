import { Col, Row } from 'react-bootstrap';
import styled, { css } from 'styled-components/macro';

import { Text } from '@features/theme';

export const LexicalItemDataWrapper = styled.div`
  width: 102.4rem;
`;

export const LexicalItemSubheader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: ${({ theme }) => theme.space.L};
`;

export const StyledCol = styled(Col)`
  padding: 0;
  margin: 0;
`;

export const StyledRow = styled(Row)`
  ${({ theme }) => css`
    gap: ${theme.space.S};
    padding: 0;
    margin: 0 0 ${theme.space.S} 0;
  `}
`;

export const FieldLabel = styled(Text)`
  color: ${({ theme }) => theme.colorV2.textTertiary};
`;

export const MatchedLexicalItemsModalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: calc(${theme.space.M} * -1);
    margin-right: calc(${theme.space.M} * -1);
  `}
`;

export const MatchedLexicalItemsModalHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space.L};
`;

export const MatchedLexicalItemsModalDescription = styled(Text)`
  color: ${({ theme }) => theme.colorV2.textTertiary};
`;

export const MatchedLexicalItemsModalResultsContainer = styled.div`
  height: 34.4rem;
  margin-bottom: ${({ theme }) => theme.space.L};
  overflow-x: scroll;
`;

export const MatchedLexicalItemsModalButtonArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.space.M};
    justify-content: flex-end;
    padding: 0 ${theme.space.M};
    width: 100%;
  `}
`;
