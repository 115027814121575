import { useHistory } from 'react-router-dom';

import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { LANGUAGE_NAMES_V2 } from '@features/content/languages';

import { BreadcrumbsWrapper } from '../styles';
import { useVocabularyReview } from '../useVocabularyReview';

export const Breadcrumbs = () => {
  const history = useHistory();
  const { contentTitle, languageId } = useVocabularyReview();

  const parentTitle = `${LANGUAGE_NAMES_V2[languageId]} Vocabulary Review`;

  return (
    <BreadcrumbsWrapper>
      <BreadcrumbNavigation
        breadcrumbItems={[
          <span
            className="breadcrumb-navigation__node breadcrumb-navigation__node_link"
            onClick={() => history.push(`/vocabulary-review/${languageId}`)}
          >
            {parentTitle}
          </span>,
          <span>{contentTitle || ''}</span>,
        ]}
      />
    </BreadcrumbsWrapper>
  );
};
