import { FunctionComponent, useMemo } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { TipContentProps } from '../../PreviewModalProps';
import { removeBrTag } from '@helpers/htmlTagsHelper';
import { getValueByLanguage } from '../../../../localization-utils';

import './TipContent.scss';
import { useAppSelector } from '@redux/store';
import { selectInterfaceLanguages } from '@selectors/CoursesSelectors';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';

const getValueByInterfaceLanguage = (
  localizedContent: LocalizationInterface[],
  interfaceLanguages: string[],
): string[] => {
  if (localizedContent && localizedContent.length) {
    return localizedContent
      .filter(
        (localization) =>
          interfaceLanguages.includes(localization.language) &&
          removeBrTag(localization.value) &&
          localization.language !== DEFAULT_LANGUAGE_V2,
      )
      .map((localization) => localization.value);
  }
  return [];
};

export const TipContent: FunctionComponent<TipContentProps> = ({
  content,
  courseLearningLanguage,
  isForList,
}: TipContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const isParagraphOnCourseLang = useMemo(
    () => !content.paragraphLanguage || content.paragraphLanguage === 'learning',
    [content.paragraphLanguage],
  );
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);
  const paragraphOnEnLang = useMemo(
    () => getValueByLanguage(isForList ? content.paragraph?.textLocalizations : values.paragraph),
    [values, isForList, content.paragraph],
  );
  const paragraphOnCourseLang = useMemo(
    () =>
      getValueByLanguage(isForList ? content.paragraph?.textLocalizations : values.paragraph, courseLearningLanguage),
    [values, courseLearningLanguage, isForList, content.paragraph],
  );

  const isExamplesExist = content.examples?.some((examplesRow) =>
    examplesRow.some((example) => {
      const exampleOnCourseLang = getValueByLanguage(example?.textLocalizations, courseLearningLanguage);
      const exampleOnENLang = getValueByLanguage(example?.textLocalizations);
      const exampleOnInterfaceLang = getValueByInterfaceLanguage(example?.textLocalizations, interfaceLanguages);
      return exampleOnCourseLang || exampleOnENLang || !!exampleOnInterfaceLang.length;
    }),
  );

  return (
    <>
      <span
        className="tip-text"
        dangerouslySetInnerHTML={{
          __html: isParagraphOnCourseLang
            ? paragraphOnCourseLang || ''
            : // Need to know which language to use, when not 'learning' selected
              paragraphOnEnLang || '',
        }}
      />
      <div className="tip-examples">
        {isExamplesExist &&
          content.examples.map((example: TranslationsPanelContentInterface[]) => (
            <div className={classnames('tip-example', { 'tip-example--only-one': content.examples.length === 1 })}>
              {example.map((exam) => {
                const exampleOnCourseLang = getValueByLanguage(exam?.textLocalizations, courseLearningLanguage);
                const exampleOnENLang = getValueByLanguage(exam?.textLocalizations);
                const exampleOnInterfaceLang = getValueByInterfaceLanguage(exam?.textLocalizations, interfaceLanguages);
                let exampleValue = '';

                if (removeBrTag(exampleOnENLang)) {
                  exampleValue = exampleOnENLang;
                } else {
                  if (exampleOnInterfaceLang.length) {
                    exampleValue = exampleOnInterfaceLang[0];
                  } else {
                    exampleValue = exampleOnCourseLang || '';
                  }
                }

                return (
                  <div
                    className={classnames('tip-example__instance', `tip-example__instance--${example.length}`)}
                    dangerouslySetInnerHTML={{ __html: exampleValue || '' }}
                  />
                );
              })}
            </div>
          ))}
      </div>
    </>
  );
};
