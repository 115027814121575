import { useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DBId } from '@common/types/DBId';
import { useCache } from '@features/app/cache';
import type { LanguageV2 } from '@features/content/languages';
import { SearchFeedback } from '@features/search';
import { Loader } from '@features/theme';
import { useAppSelector } from '@redux/store';
import { selectVocabularyReview } from '@selectors/VocabularyReviewSelectors';

import { DEFAULT_LEXICAL_ITEMS_LIST_OFFSET, DEFAULT_LEXICAL_ITEMS_LIST_PAGE } from '../constants';
import { generateCacheKey } from './util';
import { VocabularyReviewService } from '../VocabularyReviewService';
import { LexicalItemsTable } from './LexicalItemsTable';
import { LexicalItemsPagination } from './LexicalItemsPagination';
import { type FiltersState, areFiltersSelected, VocabularyReviewFilters } from './VocabularyReviewFilters';
import type { VocabularyReviewListItem, VocabularyReviewViewParams } from '../types';

const FILTERS_INITIAL_STATE: FiltersState = {
  query: '',
  wordsRange: null,
};

/**
 * Lexical Items cache
 * 1. in-memory cache fallbacks to
 * 2. session storage fallbacks to
 * 3. API request
 *
 * Cache TTL by default (1 hour);
 */
const LEXICAL_ITEMS_STORAGE_KEY = 'lexical-items';
const LEXICAL_ITEMS_LAST_UPDATED_STORAGE_KEY = `${LEXICAL_ITEMS_STORAGE_KEY}-last-updated`;

const lexicalItemsCache: Record<string, { lexicalItems: VocabularyReviewListItem[]; totalCount: number }> = {};
let lexicalItemsCacheLastUpdated = '';

export const VocabularyReviewData = () => {
  const [storedLexicalItemsCache, shouldRefreshLexicalItemsCache, updateLexicalItemsStorage] = useCache({
    lastUpdated: lexicalItemsCacheLastUpdated,
    lastUpdatedStorageKey: LEXICAL_ITEMS_LAST_UPDATED_STORAGE_KEY,
    storageKey: LEXICAL_ITEMS_STORAGE_KEY,
  });

  const vocabularyReview = useAppSelector(selectVocabularyReview);

  const [busy, setBusy] = useState(false);
  const [currentLexicalItems, setCurrentLexicalItems] = useState<VocabularyReviewListItem[]>([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_LEXICAL_ITEMS_LIST_PAGE);
  const [dirty, setDirty] = useState(false);
  const [filters, setFilters] = useReducer(
    (state: FiltersState, action: Partial<FiltersState & { reset: boolean }>) => {
      if (action.reset) return FILTERS_INITIAL_STATE;
      return { ...state, ...action };
    },
    FILTERS_INITIAL_STATE,
  );
  const [totalCount, setTotalCount] = useState(0);

  const history = useHistory();

  const { language } = vocabularyReview;
  const totalPages = totalCount ? Math.ceil(totalCount / DEFAULT_LEXICAL_ITEMS_LIST_OFFSET) : 0;

  const updateView = useCallback(
    async ({ language, filters, page }: VocabularyReviewViewParams) => {
      setCurrentLexicalItems([]);
      setTotalCount(0);
      setBusy(true);

      const cacheKey = generateCacheKey({ language, filters, page });
      let data = lexicalItemsCache[cacheKey] ?? storedLexicalItemsCache?.[cacheKey];

      if (shouldRefreshLexicalItemsCache() || !data?.lexicalItems?.length) {
        const { count, results } = await VocabularyReviewService.getLexicalItems({ filters, language, page });

        lexicalItemsCache[cacheKey] = data = {
          lexicalItems: results,
          totalCount: count,
        };
        lexicalItemsCacheLastUpdated = new Date().toISOString();
        updateLexicalItemsStorage(lexicalItemsCache, lexicalItemsCacheLastUpdated);
      }

      const { lexicalItems, totalCount } = data;

      setCurrentLexicalItems(lexicalItems);
      setTotalCount(totalCount);
      setBusy(false);
    },
    [shouldRefreshLexicalItemsCache, storedLexicalItemsCache, updateLexicalItemsStorage],
  );

  useEffect(() => {
    if (language) {
      updateView({
        filters: areFiltersSelected(filters) ? filters : FILTERS_INITIAL_STATE,
        language: language as LanguageV2,
        page: currentPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, language]);

  useEffect(() => {
    // reset to initial filters when clearing a filter and no other filters selected
    if (!areFiltersSelected(filters) && dirty) {
      updateView({
        filters: FILTERS_INITIAL_STATE,
        language: language as LanguageV2,
        page: currentPage,
      });

      setDirty(false);
    }

    if (areFiltersSelected(filters) && !dirty) {
      setDirty(true);
    }
  }, [currentPage, dirty, filters, language, updateView]);

  const onApplyFilters = () => {
    updateView({
      filters,
      language: language as LanguageV2,
      page: currentPage,
    });
  };

  const onClearFilters = () => {
    setFilters({ reset: true });

    updateView({
      filters: FILTERS_INITIAL_STATE,
      language: language as LanguageV2,
      page: currentPage,
    });
  };

  return (
    <>
      <VocabularyReviewFilters
        filters={filters}
        onApply={onApplyFilters}
        onClear={onClearFilters}
        onFilter={(filterType, value) => {
          setFilters({ [filterType]: value });
        }}
      />
      {busy ? (
        <Loader size="L" />
      ) : (
        <>
          {currentLexicalItems?.length ? (
            <>
              <LexicalItemsTable
                lexicalItems={currentLexicalItems}
                onLexicalItemClick={(lexicalItemId: DBId) => history.push(`${language}/lexical-item/${lexicalItemId}`)}
              />
              <LexicalItemsPagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
            </>
          ) : (
            <SearchFeedback notFound />
          )}
        </>
      )}
    </>
  );
};
