import styled from 'styled-components/macro';

const HEIGHT_OF_NON_SEARCH_RESULT_LIST_BLOCKS_IN_REMS = '12';

export const ResultsTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  padding: 0 1.6rem;
`;

export const ResultsTitle = styled.span`
  font-weight: 800;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.color.brandBlack};
`;

export const ResultsListWrapper = styled.div`
  height: calc(100% - ${HEIGHT_OF_NON_SEARCH_RESULT_LIST_BLOCKS_IN_REMS}rem);
  overflow: auto;
  padding: 0 1.6rem;
`;

export const BottomPaginationContainer = styled.div`
  margin-top: 1.6rem;
  padding: 0 1.6rem;
  display: flex;
  justify-content: end;
`;
