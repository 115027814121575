import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { constants as contentConstants, findLocalizationInSearchedLanguage } from '@features/content';
import { ContentTypes } from '@common/enums/ContentTypes';
import { getResources } from '@helpers/getResourcesHelper';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { useIsEditAvailable } from '@features/content/courses';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { useAppDispatch, useAppSelector } from '@redux/store';
import PhraseBuilderExerciseInterface from './interfaces/PhraseBuilderExerciseInterface';
import { PhraseBuilderExerciseActionsCreator } from '@actionCreators/PhraseBuilderExerciseActionsCreator';
import { PhraseBuilderExerciseView } from './PhraseBuilderExerciseView';
import { removeMediaProcessingValidationError } from '@features/content/exercises';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

export const PhraseBuilderExercise = () => {
  const dispatch = useAppDispatch();
  const { isEditorHidden } = useIsEditorHidden({ bundleName: 'mainBundle', visitedBranch: 'phrase' });
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as PhraseBuilderExerciseInterface;

  const audioData = ImageUploadUtils.getAudioForFileUpload(
    loadedExercise,
    'phrase',
    courseLearningLanguage,
    'mainBundle',
  );

  const audioLocalizations = loadedExercise?.content?.mainBundle?.phrase.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableAudioLocalizations = audioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  const { isEditAvailable } = useIsEditAvailable();

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const mainBundleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle');
  const phraseErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'mainBundle.phrase' || error.field === 'phrase',
  );
  const phraseAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      (error.field === 'mainBundle.phrase' || error.field === 'phrase') &&
      error.message.toLowerCase().includes('audio'),
  );

  if (phraseAudioErrors.length) {
    let updatedErrors = [...errors];

    availableAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const isPhraseChangeBlocked =
    (loadedExercise.content?.mainBundle?.phrase?.mappings?.count > 1 ||
      loadedExercise.content?.mainBundle?.phrase?.mappings?.length > 1) &&
    !loadedExercise.content?.mainBundle?.phrase?.isReusingConfirmed;

  const isMainBundleChangeBlocked =
    loadedExercise.content.mainBundle?.mappings?.count &&
    loadedExercise.content.mainBundle?.mappings?.count > 1 &&
    !loadedExercise.content.mainBundle?.isReusingConfirmed;

  const setReusedData = (id: string, field: string, isBundle: boolean) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.exercise,
        field,
        undefined,
        undefined,
        'mainBundle',
        isBundle,
      ),
    );
  };

  const onAudioRemove = () =>
    dispatch(
      PhraseBuilderExerciseActionsCreator.removeAudio({
        contentId: loadedExercise.content.mainBundle?.phrase?._id || loadedExercise.content.mainBundle?.phrase?.id,
        language: courseLearningLanguage,
      }),
    );

  const onInstructionLanguageChange = (displayContentToUserMode: DisplayContentToUserModeType) =>
    dispatch(PhraseBuilderExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));

  const onAudioTogglerRemove = () => {
    if (isMainBundleChangeBlocked) {
      setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
    } else if (isPhraseChangeBlocked) {
      setReusedData(
        loadedExercise.content.mainBundle?.phrase?._id || loadedExercise.content.mainBundle?.phrase?.id || '',
        'phrase',
        false,
      );
    } else {
      onAudioRemove();
    }
  };

  const onAudioProcessingFinish = (url: string) => {
    dispatch(
      CommonExerciseActionsCreator.setValueAfterProcessing({
        url,
        mediaType: 'audio',
        type: ContentTypes.exercise,
        field: 'phrase',
        language: courseLearningLanguage,
        bundleName: 'mainBundle',
      }),
    );
  };

  const onAudioChangeInstant = () => {
    if (isMainBundleChangeBlocked) {
      setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
    } else {
      setReusedData(
        loadedExercise.content.mainBundle?.phrase?._id || loadedExercise.content.mainBundle?.phrase?.id || '',
        'phrase',
        false,
      );
    }
  };

  const isAudioTogglerOpened = !!findLocalizationInSearchedLanguage(
    loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations,
    courseLearningLanguage,
  )?.value;

  const onMediaTypeChange = (newMediaType: boolean) => dispatch(ExerciseCommonActions.setAudioEnabled(newMediaType));

  return (
    <PhraseBuilderExerciseView
      audioData={audioData}
      bundleResources={getResources(loadedExercise, courseLearningLanguage)}
      courseLearningLanguage={courseLearningLanguage}
      exerciseContent={loadedExercise.content}
      isAudioTogglerOpened={isAudioTogglerOpened}
      isChangeBlocked={isMainBundleChangeBlocked || isPhraseChangeBlocked}
      isEditAvailable={isEditAvailable}
      isEditorHidden={isEditorHidden}
      instructionsErrors={instructionsErrors}
      mainBundleErrors={mainBundleErrors}
      phraseAudioErrors={phraseAudioErrors}
      phraseErrors={phraseErrors}
      onAudioChangeInstant={onAudioChangeInstant}
      onAudioProcessingFinish={onAudioProcessingFinish}
      onAudioRemove={onAudioRemove}
      onAudioTogglerRemove={onAudioTogglerRemove}
      onInstructionLanguageChange={onInstructionLanguageChange}
      onMediaTypeChange={onMediaTypeChange}
      uploadSound={(uploadedSound, progressHandler) => {
        dispatch(
          AudioUploadActionsCreator.uploadSound(
            loadedExercise.content.id,
            ExerciseTypes.phraseBuilder,
            courseLearningLanguage,
            loadedExercise.content?.mainBundle?.phrase?._id || loadedExercise.content?.mainBundle?.phrase?.id,
            findLocalizationInSearchedLanguage(
              loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations,
              courseLearningLanguage,
            ),
            uploadedSound,
            'phrase',
            undefined,
            undefined,
            true,
            'mainBundle',
            progressHandler,
          ),
        );
      }}
    />
  );
};
