import { FunctionComponent, SVGProps, useId, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';

import { DBId } from '@common/types/DBId';
import { addToast } from '@features/app/toast';

import { AvailableSearchType } from '../../../../types';

import { ReactComponent as ActivityImage } from '../../img/activity.svg';
import { ReactComponent as BundleImage } from '../../img/bundle.svg';
import { ReactComponent as ChapterImage } from '../../img/chapter.svg';
import { ReactComponent as ExerciseImage } from '../../img/exercise.svg';
import { ReactComponent as IdImage } from '../../img/id.svg';
import { ReactComponent as LessonImage } from '../../img/lesson.svg';
import { ReactComponent as StringImage } from '../../img/string.svg';
import type { FavouriteSearch } from '../../SearchContent/Common/favouriteSearches';
import { ReactComponent as CancelIcon } from '../../SearchContent/Common/SearchHeader/img/red-cross.svg';
import { ReactComponent as ConfirmIcon } from '../../SearchContent/Common/SearchHeader/img/green-check.svg';

import { ReactComponent as EditIcon } from './_img/edit.svg';
import { favouriteSearchItemIconStyles } from './styles';

import {
  EditButtonsContainer,
  FavouriteSearchItemContainer,
  FavouriteSearchItemInput,
  FavouriteSearchItemName,
  FavouriteSearchItemWrapper,
  StyledDeleteIcon,
} from './styles';

const showErrorFeedback = (title: string, error: any) => {
  addToast({
    type: 'error',
    title,
    description: `${error.response?.data?.detail ? error.response.data.detail : error.message}`,
  });
};

const showSuccessFeedback = (title: string) => {
  addToast({
    type: 'success',
    title,
  });
};

const favouriteSearchItemIconMap: Record<AvailableSearchType, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  activity: ActivityImage,
  bundle: BundleImage,
  chapter: ChapterImage,
  exercise: ExerciseImage,
  id: IdImage,
  lesson: LessonImage,
  string: StringImage,
  lexicalItem: StringImage, // Lexical Item doesn't have its own icon
};

const FavouriteSearchItemIcon = ({ type, onClick }: { type: AvailableSearchType; onClick: () => void }) => {
  const Icon = styled(favouriteSearchItemIconMap[type])`
    ${favouriteSearchItemIconStyles}
  `;

  return <Icon onClick={onClick} />;
};

type FavouriteSearchItemPropsType = {
  favouriteSearch: FavouriteSearch<any>;
  onRemove: (favouriteSearchId: DBId) => Promise<void>;
  onSearch: () => void;
  onUpdate: (name: string) => Promise<void>;
};

export const FavouriteSearchItem = ({
  favouriteSearch,
  onRemove,
  onSearch,
  onUpdate,
}: FavouriteSearchItemPropsType) => {
  const { id, name: initialSearchName, type } = favouriteSearch;

  const [busy, setBusy] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchName, setSearchName] = useState(initialSearchName);

  const tooltipId = useId();

  const onCancel = () => {
    setIsEditMode(false);
    setSearchName(initialSearchName);
  };

  const handleOnUpdate = (searchName: string) => {
    setIsEditMode(false);
    setBusy(true);

    try {
      onUpdate(searchName)
        .then(() => {
          showSuccessFeedback('Your favourite search was successfully updated');
        })
        .finally(() => {
          setBusy(false);
        });
    } catch (error) {
      showErrorFeedback('An error occurred when updating your favourite search', error);
    }
  };

  const handleOnRemove = (searchName: string) => {
    setBusy(true);

    try {
      onRemove(id)
        .then(() => {
          showSuccessFeedback(`"${searchName}" was removed from your favourite searches`);
        })
        .finally(() => {
          setBusy(false);
        });
    } catch (error: any) {
      showErrorFeedback('An error occurred when removing your favourite search', error);
    }
  };

  return (
    <FavouriteSearchItemWrapper
      $busy={busy}
      onBlur={() => {
        isEditMode && searchName === initialSearchName && onCancel();
      }}
    >
      <FavouriteSearchItemContainer>
        <FavouriteSearchItemIcon type={type} onClick={onSearch} />

        {isEditMode ? (
          <FavouriteSearchItemInput
            autoFocus
            size={searchName.length - 1}
            value={searchName}
            onChange={(evt) => setSearchName(evt.target.value)}
          />
        ) : (
          <FavouriteSearchItemName role="button" onClick={onSearch}>
            {searchName}
          </FavouriteSearchItemName>
        )}

        {!isEditMode && <EditIcon role="button" onClick={() => setIsEditMode(true)} />}

        <EditButtonsContainer $isEditMode={isEditMode}>
          <CancelIcon role="button" onClick={onCancel} />
          <ConfirmIcon role="button" onClick={() => handleOnUpdate(searchName)} />
        </EditButtonsContainer>
      </FavouriteSearchItemContainer>
      <StyledDeleteIcon
        data-for={tooltipId}
        data-tip={`Remove "${searchName}" from favourite searches`}
        $isEditMode={isEditMode}
        role="button"
        onClick={() => handleOnRemove(searchName)}
      />
      <ReactTooltip id={tooltipId} effect="solid" place="right" />
    </FavouriteSearchItemWrapper>
  );
};
