import { SectionActions } from '@actions/SectionActions';
import { EXERCISE_SPELLING } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { updateTextField } from '@helpers/updateTextField';
import { SpellingExerciseActions } from '@actions/SpellingExerciseActions';
import SpellingExerciseInterface from '@components/Exercises/Spelling/interfaces/SpellingExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import { removeAudioDataFromBundle } from './common/helpers';

const SpellingExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_SPELLING_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: SpellingExerciseInterface = payload;

    let exercise: SpellingExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: EXERCISE_SPELLING,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [SpellingExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckbox('instructionsLanguage', state, payload);
  },
  [SpellingExerciseActions.REMOVE_IMAGE]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'image',
      bundleName: 'mainBundle',
      state,
    });
  },
  [SpellingExerciseActions.REMOVE_AUDIO]: (state: CourseSliceInterface): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return removeAudioDataFromBundle(clonedCourseSlice, 'phrase', 'mainBundle', true);
  },
  [SpellingExerciseActions.SET_SPELLING_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'mainBundle');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'feedback');

    return clonedCourseSlice;
  },
};

export default SpellingExerciseCourseReducers;
