import styled, { css } from 'styled-components/macro';

import { CardWrapper, CardTranslation } from '../Common';
import { CardAudio } from './CardAudio';

const fontStyles = css`
  font-size: 1.4rem;
`;

export const LexicalItemCardWrapper = styled(CardWrapper)<{ forReusing: boolean }>`
  ${({ forReusing }) => css`
    ${fontStyles};
    grid-template-columns: 5.6rem 50% minmax(8.5rem, 1fr) minmax(8.5rem, 1fr) ${forReusing
        ? 'minmax(12rem, 1fr)'
        : '5%'};
  `}
`;

export const StyledCardAudio = styled(CardAudio)`
  ${fontStyles};
`;

export const StyledCardTranslation = styled(CardTranslation)`
  ${fontStyles};

  span {
    ${fontStyles};
    font-weight: 400;
  }

  svg {
    display: none;
  }
`;

export const StyledImage = styled.img`
  border-radius: 50%;
  height: 5.6rem;
  object-fit: none;
  object-position: center;
  width: 5.6rem;
`;

export const NavigateLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
