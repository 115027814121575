import lodash from 'lodash';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import { MultiValue } from 'react-select';
import Tooltip from 'react-tooltip';
import styled from 'styled-components/macro';

import type { LanguageV2 } from '@features/content/languages';
import { Button, FilterInput } from '@features/theme';

import { LanguageFilter } from '../../Common';
import { AudioRequestStatusType } from '../../types';
import { RequesterFilter, StatusFilter } from './Filters';
import { exportAudioRequests } from '../../_service';
import { VoiceArtistFilter } from '../../Common';
import { UploadAudios } from '../../UploadAudios';
import { ReactComponent as ClearIndicator } from './img/clearIndicator.svg';
import { CommonFilterOptionType } from '@components/SelectorCommonComponents';
import { useToast } from '@features/app/toast';

const Filters = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  gap: 1.5rem;
`;

const Filter = styled(FilterInput)`
  width: 25rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  top: -8.2rem;
  right: 0;
`;

const ExportButton = styled(Button)`
  margin-left: 1rem;
`;

type FiltersAreaProps = {
  selectedLanguage: { value: LanguageV2; label: string } | null;
  setSelectedLanguage: (language: { value: LanguageV2; label: string } | null) => void;
  selectedVoiceArtist: { value: string; label: string } | null;
  setSelectedVoiceArtist: (voiceArtist: { value: string; label: string } | null) => void;
  selectedRequesters: MultiValue<CommonFilterOptionType> | null;
  setSelectedRequesters: (requesters: MultiValue<CommonFilterOptionType> | null) => void;
  selectedStatus: { value: AudioRequestStatusType; label: string } | null;
  setSelectedStatus: (status: { value: AudioRequestStatusType; label: string } | null) => void;
  exportId: string;
  setExportId: (exportId: string) => void;
  onExportSuccess: (exportId: string) => void;
};

export const FiltersArea = ({
  selectedLanguage,
  selectedVoiceArtist,
  selectedRequesters,
  selectedStatus,
  exportId,
  setSelectedLanguage,
  setSelectedVoiceArtist,
  setSelectedRequesters,
  setSelectedStatus,
  setExportId,
  onExportSuccess,
}: FiltersAreaProps) => {
  const [showUploadAudiosModal, setShowUploadAudiosModal] = useState(false);

  const showToast = useToast();

  const exportButtonTooltipText =
    !selectedLanguage || !selectedVoiceArtist || selectedStatus?.value !== 'new'
      ? "You must select a Language, a Voice Artist and set Status to 'new' to export!"
      : '';

  return (
    <Filters>
      <LanguageFilter selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
      <VoiceArtistFilter
        selectedVoiceArtist={selectedVoiceArtist}
        selectedLanguage={selectedLanguage}
        onlyActive
        setSelectedVoiceArtist={setSelectedVoiceArtist}
      />
      <RequesterFilter selectedRequesters={selectedRequesters} setSelectedRequesters={setSelectedRequesters} />
      <StatusFilter selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
      <Filter
        placeholder="Filter by Export ID"
        value={exportId}
        onChange={lodash.debounce((exportId) => setExportId(exportId), 300)}
      />
      {selectedLanguage || selectedVoiceArtist || selectedRequesters || selectedStatus || exportId ? (
        <Button
          icon={<ClearIndicator />}
          size="S"
          variant="text"
          onClick={() => {
            setSelectedLanguage(null);
            setSelectedVoiceArtist(null);
            setSelectedRequesters(null);
            setSelectedStatus(null);
            setExportId('');
          }}
        >
          Clear all filters
        </Button>
      ) : null}
      <ButtonsContainer>
        <Button variant="secondary" onClick={() => setShowUploadAudiosModal(true)}>
          Upload Audios
        </Button>
        <UploadAudios show={showUploadAudiosModal} onHide={() => setShowUploadAudiosModal(false)} />
        <div data-tip={exportButtonTooltipText} data-for="export-button-tooltip">
          <ExportButton
            disabled={!selectedLanguage || !selectedVoiceArtist || selectedStatus?.value !== 'new'}
            variant="primary"
            onClick={() => {
              exportAudioRequests({
                selectedLanguage: selectedLanguage?.value,
                selectedVoiceArtist: selectedVoiceArtist?.value,
                selectedRequesters: selectedRequesters?.map((requester) => requester.value),
                selectedStatus: selectedStatus?.value,
                exportId,
              }).then((result) => {
                const fileName = result.headers['x-export-id'];

                showToast({
                  type: 'info',
                  title: 'Download should have started automatically',
                });

                fileDownload(result.data, `${fileName}.xlsx`);
                onExportSuccess(fileName);
              });
            }}
          >
            Export Audio Requests
            <Tooltip id="export-button-tooltip" effect="solid" />
          </ExportButton>
        </div>
      </ButtonsContainer>
    </Filters>
  );
};
