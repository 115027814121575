import { call, put, select } from 'redux-saga/effects';
import DialogueExerciseInterface from '@components/Exercises/Dialogue/interfaces/DialogueExerciseInterface';
import DialogueExerciseService from '@services/ExerciseRelatedServices/DialogueExerciseService';
import { DialogueExerciseActions } from '@actions/DialogueExerciseActions';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import type { LanguageV2 } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const DialogueExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: DialogueExerciseInterface;
        learningLanguage: LanguageV2;
        values: FormikValuesInterface;
      };
    },
  ) {
    let { values } = payload;

    yield put({
      type: DialogueExerciseActions.SET_DIALOGUE_ALL_FIELDS,
      payload: {
        values,
      },
    });
    let exercise: DialogueExerciseInterface = yield select(selectLoadedExerciseData);
    yield call(DialogueExerciseService.save, dispatch, exercise, values);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default DialogueExerciseSagas;
