import { call, put, select } from 'redux-saga/effects';
import SpellingExerciseService from '@services/ExerciseRelatedServices/SpellingExerciseService';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import SpellingExerciseInterface from '@components/Exercises/Spelling/interfaces/SpellingExerciseInterface';
import { SpellingExerciseActions } from '@actions/SpellingExerciseActions';
import type { LanguageV2 } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const SpellingExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: SpellingExerciseInterface;
        learningLanguage: LanguageV2;
        values: FormikValuesInterface;
      };
    },
  ) {
    let { values } = payload;
    yield put({
      type: SpellingExerciseActions.SET_SPELLING_ALL_FIELDS,
      payload: {
        values,
      },
    });
    let exercise: SpellingExerciseInterface = yield select(selectLoadedExerciseData);

    yield call(SpellingExerciseService.save, dispatch, exercise, values);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default SpellingExerciseSagas;
