import { Link } from 'react-router-dom';

import { ReactComponent as NavigateIcon } from '../../SearchResultItemDetails/Common/LocationsContent/img/navigate.svg';
import { NavigateLinkWrapper } from './styles';

export const NavigateLink = ({ path }: { path: string }) => (
  <NavigateLinkWrapper>
    <Link to={path} target="_blank">
      <NavigateIcon />
    </Link>
  </NavigateLinkWrapper>
);
