import { Formik } from 'formik';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { coursesInfoRequest } from '@actionCreators/CourseActionCreator';
import { VocabularyReviewActionsCreator } from '@actionCreators/VocabularyReviewActionsCreator';
import { LoadingStage } from '@common/enums/LoadingStage';
import { layoutStyles } from '@features/app';
import { SearchModal, useOpenSearchShortcut } from '@features/search';
import { Loader } from '@features/theme';
import { processLocalizationsForFormikValues } from '@helpers/formikInitialValuesHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectLexicalItemContent } from '@selectors/VocabularyReviewSelectors';
import { selectCoursesInfo } from '@selectors/CoursesSelectors';
import { selectLexicalItemTranslationsPanel } from '@selectors/UiSelectors';

import { Header } from '../Header';
import { TranslationsPanel } from '../TranslationsPanel';
import { useVocabularyReview } from '../useVocabularyReview';
import { Breadcrumbs } from './Breadcrums';
import { LexicalItemData } from './LexicalItemData';

const LexicalItem = () => {
  const { languageId, isNewLexicalItem, lexicalItem, lexicalItemId } = useVocabularyReview();

  const dispatch = useAppDispatch();

  const coursesInfo = useAppSelector(selectCoursesInfo);
  const lexicalItemContent = useAppSelector(selectLexicalItemContent);
  const lexicalItemTranslationsPanel = useAppSelector(selectLexicalItemTranslationsPanel);
  const { EditionComposerWrapper } = layoutStyles;

  useOpenSearchShortcut();

  /** Courses info is needed for Search */
  useEffect(() => {
    if (coursesInfo.loaded === LoadingStage.notLoaded) {
      dispatch(coursesInfoRequest());
    }
  }, [dispatch, coursesInfo]);

  useEffect(() => {
    dispatch(VocabularyReviewActionsCreator.setInitialVocabularyReview(languageId));
    dispatch(VocabularyReviewActionsCreator.setInitialLexicalItem(languageId));
  }, [dispatch, languageId]);

  useEffect(() => {
    if (lexicalItem.loaded === LoadingStage.notLoaded && !isNewLexicalItem) {
      dispatch(VocabularyReviewActionsCreator.getLexicalItem(lexicalItemId));
    }
  }, [dispatch, isNewLexicalItem, lexicalItem.loaded, lexicalItemId]);

  const getInitialValuesForLexicalItemContent = () => {
    return {
      phrase: processLocalizationsForFormikValues(lexicalItemContent?.phrase?.textLocalizations),
      phraseContext: lexicalItemContent?.phrase?.description || '',
      phraseChanged: false,
      example: processLocalizationsForFormikValues(lexicalItemContent?.example?.textLocalizations),
      exampleContext: lexicalItemContent?.example?.description || '',
      exampleChanged: false,
    };
  };

  if (lexicalItem.loaded === LoadingStage.loading) {
    return (
      <>
        <Header />
        <EditionComposerWrapper fullWidth>
          <Loader size="L" />
        </EditionComposerWrapper>
      </>
    );
  }

  if (lexicalItem.loaded === LoadingStage.loaded || isNewLexicalItem) {
    return (
      <Formik initialValues={getInitialValuesForLexicalItemContent()} onSubmit={() => {}}>
        <>
          {lexicalItemTranslationsPanel.visible && <TranslationsPanel content={lexicalItemContent} />}
          <Header />
          <EditionComposerWrapper fullWidth>
            <Breadcrumbs />
            <LexicalItemData isNewLexicalItem={isNewLexicalItem} />
          </EditionComposerWrapper>
          <SearchModal />
        </>
      </Formik>
    );
  }

  return null;
};

export const LexicalItemContent = withRouter(LexicalItem);
