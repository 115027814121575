import type { LanguageV2 } from '@features/content/languages';
import { NewCoursePanelActions } from '@actions/NewCoursePanelActions';

export const CourseLanguagesPanelActionsCreator = {
  courseCreatedSuccessfully: (courseData: any) => ({
    type: NewCoursePanelActions.COURSE_CREATE_SUCCEEDED,
    payload: {
      course: courseData,
    },
  }),
  setCourseSetUILanguages: (toggledInterfaceLanguage: LanguageV2) => ({
    type: NewCoursePanelActions.COURSE_TOGGLE_UI_LANGUAGE,
    payload: { toggledInterfaceLanguage },
  }),
  setCourseLearningLanguage: (courseLanguage: LanguageV2) => ({
    type: NewCoursePanelActions.COURSE_SET_MAIN_LANGUAGE,
    payload: { courseLanguage },
  }),
  setAllCourseUILanguagesAsToggled: (toggled: boolean) => ({
    type: NewCoursePanelActions.SET_ALL_COURSE_UI_LANGUAGES_AS_TOGGLED,
    payload: { toggled },
  }),
};
