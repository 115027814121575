import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { CommonActions } from '@actions/CommonActions';
import { VocabularyReviewActions } from '@actions/VocabularyReviewActions';
import { VocabularyReviewActionsCreator } from '@actionCreators/VocabularyReviewActionsCreator';
import { DBId } from '@common/types/DBId';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { apiClient } from '@features/api';
import { LanguageV2 } from '@features/content/languages';
import { addToast } from '@features/app/toast';
import {
  type LexicalItemContentType,
  type LexicalItemFieldWithLocalizationNames,
  type LexicalItemFormikValues,
  VocabularyReviewService,
} from '@features/content/vocabularyReview';
import { AppDispatch } from '@redux/store';
import { selectLexicalItemContent } from '@selectors/VocabularyReviewSelectors';

export const VocabularyReviewSagas = {
  *getLexicalItem({ payload }: PayloadAction<{ lexicalItemId: DBId }>) {
    try {
      yield put({ type: VocabularyReviewActions.LEXICAL_ITEM_LOADING });

      const { lexicalItemId } = payload;

      const result: Awaited<ReturnType<typeof VocabularyReviewService.getLexicalItem>> = yield call(
        VocabularyReviewService.getLexicalItem,
        lexicalItemId,
      );

      if (result.status === 200) {
        yield put({ type: VocabularyReviewActions.LEXICAL_ITEM_LOADED, payload: result.data.lexicalItem });
      }
    } catch (error) {
      console.error(error);
    }
  },
  *removeAudioFromLexicalItem({ payload }: PayloadAction<{ contentId: DBId; language: LanguageV2 }>) {
    try {
      const { contentId, language } = payload;
      const audioLocalizations = [
        {
          fileId: null,
          language,
          value: '',
        },
      ];

      yield call(() => apiClient.noErrorsV2.put(`content/resources/${contentId}`, { audioLocalizations }));
    } catch (e) {
      console.error(e);
    }
  },
  *saveLexicalItem(
    dispatch: AppDispatch,
    { payload }: PayloadAction<{ language: LanguageV2; lexicalItemId: DBId; values: LexicalItemFormikValues }>,
  ) {
    let payloadForUpdate: Awaited<ReturnType<typeof VocabularyReviewService.getPayloadForLexicalItemUpdate>>;

    try {
      const { lexicalItemId, values } = payload;

      const lexicalItemContent: LexicalItemContentType = yield select(selectLexicalItemContent);

      payloadForUpdate = yield call(
        VocabularyReviewService.getPayloadForLexicalItemUpdate,
        lexicalItemContent,
        values,
        (contentType, fieldName, contentId) => {
          dispatch(
            VocabularyReviewActionsCreator.setContentId({
              contentType,
              fieldName,
              contentId,
            }),
          );
        },
      );

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: true },
      });

      const saveResult: Awaited<ReturnType<typeof VocabularyReviewService.saveLexicalItem>> = yield call(
        VocabularyReviewService.saveLexicalItem,
        lexicalItemId,
        payloadForUpdate,
      );

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: false },
      });

      if (saveResult?.status === 200) {
        addToast({
          type: 'success',
          title: 'Content changes have been saved',
        });
      }

      yield put({
        type: VocabularyReviewActions.GET_LEXICAL_ITEM,
        payload: {
          lexicalItemId,
        },
      });
    } catch (error) {
      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: false,
          updateData: false,
        },
      });

      Sentry.captureException(error, (scope) => {
        scope.setTag('logosSection', 'Lexical Item');
        scope.setExtras({
          payloadForUpdate,
        });

        return scope;
      });
    }
  },
  *removeStringFromLexicalItem({
    payload: { fieldName },
  }: PayloadAction<{ fieldName: LexicalItemFieldWithLocalizationNames }>) {
    let stringToRemove: TranslationsPanelContentInterface | null;

    try {
      const lexicalItemContent: LexicalItemContentType = yield select(selectLexicalItemContent);
      const stringToRemove = lexicalItemContent[fieldName];

      if (stringToRemove) {
        const { audioLocalizations, textLocalizations } = stringToRemove;
        const newTextLocaliz =
          textLocalizations?.map((loc) => ({ ...loc, value: '', phoneticValue: '', _id: '' })) || [];
        const newAudioLocaliz = audioLocalizations?.map((loc) => ({ ...loc, value: '', _id: '' })) || [];

        const newString = {
          _id: '',
          id: '',
          description: '',
          textLocalizations: newTextLocaliz,
          audioLocalizations: newAudioLocaliz,
        };

        yield put({
          type: VocabularyReviewActions.SET_STRING,
          payload: { newString, fieldName },
        });
      }
    } catch (error) {
      Sentry.captureException(error, (scope) => {
        scope.setTag('logosSection', 'Lexical Item');
        scope.setExtras({
          fieldName,
          stringToRemove,
        });

        return scope;
      });
    }
  },
};
