import styled from 'styled-components';
import ReactPlayer from 'react-player';

import { getValueByLanguage } from '@features/content';
import { WritableInputText } from '@components/WritableInputText';
import { BundleSearchResultDetailsType } from '../../types';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';

const GeneralInfoContainer = styled.div`
  max-width: 86.8rem;
  padding: 2rem 0;
`;

const Field = styled.div`
  margin-bottom: 1.6rem;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
  color: ${({ theme }) => theme.color.brandGreyscale800};
`;

const MediaContainer = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 0.8rem) calc(50% - 0.8rem);
  grid-gap: 1.6rem;
`;

const Media = styled.img`
  border: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
  border-radius: 1.6rem;
  height: 24rem;
  width: 100%;
`;

const VideoMedia = styled(ReactPlayer)`
  height: 24rem !important;
  width: 100%;

  video {
    width: 100%;
    border: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
    border-radius: 1.6rem;
    height: 24rem !important;
  }
`;

export const GeneralInfo = ({ details }: { details: BundleSearchResultDetailsType | null }) => (
  <GeneralInfoContainer>
    <Field>
      <Label>Phrase</Label>
      <WritableInputText disabled value={getValueByLanguage(details?.phrase?.textLocalizations)} />
    </Field>
    <Field>
      <Label>Key Phrase</Label>
      <WritableInputText disabled value={getValueByLanguage(details?.keyPhrase?.textLocalizations)} />
    </Field>
    <MediaContainer>
      {details?.image && (
        <div>
          <Label>Image</Label>
          <Media
            src={details.image.imageLocalizations.find((image) => image.language === DEFAULT_LANGUAGE_V2)?.value}
          />
        </div>
      )}
      {details?.video && (
        <div>
          <Label>Video</Label>

          <VideoMedia
            url={details.video.videoLocalizations.find((video) => video.language === DEFAULT_LANGUAGE_V2)?.value}
            controls={false}
            width={'initial'}
          />
        </div>
      )}
    </MediaContainer>
  </GeneralInfoContainer>
);
