import React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import {
  ALL_LANGUAGES_V2,
  LanguageV2,
  LANGUAGE_FLAGS,
  LANGUAGE_NAMES_V2,
  LANGUAGE_SHORT_NAMES,
} from '@features/content/languages';
import { FlagTextPositionMode, type FlagTextPositionModeType } from './enums/FlagTextPositionMode';
import { random } from 'lodash';
import { isDeprecatedLanguageFormat, mapLanguageToLanguageV2 } from '@features/content/languages';

type FlagCountryMode = 'multi' | 'one';

type FlagProps = {
  countries: LanguageV2 | LanguageV2[];
  mode?: FlagTextPositionModeType;
  onClick?: Function;
  className?: string;
  showCountryName?: boolean;
  tooltipEnabled?: boolean;
  forCoursesList?: boolean;
};

/** used for languages stored with old language format */
const normalizeCountriesFormat = (countries: any) => {
  if (Array.isArray(countries)) {
    return countries.map((country) =>
      isDeprecatedLanguageFormat(country) ? mapLanguageToLanguageV2(country) : country,
    );
  }

  return isDeprecatedLanguageFormat(countries) ? mapLanguageToLanguageV2(countries) : countries;
};

export const Flag = ({
  countries,
  mode = FlagTextPositionMode.before,
  onClick,
  className,
  showCountryName = true,
  tooltipEnabled = true,
  forCoursesList = false,
}: FlagProps) => {
  const tooltipId = `${random(0, 10000)}`;
  let img;
  let altText: string;
  let displayText: string;
  let imgClassName = 'flag__country-flag';
  let languages = normalizeCountriesFormat(countries) as LanguageV2[];

  if (Array.isArray(languages)) {
    languages = languages.sort((countryCode1: LanguageV2, countryCode2: LanguageV2) => {
      return countryCode1.localeCompare(countryCode2);
    });
  } else {
    languages = [countries] as LanguageV2[];
  }

  let maxItems: number = 4;

  let flagType: FlagCountryMode;

  if (Array.isArray(languages) && languages.length > 1) {
    flagType = 'multi';
  } else {
    flagType = 'one';
  }

  const isCountriesNumberLessThanMaximalNumber = languages.length < ALL_LANGUAGES_V2.length - 1;

  const languagesForDisplayText = languages.map((lang) => LANGUAGE_SHORT_NAMES[lang]);
  if (flagType === 'multi') {
    const countriesCloneFullName = languages.map((language: LanguageV2) => LANGUAGE_NAMES_V2[language]);

    imgClassName += '--multi';

    if (languagesForDisplayText.length > maxItems) {
      if (languagesForDisplayText.length === ALL_LANGUAGES_V2.length - 1) {
        displayText = 'All languages';
      } else {
        displayText = `${[...languagesForDisplayText].splice(0, maxItems).join(', ')} ...`;
      }
    } else {
      displayText = `${languagesForDisplayText.join(', ')}`;
    }

    altText = `${countriesCloneFullName.map((countryName: string) => countryName).join(', ')} flags`;

    if (languages.length > maxItems) {
      img = (
        <div data-tip={tooltipEnabled ? altText : ''} data-for={tooltipId}>
          <ul className={imgClassName}>
            {languages.map((language: LanguageV2, idx: number) => {
              if (idx < maxItems) {
                return (
                  <li key={language}>
                    <img src={LANGUAGE_FLAGS[language]} className={imgClassName} alt={`${language} ${idx} flag`} />
                  </li>
                );
              } else {
                return <React.Fragment key={idx}></React.Fragment>;
              }
            })}
            <li key="countries-circle" className="flag__more-countries-circle">
              +{languages.length - maxItems}
            </li>
          </ul>
        </div>
      );
    } else {
      img = (
        <div data-tip={tooltipEnabled ? altText : ''} data-for={tooltipId}>
          <ul className={imgClassName}>
            {languages.map((language: LanguageV2) => {
              return (
                <li key={language}>
                  <img src={LANGUAGE_FLAGS[language]} className={imgClassName} alt={`${language} flag`} />
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  } else {
    altText = `${LANGUAGE_NAMES_V2[languages[0] as LanguageV2]} flag`;
    displayText = `${languagesForDisplayText}`;

    img = (
      <div data-tip={tooltipEnabled ? altText : ''} data-for={tooltipId}>
        <img src={LANGUAGE_FLAGS[languages[0]]} className={imgClassName} alt={altText} />
      </div>
    );
  }

  return (
    <div
      className={classnames('flag', { [`${className}`]: className }, { 'flag--no-language-name': showCountryName })}
      onClick={() => onClick !== undefined && onClick()}
    >
      {mode === FlagTextPositionMode.before && (
        <>
          {isCountriesNumberLessThanMaximalNumber && img}

          <span className={`flag__text ${flagType === 'multi' ? 'flag__text--before-multi' : 'flag__text--before'}`}>
            {showCountryName && displayText}
          </span>
        </>
      )}
      {mode === FlagTextPositionMode.after && (
        <>
          <span className={`flag__text ${flagType === 'multi' ? 'flag__text--after-multi' : 'flag__text--after'}`}>
            {showCountryName && displayText}
          </span>

          {isCountriesNumberLessThanMaximalNumber && img}
        </>
      )}
      {mode === FlagTextPositionMode.withoutText && <>{isCountriesNumberLessThanMaximalNumber && img}</>}
      {mode === FlagTextPositionMode.withoutText && isCountriesNumberLessThanMaximalNumber && (
        <>
          <ReactTooltip className="tooltip" effect="solid" id={tooltipId} scrollHide={false} />
        </>
      )}
    </div>
  );
};

export default Flag;
