import { FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { Flag, FlagTextPositionMode } from '@components/Flag';
import { DBId } from '@common/types/DBId';
import { useAppDispatch } from '@redux/store';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { AnyExerciseContentBranch, CourseContentBranches } from '@common/types/AnyExerciseContentBranch';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { AudioUpload } from '@components/MediaUpload';
import { type ExerciseTypesType } from '@common/enums/ExerciseTypes';

interface AudioBlockProps {
  content: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {};
  exerciseId: DBId | undefined;
  exerciseType: ExerciseTypesType;
  courseLearningLanguage: string;
  contentId: DBId | undefined;
  disabled: boolean;
  visitedBranch: AnyExerciseContentBranch | CourseContentBranches;
  row?: number | undefined;
  column?: number | undefined;
  validationErrors?: ValidationErrorInterface[];
  translationsBlockValuesArray?: LocalizationInterface[];
  show?: boolean;
  bundleName?: string;
  isBundleChangeBlocked?: boolean;
  type?: string;
}

interface AudiosBlockInstanceProps extends AudioBlockProps {
  localization: LocalizationInterface;
  idx: number;
}

export const AudiosBlockInstance: FunctionComponent<AudiosBlockInstanceProps> = ({
  content,
  exerciseId,
  exerciseType,
  courseLearningLanguage,
  contentId,
  disabled,
  visitedBranch,
  row,
  column,
  validationErrors,
  localization,
  idx,
  show,
  bundleName,
  isBundleChangeBlocked,
  type,
}: AudiosBlockInstanceProps) => {
  const dispatch = useAppDispatch();

  let isChangeBlocked = bundleName
    ? ((content as any)[bundleName][visitedBranch]?.mappings?.count > 1 ||
        (content as any)[bundleName][visitedBranch]?.mappings?.length > 1) &&
      !(content as any)[bundleName][visitedBranch]?.isReusingConfirmed
    : ((content as any)[visitedBranch]?.mappings?.count > 1 || (content as any)[visitedBranch]?.mappings?.length > 1) &&
      !(content as any)[visitedBranch]?.isReusingConfirmed;

  if (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)) {
    isChangeBlocked =
      ((content as any).phrases[row || 0].mappings?.count > 1 ||
        (content as any).phrases[row || 0].mappings?.length > 1) &&
      !(content as any).phrases[row || 0].isReusingConfirmed;
  } else if (visitedBranch === 'script') {
    isChangeBlocked =
      ((content as any)[visitedBranch][row || 0].line.mappings?.count > 1 ||
        (content as any)[visitedBranch][row || 0].line.mappings?.length > 1) &&
      !(content as any)[visitedBranch][row || 0].line.isReusingConfirmed;
  }

  const validationError = validationErrors
    ?.filter((error) => error.message.toLowerCase().includes('audio'))
    .find((error) => error.message.includes(localization.language));

  const requestOrCancelAudioPayload = {
    bundleName,
    column,
    field: visitedBranch,
    language: localization.language,
    type: type || '',
    row,
  };

  const onProcessingFinished = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: type || '',
          field: visitedBranch,
          language: localization.language,
          bundleName,
          row,
          column,
        }),
      );
    },
    [bundleName, column, dispatch, localization.language, row, type, visitedBranch],
  );

  const getPhraseText = () => {
    let textLocalizations = bundleName
      ? (content as any)[bundleName][visitedBranch]?.textLocalizations
      : (content as any)[visitedBranch]?.textLocalizations;

    if (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)) {
      textLocalizations = (content as any).phrases[row || 0].textLocalizations;
    } else if (visitedBranch === 'examples') {
      textLocalizations = (content as any).examples[row || 0][column || 0].textLocalizations;
    } else if (visitedBranch === 'script') {
      textLocalizations = (content as any)[visitedBranch][row || 0].line.textLocalizations;
    }

    return textLocalizations.find((loc: LocalizationInterface) => loc.language === localization.language).value;
  };

  return (
    <li key={idx} className="translations-panel-modal__audio" style={{ height: show ? 'unset' : '1px' }}>
      {show ? (
        <div
          className={classnames(
            'translations-panel-modal__translation-data',
            'translations-panel-modal__translation-data--for-audio',
            {
              'translations-panel-modal__translation-data--with-error': validationError,
            },
          )}
        >
          <Flag mode={FlagTextPositionMode.before} countries={localization.language} />

          <AudioUpload
            fullScreen
            audioData={{
              mediaValue: localization?.value,
              mediaId: localization?._id || '',
              contentId: contentId || '',
              processed: localization?.processed || false,
              phraseText: getPhraseText(),
              audioRequest: localization.audioRequest,
            }}
            onProcessingFinished={onProcessingFinished}
            disabled={disabled}
            onAudioRequestSuccess={(audioRequestId: string) =>
              dispatch(
                CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                  ...requestOrCancelAudioPayload,
                  audioRequestData: {
                    id: audioRequestId,
                    status: 'new',
                  },
                }),
              )
            }
            onCancelAudioRequestSuccess={() => {
              dispatch(
                CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
              );
            }}
            onChange={(uploadedMedia, progressHandler) => {
              dispatch(
                AudioUploadActionsCreator.uploadSoundForContentInTranslatorPanel(
                  content,
                  exerciseId,
                  exerciseType,
                  courseLearningLanguage,
                  contentId,
                  localization,
                  uploadedMedia,
                  visitedBranch,
                  row,
                  column,
                  bundleName,
                  progressHandler,
                ),
              );
            }}
            onRemove={() => {
              dispatch(
                AudioUploadActionsCreator.uploadSoundForContentInTranslatorPanel(
                  content,
                  exerciseId,
                  exerciseType,
                  courseLearningLanguage,
                  contentId,
                  localization,
                  null,
                  visitedBranch,
                  row,
                  column,
                  bundleName,
                ),
              );
            }}
            onChangeInstant={() => {
              const contentId = isBundleChangeBlocked
                ? bundleName
                  ? (content as any)[bundleName]?._id
                  : ''
                : visitedBranch === 'script'
                  ? (content as any)[visitedBranch][row || 0].line?._id ||
                    (content as any)[visitedBranch][row || 0].line?.id ||
                    ''
                  : bundleName
                    ? (content as any)[bundleName][visitedBranch]._id ||
                      (content as any)[bundleName][visitedBranch]?.id ||
                      ''
                    : (content as any)[visitedBranch]._id || (content as any)[visitedBranch]?.id || '';
              dispatch(
                TranslationTipActionsCreator.setCurrentContentId(
                  contentId,
                  type || '',
                  visitedBranch,
                  row,
                  column,
                  bundleName,
                  isBundleChangeBlocked,
                ),
              );
            }}
            isChangeBlocked={isChangeBlocked || isBundleChangeBlocked}
            errors={validationError ? [validationError] : undefined}
            fieldName={visitedBranch}
            currentLanguage={localization.language}
            row={row}
          />
        </div>
      ) : null}
      <ValidationErrorDisplayer text={validationError?.message} />
    </li>
  );
};

export const AudiosBlock: FunctionComponent<AudioBlockProps> = (props: AudioBlockProps) => {
  const { translationsBlockValuesArray, show } = props;

  return (
    <ul
      className={classnames('translations-panel-modal__audios-block', {
        'translations-panel-modal__audios-block--hidden': !show,
      })}
    >
      {translationsBlockValuesArray?.length &&
        [...translationsBlockValuesArray]
          .sort((localization: LocalizationInterface, localization2: LocalizationInterface) => {
            return localization.language.localeCompare(localization2.language);
          })
          .map((localization: LocalizationInterface, idx: number) => {
            return <AudiosBlockInstance {...props} localization={localization} idx={idx} key={idx} />;
          })}
    </ul>
  );
};
