import { FormikContextType, useFormikContext } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { DBId } from '@common/types/DBId';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { LANGUAGE_NAMES_V2, DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';
import { useAppSelector } from '@redux/store';

import { RoleplayCategoryFormikValues } from '../../types';
import { selectRoleplayNavigationStructure } from '@selectors/roleplaySelectors';

export const Breadcrumbs = () => {
  const history = useHistory();
  const { languageId, categoryId } = useParams<{ languageId: LanguageV2; categoryId: DBId }>();
  const formikContext: FormikContextType<RoleplayCategoryFormikValues> = useFormikContext();

  const navigation = useAppSelector(selectRoleplayNavigationStructure);

  const scenarioTitle =
    formikContext?.values.title?.find((loc) => loc.language === DEFAULT_LANGUAGE_V2)?.value || 'Roleplay Scenario';

  return (
    <BreadcrumbNavigation
      breadcrumbItems={[
        <span onClick={() => history.push(`/roleplay/${languageId}`)}>
          {`${LANGUAGE_NAMES_V2[languageId]} Roleplay`}
        </span>,
        <span onClick={() => history.push(`/roleplay/${languageId}/category/${categoryId}`)}>
          {navigation.find((element) => element.id === categoryId)?.title || 'Roleplay Category'}
        </span>,
        <span>{scenarioTitle}</span>,
      ]}
    />
  );
};
