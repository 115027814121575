import { DBId } from '@common/types/DBId';
import { TranslationsTipWidget as StyledTranslationsTipWidget } from '@components/TranslationTip/styles';
import { LanguageV2 } from '@features/content/languages';

import { LanguageSwitcher } from './LanguageSwitcher';
import { WidgetOptions } from './WidgetOptions';
import type { TranslationsTipCommonProps } from '../types';

type TranslationsTipWidgetProps<T> = TranslationsTipCommonProps<T> & {
  audioElements: string;
  compact: boolean;
  currentLanguage: LanguageV2;
  isChangeBlocked: boolean;
  isEditDisabled: boolean;
  isWidgetActive: boolean;
  isWidgetVisible: boolean;
  suggestionsQuery: string;
  translationElements: string;
  visitedContentId: DBId | undefined;
  onLanguageSwitchToggled: (switchedToEnglishLanguage: boolean, isPhoneticSelected: boolean) => void;
};

export const TranslationsTipWidget = <T extends string>({
  audioElements,
  compact,
  currentLanguage,
  defaultContextForTranslators,
  fieldName,
  isEditDisabled,
  isChangeBlocked,
  isWidgetActive,
  isWidgetVisible,
  showSearch,
  suggestionsQuery,
  translationElements,
  type,
  visitedContentId,
  onContentSuggestionsClick,
  onLanguageSwitchToggled,
  onOpenTranslationsPanel,
  onRemoveString,
}: TranslationsTipWidgetProps<T>) => (
  <StyledTranslationsTipWidget isWidgetActive={isWidgetActive} isWidgetVisible={isWidgetVisible}>
    <LanguageSwitcher onLanguageSwitchToggled={onLanguageSwitchToggled} />
    <WidgetOptions<T>
      audioElements={audioElements}
      currentLanguage={currentLanguage}
      compact={compact}
      defaultContextForTranslators={defaultContextForTranslators}
      fieldName={fieldName}
      isChangeBlocked={isChangeBlocked}
      isEditDisabled={isEditDisabled}
      isWidgetActive={isWidgetActive}
      type={type}
      showSearch={showSearch}
      suggestionsQuery={suggestionsQuery}
      translationElements={translationElements}
      visitedContentId={visitedContentId}
      onContentSuggestionsClick={onContentSuggestionsClick}
      onOpenTranslationsPanel={onOpenTranslationsPanel}
      onRemoveString={onRemoveString}
    />
  </StyledTranslationsTipWidget>
);
