import { type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { TranslationsTipWidget as StyledTranslationsTipWidget } from '@components/TranslationTip/styles';
import type { LanguageV2 } from '@features/content/languages';

import { LanguageSwitcher } from './LanguageSwitcher';
import { WidgetOptions } from './WidgetOptions';
import { GrammarCategoryEditableFieldNames } from '../../GrammarCategory';

type TranslationsTipWidgetProps = {
  isWidgetActive: boolean;
  isWidgetVisible: boolean;
  translationElements: string;
  audioElements: string;
  visitedContentId: DBId | undefined;
  fieldName: GrammarCategoryEditableFieldNames;
  isEditDisabled: boolean;
  isChangeBlocked: boolean;
  onLanguageSwitchToggled: (switchedToEnglishLanguage: boolean, isPhoneticSelected: boolean) => void;
  type: ContentTypesType;
  currentLanguage: LanguageV2;
  suggestionsQuery: string;
  compact: boolean;
  defaultContextForTranslators?: string;
};

export const TranslationsTipWidget = ({
  translationElements,
  audioElements,
  visitedContentId,
  fieldName,
  isEditDisabled,
  isChangeBlocked,
  onLanguageSwitchToggled,
  type,
  currentLanguage,
  isWidgetActive,
  isWidgetVisible,
  suggestionsQuery,
  compact,
  defaultContextForTranslators,
}: TranslationsTipWidgetProps) => (
  <StyledTranslationsTipWidget isWidgetActive={isWidgetActive} isWidgetVisible={isWidgetVisible}>
    <LanguageSwitcher onLanguageSwitchToggled={onLanguageSwitchToggled} />
    <WidgetOptions
      translationElements={translationElements}
      audioElements={audioElements}
      visitedContentId={visitedContentId}
      fieldName={fieldName}
      isEditDisabled={isEditDisabled}
      isChangeBlocked={isChangeBlocked}
      isWidgetActive={isWidgetActive}
      type={type}
      currentLanguage={currentLanguage}
      suggestionsQuery={suggestionsQuery}
      compact={compact}
      defaultContextForTranslators={defaultContextForTranslators}
    />
  </StyledTranslationsTipWidget>
);
