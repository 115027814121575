import { ContentTypes } from '@common/enums/ContentTypes';
import { CommonActions } from '@actions/CommonActions';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import type { LanguageV2 } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ConversationExerciseActions } from '@actions/ConversationExerciseActions';
import ConversationExerciseInterface from '@components/Exercises/Conversation/interfaces/ConversationExerciseInterface';

export const ConversationExerciseActionsCreator = {
  save: (exercise: AnyExerciseInterface, learningLanguage: LanguageV2, values: FormikValuesInterface) => ({
    type: ConversationExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: ConversationExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  nullifyHint: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'hint',
    },
  }),
  updateMinimumNumberOfWords: (minimumNumberOfWords: number) => ({
    type: ConversationExerciseActions.UPDATE_MINIMUM_NUMBER_OF_WORDS,
    payload: {
      minimumNumberOfWords,
    },
  }),
  setImage1: (exercise: ConversationExerciseInterface, image: File, progressHandler: Function) => ({
    type: ConversationExerciseActions.UPLOAD_IMAGE1,
    payload: {
      exercise,
      image,
      exerciseType: ContentTypes.conversation,
      section: ConversationExerciseActions.UPLOAD_IMAGE1,
      progressHandler,
    },
  }),
  removeImage1: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'image1',
    },
  }),
  removeImage2: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'image2',
    },
  }),
  removeImage3: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'image3',
    },
  }),
  setImage2: (exercise: ConversationExerciseInterface, image: File, progressHandler: Function) => ({
    type: ConversationExerciseActions.UPLOAD_IMAGE2,
    payload: {
      exercise,
      image,
      exerciseType: ContentTypes.conversation,
      section: ConversationExerciseActions.UPLOAD_IMAGE2,
      progressHandler,
    },
  }),
  setImage3: (exercise: ConversationExerciseInterface, image: File, progressHandler: Function) => ({
    type: ConversationExerciseActions.UPLOAD_IMAGE3,
    payload: {
      exercise,
      image,
      exerciseType: ContentTypes.conversation,
      section: ConversationExerciseActions.UPLOAD_IMAGE3,
      progressHandler,
    },
  }),
};
