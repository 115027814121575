import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LinkToRolesPage } from '@components/RolesAndPermissions/AdminSettings/LinkToRolesPage/LinkToRolesPage';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { Loader } from '@features/theme';
import {
  Dropdown,
  DropdownInterface,
  dropdown_defaultCode,
  generateLanguageDropdownOptions,
} from '@components/Dropdown';
import { UserInterface } from '@common/interfaces/users/UserInterface';
import { ProfileImage } from '@components/RolesAndPermissions/ProfileImage/ProfileImage';
import { SearchBox } from '@components/Search';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { Table } from '@components/RolesAndPermissions/AdminSettings/Table/Table';
import { TableFooter } from '@components/RolesAndPermissions/AdminSettings/TableFooter/TableFooter';
import { SortableFieldType, sortBy } from '@features/dashboard';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { usersSlice, usersSelectors, selectIsUsersLoaded } from '@redux/slices/usersSlice';
import { AVAILABLE_LEARNING_LANGUAGES_V2, type LanguageV2 } from '@features/content/languages';

import './AdminSettings.scss';
import { Logo } from '@features/app/logo';
import { useUser } from '@features/auth';
import { ALL_ROLES, ROLE_NAMES, useIsAdmin } from '@features/auth/roles';

export const AdminSettings = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { user } = useUser();
  const isAdmin = useIsAdmin();
  const users = useAppSelector(usersSelectors.selectAll);
  const usersLoaded = useAppSelector(selectIsUsersLoaded);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [visibleUsers, setVisibleUsers] = useState(users.slice(0, 9));
  const [filterTerm, setFilterTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowSize, setRowSize] = useState(10);
  const [totalPages, setTotalPages] = useState(Math.ceil(users.length / 10));

  const [orderByField, setOrderByField] = useState<SortableFieldType>({ field: 'default', ascending: false });

  const sortOrder = useCallback(
    (newFieldOrder: SortableFieldType) => {
      const filteredAllUsers = sortBy(
        filteredUsers,
        newFieldOrder.field,
        newFieldOrder.fieldType || ContentTypes.string,
        newFieldOrder.ascending,
      );
      setOrderByField({
        field: newFieldOrder.field,
        ascending: newFieldOrder.ascending,
      });

      setFilteredUsers(filteredAllUsers as UserInterface[]);
    },
    [filteredUsers],
  );

  const [filterCourseLanguage, setFilterCourseLanguage] = useState<DropdownInterface['code']>(dropdown_defaultCode);

  const [filterRole, setFilterRole] = useState<DropdownInterface['code']>(dropdown_defaultCode);

  const courseLanguagesDropdownFilters: DropdownInterface[] = generateLanguageDropdownOptions(
    AVAILABLE_LEARNING_LANGUAGES_V2,
    'All course languages',
    'courses',
  );

  useEffect(() => {
    let filtered = users;
    if (filterCourseLanguage !== dropdown_defaultCode) {
      filtered = filtered.filter(
        (user: UserInterface) => user.languages.list.includes(filterCourseLanguage as LanguageV2) || user.languages.all,
      );
    }
    if (filterRole !== dropdown_defaultCode) {
      filtered = filtered.filter((user: UserInterface) => user.role === filterRole);
    }
    if (filterTerm.length > 0) {
      filtered = filtered?.filter((user: UserInterface) => {
        return (
          user.firstName.toLocaleLowerCase().lastIndexOf(filterTerm.toLocaleLowerCase()) > -1 ||
          user.lastName.toLocaleLowerCase().lastIndexOf(filterTerm.toLocaleLowerCase()) > -1
        );
      });
    }
    setFilteredUsers(filtered);
  }, [filterCourseLanguage, filterRole, filterTerm, users]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredUsers.length / rowSize));
    setCurrentPage(1);
  }, [rowSize, filterCourseLanguage, filterRole, filterTerm, filteredUsers.length]);

  useEffect(() => {
    setVisibleUsers(filteredUsers.slice(rowSize * (currentPage - 1), rowSize * (currentPage - 1) + rowSize));
  }, [currentPage, filteredUsers, rowSize, users]);

  useEffect(() => {
    if (usersLoaded === LoadingStage.notLoaded) {
      dispatch(usersSlice.actions.getUsers());
    }
  }, [usersLoaded, dispatch]);

  useEffect(() => {
    if (!isAdmin) {
      history.replace('/');
    }
  }, [isAdmin, history]);

  return (
    <div className="admin-settings-wrapper">
      <div className="admin-settings-header">
        <Logo size="large" withLink />
        <ProfileImage />
      </div>
      <div className="admin-settings">
        <BreadcrumbNavigation breadcrumbItems={[<span>Admin Settings</span>]} />
        <span className="admin-settings__title">Admin Settings</span>
        <LinkToRolesPage />

        {usersLoaded === LoadingStage.loading ? (
          <Loader size="L" />
        ) : (
          <>
            <div className="admin-settings__filters">
              <div className="admin-settings__filters__block">
                <Dropdown
                  id="role"
                  values={[
                    { name: 'All', code: dropdown_defaultCode },
                    ...ALL_ROLES.map((role) => ({
                      name: ROLE_NAMES[role],
                      code: role,
                    })),
                  ]}
                  value={filterRole}
                  title="Role"
                  onChange={(selectedValue: DropdownInterface) => {
                    setFilterRole(selectedValue.code);
                  }}
                  className="admin-settings__filters__block__filter"
                />

                <Dropdown
                  id="course-language"
                  values={courseLanguagesDropdownFilters}
                  value={filterCourseLanguage}
                  title="Course language"
                  onChange={(selectedValue) => {
                    setFilterCourseLanguage(selectedValue.code);
                  }}
                  className="admin-settings__filters__block__filter"
                />
              </div>

              <SearchBox
                filterTerm={filterTerm}
                onSearchChange={(value: string) => setFilterTerm(value)}
                placeholder="Filter by name"
                className="admin-settings__filters__search"
              />
            </div>
            <Table
              users={users}
              sortOrder={sortOrder}
              orderByField={orderByField}
              filteredUsers={filteredUsers}
              visibleUsers={visibleUsers}
              activeUser={user}
            />
            <TableFooter
              setRowSize={setRowSize}
              rowSize={rowSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </>
        )}
      </div>
    </div>
  );
};
