import { apiClient } from '@features/api';
import { VocabularyReviewListItem } from '@features/content/vocabularyReview';

import type { LexicalItemQueryParamsType } from './types';

export const searchByLexicalItem = async (
  query: string,
  params: LexicalItemQueryParamsType,
): Promise<{ data: { results: VocabularyReviewListItem[]; count: number } }> => {
  return await apiClient.noErrorsV2.get('/search/lexical-items', {
    params: {
      query,
      ...params,
    },
  });
};
