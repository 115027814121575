import * as _ from 'lodash';
import React, { ReactElement, useState } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';

import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import {
  formatCounterOfFilledLocalizations,
  isLocalizationFilled,
  isLocalizationNeedsToBeCounted,
} from '@features/content';
import { useIsAdmin } from '@features/auth/roles';
import { camelCaseSeparateWords } from '@helpers/camel-case-separator';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { DEFAULT_LANGUAGE_V2, LANGUAGE_NAMES_V2, LanguageV2 } from '@features/content/languages';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { TranslationsTip } from './TranslationsTip';

import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';

import type { TranslationsTipCommonProps } from './types';

type TranslationsTipWrapperProps<T> = TranslationsTipCommonProps<T> & {
  children: ReactElement;
  className?: string;
  content: TranslationsPanelContentInterface | null;
  errors?: ValidationErrorInterface[];
  interfaceLanguages: LanguageV2[];
  isChangeDisabled?: boolean;
  learningLanguage?: LanguageV2;
};

export const TranslationsTipWrapper = <T extends string, K extends FormikValues>({
  children,
  className,
  content,
  defaultContextForTranslators,
  errors = [],
  fieldName,
  interfaceLanguages,
  isChangeDisabled = false,
  learningLanguage = DEFAULT_LANGUAGE_V2,
  showSearch = true,
  type,
  onContentSuggestionsClick,
  onOpenTranslationsPanel,
  onRemoveString,
}: TranslationsTipWrapperProps<T>) => {
  const isAdmin = useIsAdmin();
  const { values, setFieldValue }: FormikContextType<K> = useFormikContext();
  const { isEditAvailable } = useIsEditAvailable();
  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const isErrorsShown = isIssuesShown && errors?.length;
  const hasErrors = errors?.some((e) => !e.isWarning);
  const hasWarnings = errors?.some((e) => e.isWarning);

  let totalNeededLanguages = interfaceLanguages.length;

  if (!interfaceLanguages.includes(learningLanguage)) {
    totalNeededLanguages = totalNeededLanguages + 1;
  }

  if (!interfaceLanguages.includes(DEFAULT_LANGUAGE_V2) && learningLanguage !== DEFAULT_LANGUAGE_V2) {
    totalNeededLanguages = totalNeededLanguages + 1;
  }

  const numberOfTranslations =
    content?.audioLocalizations.filter(
      (localization) =>
        isLocalizationFilled(localization) &&
        isLocalizationNeedsToBeCounted(
          localization,
          interfaceLanguages.map((interfaceLang) => interfaceLang),
          learningLanguage,
        ),
    ).length || 0;
  const audioElements = `${numberOfTranslations}/${totalNeededLanguages}`;
  const translationElements = formatCounterOfFilledLocalizations(
    values?.[fieldName]?.filter((item: K) => item.value && !item.isPhonetic) || [],
    interfaceLanguages.map((interfaceLang) => interfaceLang),
    learningLanguage,
  );

  const [currentLanguage, setCurrentLanguage] = useState(learningLanguage);
  const [isPhonetic, setIsPhonetic] = useState(false);

  const displayedValueInChildren =
    values?.[fieldName].find((item: K) => item.language === currentLanguage && !item.isPhonetic)?.value || '';

  const placeholder =
    children.props.placeholder === undefined
      ? `${camelCaseSeparateWords(_.upperFirst(fieldName))} in ${LANGUAGE_NAMES_V2[currentLanguage]}`
      : children.props.placeholder;

  const isDefaultString = (content?._id || content?.id)?.includes('default_');
  const isEditDisabled = isChangeDisabled || !isEditAvailable;

  const isChangeBlocked =
    (isDefaultString && isAdmin) ||
    (!isEditDisabled && (content?.mappings?.count > 1 || !!content?.mappings?.length) && !content?.isReusingConfirmed);

  return (
    <>
      <TranslationsTip<T>
        audioElements={audioElements}
        className={className}
        currentLanguage={currentLanguage}
        defaultContextForTranslators={defaultContextForTranslators}
        fieldName={fieldName}
        isChangeBlocked={isChangeBlocked}
        isEditDisabled={isEditDisabled}
        showSearch={showSearch}
        suggestionsQuery={displayedValueInChildren}
        translationElements={translationElements}
        type={type}
        visitedContentId={content?._id || content?.id}
        onContentSuggestionsClick={onContentSuggestionsClick}
        onLanguageSwitchToggled={(switchedToEnglishLanguage: boolean, isPhoneticSelected: boolean) => {
          setCurrentLanguage(switchedToEnglishLanguage ? DEFAULT_LANGUAGE_V2 : learningLanguage);
          setIsPhonetic(isPhoneticSelected);
        }}
        onOpenTranslationsPanel={onOpenTranslationsPanel}
        onRemoveString={onRemoveString}
      >
        {React.cloneElement(children, {
          currentLanguage,
          disabled: isEditDisabled,
          errorsShown: isErrorsShown,
          errorType: hasErrors ? 'error' : hasWarnings ? 'warning' : undefined,
          placeholder: placeholder,
          value: displayedValueInChildren,
          onChange: (availableText: string) => {
            const newValues = values?.[fieldName]?.map((item: K) =>
              item.language === currentLanguage && item.isPhonetic === isPhonetic
                ? {
                    ...item,
                    value: availableText,
                  }
                : item,
            );

            setFieldValue(fieldName, newValues);
            setFieldValue(`${fieldName}Changed`, true);
          },
        })}
      </TranslationsTip>
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(errors)} />
    </>
  );
};
