import { without } from 'lodash';

/** @TODO Remove all deprecated constants and types once Backend alignment is finished */

/**
 * @deprecated DO NOT USE - Use ALL_LANGUAGES_V2 array instead
 * maintained for language formats mappers only
 **/
const ALL_LANGUAGES_UPPERCASE = [
  'AR',
  'ZH',
  'EN',
  'en_US',
  'FR',
  'DE',
  'IT',
  'JA',
  'PT',
  'PL',
  'RU',
  'ES',
  'TR',
  'VI',
  'ID',
  'KO',
  'NL',
] as const;

type LanguageTupleUppercase = typeof ALL_LANGUAGES_UPPERCASE;
/**
 * @deprecated DO NOT USE - Use LanguageV2 instead
 * maintained for language formats mappers only
 **/
type Language = LanguageTupleUppercase[number];

export const sort = (languages: readonly LanguageV2[]) => {
  return Array.from(new Set(languages)).sort((a, b) => a.localeCompare(b));
};

/** @deprecated Use AVAILABLE_LEARNING_LANGUAGES_V2 array instead */
export const AVAILABLE_LEARNING_LANGUAGES = without(ALL_LANGUAGES_UPPERCASE, 'VI', 'ID', 'en_US');
/** @deprecated Use AVAILABLE_INTERFACE_LANGUAGES_V2 array instead */
export const AVAILABLE_INTERFACE_LANGUAGES = without(ALL_LANGUAGES_UPPERCASE, 'NL');
/** @deprecated Use PHONETIC_LANGUAGES_V2 array instead */
export const PHONETIC_LANGUAGES: Language[] = ['AR', 'ZH', 'JA', 'RU', 'KO'];

export const ALL_LANGUAGES_V2 = [
  'ar',
  'zh',
  'en',
  'en-US',
  'fr',
  'de',
  'it',
  'ja',
  'pt',
  'pl',
  'ru',
  'es',
  'tr',
  'vi',
  'id',
  'ko',
  'nl',
] as const;

type LanguageV2Tuple = typeof ALL_LANGUAGES_V2;
export type LanguageV2 = LanguageV2Tuple[number];

export const AVAILABLE_LEARNING_LANGUAGES_V2 = without(ALL_LANGUAGES_V2, 'vi', 'id', 'en-US');
export const AVAILABLE_INTERFACE_LANGUAGES_V2 = without(ALL_LANGUAGES_V2, 'nl');
export const DEFAULT_LANGUAGE_V2: LanguageV2 = 'en';
export const PHONETIC_LANGUAGES_V2: LanguageV2[] = ['ar', 'zh', 'ja', 'ru', 'ko'];

/** Helpers and mappers to handle language conversion */

export const isDeprecatedLanguageFormat = (value: string) => {
  if (!value) return false;

  // get the two first chars (en-US/EN_US case)
  const _value = value.substring(0, 2);
  return _value === _value.toUpperCase();
};

export const mapLanguageV2ToLanguage = (language: LanguageV2): Language => {
  return language === 'en-US' ? 'en_US' : (language.toUpperCase() as Language);
};

export const mapLanguageToLanguageV2 = (language: Language): LanguageV2 => {
  return language === 'en_US' ? 'en-US' : (language.toLowerCase() as LanguageV2);
};
