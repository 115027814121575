import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { CourseLanguagesPanelActionsCreator } from '@actionCreators/CourseLanguagesPanelActionsCreator';
import { Dropdown } from '@components/Dropdown';
import { FlagSelectable } from '@components/FlagSelectable/FlagSelectable';
import { useUser } from '@features/auth';
import { Button } from '@features/theme';
import { useToast } from '@features/app/toast';
import { AVAILABLE_INTERFACE_LANGUAGES_V2, LanguageV2 } from '@features/content/languages';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse, selectLearningLanguage, selectInterfaceLanguages } from '@selectors/CoursesSelectors';
import CoursesService from '@services/CoursesService';
import courseLanguages from '@static/json/courseLanguages.json';

const CourseCreationStageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const SubHeader = styled.h4`
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

const StyledDropdown = styled(Dropdown)`
  width: 21rem;
  margin-bottom: 3.2rem;

  .dropdown-toggle {
    margin-left: 0;
  }
`;

const SelectInterfaceLangArea = styled.div`
  align-items: center;
  display: flex;
  gap: 1.2rem;
  margin-bottom: 1.8rem;

  ${SubHeader} {
    margin-bottom: 0;
  }
`;

const SelectInterfaceLang = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.colorV2.uiBackgroundSecondary};
  border-radius: 0.4rem;
  display: flex;
  font-size: 1.4rem;
  height: 2.9rem;
  justify-content: center;
  padding: 1rem;

  label {
    margin-bottom: 0;
  }
`;

const InterfaceLangList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 0;
`;

const InterfaceLangListItem = styled.li`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 3.2rem;
  width: 20rem;
`;

const ButtonsArea = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: flex-end;
`;

type CourseCreationStageProps = {
  isEditingCourse: boolean;
  close: () => void;
  onUpdated?: (courseId: string) => void;
};
const CourseCreationStage = ({ isEditingCourse, close, onUpdated }: CourseCreationStageProps) => {
  const dispatch = useAppDispatch();
  let courseAllData = useAppSelector(selectCourse);

  let courseMainLanguage = useAppSelector(selectLearningLanguage);
  let courseUILanguages = useAppSelector(selectInterfaceLanguages);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { user } = useUser();

  const availableCourseLanguages = user?.languages.all
    ? courseLanguages
    : courseLanguages.filter((lang) => user?.languages.list.includes(lang.code as LanguageV2));

  let areAllToggled = courseUILanguages.length === AVAILABLE_INTERFACE_LANGUAGES_V2.length;

  const toggleAll = () => {
    if (areAllToggled) {
      dispatch(CourseLanguagesPanelActionsCreator.setAllCourseUILanguagesAsToggled(false));
    } else {
      dispatch(CourseLanguagesPanelActionsCreator.setAllCourseUILanguagesAsToggled(true));
    }
  };

  const toggleLanguage = (language: LanguageV2) => {
    dispatch(CourseLanguagesPanelActionsCreator.setCourseSetUILanguages(language));
  };

  const showToast = useToast();

  const onCreateCourse = async () => {
    setButtonDisabled(true);

    try {
      const courseCreated: any = await CoursesService.create({
        learningLanguage: courseMainLanguage,
        interfaceLanguages: courseUILanguages,
      });

      dispatch(CourseLanguagesPanelActionsCreator.courseCreatedSuccessfully(courseCreated));

      onUpdated && onUpdated(courseCreated.id);
    } catch (e: any) {
      showToast({
        type: 'error',
        title: 'Error',
        description: e.message,
      });

      console.error(e);
    }
  };

  const onEditCourse = async () => {
    setButtonDisabled(true);

    try {
      await CoursesService.updateUILanguages({
        id: courseAllData.id,
        name: courseAllData.name,
        learningLanguage: courseAllData.learningLanguage,
        interfaceLanguage: courseUILanguages,
      });

      showToast({
        type: 'success',
        title: 'Updated interface languages',
      });

      onUpdated && onUpdated(courseAllData.id);
    } catch (e: any) {
      showToast({
        type: 'error',
        title: 'Failed to update',
        description: e.message,
      });
    }

    close();
  };

  useEffect(() => {
    let isCourseLanguageSelected = false;
    let isOneLanguageEnabled = false;

    if (courseMainLanguage) isCourseLanguageSelected = true;
    if (courseUILanguages.length > 0) isOneLanguageEnabled = true;

    if (isCourseLanguageSelected && isOneLanguageEnabled) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [courseMainLanguage, courseUILanguages]);

  return (
    <CourseCreationStageWrapper>
      <div>
        {!isEditingCourse && (
          <>
            <SubHeader>Select course language</SubHeader>
            <StyledDropdown
              value={courseMainLanguage}
              id="course-language"
              values={availableCourseLanguages}
              title="Select a language"
              onChange={(courseMainLanguage) => {
                dispatch(
                  CourseLanguagesPanelActionsCreator.setCourseLearningLanguage(courseMainLanguage.code as LanguageV2),
                );
              }}
            ></StyledDropdown>
          </>
        )}

        <div>
          <SelectInterfaceLangArea>
            <SubHeader>Select interface languages</SubHeader>

            <SelectInterfaceLang>
              <input type="checkbox" onChange={() => toggleAll()} checked={areAllToggled} id="add-course-toggle" />
              <label htmlFor="add-course-toggle">{areAllToggled ? 'Unselect all' : 'Select all'}</label>
            </SelectInterfaceLang>
          </SelectInterfaceLangArea>

          <InterfaceLangList>
            {AVAILABLE_INTERFACE_LANGUAGES_V2.map((language, idx) => {
              let languageFound: boolean = courseUILanguages.lastIndexOf(language) > -1;
              return (
                <InterfaceLangListItem key={idx}>
                  <FlagSelectable
                    language={language}
                    checked={languageFound}
                    onChange={() => toggleLanguage(language)}
                  ></FlagSelectable>
                </InterfaceLangListItem>
              );
            })}
          </InterfaceLangList>
        </div>
      </div>

      <ButtonsArea>
        <Button variant="tertiary" onClick={close}>
          Cancel
        </Button>
        <Button disabled={buttonDisabled} onClick={() => (isEditingCourse ? onEditCourse() : onCreateCourse())}>
          {isEditingCourse ? 'Update course' : 'Create course'}
        </Button>
      </ButtonsArea>
    </CourseCreationStageWrapper>
  );
};

export default CourseCreationStage;
