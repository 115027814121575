import { AppDispatch } from '@redux/store';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';

import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { findLocalizationInSearchedLanguage } from '@features/content';
import type { LanguageV2 } from '@features/content/languages';
import { clone } from '@helpers/clone';
import { FormikValueInterface } from '@helpers/formikInitialValuesHelper';
import { brTag, removeTableTags } from '@helpers/htmlTagsHelper';

import { BidimensionalEditorActionsCreator } from '@actionCreators/BidimensionalEditorActionsCreator';
import { BidimensionalEditorModes, type BidimensionalEditorModesType } from './enums/BidimensionalEditorModes';
import React from 'react';
import { EditorDataSourceMode } from '@common/types/EditorDataSourceMode';

const BidimensionalEditorUtils = {
  onEditorFocus(
    setDataSourceModeForEditor: React.Dispatch<React.SetStateAction<EditorDataSourceMode>>,
    setDisplayedValueInChildren: React.Dispatch<React.SetStateAction<string>>,
    languageForTips: LanguageV2[][],
    setSelectedToolbar: React.Dispatch<React.SetStateAction<string>>,
    setCurrentRow: React.Dispatch<React.SetStateAction<number>>,
    setCurrentColumn: React.Dispatch<React.SetStateAction<number>>,
    mode: BidimensionalEditorModesType,
    setTranslationsPanelContentInterfaceOfCurrentCell: React.Dispatch<
      React.SetStateAction<TranslationsPanelContentInterface | null>
    >,
    translationsPanelContent: TranslationsPanelContentInterface | TranslationsPanelContentInterface[][],
    row: number,
    column: number,
  ) {
    setDataSourceModeForEditor('defaultEditorState');
    setSelectedToolbar(`${row}_${column}`);
    setCurrentRow(row);
    setCurrentColumn(column);

    const cell =
      (translationsPanelContent as TranslationsPanelContentInterface[][])?.[row]?.[column] || translationsPanelContent;
    const localization = cell
      ? cell.textLocalizations.find((loc: LocalizationInterface) => loc.language === languageForTips[row][column])
      : undefined;

    setDisplayedValueInChildren(localization ? localization.value : '');

    if (mode === BidimensionalEditorModes.mono) {
      setTranslationsPanelContentInterfaceOfCurrentCell(translationsPanelContent as TranslationsPanelContentInterface);
    } else {
      setTranslationsPanelContentInterfaceOfCurrentCell(
        (translationsPanelContent as TranslationsPanelContentInterface[][])?.[row]?.[column],
      );
    }
  },
  removeColumn(
    dispatch: AppDispatch,
    cells: any,
    columnToRemove: number,
    setColumns: React.Dispatch<React.SetStateAction<number>>,
    setCells: any,
    setEditorToIdleState: () => void,
    storeBranch: string,
  ) {
    let clonedCells = BidimensionalEditorUtils.removeColumnFromBidimensional([...cells], columnToRemove);
    dispatch(BidimensionalEditorActionsCreator.removeColumn(columnToRemove, storeBranch));

    setColumns(clonedCells[0].length);
    setCells(clonedCells);
    setEditorToIdleState();
  },
  removeRow(
    dispatch: AppDispatch,
    cells: any,
    rows: number,
    rowToRemove: number,
    setRows: React.Dispatch<React.SetStateAction<number>>,
    setCells: any,
    setEditorToIdleState: () => void,
    storeBranch: string,
  ) {
    let clonedCells = [...cells];
    let outputCells: EditorState[][] = [];

    for (let row = 0; row < rows; row++) {
      if (row !== rowToRemove) {
        outputCells.push(clonedCells[row]);
      }
    }

    setRows(rows - 1);
    setCells(outputCells);
    setEditorToIdleState();
    dispatch(BidimensionalEditorActionsCreator.removeRow(rowToRemove, storeBranch));
  },
  addRow(
    dispatch: AppDispatch,
    setRows: React.Dispatch<React.SetStateAction<number>>,
    rows: number,
    setEditorToIdleState: () => void,
    storeBranch: string,
    insertAt?: number,
  ) {
    setRows(rows + 1);
    setEditorToIdleState();

    dispatch(BidimensionalEditorActionsCreator.addRow(storeBranch, insertAt));
  },
  addColumn(
    dispatch: AppDispatch,
    columns: number,
    maxColums: number,
    setColumns: React.Dispatch<React.SetStateAction<number>>,
    setEditorToIdleState: () => void,
    storeBranch: string,
    insertAt?: number,
  ) {
    if (columns + 1 > maxColums) {
      setColumns(maxColums);
      return;
    }

    setColumns(columns + 1);
    setEditorToIdleState();

    dispatch(BidimensionalEditorActionsCreator.addColumn(storeBranch, insertAt));
  },
  onEditorStateChange: (
    dispatch: AppDispatch,
    cells: any,
    ignoreLanguageForTips: boolean,
    language: LanguageV2 | undefined,
    languageForTips: LanguageV2[][],
    mode: BidimensionalEditorModesType,
    storeRow: number | boolean | undefined,
    storeColumn: number | boolean | undefined,
    translationsPanelContent: any,
    localizationId: string | undefined,
    storeBranch: string,
    setOneCellHasBeenFocused: React.Dispatch<React.SetStateAction<boolean>>,
    setCells: any,
    editorState: EditorState,
    row: number,
    column: number,
    bundleName?: string,
  ) => {
    let clonedCells: EditorState[][] = [...cells];
    clonedCells[row][column] = editorState;

    setOneCellHasBeenFocused(true);

    let selectedLanguage: string;

    if (ignoreLanguageForTips) {
      selectedLanguage = language as string;
    } else {
      if (languageForTips && languageForTips[row] && languageForTips[row][column]) {
        selectedLanguage = languageForTips[row][column];
      } else {
        selectedLanguage = languageForTips[0][0];
      }
    }

    if (mode === BidimensionalEditorModes.mono) {
      clonedCells[0][0] = editorState;
      setCells(clonedCells);

      dispatch(
        BidimensionalEditorActionsCreator.updateReduxStoreCell(
          BidimensionalEditorUtils.stateToHTMLHelper(editorState),
          storeRow as number,
          storeColumn as number,
          storeBranch,
          selectedLanguage,
          localizationId,
          bundleName,
        ),
      );
    } else {
      let clonedValues = clone(translationsPanelContent);

      if (clonedValues[row]?.[column] !== undefined) {
        dispatch(
          BidimensionalEditorActionsCreator.updateReduxStoreCell(
            BidimensionalEditorUtils.stateToHTMLHelper(editorState),
            row as number,
            column as number,
            storeBranch,
            selectedLanguage,
            localizationId,
            bundleName,
          ),
        );
      }
    }
  },
  discoverEditorStateForThisCell: (
    language: LanguageV2,
    languageForTips: LanguageV2[][],
    ignoreLanguageForTips: boolean,
    mode: BidimensionalEditorModesType,
    storeRow: number | boolean | undefined,
    storeColumn: number | boolean | undefined,
    translationsPanelContent: TranslationsPanelContentInterface | TranslationsPanelContentInterface[][] | undefined,
    localizationId: string | undefined,
    row: number,
    column: number,
  ) => {
    let editorState: EditorState, localization: LocalizationInterface;
    let languageForTipsValueForThisCell = ignoreLanguageForTips
      ? (language as string)
      : languageForTips?.[row]?.[column] !== undefined
        ? languageForTips[row][column]
        : (language as string);

    if (mode === BidimensionalEditorModes.mono) {
      if (storeRow !== false && storeRow !== undefined && storeColumn !== false && storeColumn !== undefined) {
        translationsPanelContent = translationsPanelContent as TranslationsPanelContentInterface[][];
        localization = findLocalizationInSearchedLanguage(
          translationsPanelContent[storeRow as number][storeColumn as number].textLocalizations,
          languageForTipsValueForThisCell as LanguageV2,
        ) as LocalizationInterface;
      } else {
        translationsPanelContent = translationsPanelContent as TranslationsPanelContentInterface;

        if (!localizationId) {
          localization = findLocalizationInSearchedLanguage(
            translationsPanelContent.textLocalizations,
            languageForTipsValueForThisCell as LanguageV2,
          ) as LocalizationInterface;
        } else {
          localization = translationsPanelContent.textLocalizations.find(
            (localization: LocalizationInterface) => localization._id === localizationId,
          ) as LocalizationInterface;
        }
      }
    } else {
      translationsPanelContent = translationsPanelContent as TranslationsPanelContentInterface[][];
      let localizationInArray = translationsPanelContent?.[row]?.[column];

      if (localizationInArray === undefined) {
        localization = undefined as any;
      } else {
        localization = findLocalizationInSearchedLanguage(
          localizationInArray.textLocalizations,
          languageForTipsValueForThisCell as LanguageV2,
        ) as LocalizationInterface;
      }
    }

    if (localization === undefined) {
      editorState = BidimensionalEditorUtils.stringToHTMLForBidimensionalEditor('');
    } else {
      editorState = BidimensionalEditorUtils.stringToHTMLForBidimensionalEditor(localization.value);
    }

    return editorState;
  },
  discoverEditorStateForThisCellV2: (
    language: LanguageV2,
    languageForTips: LanguageV2[][],
    ignoreLanguageForTips: boolean,
    mode: BidimensionalEditorModesType,
    storeRow: number | boolean | undefined,
    storeColumn: number | boolean | undefined,
    translationsPanelContent: any,
    localizationId: string | undefined,
    row: number,
    column: number,
    values: FormikValueInterface[],
    charactersLimit: number,
  ) => {
    let editorState: EditorState, localization: LocalizationInterface | undefined;
    let languageForTipsValueForThisCell = ignoreLanguageForTips
      ? (language as string)
      : languageForTips?.[row]?.[column] !== undefined
        ? languageForTips[row][column]
        : (language as string);

    if (mode === BidimensionalEditorModes.mono) {
      if (storeRow !== false && storeRow !== undefined && storeColumn !== false && storeColumn !== undefined) {
        translationsPanelContent = translationsPanelContent as TranslationsPanelContentInterface[][];
        localization = findLocalizationInSearchedLanguage(
          translationsPanelContent[storeRow as number][storeColumn as number].textLocalizations,
          languageForTipsValueForThisCell as LanguageV2,
        ) as LocalizationInterface;
      } else {
        translationsPanelContent = translationsPanelContent as TranslationsPanelContentInterface;

        localization = findLocalizationInSearchedLanguage(
          values.length ? values : translationsPanelContent.textLocalizations,
          languageForTipsValueForThisCell as LanguageV2,
        ) as LocalizationInterface;
      }
    } else {
      translationsPanelContent = translationsPanelContent as TranslationsPanelContentInterface[][];
      let localizationInArray = translationsPanelContent?.[row]?.[column];

      if (localizationInArray === undefined) {
        localization = undefined;
      } else {
        localization = findLocalizationInSearchedLanguage(
          localizationInArray.textLocalizations,
          languageForTipsValueForThisCell as LanguageV2,
        ) as LocalizationInterface;
      }
    }

    if (localization === undefined) {
      editorState = BidimensionalEditorUtils.stringToHTMLForBidimensionalEditor('');
    } else {
      editorState = BidimensionalEditorUtils.stringToHTMLForBidimensionalEditor(
        // Need to replace '\n' to brTag to display string the same like for clients
        localization.value.replaceAll('\n', brTag),
      );
      editorState = BidimensionalEditorUtils.updateTextAccordingLimit(editorState, charactersLimit);
    }

    return editorState;
  },
  getContentLength(editorState: EditorState) {
    return editorState.getCurrentContent().getFirstBlock().getLength() || 0;
  },
  updateTextAccordingLimit(editorState: EditorState, charactersLimit: number): EditorState {
    const block = editorState.getCurrentContent().getFirstBlock();

    if (charactersLimit && block.getLength() > charactersLimit) {
      let contentState;
      let currentContent = editorState.getCurrentContent();

      let selection = SelectionState.createEmpty(block.getKey());

      selection = selection.merge({
        anchorOffset: charactersLimit,
        focusOffset: block.getLength(),
      });
      contentState = Modifier.applyInlineStyle(currentContent, selection, charactersLimit ? 'LIMIT_EXCEEDED' : '');

      editorState = EditorState.push(editorState, contentState, 'change-inline-style');
    }
    return editorState;
  },
  mirrorCellsForLanguageTip(cells: EditorState[][], languageForTips: LanguageV2[][], courseMainLanguage: LanguageV2) {
    let clonedInitialCellsState: any[][] = clone(cells);

    for (let row = 0; row < clonedInitialCellsState.length; row++) {
      for (let column = 0; column < clonedInitialCellsState[row].length; column++) {
        if (languageForTips === undefined) {
          clonedInitialCellsState[row][column] = courseMainLanguage;
        } else {
          let currentLanguageForTips = languageForTips?.[row]?.[column];

          if (currentLanguageForTips === undefined) {
            clonedInitialCellsState[row][column] = courseMainLanguage;
          } else {
            clonedInitialCellsState[row][column] = currentLanguageForTips;
          }
        }
      }
    }

    return clonedInitialCellsState;
  },
  _replicateCellsStructureWithEmptyEditorStates(cells: EditorState[][]) {
    let row, col;
    let tempCellsRow = [];

    let tempCells: EditorState[][] = [];

    for (row = 0; row < cells.length; row++) {
      tempCellsRow = [];

      for (col = 0; col < cells[0].length; col++) {
        tempCellsRow.push(EditorState.createEmpty());
      }

      tempCells.push(tempCellsRow);
    }

    return tempCells;
  },
  _addNewEmptyCellsForNewContent(cells: EditorState[][], tempCells: EditorState[][], rows: number, columns: number) {
    let row, col;
    let tempCellsRowForANewlyCreatedRow = [];

    for (row = 0; row < rows; row++) {
      for (col = 0; col < columns; col++) {
        if (cells[row] === undefined) {
          tempCellsRowForANewlyCreatedRow = [];

          for (let colForNewRow = 0; colForNewRow < columns; colForNewRow++) {
            tempCellsRowForANewlyCreatedRow.push(EditorState.createEmpty());
          }

          tempCells[row] = tempCellsRowForANewlyCreatedRow;
        } else {
          tempCells[row][col] = cells[row][col];
        }
      }
    }

    return tempCells;
  },
  updateCellsOnRowOrColumnChange(cells: EditorState[][], setCells: Function, rows: number, columns: number) {
    let tempCells: EditorState[][] = BidimensionalEditorUtils._replicateCellsStructureWithEmptyEditorStates(cells);

    tempCells = BidimensionalEditorUtils._addNewEmptyCellsForNewContent(cells, tempCells, rows, columns);

    setCells(tempCells);
  },
  stringToHTMLForBidimensionalEditor(value: string) {
    const valueWithSupport = value
      .replaceAll('<u>', '<ins>')
      .replaceAll('</u>', '</ins>')
      .replaceAll('<b>', '<strong>')
      .replaceAll('</b>', '</strong>')
      .replaceAll('<i>', '<em>')
      .replaceAll('</i>', '</em>');
    // p tags added for editor to not trim spaces in the beginning and in the end of string
    const { contentBlocks, entityMap } = htmlToDraft('<p>' + valueWithSupport + '</p>');
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  },

  stateToHTMLHelper(e: EditorState) {
    return stateToHTML(e.getCurrentContent(), {
      // @ts-ignore
      defaultBlockTag: null,
      inlineStyles: {
        BOLD: { element: 'b' },
        ITALIC: { element: 'i' },
        UNDERLINE: { element: 'u' },
      },
    });
  },

  removeColumnFromBidimensional(cells: any, columnToRemove: number) {
    let rows = cells.length;
    let columns = cells[0].length;
    let outputCells: any[] = [];

    for (let row = 0; row < rows; row++) {
      outputCells[row] = [];

      for (let col = 0; col < columns; col++) {
        if (col !== columnToRemove) {
          outputCells[row].push(cells[row][col]);
        }
      }
    }

    return outputCells;
  },

  generateInitialCellsState(
    mode: BidimensionalEditorModesType,
    values: TranslationsPanelContentInterface | TranslationsPanelContentInterface[][],
    courseMainLanguage: string,
    row?: number | undefined,
    column?: number | undefined,
  ) {
    if (mode === BidimensionalEditorModes.mono) {
      if (values === null || values === undefined) return [[EditorState.createEmpty()]];
      else {
        let textLocalizations;

        if (row !== undefined && column !== undefined) {
          textLocalizations = (values as TranslationsPanelContentInterface[][])[row][column].textLocalizations;
        } else {
          textLocalizations = (values as TranslationsPanelContentInterface).textLocalizations;
        }

        let foundLocalization = findLocalizationInSearchedLanguage(textLocalizations, courseMainLanguage as LanguageV2);

        if (foundLocalization === undefined) {
          return [[BidimensionalEditorUtils.stringToHTMLForBidimensionalEditor('')]];
        } else {
          return [
            [BidimensionalEditorUtils.stringToHTMLForBidimensionalEditor(removeTableTags(foundLocalization.value))],
          ];
        }
      }
    } else {
      if (values === null || values === undefined) return [[EditorState.createEmpty()]];
      else {
        let output: EditorState[][] = [];
        values = values as TranslationsPanelContentInterface[][];

        for (let row = 0; row < values.length; row++) {
          output[row] = [];

          for (let column = 0; column < values[row].length; column++) {
            // Property 'textLocalizations_groupedByLanguage' does not exist on TranslationsPanelContentInterface
            let stringValue = (values[row][column] as any)?.textLocalizations_groupedByLanguage;

            if (stringValue !== undefined) {
              stringValue = stringValue[courseMainLanguage]?.value;
            }

            output[row][column] =
              stringValue === undefined
                ? EditorState.createEmpty()
                : BidimensionalEditorUtils.stringToHTMLForBidimensionalEditor(removeTableTags(stringValue));
          }
        }

        return output;
      }
    }
  },
};

export default BidimensionalEditorUtils;
