import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { VocabularyReviewActionsCreator } from '@actionCreators/VocabularyReviewActionsCreator';

import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { layoutStyles } from '@features/app';
import { Button } from '@features/theme';
import { type LanguageV2, LANGUAGE_NAMES_V2 } from '@features/content/languages';
import { useAppDispatch } from '@redux/store';

import { Header } from '../Header';
import { BreadcrumbsWrapper, Subheader, Title } from '../styles';

import { ReactComponent as AddIcon } from './_img/add.svg';
import { VocabularyReviewData } from './VocabularyReviewData';

export const VocabularyReviewContent = () => {
  const { languageId } = useParams<{ languageId: LanguageV2 }>();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const contentTitle = `${LANGUAGE_NAMES_V2[languageId]} Vocabulary Review`;
  const { EditionComposerWrapper } = layoutStyles;

  useEffect(() => {
    dispatch(VocabularyReviewActionsCreator.setInitialVocabularyReview(languageId));
  }, [dispatch, languageId]);

  return (
    <>
      <Header />
      <EditionComposerWrapper fullWidth>
        <BreadcrumbsWrapper>
          <BreadcrumbNavigation breadcrumbItems={[<span>{contentTitle}</span>]} />
        </BreadcrumbsWrapper>
        <Subheader>
          <Title>{contentTitle}</Title>
          <Button icon={<AddIcon />} onClick={() => history.push(`${languageId}/lexical-item`)}>
            Create Lexical Item
          </Button>
        </Subheader>
        <VocabularyReviewData />
      </EditionComposerWrapper>
    </>
  );
};
