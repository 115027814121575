import { LanguageV2 } from './languages';

export const LANGUAGE_NAMES_V2: { [key in LanguageV2]: string } = {
  ar: 'Arabic',
  de: 'German',
  en: 'English',
  'en-US': 'English (US)',
  es: 'Spanish',
  fr: 'French',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  nl: 'Dutch',
  pl: 'Polish',
  pt: 'Portuguese',
  ru: 'Russian',
  tr: 'Turkish',
  vi: 'Vietnamese',
  zh: 'Chinese',
};

export const LANGUAGE_SHORT_NAMES: { [key in LanguageV2]: string } = {
  ar: 'AR',
  de: 'DE',
  en: 'EN',
  'en-US': 'EN (US)',
  es: 'ES',
  fr: 'FR',
  id: 'ID',
  it: 'IT',
  ja: 'JA',
  ko: 'KO',
  nl: 'NL',
  pl: 'PL',
  pt: 'PT',
  ru: 'RU',
  tr: 'TR',
  vi: 'VI',
  zh: 'ZH',
};
