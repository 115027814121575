import produce from 'immer';
import { PayloadAction } from '@reduxjs/toolkit';

import { VocabularyReviewActions } from '@actions/VocabularyReviewActions';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { MediaTypesType } from '@common/enums/MediaTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { VocabularyReviewSliceType } from '@common/interfaces/slices/VocabularyReviewSliceType';
import { DBId } from '@common/types/DBId';
import { AlternativeValue } from '@components/Exercises/Common/AlternativeValueCreator/AlternativeValueCreator';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import type { LanguageV2 } from '@features/content/languages';
import { ImageDataType } from '@features/content/media';
import type {
  LexicalItemContentType,
  LexicalItemFieldWithLocalizationNames,
  VocabularyReviewListItem,
} from '@features/content/vocabularyReview';
import { lexicalItemInitialContent } from '@redux/initialStates/vocabularyReviewInitialState';

export const VocabularyReviewReducers = {
  // Vocabulary Review
  [VocabularyReviewActions.SET_INITIAL_VOCABULARY_REVIEW]: (
    state: VocabularyReviewSliceType,
    { payload }: PayloadAction<{ language: LanguageV2 }>,
  ) => {
    return produce(state, (draft) => {
      draft.vocabularyReview.language = payload.language;
      draft.vocabularyReview.loaded = LoadingStage.notLoaded;
    });
  },
  [VocabularyReviewActions.VOCABULARY_REVIEW_LOADING]: (state: VocabularyReviewSliceType) => {
    return produce(state, (draft) => {
      draft.vocabularyReview.loaded = LoadingStage.loading;
    });
  },
  [VocabularyReviewActions.VOCABULARY_REVIEW_LOADED]: (
    state: VocabularyReviewSliceType,
    { payload }: PayloadAction<{ lexicalItems: VocabularyReviewListItem[]; totalCount: number }>,
  ) => {
    return produce(state, (draft) => {
      draft.vocabularyReview.loaded = LoadingStage.loaded;
      draft.vocabularyReview.totalCount = payload.totalCount;
    });
  },
  // Lexical Item
  [VocabularyReviewActions.SET_INITIAL_LEXICAL_ITEM]: (
    state: VocabularyReviewSliceType,
    { payload }: PayloadAction<{ language: LanguageV2 }>,
  ) => {
    return produce(state, (draft) => {
      draft.lexicalItem.language = payload.language;
      draft.lexicalItem.loaded = LoadingStage.notLoaded;
      draft.lexicalItem.content = lexicalItemInitialContent;
    });
  },
  [VocabularyReviewActions.LEXICAL_ITEM_LOADED]: (
    state: VocabularyReviewSliceType,
    { payload }: PayloadAction<LexicalItemContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.lexicalItem.loaded = LoadingStage.loaded;

      const content = {
        ...payload,
        // Fields with localization
        phrase:
          payload.phrase?.textLocalizations?.length || payload.phrase?.audioLocalizations?.length
            ? {
                ...payload.phrase,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.phrase?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.phrase?.audioLocalizations,
                ),
              }
            : {
                ...payload.phrase,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        example:
          payload.example?.textLocalizations?.length || payload.example?.audioLocalizations?.length
            ? {
                ...payload.example,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.example?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.example?.audioLocalizations,
                ),
              }
            : {
                ...payload.example,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
      };

      draft.lexicalItem.content = content;
    });
  },
  [VocabularyReviewActions.SET_PHRASE_ALT_VALUES]: (
    state: VocabularyReviewSliceType,
    { payload }: PayloadAction<{ language: LanguageV2; values: AlternativeValue[] }>,
  ) => {
    const { language, values } = payload;

    return produce(state, (draft) => {
      draft.lexicalItem.content.phrase.textLocalizations = draft.lexicalItem.content.phrase.textLocalizations.map(
        (textLocalization) => {
          if (textLocalization.language === language) {
            return {
              ...textLocalization,
              alternativeValues: values.length ? values.map((altValue) => altValue.value) : undefined,
            };
          }

          return textLocalization;
        },
      );

      draft.lexicalItem.content.phraseChanged = true;
    });
  },
  [VocabularyReviewActions.SET_IMAGE]: (
    state: VocabularyReviewSliceType,
    { payload: { description, id, localizationId, value } }: PayloadAction<ImageDataType>,
  ) => {
    return produce(state, (draft) => {
      if (id === null) {
        draft.lexicalItem.content.image = null;
      } else {
        draft.lexicalItem.content.image = {
          description,
          id,
          localizationId,
          value,
          processed: false,
        };
      }

      draft.lexicalItem.content.imageChanged = true;
    });
  },
  [VocabularyReviewActions.SET_MEDIA_VALUE_AFTER_PROCESSING]: (
    state: VocabularyReviewSliceType,
    {
      payload: { contentType, mediaType, mediaUrl },
    }: PayloadAction<{ contentType: DBId; mediaType: MediaTypesType; mediaUrl: string }>,
  ) => {
    return produce(state, (draft) => {
      if (contentType === ContentTypes.lexicalItem) {
        (draft.lexicalItem.content[mediaType as keyof LexicalItemContentType] as ImageDataType).value = mediaUrl;
        (draft.lexicalItem.content[mediaType as keyof LexicalItemContentType] as ImageDataType).processed = true;
      }
    });
  },
  [VocabularyReviewActions.UPLOAD_AUDIO_SUCCEEDED]: (
    state: VocabularyReviewSliceType,
    {
      payload: { content, localization, mediaURL, visitedBranch },
    }: PayloadAction<{
      content: { id: string } & TranslationsPanelContentInterface;
      localization: LocalizationInterface;
      mediaURL: string;
      visitedBranch: LexicalItemFieldWithLocalizationNames;
    }>,
  ) => {
    return produce(state, (draft) => {
      draft.lexicalItem.content[visitedBranch].audioLocalizations = draft.lexicalItem.content[
        visitedBranch
      ].audioLocalizations.map((audioLocalization) => {
        if (audioLocalization.language === localization.language) {
          return {
            ...localization,
            id: mediaURL,
            processed: false,
          };
        }

        return audioLocalization;
      });
    });
  },
  [VocabularyReviewActions.REMOVE_AUDIO]: (
    state: VocabularyReviewSliceType,
    {
      payload: { fieldName, language },
    }: PayloadAction<{
      contentId: DBId;
      fieldName: LexicalItemFieldWithLocalizationNames;
      language: LanguageV2;
    }>,
  ) => {
    return produce(state, (draft) => {
      draft.lexicalItem.content[fieldName].audioLocalizations = draft.lexicalItem.content[
        fieldName
      ].audioLocalizations.map((audioLocalization) => {
        if (audioLocalization.language === language) {
          return {
            ...audioLocalization,
            id: '',
            value: '',
            processed: true,
          };
        }

        return audioLocalization;
      });
    });
  },
  [VocabularyReviewActions.SET_STRING]: (
    state: VocabularyReviewSliceType,
    {
      payload: { newString: notFilledNewString, fieldName, isReuse = false },
    }: PayloadAction<{
      newString: TranslationsPanelContentInterface;
      fieldName: LexicalItemFieldWithLocalizationNames;
      isReuse?: boolean;
    }>,
  ): VocabularyReviewSliceType => {
    const newString = {
      ...notFilledNewString,
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['textLocalizations'],
        notFilledNewString.textLocalizations,
      ),
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['audioLocalizations'],
        notFilledNewString.audioLocalizations,
      ),
      isReused: isReuse,
    };

    return produce(state, (draft) => {
      draft.lexicalItem.content[fieldName] = newString;
      draft.lexicalItem.content[`${fieldName}Changed`] = true;
    });
  },
};
