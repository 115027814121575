import * as Sentry from '@sentry/react';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';
import type { LanguageV2 } from '@features/content/languages';
import { selectTextLocalizationsForSave, selectMediaLocalizationsForSave } from '@helpers/localizationSaveHelper';
import ContentsService from '@services/ContentsService';

import {
  CefrType,
  RoleplayCategoryChangeableFieldNames,
  RoleplayCategoryContentType,
  RoleplayCategoryFieldWithLocalizationNames,
  RoleplayCategoryFormikValues,
  RoleplayListItemType,
  RoleplayScenarioContentType,
  RoleplayScenarioFieldWithLocalizationNames,
  RoleplayScenarioStringFieldNames,
  RoleplayScenarioFormikValues,
  RoleplayScenarioNumberFieldNames,
  RoleplayCategoryListItemType,
} from './types';
import { AxiosResponse } from 'axios';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { FormikValueInterface } from '@helpers/formikInitialValuesHelper';
import { getPhrasebookValue } from './_helpers/getPhrasebookValue';

type SaveScenarioPayloadType = {
  cefr: CefrType;
  description: string | null;
  firstMessages: string[] | [];
  goal: string | null;
  image: string | null;
  messageLimit: number;
  phrasebook: string[];
  prompt: string | null;
  title: string | null;
  voice: string | null;
};

export const RoleplayService = {
  async getRoleplay(language: LanguageV2) {
    return await apiClient.noErrorsV2.get(`/content/roleplays/${language}`);
  },

  async getRoleplayCategories(
    language: LanguageV2,
  ): Promise<AxiosResponse<{ roleplayCategories: RoleplayListItemType[] }>> {
    return await apiClient.noErrorsV2.get(`content/roleplays/${language}/roleplay-categories`);
  },

  async createRoleplayCategory(language: LanguageV2, position: number) {
    return await apiClient.noErrorsV2.post(`content/roleplays/${language}/roleplay-categories`, { position });
  },

  async removeRoleplayCategory(language: LanguageV2, categoryId: DBId) {
    return await apiClient.noErrorsV2.delete(`content/roleplays/${language}/roleplay-categories/${categoryId}`);
  },

  async reorderRoleplayCategories(language: LanguageV2, categoryId: DBId, newPosition: number) {
    return await apiClient.noErrorsV2.put(`content/roleplays/${language}/categories-order`, {
      roleplayCategoryId: categoryId,
      newPosition,
    });
  },

  async getRoleplayNavigation(language: LanguageV2) {
    return await apiClient.noErrorsV2.get(`navigation/roleplays/${language}/roleplay-categories`);
  },

  async getRoleplayCategoryNavigation(categoryId: DBId) {
    return await apiClient.noErrorsV2.get(`navigation/roleplay-categories/${categoryId}/roleplay-scenarios`);
  },

  async getRoleplayCategory(categoryId: DBId) {
    return await apiClient.noErrorsV2.get(`content/roleplay-categories/${categoryId}`);
  },

  async getRoleplayScenarios(categoryId: DBId) {
    return await apiClient.noErrorsV2.get(`content/roleplay-categories/${categoryId}/roleplay-scenarios`);
  },

  async getRoleplayScenariosByLanguage(language: LanguageV2): Promise<{ data: RoleplayCategoryListItemType[] }> {
    return await apiClient.noErrorsV2.get(`content/roleplays/${language}/roleplay-scenarios`);
  },

  async getRoleplayCategoryIcons() {
    return await apiClient.noErrorsV2.get('content/roleplay-categories/icons');
  },

  async createScenario(categoryId: DBId, position: number) {
    return await apiClient.noErrorsV2.post(`content/roleplay-categories/${categoryId}/roleplay-scenarios`, {
      position,
    });
  },

  async removeScenario(categoryId: DBId, scenarioId: DBId) {
    return await apiClient.noErrorsV2.delete(
      `content/roleplay-categories/${categoryId}/roleplay-scenarios/${scenarioId}`,
    );
  },

  async reorderScenarios(categoryId: DBId, scenarioId: DBId, newPosition: number) {
    return await apiClient.noErrorsV2.put(`content/roleplay-categories/${categoryId}/scenarios-order`, {
      roleplayScenarioId: scenarioId,
      newPosition,
    });
  },

  async getScenario(scenarioId: DBId) {
    return await apiClient.noErrorsV2.get(`content/roleplay-scenarios/${scenarioId}`);
  },

  async getPayloadForScenarioUpdate(
    roleplayScenario: RoleplayScenarioContentType,
    values: RoleplayScenarioFormikValues,
    language: LanguageV2,
    setContentId: (
      contentType: ContentTypesType,
      fieldName:
        | RoleplayScenarioFieldWithLocalizationNames
        | RoleplayScenarioStringFieldNames
        | RoleplayScenarioNumberFieldNames,
      contentId: DBId,
    ) => void,
  ) {
    try {
      const isTitleChanged =
        (values?.titleChanged || roleplayScenario.titleChanged) && !roleplayScenario.title.isReused;
      const isDescriptionChanged =
        (values?.descriptionChanged || roleplayScenario.descriptionChanged) && !roleplayScenario.description.isReused;
      const isGoalChanged = (values?.goalChanged || roleplayScenario.goalChanged) && !roleplayScenario.goal.isReused;
      const isPhraseBookPhrase1Changed = values?.phraseBookPhrase1Changed || roleplayScenario.phraseBookPhrase1Changed;
      const isPhraseBookPhrase2Changed = values?.phraseBookPhrase2Changed || roleplayScenario.phraseBookPhrase2Changed;
      const isPhraseBookPhrase3Changed = values?.phraseBookPhrase3Changed || roleplayScenario.phraseBookPhrase3Changed;
      const isPhraseBookPhrase4Changed = values?.phraseBookPhrase4Changed || roleplayScenario.phraseBookPhrase4Changed;
      const isPhraseBookPhrase5Changed = values?.phraseBookPhrase5Changed || roleplayScenario.phraseBookPhrase5Changed;

      const isTriggerMessage1Changed = values?.triggerMessage1Changed || roleplayScenario.triggerMessage1Changed;

      const payloadAccordingChangedFields: Partial<
        Record<
          | RoleplayScenarioFieldWithLocalizationNames
          | RoleplayScenarioStringFieldNames
          | RoleplayScenarioNumberFieldNames
          | 'phrasebook'
          | 'triggerMessages',
          string | (string | null)[] | number | null
        >
      >[] = [];

      /**
       * Fields with localization
       */
      if (isTitleChanged) {
        const title = await RoleplayService.updateRoleplayContentField(
          roleplayScenario,
          'title',
          values,
          ContentTypes.roleplayScenario,
          setContentId,
        );

        payloadAccordingChangedFields.push(title);
      }

      if (isDescriptionChanged) {
        const description = await RoleplayService.updateRoleplayContentField(
          roleplayScenario,
          'description',
          values,
          ContentTypes.roleplayScenario,
          setContentId,
        );

        payloadAccordingChangedFields.push(description);
      }

      if (isGoalChanged) {
        const goal = await RoleplayService.updateRoleplayContentField(
          roleplayScenario,
          'goal',
          values,
          ContentTypes.roleplayScenario,
          setContentId,
        );

        payloadAccordingChangedFields.push(goal);
      }

      let triggerMessagesValue: string[] = [];

      if (isTriggerMessage1Changed) {
        const triggerMessage1 = await RoleplayService.updateRoleplayContentField(
          roleplayScenario,
          'triggerMessage1',
          values,
          ContentTypes.roleplayScenario,
          setContentId,
        );

        triggerMessagesValue = [...triggerMessagesValue, ...(Object.values(triggerMessage1) as string[])];
      }

      if (triggerMessagesValue.length) {
        payloadAccordingChangedFields.push({ triggerMessages: triggerMessagesValue });
      }

      let phraseBookValue: (DBId | null)[] = getPhrasebookValue(roleplayScenario, language);

      if (isPhraseBookPhrase1Changed) {
        const phraseBookPhrase1 = await RoleplayService.updateRoleplayContentField(
          roleplayScenario,
          'phraseBookPhrase1',
          values,
          ContentTypes.roleplayScenario,
          setContentId,
        );

        phraseBookValue.splice(0, 1, ...(Object.values(phraseBookPhrase1) as DBId[]));
      }

      if (isPhraseBookPhrase2Changed) {
        const phraseBookPhrase2 = await RoleplayService.updateRoleplayContentField(
          roleplayScenario,
          'phraseBookPhrase2',
          values,
          ContentTypes.roleplayScenario,
          setContentId,
        );

        phraseBookValue.splice(1, 1, ...(Object.values(phraseBookPhrase2) as DBId[]));
      }

      if (isPhraseBookPhrase3Changed) {
        const phraseBookPhrase3 = await RoleplayService.updateRoleplayContentField(
          roleplayScenario,
          'phraseBookPhrase3',
          values,
          ContentTypes.roleplayScenario,
          setContentId,
        );

        phraseBookValue.splice(2, 1, ...(Object.values(phraseBookPhrase3) as DBId[]));
      }

      if (isPhraseBookPhrase4Changed) {
        const phraseBookPhrase4 = await RoleplayService.updateRoleplayContentField(
          roleplayScenario,
          'phraseBookPhrase4',
          values,
          ContentTypes.roleplayScenario,
          setContentId,
        );

        phraseBookValue.splice(3, 1, ...(Object.values(phraseBookPhrase4) as DBId[]));
      }

      if (isPhraseBookPhrase5Changed) {
        const phraseBookPhrase5 = await RoleplayService.updateRoleplayContentField(
          roleplayScenario,
          'phraseBookPhrase5',
          values,
          ContentTypes.roleplayScenario,
          setContentId,
        );

        phraseBookValue.splice(4, 1, ...(Object.values(phraseBookPhrase5) as DBId[]));
      }

      if (phraseBookValue.length) {
        payloadAccordingChangedFields.push({ phrasebook: phraseBookValue });
      }

      /**
       * Fields without localization
       */
      if (values?.cefrChanged || roleplayScenario.cefrChanged) {
        payloadAccordingChangedFields.push({ cefr: values.cefr });
      }

      if (values?.messageLimitChanged || roleplayScenario.messageLimitChanged) {
        payloadAccordingChangedFields.push({ messageLimit: values.messageLimit });
      }

      if (values?.chatbotScenarioChanged || roleplayScenario.chatbotScenarioChanged) {
        payloadAccordingChangedFields.push({ chatbotScenario: values.chatbotScenario });
      }

      if (values?.chatbotGoalChanged || roleplayScenario.chatbotGoalChanged) {
        payloadAccordingChangedFields.push({ chatbotGoal: values.chatbotGoal });
      }

      if (values?.chatbotRoleChanged || roleplayScenario.chatbotRoleChanged) {
        payloadAccordingChangedFields.push({ chatbotRole: values.chatbotRole });
      }

      if (values?.vocabularyChanged || roleplayScenario.vocabularyChanged) {
        payloadAccordingChangedFields.push({ vocabulary: values.vocabulary });
      }

      if (values?.grammarChanged || roleplayScenario.grammarChanged) {
        payloadAccordingChangedFields.push({ grammar: values.grammar });
      }

      if (values?.sampleDialogueChanged || roleplayScenario.sampleDialogueChanged) {
        payloadAccordingChangedFields.push({ sampleDialogue: values.sampleDialogue });
      }

      if (values?.voiceAccentChanged || roleplayScenario.voiceAccentChanged) {
        payloadAccordingChangedFields.push({ voiceAccent: values.voiceAccent });
      }

      if (values?.voiceGenderChanged || roleplayScenario.voiceGenderChanged) {
        payloadAccordingChangedFields.push({ voiceGender: values.voiceGender });
      }

      const payload: Partial<
        Record<
          RoleplayScenarioFieldWithLocalizationNames &
            RoleplayScenarioStringFieldNames &
            RoleplayScenarioNumberFieldNames,
          string | null
        >
      > = {
        ...payloadAccordingChangedFields.reduce((sum, item) => ({ ...sum, ...item }), {}),
      };

      return payload;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  async saveScenario(scenarioId: DBId, payload: Partial<SaveScenarioPayloadType>) {
    return await apiClient.noErrorsV2.put(`content/roleplay-scenarios/${scenarioId}`, payload);
  },

  async publishScenario(scenarioId: DBId, parentId: DBId) {
    return await apiClient.noErrorsV2.post(`content/roleplay-scenarios/${scenarioId}/publish`, { parentId });
  },

  async unpublishScenario(scenarioId: DBId, parentId: DBId) {
    return await apiClient.noErrorsV2.post(`content/roleplay-scenarios/${scenarioId}/unpublish`, { parentId });
  },

  async getVoiceAccents(languageId: LanguageV2): Promise<AxiosResponse<{ accents: string[] }>> {
    return await apiClient.noErrorsV2.get(`content/roleplays/${languageId}/accents`);
  },

  async attachScenarioToLesson(scenarioId: DBId, lessonId: DBId) {
    return await apiClient.noErrorsV2.post(`content/roleplay-scenarios/${scenarioId}/attach-to-lesson`, { lessonId });
  },

  async updateRoleplayContentField(
    roleplayContent: RoleplayCategoryContentType | RoleplayScenarioContentType,
    fieldName: RoleplayCategoryFieldWithLocalizationNames | RoleplayScenarioFieldWithLocalizationNames,
    values: RoleplayCategoryFormikValues | RoleplayScenarioFormikValues,
    contentType: ContentTypesType,
    setContentId: (
      contentType: ContentTypesType,
      fieldName: RoleplayCategoryFieldWithLocalizationNames | RoleplayScenarioFieldWithLocalizationNames,
      contentId: DBId,
    ) => void,
  ): Promise<
    Partial<
      Record<RoleplayCategoryFieldWithLocalizationNames | RoleplayScenarioFieldWithLocalizationNames, string | null>
    >
  > {
    const contentFieldName = roleplayContent[
      fieldName as keyof (RoleplayCategoryContentType | RoleplayScenarioContentType)
    ] as TranslationsPanelContentInterface;
    const valueFieldName = values[
      fieldName as keyof (RoleplayCategoryFormikValues | RoleplayScenarioFormikValues)
    ] as FormikValueInterface[];

    const textLocalizationsFilledFromFormik = contentFieldName?.textLocalizations.map((loc) => ({
      ...loc,
      value: valueFieldName.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
      phoneticValue: valueFieldName.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
    }));

    const contentPayload = {
      description:
        values[`${fieldName}Context` as keyof (RoleplayCategoryFormikValues | RoleplayScenarioFormikValues)] || '',
      textLocalizations: selectTextLocalizationsForSave(textLocalizationsFilledFromFormik || []),
      audioLocalizations: selectMediaLocalizationsForSave(contentFieldName?.audioLocalizations || []),
      imageLocalizations: [],
      videoLocalizations: [],
    };
    if (
      !contentPayload.textLocalizations.length &&
      !contentPayload.audioLocalizations.length &&
      !contentPayload.description
    ) {
      return { [fieldName]: null };
    }

    let contentId = contentFieldName?.id || contentFieldName?._id || '';

    if (!contentId) {
      try {
        contentId = await ContentsService.contents.createNewContent(contentPayload);

        setContentId(contentType, fieldName, contentId);
      } catch (error) {
        Sentry.captureException(error, (scope) => {
          scope.setTag('logosSection', 'Roleplay');
          scope.setExtras({
            contentType,
            fieldName,
          });

          return scope;
        });
      }
    } else {
      await ContentsService.contents.update(contentId, contentPayload);
    }

    return {
      [fieldName]: contentId,
    };
  },

  async getPayloadForCategoryUpdate(
    roleplayCategory: RoleplayCategoryContentType,
    values: RoleplayCategoryFormikValues,
    setContentId: (
      contentType: ContentTypesType,
      fieldName: RoleplayCategoryFieldWithLocalizationNames | RoleplayScenarioFieldWithLocalizationNames,
      contentId: DBId,
    ) => void,
  ) {
    try {
      const isTitleChanged =
        (values?.titleChanged || roleplayCategory.titleChanged) && !roleplayCategory.title.isReused;

      const isIconChanged = roleplayCategory.iconChanged;

      const payloadAccordingChangedFields = await Promise.all([
        isTitleChanged
          ? await RoleplayService.updateRoleplayContentField(
              roleplayCategory,
              'title',
              values,
              ContentTypes.roleplayCategory,
              setContentId,
            )
          : { title: roleplayCategory.title.id },
        isIconChanged ? { icon: roleplayCategory.icon } : null,
      ]);

      const payload: Partial<
        | Record<RoleplayCategoryFieldWithLocalizationNames, string | null>
        | Record<RoleplayCategoryChangeableFieldNames, string | null>
      > = {
        ...payloadAccordingChangedFields.reduce((sum, item) => ({ ...sum, ...item }), {}),
      };

      return payload;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  async saveCategory(
    categoryId: DBId,
    payload: Partial<
      | Record<RoleplayCategoryFieldWithLocalizationNames, string | null>
      | Record<RoleplayCategoryChangeableFieldNames, string | null>
    >,
  ) {
    return await apiClient.noErrorsV2.put(`content/roleplay-categories/${categoryId}`, payload);
  },

  async getValidationResult(type: string, id: LanguageV2 | DBId) {
    let slug;

    if (type === ContentTypes.roleplay) {
      slug = 'roleplays';
    } else if (type === ContentTypes.roleplayCategory) {
      slug = 'roleplay-categories';
    } else if (type === ContentTypes.roleplayScenario) {
      slug = 'roleplay-scenarios';
    }

    return await apiClient.noErrorsV2.get(`content/${slug}/${id}/status`);
  },

  async publishCategory(categoryId: DBId, parentId: DBId) {
    return await apiClient.noErrorsV2.post(`content/roleplay-categories/${categoryId}/publish`, { parentId });
  },

  async unpublishCategory(categoryId: DBId, parentId: DBId) {
    return await apiClient.noErrorsV2.post(`content/roleplay-categories/${categoryId}/unpublish`, { parentId });
  },

  async publishRoleplay(language: LanguageV2) {
    return await apiClient.noErrorsV2.post(`content/roleplays/${language}/publish`);
  },

  async unpublishRoleplay(language: LanguageV2) {
    return await apiClient.noErrorsV2.post(`content/roleplays/${language}/unpublish`);
  },
};
