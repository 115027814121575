import { LanguageV2 } from '../languages';

import ARFlag from '@static/svg/flags/AR.svg';
import DEFlag from '@static/svg/flags/DE.svg';
import ENFlag from '@static/svg/flags/EN.svg';
import ESFlag from '@static/svg/flags/ES.svg';
import ES_USFlag from '@static/svg/flags/EN_US.svg';
import FRFlag from '@static/svg/flags/FR.svg';
import IDFlag from '@static/svg/flags/ID.svg';
import ITFlag from '@static/svg/flags/IT.svg';
import JAFlag from '@static/svg/flags/JA.svg';
import NLFlag from '@static/svg/flags/NL.svg';
import KOFlag from '@static/svg/flags/KO.svg';
import PLFlag from '@static/svg/flags/PL.svg';
import PTFlag from '@static/svg/flags/PT.svg';
import RUFlag from '@static/svg/flags/RU.svg';
import TRFlag from '@static/svg/flags/TR.svg';
import VIFlag from '@static/svg/flags/VI.svg';
import ZHFlag from '@static/svg/flags/ZH.svg';

export const LANGUAGE_FLAGS: { [key in LanguageV2]: string } = {
  ar: ARFlag,
  de: DEFlag,
  en: ENFlag,
  'en-US': ES_USFlag,
  es: ESFlag,
  fr: FRFlag,
  id: IDFlag,
  it: ITFlag,
  ko: KOFlag,
  nl: NLFlag,
  ja: JAFlag,
  pl: PLFlag,
  pt: PTFlag,
  ru: RUFlag,
  tr: TRFlag,
  vi: VIFlag,
  zh: ZHFlag,
};
