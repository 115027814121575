import { useState } from 'react';

import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { IssuesToggler } from '@components/IssuesToggler';
import { UploadTranslations } from '@components/UploadTranslations';
import { useShowDebugUI } from '@features/app/debug';
import { useToast } from '@features/app/toast';
import { useIsUploadTranslationsAvailable } from '@features/auth/roles';
import {
  ButtonsList,
  ContentData,
  HeaderItemSeparator,
  HeaderWrapper,
  PendingChangesButton,
  SaveButton,
} from '@features/content/header';
import { ValidationButton } from '@features/content/forceValidation';
import { ListPreviewModal } from '@features/content/preview';
import type { LanguageV2 } from '@features/content/languages';
import ContentsService from '@services/ContentsService';

import { PublishButton } from './PublishButton';
import { useRoleplay } from '../useRoleplay';
/** @TODO Use shared @features/content/translations/UploadDownloadTranslationsMenu */
import { UploadDownloadTranslationsMenu } from './UploadDownloadTranslationsMenu';

type HeaderProps = {
  contentId: DBId;
  contentName: string;
  learningLanguage: LanguageV2;
};

export const Header = ({ contentId, contentName, learningLanguage }: HeaderProps) => {
  const isUploadTranslationsAvailable = useIsUploadTranslationsAvailable();
  const showDebugUI = useShowDebugUI();
  const showToast = useToast();

  const showDownloadStartedToast = () => {
    showToast({
      type: 'info',
      title: 'Download should have started automatically',
    });
  };

  const { childrenArray, interfaceLanguages, isRoleplayLoaded, roleplayContent, roleplayType, onSave } = useRoleplay();

  const isDataForTopicPreviewReady = isRoleplayLoaded && childrenArray.length > 0;
  const shouldShowTranslationsUpload = isUploadTranslationsAvailable && roleplayType === ContentTypes.roleplayCategory;

  const [showTranslationsUploadModal, setShowTranslationsUploadModal] = useState(false);

  return (
    <>
      <HeaderWrapper>
        {isDataForTopicPreviewReady && <ListPreviewModal />}
        <ContentData
          contentName={contentName}
          learningLanguage={learningLanguage}
          interfaceLanguages={interfaceLanguages}
        />
        <ButtonsList>
          {shouldShowTranslationsUpload && (
            <UploadDownloadTranslationsMenu
              isUploadTranslationsAvailable={isUploadTranslationsAvailable}
              onDownload={() => {
                ContentsService.csv.download(
                  contentId,
                  learningLanguage,
                  interfaceLanguages,
                  contentName,
                  'roleplayCategory',
                );

                showDownloadStartedToast();
              }}
              onUpload={() => setShowTranslationsUploadModal(true)}
            />
          )}
          {/**
            * // @TODO INTO-3464 Scenario Preview
          (roleplayType === ContentTypes.roleplayScenario ||
            (roleplayType === ContentTypes.roleplayCategory && isDataForTopicPreviewReady)) && (
            <>
              <PreviewButton
                onPreviewClick={() => {
                  if (roleplayType === ContentTypes.roleplayScenario) {
                    dispatch(PreviewModalActionsCreator.openPreview());
                  } else if (roleplayType === ContentTypes.roleplayCategory) {
                    dispatch(PreviewModalActionsCreator.openPreviewForExercisesList());
                  }
                }}
              />
              <HeaderItemSeparator />
            </>
          )*/}
          {isRoleplayLoaded === LoadingStage.loaded &&
            (showDebugUI && learningLanguage ? (
              <ValidationButton id={learningLanguage} type={roleplayType} />
            ) : (
              <IssuesToggler
                validation={roleplayContent.validationStatus}
                type={roleplayType}
                hasChildrenArrayInvalidItems={
                  !!childrenArray.filter((children) => !children.validationStatus?.valid).length
                }
              />
            ))}
          <HeaderItemSeparator />
          <PendingChangesButton />
          <SaveButton onSave={onSave} />
          <PublishButton contentToPublish={roleplayContent} />
        </ButtonsList>
      </HeaderWrapper>
      {shouldShowTranslationsUpload && (
        <UploadTranslations
          contentType={roleplayType}
          show={showTranslationsUploadModal}
          onHide={() => setShowTranslationsUploadModal(false)}
        />
      )}
    </>
  );
};
