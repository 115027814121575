import { arrayMoveImmutable } from 'array-move';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DraggableList } from '@components/DraggableList/DraggableList';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { AccessWarning } from '@components/Warning';
import type { LanguageV2 } from '@features/content/languages';
import { useIsEditAvailable } from '@features/content/courses';
import { HelpDisplayer } from '@features/help';
import { Loader } from '@features/theme';
import { clone } from '@helpers/clone';
import { useAppDispatch } from '@redux/store';
import helpersService from '@services/HelpersService';

import { RoleplayType } from '../types';

type RoleplayDataPropsType = {
  contentName: string;
  languageId: LanguageV2;
  roleplay: RoleplayType;
};

export const RoleplayData = ({ contentName, languageId, roleplay }: RoleplayDataPropsType) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  const { content, categoriesData } = roleplay;

  const childrenValidationErrors =
    content?.validationStatus?.errors.filter((error) => error.field === 'roleplayCategorys') || [];

  if (roleplay.loaded === LoadingStage.loading) {
    return <Loader size="L" />;
  }

  return (
    <div className="course-composer">
      <div className="course-edition__first-top-element">
        <div className="course-edition__publish-status-and-other-places-container">
          <PublishingStatus
            ready={content ? content.ready : false}
            changeStatus={
              content
                ? content.changeStatus
                : {
                    hasNewChanges: false,
                    hasPendingChanges: false,
                  }
            }
          />
        </div>
        <div className="content-common-header__activity-details">
          <IDDisplayer id={content?.id} mode="normal" />
          <div className="content-common-header__content-title content-common-header__content-title--fixed-height">
            <span>{contentName}</span>
            <HelpDisplayer id="how-to-roleplay" type="how-to" />
          </div>
        </div>
        {!isEditAvailable && <AccessWarning />}
      </div>

      {categoriesData.loaded !== LoadingStage.loaded ? (
        <Loader size="L" />
      ) : (
        <DraggableList
          arrayOfChildren={categoriesData.categories}
          onDragEnd={(result) => {
            result.destination &&
              dispatch(
                RoleplayActionCreators.setRoleplayCategoriesOrder(
                  languageId,
                  result.destination.index,
                  arrayMoveImmutable(clone(categoriesData.categories), result.source.index, result.destination.index),
                ),
              );
          }}
          onContentCreate={(contentTypeToCreate, contentCategory, position) => {
            dispatch(RoleplayActionCreators.createRoleplayCategory(languageId, position));
          }}
          creatorAcceptableButtons={{
            [ContentTypes.roleplayCategory]: true,
          }}
        />
      )}
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(childrenValidationErrors)} />
    </div>
  );
};
