import styled from 'styled-components/macro';

import { SearchBox } from '@components/Search';

export const LexicalItemSearchHeader = styled.div`
  padding: 4rem 4rem 2.4rem 4rem;
`;

export const LexicalItemSearchTitle = styled.h2`
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.space.L};
`;

export const SearchBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledSearchBox = styled(SearchBox)`
  margin-right: 0.8rem;
  flex-grow: 1;
`;
