import { AxiosResponse } from 'axios';

import { ContentTypes } from '@common/enums/ContentTypes';
import { MediaTypes } from '@common/enums/MediaTypes';
import { MediaUploadImagesCategories } from '@common/enums/MediaUpload';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { CourseImageBranches } from '@common/types/AnyExerciseContentBranch';
import { DBId } from '@common/types/DBId';
import type { LanguageV2 } from '@features/content/languages';
import { apiClient } from '@features/api';

import MediaService from './MediaService';

const CoursesService = {
  async uploadThumbnailImage(
    thumbnailImage: File | null,
    loadedCourse: CourseInterface,
    progressHandler: (progress: number) => void,
  ) {
    let result = await MediaService.uploadMedia(
      MediaTypes.image,
      thumbnailImage,
      MediaUploadImagesCategories.course_thumbnail,
      undefined,
      loadedCourse.thumbnailImage?._id,
      progressHandler,
    );

    return result;
  },
  async uploadPaywallImage(
    paywallImage: File | null,
    loadedCourse: CourseInterface,
    progressHandler: (progress: number) => void,
  ) {
    let result = await MediaService.uploadMedia(
      MediaTypes.image,
      paywallImage,
      MediaUploadImagesCategories.course_paywall,
      undefined,
      loadedCourse.paywallImage?._id,
      progressHandler,
    );

    return result;
  },
  async getCoursesInfo() {
    return apiClient.v2.get(`/content/courses`).then((data) => data.data.courses as Record<string, any>);
  },
  async getValidationResult(type: string, contentId: DBId | undefined, courseId: DBId) {
    return apiClient.v2
      .get(`/content/${type === ContentTypes.activity ? 'activitie' : type}s/${contentId}/status?courseId=${courseId}`)
      .then((data) => data);
  },
  async updateCourse(
    courseId: DBId | undefined,
    payload: Partial<Record<'title' | 'description' | CourseImageBranches, DBId | null>>,
  ) {
    return apiClient.v2.put(`content/courses/${courseId}`, payload);
  },
  async get(courseId: DBId) {
    return apiClient.v1.get(`/courses/${courseId}`).then((data) => data.data as Record<string, any>);
  },
  updateUILanguages(updatedCoursePayload: {
    id: DBId;
    name: string;
    learningLanguage: LanguageV2;
    interfaceLanguage: LanguageV2[];
  }) {
    return apiClient.v2.put(`content/courses/${updatedCoursePayload.id}`, updatedCoursePayload);
  },
  async create(coursePayload: any) {
    return apiClient.v2.post(`content/courses`, coursePayload).then((data: AxiosResponse) => {
      if (data.data.id) {
        return CoursesService.get(data.data.id);
      }
    });
  },
};

export default CoursesService;
