import { MouseEvent, useEffect, useState } from 'react';
import Slider from 'rc-slider';
import { useTheme } from 'styled-components/macro';

import { ReactComponent as ClearIndicator } from './img/clearIndicator.svg';
import {
  ClearIndicatorWrapper,
  RangeInput,
  RangeInputsWrapper,
  StyledDropdown,
  StyledDropdownMenu,
  StyledDropdownToogle,
  getSliderStyles,
} from './RangeSelector.styles';

import 'rc-slider/assets/index.css';

const DEFAULT_RANGE_LIMIT = {
  MAX: 5,
  MIN: 0,
};

const DEFAULT_SLIDER_VALUE = [0, 0];

export type RangeSelectorValue = {
  max: number;
  min: number;
};

type RangeSelectorProps = {
  maxLimit?: number;
  minLimit?: number;
  rule: string;
  value: RangeSelectorValue | null;
  onChange: (value: RangeSelectorValue) => void;
};

export const RangeSelector = ({
  maxLimit = DEFAULT_RANGE_LIMIT.MAX,
  minLimit = DEFAULT_RANGE_LIMIT.MIN,
  rule,
  value,
  onChange,
}: RangeSelectorProps) => {
  const [availableMaxRange, setAvailableMaxRange] = useState([minLimit + 1, maxLimit]);
  const [availableMinRange, setAvailableMinRange] = useState([minLimit, maxLimit - 1]);
  const [selectedMaxValue, setSelectedMaxValue] = useState(value ? value.max.toString() : '');
  const [selectedMinValue, setSelectedMinValue] = useState(value ? value.min.toString() : '');
  const [sliderValue, setSliderValue] = useState(value ? [value.min, value.max] : DEFAULT_SLIDER_VALUE);
  const [valueContainerText, setValueContainerText] = useState(rule);

  const theme = useTheme();

  const sliderStyles = getSliderStyles(theme);

  const clearValue = (evt: MouseEvent) => {
    evt.stopPropagation();

    setSelectedMaxValue('');
    setSelectedMinValue('');
    setSliderValue(DEFAULT_SLIDER_VALUE);
    onChange({ max: 0, min: 0 });
  };

  const handleOnChange = (value: number | number[], triggerId?: string) => {
    const [minValue, maxValue] = value as number[];

    setSelectedMaxValue(maxValue > 0 ? maxValue.toString() : '');
    setSelectedMinValue(minValue > 0 ? minValue.toString() : '');
    setAvailableMaxRange(() => [minValue > 0 && minValue < maxLimit ? minValue + 1 : minLimit, maxLimit]);
    setAvailableMinRange(() => [minLimit, maxValue > 0 ? maxValue - 1 : maxLimit]);

    if (triggerId && ['maxValue', 'minValue'].includes(triggerId)) {
      setSliderValue(minValue > 0 && maxValue > 0 ? (value as number[]) : DEFAULT_SLIDER_VALUE);
    } else {
      setSliderValue(value as number[]);
    }

    onChange({ max: maxValue, min: minValue });
  };

  const validate = (input: HTMLInputElement) => {
    if (input.value.length > maxLimit.toString().length || Number(input.value) > maxLimit) {
      if (input.id === 'minValue') {
        setSelectedMinValue(maxLimit.toString());
      } else {
        setSelectedMaxValue(maxLimit.toString());
      }

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (value === null) {
      setSelectedMaxValue('');
      setSelectedMinValue('');
      setSliderValue(DEFAULT_SLIDER_VALUE);
    }
  }, [onChange, value]);

  useEffect(() => {
    let newValueContainerText = rule;

    if (selectedMaxValue && selectedMinValue) {
      newValueContainerText += `: ${selectedMinValue} - ${selectedMaxValue}`;
    } else if (selectedMaxValue) {
      newValueContainerText += `: Max ${selectedMaxValue}`;
    } else if (selectedMinValue) {
      newValueContainerText += `: Min ${selectedMinValue}`;
    }

    setValueContainerText(newValueContainerText);
  }, [rule, selectedMaxValue, selectedMinValue]);

  return (
    <StyledDropdown>
      <StyledDropdownToogle>
        {valueContainerText}
        {(selectedMaxValue || selectedMinValue) && (
          <ClearIndicatorWrapper role="button" onClick={clearValue}>
            <ClearIndicator />
          </ClearIndicatorWrapper>
        )}
      </StyledDropdownToogle>
      <StyledDropdownMenu>
        <p>Enter a minimum, maximum or range limit</p>
        <RangeInputsWrapper>
          <RangeInput
            id="minValue"
            type="number"
            min={availableMinRange[0]}
            max={availableMinRange[1]}
            value={selectedMinValue}
            onChange={({ target }) =>
              validate(target) && handleOnChange([Number(target.value), Number(selectedMaxValue)], target.id)
            }
          />
          <RangeInput
            id="maxValue"
            type="number"
            min={availableMaxRange[0]}
            max={availableMaxRange[1]}
            value={selectedMaxValue}
            onChange={({ target }) =>
              validate(target) && handleOnChange([Number(selectedMinValue), Number(target.value)], target.id)
            }
          />
        </RangeInputsWrapper>
        <Slider
          allowCross={false}
          handleStyle={sliderStyles.handleStyle}
          min={minLimit}
          max={maxLimit}
          pushable={1}
          range
          railStyle={sliderStyles.railStyle}
          trackStyle={sliderStyles.trackStyle}
          value={sliderValue}
          onChange={handleOnChange}
        />
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

type SimpleRangeSelectorValue = {
  min: number | null;
  max: number | null;
};

type SimpleRangeSelectorProps = Omit<RangeSelectorProps, 'value' | 'onChange'> & {
  value: SimpleRangeSelectorValue | null;
  onChange: (value: SimpleRangeSelectorValue | null) => void;
};

const rangeValueIsEmpty = ({ max, min }: SimpleRangeSelectorValue) =>
  (max === 0 || max === null) && (min === 0 || min === null);

export const SimpleRangeSelector = ({ value, onChange, ...restProps }: SimpleRangeSelectorProps) => {
  const fullValue = value
    ? {
        min: value.min || 0,
        max: value.max || 0,
      }
    : null;

  return (
    <RangeSelector
      value={fullValue}
      onChange={(fullValue) =>
        onChange(
          !rangeValueIsEmpty(fullValue)
            ? {
                min: fullValue.min === 0 ? null : fullValue.min,
                max: fullValue.max === 0 ? null : fullValue.max,
              }
            : null,
        )
      }
      {...restProps}
    />
  );
};
