import { apiClient } from '@features/api';
import type { LanguageV2 } from '@features/content/languages';

import { GenderType } from '../types';

type SendCreateVoiceArtistParams = {
  accent: string;
  gender: GenderType;
  languages: LanguageV2[];
  name: string;
};

export const sendCreateVoiceArtist = async ({ accent, gender, languages, name }: SendCreateVoiceArtistParams) => {
  return await apiClient.noErrorsV2.post('/voice-artists', {
    accent,
    gender,
    languages,
    name,
  });
};
