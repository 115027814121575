import styled from 'styled-components';
import { useState } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { LoadedMatchUpExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import helpersService from '@services/HelpersService';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { PreviewModal } from '@features/content/preview';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { useIsEditAvailable } from '@features/content/courses';
import { AccessWarning } from '@components/Warning';
import { constants as contentConstants } from '@features/content';
import { getResources } from '@helpers/getResourcesHelper';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { Flag } from '@components/Flag';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { WritableInputText } from '@components/WritableInputText';
import { Explanation, Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { courseSlice } from '@redux/slices/courseSlice';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { ExperimentSelector } from '@features/experiment';
import { Instructions, LexicalItemsMatched, removeMediaProcessingValidationError } from '@features/content/exercises';
import { DEFAULT_LANGUAGE_V2, LANGUAGE_NAMES_V2 } from '@features/content/languages';

import { MatchingItemsLanguage, type MatchingItemsLanguageType } from './enums/MatchingItemsLanguageType';
import arrowIcon from './img/arrow.svg';
import worldwideIcon from './img/worldwide.svg';
import MatchUpExerciseInterface from './interfaces/MatchUpExerciseInterface';
import { MatchUpExerciseActionsCreator } from '@actionCreators/MatchUpExerciseActionsCreator';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';
import { DBId } from '@common/types/DBId';
import { isFeatureEnabled } from '@helpers/featuresHelper';

const MatchUpExercisePairContainer = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 0.8rem) calc(50% - 0.8rem);
  grid-gap: 1.6rem;
  min-width: 90rem;
`;

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

const getAvailableAudioLocalizations = (
  audioLocalizations: LocalizationInterface[] = [],
  availableLanguages: string[],
) => audioLocalizations.filter((audioLocalization) => availableLanguages.includes(audioLocalization?.language));

const MatchUpExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedMatchUpExerciseInterface>) => {
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as MatchUpExerciseInterface;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));

  const fixedItem1AudioLocalizations = getAvailableAudioLocalizations(
    loadedExercise?.content?.fixedItem1?.phrase.audioLocalizations,
    availableLanguagesForAudioLocalizations,
  );
  const fixedItem2AudioLocalizations = getAvailableAudioLocalizations(
    loadedExercise?.content?.fixedItem2?.phrase.audioLocalizations,
    availableLanguagesForAudioLocalizations,
  );
  const fixedItem3AudioLocalizations = getAvailableAudioLocalizations(
    loadedExercise?.content?.fixedItem3?.phrase.audioLocalizations,
    availableLanguagesForAudioLocalizations,
  );
  const matchingItem1AudioLocalizations = getAvailableAudioLocalizations(
    loadedExercise?.content?.matchingItem1?.phrase.audioLocalizations,
    availableLanguagesForAudioLocalizations,
  );
  const matchingItem2AudioLocalizations = getAvailableAudioLocalizations(
    loadedExercise?.content?.matchingItem2?.phrase.audioLocalizations,
    availableLanguagesForAudioLocalizations,
  );
  const matchingItem3AudioLocalizations = getAvailableAudioLocalizations(
    loadedExercise?.content?.matchingItem3?.phrase.audioLocalizations,
    availableLanguagesForAudioLocalizations,
  );

  const [matchUpOption, setMatchUpOption] = useState<MatchingItemsLanguageType | undefined>(
    loadedExercise?.content?.matchingItemsLanguage,
  );

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const pairsErrors = errors.filter((error: ValidationErrorInterface) => error.field.includes('pairs'));

  if (pairsErrors.length) {
    let updatedErrors = [...errors];

    [fixedItem1AudioLocalizations, fixedItem2AudioLocalizations, fixedItem3AudioLocalizations].forEach(
      (fixedItemAudioLocalization, pairIndex) => {
        if (fixedItemAudioLocalization) {
          fixedItemAudioLocalization.forEach(({ language, processed, value }: LocalizationInterface) => {
            if (processed && value) {
              updatedErrors = removeMediaProcessingValidationError({
                errors,
                fieldName: `pairs[${pairIndex}].fixedItem`,
                message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
              });
            }
          });
        }
      },
    );

    [matchingItem1AudioLocalizations, matchingItem2AudioLocalizations, matchingItem3AudioLocalizations].forEach(
      (matchingItemAudioLocalization, pairIndex) => {
        if (matchingItemAudioLocalization) {
          matchingItemAudioLocalization.forEach(({ language, processed, value }: LocalizationInterface) => {
            if (processed && value) {
              updatedErrors = removeMediaProcessingValidationError({
                errors,
                fieldName: `pairs[${pairIndex}].matchingItem`,
                message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
              });
            }
          });
        }
      },
    );

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  return (
    <div className="exercise-matchup">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Matchup</Title>
        <HelpDisplayer type="guideline" id="guideline-matchup-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-matchup__main-wrapper">
        <div className="exercise-matchup__wrapper">
          <div className="exercise-matchup__container">
            <h1 className="exercise-matchup__box-title">{'Instruction'}</h1>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </div>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(MatchUpExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
            }}
          />
        </div>
      </div>

      {isFeatureEnabled('vocabularyReview') && (
        <div className="exercise-matchup__wrapper">
          <LexicalItemsMatched
            exerciseId={loadedExercise.content.id as DBId}
            language={courseLearningLanguage}
            values={loadedExercise.content.lexicalItems ?? []}
          />
        </div>
      )}

      <div className="exercise-matchup__wrapper">
        {(matchUpOption === null || matchUpOption === undefined) && (
          <ul className={`multi-option-chooser multi-option-chooser--flags`}>
            <li className="multi-option-chooser__option">
              <div
                className="multi-option-chooser__option-inner"
                onClick={() => {
                  if (isEditAvailable) {
                    dispatch(MatchUpExerciseActionsCreator.setMatchUpOption(MatchingItemsLanguage.interface));
                    setMatchUpOption(MatchingItemsLanguage.interface);
                  }
                }}
              >
                <div className="multi-option-chooser__flags-wrapper">
                  <ul className="multi-option-chooser__flags">
                    <li className="multi-option-chooser__flag">
                      <Flag countries={courseLearningLanguage} showCountryName={false} tooltipEnabled={false} />
                    </li>
                    <li className="multi-option-chooser__arrow">
                      <img src={arrowIcon} alt="" className="flag__arrow" />
                    </li>
                    <li className="multi-option-chooser__flag">
                      <img src={worldwideIcon} alt="" className="flag__country-flag" />
                    </li>
                  </ul>

                  <span>{LANGUAGE_NAMES_V2[courseLearningLanguage]} to Interface language</span>
                </div>
              </div>
            </li>
            <li className="multi-option-chooser__option">
              <div
                className="multi-option-chooser__option-inner"
                onClick={() => {
                  if (isEditAvailable) {
                    dispatch(MatchUpExerciseActionsCreator.setMatchUpOption(MatchingItemsLanguage.learning));
                    setMatchUpOption(MatchingItemsLanguage.learning);
                  }
                }}
              >
                <div className="multi-option-chooser__flags-wrapper">
                  <ul className="multi-option-chooser__flags">
                    <li className="multi-option-chooser__flag">
                      <Flag countries={courseLearningLanguage} showCountryName={false} tooltipEnabled={false} />
                    </li>
                    <li className="multi-option-chooser__arrow">
                      <img src={arrowIcon} alt="" className="flag__arrow" />
                    </li>
                    <li className="multi-option-chooser__flag">
                      <Flag countries={courseLearningLanguage} showCountryName={false} tooltipEnabled={false} />
                    </li>
                  </ul>

                  <span>
                    {LANGUAGE_NAMES_V2[courseLearningLanguage]} to {LANGUAGE_NAMES_V2[courseLearningLanguage]}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        )}
      </div>

      {matchUpOption === MatchingItemsLanguage.interface && (
        <ContentToggler
          open={matchUpOption === MatchingItemsLanguage.interface}
          text={`${LANGUAGE_NAMES_V2[courseLearningLanguage]} to Interface language`}
          onRemove={() => {
            setMatchUpOption(undefined);
          }}
          errors={pairsErrors.filter((error: ValidationErrorInterface) => error.field === 'pairs')}
        >
          <div className="exercise-matchup__wrapper">
            <div className="exercise-matchup__wrapper">
              <h3 className="exercise-matchup__box-title">Pair 1</h3>

              <MatchUpExercisePairContainer>
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.fixedItem1}
                  bundleName={'fixedItem1'}
                  withoutVocabularyBox
                  resources={getResources(loadedExercise, courseLearningLanguage, 'fixedItem1', courseLearningLanguage)}
                >
                  <TranslationsTipV2
                    compact
                    visitedBranch={'phrase'}
                    bundleName={'fixedItem1'}
                    flipped={false}
                    errors={pairsErrors.filter(
                      (error: ValidationErrorInterface) => error.field === 'pairs[0].fixedItem',
                    )}
                  >
                    <WritableInputText id="matchup-fixedItem1-input" />
                  </TranslationsTipV2>
                </ResourceBundleWrapper>
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.matchingItem1}
                  bundleName={'matchingItem1'}
                  withoutVocabularyBox
                  resources={getResources(loadedExercise, courseLearningLanguage, 'matchingItem1', DEFAULT_LANGUAGE_V2)}
                >
                  <TranslationsTipV2
                    compact
                    visitedBranch={'phrase'}
                    bundleName={'matchingItem1'}
                    flipped={true}
                    errors={pairsErrors.filter(
                      (error: ValidationErrorInterface) => error.field === 'pairs[0].matchingItem',
                    )}
                  >
                    <WritableInputText id="matchup-matchingItem1-input" />
                  </TranslationsTipV2>
                </ResourceBundleWrapper>
              </MatchUpExercisePairContainer>
            </div>

            <div className="exercise-matchup__wrapper">
              <h3 className="exercise-matchup__box-title">Pair 2</h3>
              <MatchUpExercisePairContainer>
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.fixedItem2}
                  bundleName={'fixedItem2'}
                  withoutVocabularyBox
                  resources={getResources(loadedExercise, courseLearningLanguage, 'fixedItem2', courseLearningLanguage)}
                >
                  <TranslationsTipV2
                    compact
                    visitedBranch={'phrase'}
                    bundleName={'fixedItem2'}
                    flipped={false}
                    errors={pairsErrors.filter(
                      (error: ValidationErrorInterface) => error.field === 'pairs[1].fixedItem',
                    )}
                  >
                    <WritableInputText id="matchup-fixedItem2-input" />
                  </TranslationsTipV2>
                </ResourceBundleWrapper>
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.matchingItem2}
                  bundleName={'matchingItem2'}
                  withoutVocabularyBox
                  resources={getResources(loadedExercise, courseLearningLanguage, 'matchingItem2', DEFAULT_LANGUAGE_V2)}
                >
                  <TranslationsTipV2
                    compact
                    visitedBranch={'phrase'}
                    bundleName={'matchingItem2'}
                    errors={pairsErrors.filter(
                      (error: ValidationErrorInterface) => error.field === 'pairs[1].matchingItem',
                    )}
                    flipped={true}
                  >
                    <WritableInputText id="matchup-matchingItem2-input" />
                  </TranslationsTipV2>
                </ResourceBundleWrapper>
              </MatchUpExercisePairContainer>
            </div>

            <ContentToggler
              text={'Pair 3'}
              open={
                !!loadedExercise?.content?.fixedItem3?.phrase?.textLocalizations.filter((loc) => loc.value).length ||
                !!loadedExercise?.content?.matchingItem3?.phrase?.textLocalizations.filter((loc) => loc.value).length
              }
              onSwitch={(opened) => {
                if (opened) {
                  dispatch(courseSlice.actions.addThirdPairToMatchup());
                  setFieldValue(
                    `fixedItem3_phrase`,
                    values.fixedItem3_phrase?.map((item: any) => ({ ...item, value: '' })),
                  );
                  setFieldValue(`fixedItem3_phraseChanged`, true);
                  setFieldValue(
                    `matchingItem3_phrase`,
                    values.matchingItem3_phrase?.map((item: any) => ({ ...item, value: '' })),
                  );
                  setFieldValue(`matchingItem3_phraseChanged`, true);
                }
              }}
              onRemove={() => {
                dispatch(MatchUpExerciseActionsCreator.removeThirdFixedItem());
                dispatch(MatchUpExerciseActionsCreator.removeThirdMatchingItem());
                setFieldValue(
                  `fixedItem3_phrase`,
                  values.fixedItem3_phrase?.map((item: any) => ({ ...item, value: '' })),
                );
                setFieldValue(`fixedItem3_phraseChanged`, true);
                setFieldValue(
                  `matchingItem3_phrase`,
                  values.matchingItem3_phrase?.map((item: any) => ({ ...item, value: '' })),
                );
                setFieldValue(`matchingItem3_phraseChanged`, true);
              }}
            >
              <div className="exercise-matchup__wrapper">
                <MatchUpExercisePairContainer>
                  <ResourceBundleWrapper
                    bundle={loadedExercise.content.fixedItem3}
                    bundleName={'fixedItem3'}
                    withoutVocabularyBox
                    resources={getResources(
                      loadedExercise,
                      courseLearningLanguage,
                      'fixedItem3',
                      courseLearningLanguage,
                    )}
                  >
                    <TranslationsTipV2
                      compact
                      visitedBranch={'phrase'}
                      bundleName={'fixedItem3'}
                      flipped={false}
                      errors={pairsErrors.filter(
                        (error: ValidationErrorInterface) => error.field === 'pairs[2].fixedItem',
                      )}
                    >
                      <WritableInputText id="matchup-fixedItem2-input" />
                    </TranslationsTipV2>
                  </ResourceBundleWrapper>
                  <ResourceBundleWrapper
                    bundle={loadedExercise.content.matchingItem3}
                    bundleName={'matchingItem3'}
                    withoutVocabularyBox
                    resources={getResources(
                      loadedExercise,
                      courseLearningLanguage,
                      'matchingItem3',
                      DEFAULT_LANGUAGE_V2,
                    )}
                  >
                    <TranslationsTipV2
                      compact
                      visitedBranch={'phrase'}
                      bundleName={'matchingItem3'}
                      flipped={true}
                      errors={pairsErrors.filter(
                        (error: ValidationErrorInterface) => error.field === 'pairs[2].matchingItem',
                      )}
                    >
                      <WritableInputText id="matchup-matchingItem3-input" />
                    </TranslationsTipV2>
                  </ResourceBundleWrapper>
                </MatchUpExercisePairContainer>
              </div>
            </ContentToggler>
          </div>
        </ContentToggler>
      )}

      {matchUpOption === MatchingItemsLanguage.learning && (
        <ContentToggler
          open={matchUpOption === MatchingItemsLanguage.learning}
          text={`${LANGUAGE_NAMES_V2[courseLearningLanguage]} to ${LANGUAGE_NAMES_V2[courseLearningLanguage]}`}
          onRemove={() => {
            setMatchUpOption(undefined);
          }}
          errors={pairsErrors.filter((error: ValidationErrorInterface) => error.field === 'pairs')}
        >
          <div className="exercise-matchup__wrapper">
            <div className="exercise-matchup__wrapper">
              <h3 className="exercise-matchup__box-title">Pair 1</h3>
              <MatchUpExercisePairContainer>
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.fixedItem1}
                  bundleName={'fixedItem1'}
                  withoutVocabularyBox
                  resources={getResources(loadedExercise, courseLearningLanguage, 'fixedItem1', courseLearningLanguage)}
                >
                  <TranslationsTipV2
                    compact
                    visitedBranch={'phrase'}
                    bundleName={'fixedItem1'}
                    flipped={false}
                    errors={pairsErrors.filter(
                      (error: ValidationErrorInterface) => error.field === 'pairs[0].fixedItem',
                    )}
                  >
                    <WritableInputText id="matchup-fixedItem1-input" />
                  </TranslationsTipV2>
                </ResourceBundleWrapper>
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.matchingItem1}
                  bundleName={'matchingItem1'}
                  withoutVocabularyBox
                  resources={getResources(
                    loadedExercise,
                    courseLearningLanguage,
                    'matchingItem1',
                    courseLearningLanguage,
                  )}
                >
                  <TranslationsTipV2
                    compact
                    visitedBranch={'phrase'}
                    bundleName={'matchingItem1'}
                    flipped={false}
                    errors={pairsErrors.filter(
                      (error: ValidationErrorInterface) => error.field === 'pairs[0].matchingItem',
                    )}
                  >
                    <WritableInputText id="matchup-matchingItem1-input" />
                  </TranslationsTipV2>
                </ResourceBundleWrapper>
              </MatchUpExercisePairContainer>
            </div>

            <div className="exercise-matchup__wrapper">
              <h3 className="exercise-matchup__box-title">Pair 2</h3>
              <MatchUpExercisePairContainer>
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.fixedItem2}
                  bundleName={'fixedItem2'}
                  withoutVocabularyBox
                  resources={getResources(loadedExercise, courseLearningLanguage, 'fixedItem2', courseLearningLanguage)}
                >
                  <TranslationsTipV2
                    compact
                    visitedBranch={'phrase'}
                    bundleName={'fixedItem2'}
                    flipped={false}
                    errors={pairsErrors.filter(
                      (error: ValidationErrorInterface) => error.field === 'pairs[1].fixedItem',
                    )}
                  >
                    <WritableInputText id="matchup-fixedItem2-input" />
                  </TranslationsTipV2>
                </ResourceBundleWrapper>
                <ResourceBundleWrapper
                  bundle={loadedExercise.content.matchingItem2}
                  bundleName={'matchingItem2'}
                  withoutVocabularyBox
                  resources={getResources(
                    loadedExercise,
                    courseLearningLanguage,
                    'matchingItem2',
                    courseLearningLanguage,
                  )}
                >
                  <TranslationsTipV2
                    compact
                    visitedBranch={'phrase'}
                    bundleName={'matchingItem2'}
                    flipped={false}
                    errors={pairsErrors.filter(
                      (error: ValidationErrorInterface) => error.field === 'pairs[1].matchingItem',
                    )}
                  >
                    <WritableInputText id="matchup-matchingItem2-input" />
                  </TranslationsTipV2>
                </ResourceBundleWrapper>
              </MatchUpExercisePairContainer>
            </div>

            <ContentToggler
              text={'Pair 3'}
              open={
                !!loadedExercise?.content?.fixedItem3?.phrase?.textLocalizations.filter((loc) => loc.value).length ||
                !!loadedExercise?.content?.matchingItem3?.phrase?.textLocalizations.filter((loc) => loc.value).length
              }
              onRemove={() => {
                dispatch(MatchUpExerciseActionsCreator.removeThirdMatchingItem());
                dispatch(MatchUpExerciseActionsCreator.removeThirdFixedItem());
                setFieldValue(
                  `fixedItem3_phrase`,
                  values.fixedItem3_phrase?.map((item: any) => ({ ...item, value: '' })),
                );
                setFieldValue(`fixedItem3_phraseChanged`, true);
                setFieldValue(
                  `matchingItem3_phrase`,
                  values.matchingItem3_phrase?.map((item: any) => ({ ...item, value: '' })),
                );
                setFieldValue(`matchingItem3_phraseChanged`, true);
              }}
            >
              <div className="exercise-matchup__wrapper">
                <MatchUpExercisePairContainer>
                  <ResourceBundleWrapper
                    bundle={loadedExercise.content.fixedItem3}
                    bundleName={'fixedItem3'}
                    withoutVocabularyBox
                    resources={getResources(
                      loadedExercise,
                      courseLearningLanguage,
                      'fixedItem3',
                      courseLearningLanguage,
                    )}
                  >
                    <TranslationsTipV2
                      compact
                      visitedBranch={'phrase'}
                      bundleName={'fixedItem3'}
                      flipped={false}
                      errors={pairsErrors.filter(
                        (error: ValidationErrorInterface) => error.field === 'pairs[2].fixedItem',
                      )}
                    >
                      <WritableInputText id="matchup-fixedItem3-input" />
                    </TranslationsTipV2>
                  </ResourceBundleWrapper>
                  <ResourceBundleWrapper
                    bundle={loadedExercise.content.matchingItem3}
                    bundleName={'matchingItem3'}
                    withoutVocabularyBox
                    resources={getResources(
                      loadedExercise,
                      courseLearningLanguage,
                      'matchingItem3',
                      courseLearningLanguage,
                    )}
                  >
                    <TranslationsTipV2
                      compact
                      visitedBranch={'phrase'}
                      bundleName={'matchingItem3'}
                      flipped={false}
                      errors={pairsErrors.filter(
                        (error: ValidationErrorInterface) => error.field === 'pairs[2].matchingItem',
                      )}
                    >
                      <WritableInputText id="matchup-matchingItem3-input" />
                    </TranslationsTipV2>
                  </ResourceBundleWrapper>
                </MatchUpExercisePairContainer>
              </div>
            </ContentToggler>
          </div>
        </ContentToggler>
      )}

      <div className="exercise-matchup__wrapper">
        <Explanation />
        <RecapSelector exerciseId={loadedExercise?.content?.id} />
      </div>
    </div>
  );
};

export default MatchUpExercise;
