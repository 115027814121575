import {
  AnyExerciseContentBranch,
  ContentBranches,
  CourseContentBranches,
  CourseImageBranches,
} from '@common/types/AnyExerciseContentBranch';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import DialogueExerciseInterface from '@components/Exercises/Dialogue/interfaces/DialogueExerciseInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import TipExerciseInterface from '@components/Exercises/Tip/interfaces/TipExerciseInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { selectTextLocalizationsForSave, selectMediaLocalizationsForSave } from '@helpers/localizationSaveHelper';
import { constants as contentConstants } from '@features/content';
import { clone } from '@helpers/clone';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';

const TranslationsPanelUtils = {
  extractDescription(
    content: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {},
    visitedBranch: AnyExerciseContentBranch | CourseContentBranches,
    exerciseType?: ExerciseTypesType,
    row?: number,
    column?: number,
    bundleName?: string,
  ) {
    let contextForTranslators = bundleName
      ? (content as any)[bundleName][visitedBranch]?.description
      : (content as any)[visitedBranch]?.description;

    if (exerciseType === ExerciseTypes.tip && visitedBranch === 'examples') {
      contextForTranslators = (content as any)[visitedBranch][row as number][column as number].description;
    }

    if (
      exerciseType === ExerciseTypes.dialogue &&
      visitedBranch === contentConstants.DIALOGUE_EXERCISE_SCRIPT_STATE_BRANCH_NAME
    ) {
      contextForTranslators = (content as any)[visitedBranch][row as number].line.description;
    }

    if (
      exerciseType === ExerciseTypes.highlighter &&
      ['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)
    ) {
      contextForTranslators = (content as any).phrases[row as number].description;
    }

    return contextForTranslators;
  },
  createEmptyTranslationsPanelContent() {
    const TranslationsPanelContentInterface: TranslationsPanelContentInterface = {
      contentId: undefined,
      audioLocalizations: [],
      backendIsNullForThisTranslationsPanelContent: false,
      localizationIDForLangExperts: '',
      others: [],
      textLocalizations: [],
      translatorContext: '',
      _id: undefined,
      description: '',
      imageLocalizations: [],
      name: '',
      validated: false,
      videoLocalizations: [],
      id: undefined,
    };

    return TranslationsPanelContentInterface;
  },

  extractTextAndAudioLocalizationsFromExercise(
    loadedExercise: AnyExerciseInterface,
    visitedBranch: AnyExerciseContentBranch,
    row: number | undefined,
    column: number | undefined,
  ): {
    textLocalizations: LocalizationInterface[];
    audioLocalizations: LocalizationInterface[];
    description: string;
  } {
    if (loadedExercise.content.id !== undefined) {
      let textLocalizations: LocalizationInterface[] | undefined = undefined;
      let audioLocalizations: LocalizationInterface[] | undefined = undefined;
      let description: string = '';

      if (
        loadedExercise.type === ExerciseTypes.dialogue &&
        visitedBranch === contentConstants.DIALOGUE_EXERCISE_SCRIPT_STATE_BRANCH_NAME
      ) {
        textLocalizations = (loadedExercise as DialogueExerciseInterface).content.script[row as number].line
          .textLocalizations;

        audioLocalizations = (loadedExercise as DialogueExerciseInterface).content.script[row as number].line
          .audioLocalizations;

        description = (loadedExercise as DialogueExerciseInterface).content.script[row as number].line.description;

        return { textLocalizations, audioLocalizations, description };
      }

      if (loadedExercise.type === ExerciseTypes.tip && visitedBranch === 'examples') {
        textLocalizations = (loadedExercise as TipExerciseInterface).content.examples[row as number][column as number]
          .textLocalizations;

        audioLocalizations = (loadedExercise as TipExerciseInterface).content.examples[row as number][column as number]
          .audioLocalizations;

        description = (loadedExercise as TipExerciseInterface).content.examples[row as number][column as number]
          .description;

        return { textLocalizations, audioLocalizations, description };
      }

      textLocalizations = (loadedExercise.content[visitedBranch] as TranslationsPanelContentInterface)
        .textLocalizations;
      audioLocalizations = (loadedExercise.content[visitedBranch] as TranslationsPanelContentInterface)
        .audioLocalizations;
      description = (loadedExercise.content[visitedBranch] as TranslationsPanelContentInterface).description;

      return { textLocalizations, audioLocalizations, description };
    } else {
      return { textLocalizations: [], audioLocalizations: [], description: '' };
    }
  },
  extractTextAndAudioLocalizationsFromContent(
    content: GenericContentInterface,
    visitedBranch: CourseContentBranches | ContentBranches,
  ): {
    textLocalizations: LocalizationInterface[];
    audioLocalizations: LocalizationInterface[];
    description: string;
  } {
    if (content.id !== undefined) {
      let textLocalizations: LocalizationInterface[] | undefined = undefined;
      let audioLocalizations: LocalizationInterface[] | undefined = undefined;

      textLocalizations = selectTextLocalizationsForSave(
        (content[visitedBranch] as TranslationsPanelContentInterface)?.textLocalizations,
      );
      audioLocalizations = selectMediaLocalizationsForSave(
        (content[visitedBranch] as TranslationsPanelContentInterface)?.audioLocalizations,
      );
      const description = (content[visitedBranch] as TranslationsPanelContentInterface)?.description;

      return { textLocalizations, audioLocalizations, description };
    } else {
      return { textLocalizations: [], audioLocalizations: [], description: '' };
    }
  },
  extractTextImageAndAudioLocalizationsFromCourse(
    content: CourseInterface,
    visitedBranch: CourseContentBranches | CourseImageBranches,
  ): {
    textLocalizations: LocalizationInterface[];
    audioLocalizations: LocalizationInterface[];
    imageLocalizations: LocalizationInterface[];
    description: string;
  } {
    if (content.id !== undefined) {
      let textLocalizations: LocalizationInterface[] | undefined = undefined;
      let audioLocalizations: LocalizationInterface[] | undefined = undefined;
      let imageLocalizations: LocalizationInterface[] | undefined = undefined;

      textLocalizations = (content[visitedBranch] as TranslationsPanelContentInterface)?.textLocalizations;
      audioLocalizations = (content[visitedBranch] as TranslationsPanelContentInterface)?.audioLocalizations;
      imageLocalizations = (content[visitedBranch] as TranslationsPanelContentInterface)?.imageLocalizations;
      const description = (content[visitedBranch] as TranslationsPanelContentInterface)?.description;

      return { textLocalizations, audioLocalizations, imageLocalizations, description };
    } else {
      return {
        textLocalizations: [],
        audioLocalizations: [],
        imageLocalizations: [],
        description: '',
      };
    }
  },
  discoverLocalizationBlock(
    cellsFromReduxStore:
      | TranslationsPanelContentInterface
      | TranslationsPanelContentInterface[]
      | TranslationsPanelContentInterface[][],
    visitedBranch: AnyExerciseContentBranch | CourseContentBranches,
    row: number | undefined,
    column: number | undefined,
    type: 'textLocalizations' | 'audioLocalizations',
    content: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {},
    bundleName?: string,
  ) {
    if (
      cellsFromReduxStore === undefined ||
      !['examples', 'paragraph', 'script', 'phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)
    ) {
      const output = bundleName
        ? (content as any)[bundleName][visitedBranch]?.[type]
        : (content as any)[visitedBranch]?.[type];

      return output;
    } else {
      if (row === undefined && column === undefined) {
        return (cellsFromReduxStore as TranslationsPanelContentInterface)[type];
      } else {
        if (visitedBranch === 'script') {
          let localizationInterface = (cellsFromReduxStore as any)[row as number].line[type];
          localizationInterface = localizationInterface.map((localization: LocalizationInterface) => {
            localization = clone(localization);

            return localization;
          });

          return localizationInterface;
        } else if (row !== undefined && column === undefined) {
          return (cellsFromReduxStore as TranslationsPanelContentInterface[])[row as number][type];
        }

        return (cellsFromReduxStore as TranslationsPanelContentInterface[][])[row as number][column as number][type];
      }
    }
  },
  getAudioLocalizations(
    content: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {},
    exerciseType: ExerciseTypesType,
    visitedBranch: AnyExerciseContentBranch | CourseContentBranches,
    row?: number | undefined,
    column?: number | undefined,
    bundleName?: string,
  ) {
    let extractedAudioLocalizations: LocalizationInterface[] = [];

    const isHighlighterPhrase =
      exerciseType === ExerciseTypes.highlighter &&
      ['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch);
    if (
      (exerciseType === ExerciseTypes.dialogue && visitedBranch === 'script') ||
      (exerciseType === ExerciseTypes.tip && visitedBranch === 'examples') ||
      isHighlighterPhrase
    ) {
      if (exerciseType === ExerciseTypes.dialogue && visitedBranch === 'script') {
        extractedAudioLocalizations = (content as any)['script'][row as number].line.audioLocalizations;
      }
      if (isHighlighterPhrase) {
        extractedAudioLocalizations = (content as any).phrases[row as number].audioLocalizations;
      }

      if (exerciseType === ExerciseTypes.tip && visitedBranch === 'examples') {
        extractedAudioLocalizations = (content as any)['examples'][row as number][column as number].audioLocalizations;
      }
    } else {
      extractedAudioLocalizations = bundleName
        ? (content as any)[bundleName][visitedBranch].audioLocalizations
        : (content as any)[visitedBranch].audioLocalizations;
    }

    return extractedAudioLocalizations;
  },
  getMissingContext(textLocalizations: LocalizationInterface[]) {
    const targetLocalization = textLocalizations?.find((loc) => loc.language === DEFAULT_LANGUAGE_V2);
    const matches = targetLocalization?.value.match(/\b(you[rs]*|we|us|our[s]*|the[y|m]+|their[s]*)\b/gi);

    if (matches) {
      return matches;
    }

    return [];
  },
};

export default TranslationsPanelUtils;
