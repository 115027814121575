import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ActivityCardData } from '@components/ContentTypes/ActivityCard/ActivityCardData';
import { ContentTypes } from '@common/enums/ContentTypes';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { getDescriptionForActivity, getUrlToNavigate } from '@helpers/contentCardHelper';
import { PublishingCardButton } from '@components/ContentTypes/BaseContent/PublishingCardButton';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { RemoveCardButton } from '@components/ContentTypes/BaseContent/RemoveCardButton';
import { CardDragHandler } from '@components/ContentTypes/BaseContent/CardDragHandler';
import { RemoveModal } from '@components/ContentTypes/BaseContent/RemoveModal';
import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { DragAndDropType } from '@components/DraggableList/DragAndDropType';
import type { LanguageV2 } from '@features/content/languages';
import { isLegacyActivity } from '@features/content/activities';

import { CardWrapper, CardDataWrapper, CardControllers, DataWrapper } from '../card.styles';
import { selectLearningLanguage, selectNewContentElementId } from '@selectors/CoursesSelectors';

export const ActivityCard = ({ content, dndProps }: { content: ActivityInterface; dndProps?: DragAndDropType }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [hovered, setHovered] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);

  const courseLearningLanguage = useAppSelector(selectLearningLanguage);

  const { id, type, activityType, changeStatus, oldType, ready } = content;

  const newElementId = useAppSelector(selectNewContentElementId);

  const instructions = getDescriptionForActivity(activityType, isLegacyActivity(content));

  const isLegacyType = useMemo(() => {
    const legacyContentTypes: string[] = ['entity'];
    return activityType && (legacyContentTypes.includes(activityType) || oldType === ContentTypes.review);
  }, [activityType, oldType]);

  const handleRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRemoveModalOpened(true);
  };

  return (
    <CardWrapper id={`activity-${id}`} onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)}>
      <RemoveModal
        opened={isRemoveModalOpened}
        onHide={() => setIsRemoveModalOpened(false)}
        onDelete={() => {
          dispatch(ContentTypesActionsCreator.removeContent(content));
        }}
      />
      <CardDragHandler dndProps={dndProps} visible={hovered} />
      <CardDataWrapper
        to={isLegacyType ? '#' : getUrlToNavigate(params, id, type)}
        $active={dndProps?.snapshot?.isDragging || id === newElementId}
        $hovered={hovered}
      >
        <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
        <DataWrapper>
          <ActivityCardData
            content={content}
            activityType={activityType}
            instructions={instructions}
            language={courseLearningLanguage as LanguageV2}
          />
          <CardControllers>
            <PublishingCardButton hovered={hovered} content={content} />
            <RemoveCardButton hovered={hovered} content={content} onRemove={handleRemove} />
          </CardControllers>
        </DataWrapper>
      </CardDataWrapper>
    </CardWrapper>
  );
};
