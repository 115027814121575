import styled, { css } from 'styled-components/macro';

export const StyledTableRow = styled.div<{ isEditMode?: boolean }>`
  ${({ isEditMode, theme }) => css`
    align-items: center;
    background-color: ${isEditMode ? theme.colorV2.uiBackgroundSecondary : 'transparent'};
    border-bottom: 0.1rem solid ${theme.colorV2.uiBackgroundTertiary};
    color: ${({ theme }) => theme.colorV2.textPrimary};
    display: grid;
    font-size: 1.4rem;
    grid-template-columns: ${isEditMode ? '20% 10% 15% 10% 16% 29%' : '20% 10% 15% 10% 16% 10% 10% 3% 3% 3%'};
    padding: 2rem 0;
  `}

  .table__row--sortable {
    &:hover {
      cursor: pointer;
    }
  }
`;
