const NULLIFY_FIELD = 'COMMONACTIONS_NULLIFY_FIELD';
const SET_TRANSLATOR_CONTEXT = 'COMMONACTIONS_SET_TRANSLATOR_CONTEXT';
const REFRESH_IMAGE = 'COMMONACTIONS_REFRESH_IMAGE';
const SET_IMAGE_ENABLED = 'SET_IMAGE_ENABLED';
const SET_AUDIO_ENABLED = 'SET_AUDIO_ENABLED';
const SET_TEXT_ENABLED = 'SET_TEXT_ENABLED';
const SET_VIDEO_ENABLED = 'SET_VIDEO_ENABLED';
const SET_RECAP_ID = 'SET_RECAP_ID';
const IMAGE_UPLOADING_STARTED = 'IMAGE_UPLOADING_STARTED';
const IMAGE_UPLOADING_FINISHED = 'IMAGE_UPLOADING_FINISHED';
const AUDIO_UPLOADING_STARTED = 'AUDIO_UPLOADING_STARTED';
const AUDIO_UPLOADING_FINISHED = 'AUDIO_UPLOADING_FINISHED';
const SET_IS_SAVE_LOADING = 'SET_IS_SAVE_LOADING';
const VALIDATION_IS_LOADING = 'VALIDATION_IS_LOADING';
const SET_VALIDATION_RESULT = 'SET_VALIDATION_RESULT';
const SET_EXERCISE_NEW_CONTENT_ID = 'SET_EXERCISE_NEW_CONTENT_ID';
const SET_EXERCISE_NEW_LEXICAL_ITEM = 'SET_EXERCISE_NEW_LEXICAL_ITEM';
const SET_COMMON_NEW_CONTENT_ID = 'SET_COMMON_NEW_CONTENT_ID';
const TOGGLE_VOCABULARY = 'TOGGLE_VOCABULARY';
const SET_COURSE_ALL_VALUES = 'SET_COURSE_ALL_VALUES';
const SET_GROUP_ALL_VALUES = 'SET_GROUP_ALL_VALUES';
const SET_VALUE_AFTER_PROCESSING = 'SET_VALUE_AFTER_PROCESSING';
const REMOVE_BUNDLE = 'REMOVE_BUNDLE';
const SET_EXPERIMENT_VALUE = 'SET_EXPERIMENT_VALUE';
const SET_AUDIO_VALUE_AFTER_REQUEST_OR_CANCEL_AUDIO = 'SET_AUDIO_VALUE_AFTER_REQUEST_OR_CANCEL_AUDIO';
const UPDATE_VALIDATION_ERRORS = 'UPDATE_VALIDATION_ERRORS';
const SET_DEFAULT_CONTEXT_FOR_TRANSLATORS = 'SET_DEFAULT_CONTEXT_FOR_TRANSLATORS';

export const CommonActions = {
  NULLIFY_FIELD,
  SET_TRANSLATOR_CONTEXT,
  REFRESH_IMAGE,
  SET_IMAGE_ENABLED,
  SET_AUDIO_ENABLED,
  SET_TEXT_ENABLED,
  SET_VIDEO_ENABLED,
  SET_RECAP_ID,
  IMAGE_UPLOADING_STARTED,
  IMAGE_UPLOADING_FINISHED,
  AUDIO_UPLOADING_STARTED,
  AUDIO_UPLOADING_FINISHED,
  SET_IS_SAVE_LOADING,
  VALIDATION_IS_LOADING,
  SET_VALIDATION_RESULT,
  SET_EXERCISE_NEW_CONTENT_ID,
  SET_EXERCISE_NEW_LEXICAL_ITEM,
  SET_COMMON_NEW_CONTENT_ID,
  TOGGLE_VOCABULARY,
  SET_COURSE_ALL_VALUES,
  SET_GROUP_ALL_VALUES,
  SET_VALUE_AFTER_PROCESSING,
  REMOVE_BUNDLE,
  SET_EXPERIMENT_VALUE,
  SET_AUDIO_VALUE_AFTER_REQUEST_OR_CANCEL_AUDIO,
  UPDATE_VALIDATION_ERRORS,
  SET_DEFAULT_CONTEXT_FOR_TRANSLATORS,
} as const;
