import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import {
  EXERCISE_FILLGAP,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_TYPING,
} from '@common/enums/ExerciseTypes';
import { DBId } from '@common/types/DBId';
import { DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';
import type { SearchPanelParamsType } from '@features/search';
import { useAppSelector } from '@redux/store';
import { selectLoadedExercise } from '@selectors/CoursesSelectors';

import { debouncedGetStringCount, filterActualError } from './util';

const SUGGESTION_QUERY_MIN_CHARS = 5;

type UseContentSuggestionsProps<T> = {
  currentLanguage: LanguageV2;
  fieldName: T;
  isWidgetActive: boolean;
  localizationId: DBId;
  suggestionsQuery: string;
  type: ContentTypesType;
};

type UseContentSuggestionsValues = {
  // flag to show/hide the content suggestions
  showContentSuggestions: boolean;
  // the count of the latest search results
  suggestionsCount: number;
  // indicates if fetching the search results count
  suggestionQueryLoading: boolean;
};

/**
 * Search for content similar to the text entered by the user
 * @returns UseContentSuggestionsValues
 */
export const useContentSuggestions = <T extends string>({
  currentLanguage,
  fieldName,
  isWidgetActive,
  localizationId,
  suggestionsQuery,
  type,
}: UseContentSuggestionsProps<T>): UseContentSuggestionsValues => {
  const debounceTimer = useRef<ReturnType<typeof setTimeout> | undefined>();
  const controller = useRef<AbortController | undefined>();
  const isNewString = !localizationId || localizationId === 'N/A';
  const loadedExercise = useAppSelector(selectLoadedExercise);
  const shouldAddSearchKtagsFilterPreset =
    type === ContentTypes.exercise &&
    [EXERCISE_FILLGAP, EXERCISE_HIGHLIGHTER, EXERCISE_PHRASE_BUILDER, EXERCISE_TYPING].includes(
      loadedExercise.exercise.type,
    ) &&
    fieldName.startsWith('phrase');

  const contentSuggestionsSearchFiltersPreset = {
    string: {
      translations: {
        with: [DEFAULT_LANGUAGE_V2],
        without: [],
      },
      hasKTags: shouldAddSearchKtagsFilterPreset ? true : undefined,
    },
  } as SearchPanelParamsType['filtersPreset'];

  if (currentLanguage !== DEFAULT_LANGUAGE_V2 && contentSuggestionsSearchFiltersPreset?.string?.translations) {
    contentSuggestionsSearchFiltersPreset.string.translations.with.push(currentLanguage as LanguageV2);
  }

  const [showContentSuggestions, setShowContentSuggestions] = useState(false);
  const [suggestionsCount, setSuggestionsCount] = useState(0);
  const [suggestionQueryLoading, setSuggestionQueryLoading] = useState(false);

  useEffect(() => {
    if (isNewString && isWidgetActive && suggestionsQuery && suggestionsQuery.length >= SUGGESTION_QUERY_MIN_CHARS) {
      (async () => {
        try {
          debouncedGetStringCount({
            controller,
            debounceTimer,
            filtersPreset: contentSuggestionsSearchFiltersPreset,
            language: currentLanguage,
            query: suggestionsQuery.trim(),
            type,
            setSuggestionsCount,
            setSuggestionQueryLoading,
          });
        } catch (error) {
          Sentry.captureException(error);
          setSuggestionsCount(0);
          setSuggestionQueryLoading(false);
        }
      })();
    }

    if (!suggestionsQuery || suggestionsQuery.length < SUGGESTION_QUERY_MIN_CHARS) {
      try {
        controller.current?.abort();
        setSuggestionsCount(0);
        setSuggestionQueryLoading(false);
      } catch (error) {
        filterActualError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewString, isWidgetActive, suggestionsQuery]);

  useEffect(() => {
    if (isNewString) {
      setShowContentSuggestions(true);
    }

    return () => {
      setShowContentSuggestions(false);
    };
  }, [isNewString]);

  return {
    showContentSuggestions,
    suggestionsCount,
    suggestionQueryLoading,
  };
};
