import type { AppState } from '@redux/store';

export const selectIsIssuesShown = ({ ui }: AppState) => ui.isIssuesShown;

export const selectUploadAudiosPanel = ({ ui }: AppState) => ui.panels.uploadAudios;

export const selectPublishingPanelVisible = ({ ui }: AppState) => ui.panels.publishing.visible;

export const selectTranslationsPanel = ({ ui }: AppState) => ui.panels.translations;
export const selectTranslationsPanelVisible = ({ ui }: AppState) => ui.panels.translations.visible;
export const selectTranslationsPanelVisibleOrLevelLesson = ({ ui }: AppState) =>
  ui.panels.translations.visible || ui.panels.translations.visibleForLevelAndLesson;
export const selectTranslationsPanelRow = ({ ui }: AppState) => ui.panels.translations.row;
export const selectTranslationsPanelColumn = ({ ui }: AppState) => ui.panels.translations.column;
export const selectTranslationsPanelShowOtherLocations = ({ ui }: AppState) =>
  ui.panels.translations.showOtherLocations;
export const selectTranslationsPanelVisitedBranch = ({ ui }: AppState) => ui.panels.translations.visitedBranch;
export const selectTranslationsPanelVisitedContentId = ({ ui }: AppState) => ui.panels.translations.visitedContentId;
export const selectTranslationsPanelBundleName = ({ ui }: AppState) => ui.panels.translations.bundleName;
export const selectTranslationsPanelIsStringBlocked = ({ ui }: AppState) =>
  ui.panels.translations.isStringChangeBlocked;
export const selectTranslationsPanelIsBundleBlocked = ({ ui }: AppState) =>
  ui.panels.translations.isBundleChangeBlocked;
export const selectTranslationsPanelTranslationsMode = ({ ui }: AppState) =>
  ui.panels.translations.translationsPanelTranslationsMode;
export const selectTranslationsPanelPreopenedTab = ({ ui }: AppState) => ui.panels.translations.preopenedTab;
export const selectTranslationsPanelDisableLearningLanguage = ({ ui }: AppState) =>
  ui.panels.translations.disableLearningLanguage;
export const selectTranslationsPanelDefaultContextForTranslators = ({ ui }: AppState) =>
  ui.panels.translations.defaultContextForTranslators;

export const selectGrammarTranslationsPanel = ({ ui }: AppState) => ui.panels.grammarTranslations;
export const selectGrammarTranslationsPanelDefaultContextForTranslators = ({ ui }: AppState) =>
  ui.panels.grammarTranslations.defaultContextForTranslators;

export const selectRoleplayTranslationsPanel = ({ ui }: AppState) => ui.panels.roleplayTranslations;
export const selectRoleplayTranslationsPanelDefaultContextForTranslators = ({ ui }: AppState) =>
  ui.panels.roleplayTranslations.defaultContextForTranslators;

export const selectLexicalItemTranslationsPanel = ({ ui }: AppState) => ui.panels.lexicalItemTranslations;
export const selectLexicalItemTranslationsPanelDefaultContextForTranslators = ({ ui }: AppState) =>
  ui.panels.lexicalItemTranslations.defaultContextForTranslators;

export const selectDialogueCharactersPanelVisible = ({ ui }: AppState) => ui.panels.dialogueCharacters.visible;
export const selectDialogueCharactersPanelCharacters = ({ ui }: AppState) => ui.panels.dialogueCharacters.characters;
export const selectDialogueCharactersPanelCharacterIndex = ({ ui }: AppState) =>
  ui.panels.dialogueCharacters.characterIndex;
export const selectDialogueCharactersPanelTitle = ({ ui }: AppState) => ui.panels.dialogueCharacters.title;
export const selectDialogueCharactersPanelMaxCharacters = ({ ui }: AppState) =>
  ui.panels.dialogueCharacters.MAX_CHARACTERS;
export const selectDialogueCharactersPanelMode = ({ ui }: AppState) => ui.panels.dialogueCharacters.mode;

export const selectSearchPanel = ({ ui }: AppState) => ui.panels.searchV2;
export const selectSearchPanelOpened = ({ ui }: AppState) => ui.panels.searchV2.opened;
export const selectSearchPanelRow = ({ ui }: AppState) => ui.panels.searchV2.row;
export const selectSearchPanelColumn = ({ ui }: AppState) => ui.panels.searchV2.column;
export const selectSearchPanelPosition = ({ ui }: AppState) => ui.panels.searchV2.position;

export const selectUploadTranslationsPanel = ({ ui }: AppState) => ui.panels.uploadTranslations;

export const selectIsPreviewOpened = ({ ui }: AppState) => ui.isPreviewOpened;
export const selectIsPreviewForExercisesListOpened = ({ ui }: AppState) => ui.isPreviewForExercisesListOpened;
