import { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import styled from 'styled-components/macro';

import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import ExerciseLoader from '@components/ExerciseLoader/ExerciseLoader';
import TranslationsPanel from '@components/TranslationsPanel/TranslationsPanel';
import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { DialogueCharactersPanel } from '@components/Exercises/Dialogue/DialogueCharactersPanel';
import { ContentOwner } from '@features/content/contentOwnership';
import { NAVIGATION_PANEL_WIDTH } from '@features/content/navigation';
import { SearchModal } from '@features/search';
import { Loader } from '@features/theme';
import { getInitialValuesForExercise } from '@helpers/formikInitialValuesHelper';

import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { Header } from '../Header';
import { Breadcrumbs } from './Breadcrumbs';
import { useAppSelector, useAppDispatch } from '@redux/store';
import { selectCourse, selectLoadedExercise } from '@selectors/CoursesSelectors';
import { selectPlacementTestEntrypoint, selectPlacementTestExercise } from '@selectors/PlacementTestSelectors';
import { selectDialogueCharactersPanelVisible, selectTranslationsPanelVisible } from '@selectors/UiSelectors';

const CourseEditionDataComposer = styled.div`
  background: ${({ theme }) => theme.colorV2.placementTestExerciseBackground};
  left: ${NAVIGATION_PANEL_WIDTH};
  margin-top: 10.5rem;
  min-height: inherit;
  padding: 0 6rem;
  padding-bottom: 5rem;
  position: relative;
  width: calc(100% - ${NAVIGATION_PANEL_WIDTH});
`;

export const PlacementExercise = () => {
  const dispatch = useAppDispatch();
  const { courseId, entrypointId, exerciseId } = useParams<{
    courseId: DBId;
    entrypointId: DBId;
    exerciseId: DBId;
  }>();

  const currentCourse = useAppSelector(selectCourse);
  const loadedExercise: AnyLoadedExercise | null = useAppSelector(selectLoadedExercise);
  const entrypoint = useAppSelector(selectPlacementTestEntrypoint);
  const placementExercise = useAppSelector(selectPlacementTestExercise);
  const dialogueCharactersPanelVisible = useAppSelector(selectDialogueCharactersPanelVisible);
  const translationsPanelVisible = useAppSelector(selectTranslationsPanelVisible);

  useEffect(() => {
    dispatch(PlacementTestActionCreators.setInitialPlacementExercise());
  }, [dispatch, exerciseId]);

  useEffect(() => {
    if (entrypoint.loaded === LoadingStage.notLoaded && courseId) {
      dispatch(PlacementTestActionCreators.getEntrypoint(courseId, entrypointId));
    }
  }, [dispatch, courseId, entrypoint, entrypointId]);

  useEffect(() => {
    if (placementExercise.loaded === LoadingStage.notLoaded && courseId && entrypointId && exerciseId) {
      dispatch(CourseEditionActionsCreator.getExercise(courseId, exerciseId, entrypointId));
    }
  }, [dispatch, courseId, placementExercise, entrypointId, exerciseId]);

  const renderExerciseContent = () => {
    return (
      <>
        {translationsPanelVisible && (
          <TranslationsPanel
            content={loadedExercise.exercise.content}
            type={ContentTypes.exercise}
            exerciseType={loadedExercise.exercise.type}
          />
        )}
        {dialogueCharactersPanelVisible && <DialogueCharactersPanel />}

        <CourseEditionDataComposer>
          <SearchModal />
          {currentCourse && (
            <Header
              contentName="Placement test"
              learningLanguage={currentCourse.learningLanguage}
              interfaceLanguages={currentCourse.interfaceLanguages}
            />
          )}
          {loadedExercise.loaded === LoadingStage.loading ? (
            <Loader size="L" />
          ) : loadedExercise.exercise ? (
            <>
              <BreadcrumbsAndOwnershipWrapper>
                <Breadcrumbs />
                <ContentOwner
                  componentId={exerciseId}
                  contentType={ContentTypes.exercise}
                  dates={loadedExercise.exercise.content.dates}
                  owner={loadedExercise.exercise.content.owner}
                />
              </BreadcrumbsAndOwnershipWrapper>
              <ExerciseLoader loadedExercise={loadedExercise} />
            </>
          ) : null}
        </CourseEditionDataComposer>
      </>
    );
  };

  if (loadedExercise.loaded === LoadingStage.loading) {
    return renderExerciseContent();
  }

  return (
    <Formik initialValues={getInitialValuesForExercise(loadedExercise?.exercise)} onSubmit={() => {}}>
      {renderExerciseContent()}
    </Formik>
  );
};

export const PlacementExerciseContent = withRouter(PlacementExercise);
