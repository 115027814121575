import { components, OptionProps } from 'react-select';
import { useState, useEffect, ComponentType } from 'react';

import type { LanguageV2 } from '@features/content/languages';
import { CommonFilterSelector, CommonFilterOptionType } from '@components/SelectorCommonComponents';
import { GenderType, VoiceArtistsType } from '../types';
import { getVoiceArtists, getActiveVoiceArtists } from '../_service';
import { VoiceArtistOptionContainer } from './VoiceArtistOptionContainer';

type VoiceArtistOptionType = { value: string; label: string };
type ExtendedVoiceArtistOptionType = VoiceArtistOptionType & {
  gender: GenderType | null;
  audioRequestsCount: number;
  accent: string | null;
};

const CustomOption = (props: OptionProps<ExtendedVoiceArtistOptionType>) => (
  <components.Option {...props}>
    <VoiceArtistOptionContainer
      voiceArtist={props.data as ExtendedVoiceArtistOptionType}
      isSelected={props.isSelected}
    />
  </components.Option>
);

const CLASSNAME_PREFIX = 'voice-artist-selector';

type VoiceArtistFilterProps = {
  className?: string;
  onlyActive?: boolean;
  selectedVoiceArtist: VoiceArtistOptionType | null;
  selectedLanguage: { value: LanguageV2; label: string } | null;
  setSelectedVoiceArtist: (voiceArtist: VoiceArtistOptionType | null) => void;
};

export const VoiceArtistFilter = ({
  className,
  onlyActive = false,
  selectedVoiceArtist,
  selectedLanguage,
  setSelectedVoiceArtist,
}: VoiceArtistFilterProps) => {
  const [voiceArtists, setVoiceArtists] = useState<VoiceArtistsType[]>([]);
  const [voiceArtistOptions, setVoiceArtistOptions] = useState<ExtendedVoiceArtistOptionType[]>([]);

  useEffect(() => {
    if (selectedLanguage) {
      setVoiceArtistOptions(
        voiceArtists
          .filter((voiceArtist) => voiceArtist.languages.includes(selectedLanguage?.value))
          .map((voiceArtist) => ({
            value: voiceArtist.id,
            label: voiceArtist.name,
            gender: onlyActive ? null : voiceArtist.gender,
            accent: onlyActive ? null : voiceArtist.accent,
            audioRequestsCount: voiceArtist.audioRequestsCount || 0,
          })),
      );
    } else {
      setVoiceArtistOptions(
        voiceArtists.map((voiceArtist) => ({
          value: voiceArtist.id,
          label: `${voiceArtist.name}`,
          gender: onlyActive ? null : voiceArtist.gender,
          accent: onlyActive ? null : voiceArtist.accent,
          audioRequestsCount: voiceArtist.audioRequestsCount || 0,
        })),
      );
    }
  }, [voiceArtists, selectedLanguage, onlyActive]);

  return (
    <CommonFilterSelector
      placeholder="Voice Artist"
      className={className}
      classNamePrefix={CLASSNAME_PREFIX}
      value={selectedVoiceArtist}
      withSearchIcon
      options={voiceArtistOptions}
      optionComponent={CustomOption as unknown as ComponentType<OptionProps<CommonFilterOptionType, false>>}
      onChange={(voiceArtist) => setSelectedVoiceArtist(voiceArtist)}
      onFocus={async () => {
        if (!voiceArtists.length) {
          const voiceArtistsResult = onlyActive ? await getActiveVoiceArtists() : await getVoiceArtists();
          setVoiceArtists(voiceArtistsResult.data.voiceArtists);
        }
      }}
    />
  );
};
