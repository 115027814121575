import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { apiClient } from '@features/api';
import { DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';
import { SearchPanelParamsType } from '@features/search';

import { MAX_QUERY_CHARS_LENGTH } from '../constants';
import { truncateString } from '../util';

const ONE_HOUR_MS = 1000 * 60 * 60;

const stringCountCache: Record<string, { count: number; lastRequested: number }> = {};

const shouldFlushCache = (stringCountLastRequested: number) => {
  const stringCountLastRequestedTime = new Date(stringCountLastRequested).getTime();
  const currentTime = new Date().getTime();

  return currentTime - stringCountLastRequestedTime > ONE_HOUR_MS;
};

type GetStringCountParamsType = {
  query: string;
  filtersPreset: SearchPanelParamsType['filtersPreset'];
  language?: LanguageV2;
  signal: AbortSignal;
  type?: ContentTypesType;
};

export const getStringCount = async ({
  query,
  filtersPreset,
  language = DEFAULT_LANGUAGE_V2,
  signal,
  type,
}: GetStringCountParamsType): Promise<number> => {
  if (stringCountCache[query] && shouldFlushCache(stringCountCache[query].lastRequested)) {
    delete stringCountCache[query];
  }

  if (stringCountCache[query]) {
    return stringCountCache[query].count;
  } else {
    // truncate query string to avoid a server error when long strings
    const sanitizedQuery = truncateString(query, MAX_QUERY_CHARS_LENGTH);
    let endpoint = 'strings';
    let params: { [key: string]: any } = {
      hasTextTranslations: filtersPreset?.string?.translations?.with,
      hasKTags: filtersPreset?.string?.hasKTags ?? undefined,
    };

    if (type === ContentTypes.lexicalItem) {
      endpoint = 'lexical-items';
      params = {
        exactMatch: true,
        language,
      };
    }

    const { headers } = await apiClient.noErrorsV2.head(`/search/${endpoint}`, {
      params: {
        query: sanitizedQuery,
        ...params,
      },
      signal,
    });

    const count = headers['x-total-count'] ?? 0;
    stringCountCache[query] = {
      count,
      lastRequested: new Date().getTime(),
    };

    return count;
  }
};
