import produce from 'immer';

import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { NullifyPayloadArguments } from '@common/types/NullifyPayloadArguments';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { ContentTypes, ContentType, ContentTypesType } from '@common/enums/ContentTypes';
import { CommonActions } from '@actions/CommonActions';
import { EXERCISES, ExerciseTypes, type ExerciseType } from '@common/enums/ExerciseTypes';
import { SearchActions } from '@actions/SearchActions';
import { updateBundleValidationStatus } from '@helpers/updateBundleValidationStatus';
import { constants as contentConstants } from '@features/content';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { PayloadAction } from '@reduxjs/toolkit';
import { ExperimentType } from '@features/experiment';
import { clone } from '@helpers/clone';
import * as Sentry from '@sentry/react';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { SetExerciseNewLexicalItemPayload } from '@features/content/exercises';

const CommonActionsCourseReducers = {
  [CommonActions.SET_EXPERIMENT_VALUE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<ExperimentType | null>,
  ): CourseSliceInterface => {
    return produce(state, (draft) => {
      draft.loadedExercise.exercise.content.experiment = payload;
      draft.loadedExercise.exercise.content.experimentChanged = true;
    });
  },
  [CommonActions.NULLIFY_FIELD]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<NullifyPayloadArguments>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyField({
      field: payload.field,
      state,
      arrayRowPosition: payload.arrayRowPosition,
      arrayColumnPosition: payload.arrayColumnPosition,
      nullifyOnlyThisLocalizationType: payload.nullifyOnlyThisLocalizationType,
    });
  },
  [CommonActions.SET_VALUE_AFTER_PROCESSING]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface | undefined => {
    try {
      const { bundleName, column, field, language, lessonId, mediaType, row, scenarioId, type, url } = payload;

      const isLocalizationNeedToBeUpdated = (localization: LocalizationInterface) => {
        return localization.language === language && localization._id;
      };

      if (lessonId) {
        return produce(state, (draft) => {
          draft.selectedGroupsOfParent.groups = draft.selectedGroupsOfParent.groups.map((group: any) => {
            if (group.id === lessonId) {
              return {
                ...group,
                [field]: {
                  ...group[field],
                  [`${mediaType}Localizations`]: group[field][`${mediaType}Localizations`].map(
                    (localization: LocalizationInterface) => {
                      if (isLocalizationNeedToBeUpdated(localization)) {
                        return {
                          ...localization,
                          value: url,
                          processed: true,
                        };
                      }
                      return localization;
                    },
                  ),
                },
              };
            }
            return group;
          });
        });
      } else if (scenarioId) {
      } else {
        if (type === ContentTypes.exercise) {
          if (bundleName) {
            return produce(state, (draft) => {
              if (draft.loadedExercise?.exercise?.content[bundleName]?.[field]) {
                draft.loadedExercise.exercise.content[bundleName][field][`${mediaType}Localizations`] =
                  draft.loadedExercise.exercise.content[bundleName][field][`${mediaType}Localizations`].map(
                    (localization: LocalizationInterface) => {
                      if (isLocalizationNeedToBeUpdated(localization)) {
                        return {
                          ...localization,
                          value: url,
                          processed: true,
                        };
                      }
                      return localization;
                    },
                  );
              }
            });
          } else {
            return produce(state, (draft) => {
              if (field === 'script') {
                draft.loadedExercise.exercise.content[field][row || 0].line[`${mediaType}Localizations`] =
                  draft.loadedExercise.exercise.content[field][row || 0].line[`${mediaType}Localizations`].map(
                    (localization: LocalizationInterface) => {
                      if (isLocalizationNeedToBeUpdated(localization)) {
                        return {
                          ...localization,
                          value: url,
                          processed: true,
                        };
                      }
                      return localization;
                    },
                  );
              } else if (field === 'examples') {
                draft.loadedExercise.exercise.content[field][row || 0][column || 0][`${mediaType}Localizations`] =
                  draft.loadedExercise.exercise.content[field][row || 0][column || 0][`${mediaType}Localizations`].map(
                    (localization: LocalizationInterface) => {
                      if (isLocalizationNeedToBeUpdated(localization)) {
                        return {
                          ...localization,
                          value: url,
                          processed: true,
                        };
                      }
                      return localization;
                    },
                  );
              } else if (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(field)) {
                draft.loadedExercise.exercise.content.phrases[row || 0][`${mediaType}Localizations`] =
                  draft.loadedExercise.exercise.content.phrases[row || 0][`${mediaType}Localizations`].map(
                    (localization: LocalizationInterface) => {
                      if (isLocalizationNeedToBeUpdated(localization)) {
                        return {
                          ...localization,
                          value: url,
                          processed: true,
                        };
                      }
                      return localization;
                    },
                  );
              } else {
                draft.loadedExercise.exercise.content[field][`${mediaType}Localizations`] =
                  draft.loadedExercise.exercise.content[field][`${mediaType}Localizations`].map(
                    (localization: LocalizationInterface) => {
                      if (isLocalizationNeedToBeUpdated(localization)) {
                        return {
                          ...localization,
                          value: url,
                          processed: true,
                        };
                      }
                      return localization;
                    },
                  );
              }
            });
          }
        } else if (type === ContentTypes.course) {
          return produce(state, (draft) => {
            (draft.course as any)[field][`${mediaType}Localizations`] = (draft.course as any)[field][
              `${mediaType}Localizations`
            ].map((localization: LocalizationInterface) => {
              if (isLocalizationNeedToBeUpdated(localization)) {
                return {
                  ...localization,
                  value: url,
                  processed: true,
                };
              }
              return localization;
            });
          });
        } else {
          return produce(state, (draft) => {
            (draft.selectedGroupsOfParent.parentContents as any)[field][`${mediaType}Localizations`] = (
              draft.selectedGroupsOfParent.parentContents as any
            )[field][`${mediaType}Localizations`].map((localization: LocalizationInterface) => {
              if (isLocalizationNeedToBeUpdated(localization)) {
                return {
                  ...localization,
                  value: url,
                  processed: true,
                };
              }
              return localization;
            });
          });
        }
      }
    } catch (error) {
      Sentry.captureException(error, (scope) => {
        scope.setTag('logosAction', 'CommonActions.SET_VALUE_AFTER_PROCESSING');
        scope.setExtras({ payload });

        return scope;
      });
    }
  },
  [CommonActions.SET_AUDIO_VALUE_AFTER_REQUEST_OR_CANCEL_AUDIO]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface | undefined => {
    try {
      const { audioRequestData, bundleName, column, field, language, lessonId, row, type } = payload;

      if (lessonId) {
        return produce(state, (draft) => {
          draft.selectedGroupsOfParent.groups = draft.selectedGroupsOfParent.groups.map((group: any) => {
            if (group.id === lessonId) {
              return {
                ...group,
                [field]: {
                  ...group[field],
                  audioLocalizations: group[field].audioLocalizations.map((localization: LocalizationInterface) => {
                    if (localization.language === language) {
                      return {
                        ...localization,
                        audioRequest: audioRequestData,
                      };
                    }
                    return localization;
                  }),
                },
              };
            }
            return group;
          });
        });
      } else {
        if (type === ContentTypes.exercise) {
          if (bundleName) {
            return produce(state, (draft) => {
              draft.loadedExercise.exercise.content[bundleName][field].audioLocalizations =
                draft.loadedExercise.exercise.content[bundleName][field].audioLocalizations.map(
                  (localization: LocalizationInterface) => {
                    if (localization.language === language) {
                      return {
                        ...localization,
                        audioRequest: audioRequestData,
                      };
                    }
                    return localization;
                  },
                );
            });
          } else {
            return produce(state, (draft) => {
              if (field === 'script') {
                draft.loadedExercise.exercise.content[field][row || 0].line.audioLocalizations =
                  draft.loadedExercise.exercise.content[field][row || 0].line.audioLocalizations.map(
                    (localization: LocalizationInterface) => {
                      if (localization.language === language) {
                        return {
                          ...localization,
                          audioRequest: audioRequestData,
                        };
                      }
                      return localization;
                    },
                  );
              } else if (field === 'examples') {
                draft.loadedExercise.exercise.content[field][row || 0][column || 0].audioLocalizations =
                  draft.loadedExercise.exercise.content[field][row || 0][column || 0].audioLocalizations.map(
                    (localization: LocalizationInterface) => {
                      if (localization.language === language) {
                        return {
                          ...localization,
                          audioRequest: audioRequestData,
                        };
                      }
                      return localization;
                    },
                  );
              } else if (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(field)) {
                draft.loadedExercise.exercise.content.phrases[row || 0].audioLocalizations =
                  draft.loadedExercise.exercise.content.phrases[row || 0].audioLocalizations.map(
                    (localization: LocalizationInterface) => {
                      if (localization.language === language) {
                        return {
                          ...localization,
                          audioRequest: audioRequestData,
                        };
                      }
                      return localization;
                    },
                  );
              } else {
                draft.loadedExercise.exercise.content[field].audioLocalizations = draft.loadedExercise.exercise.content[
                  field
                ].audioLocalizations.map((localization: LocalizationInterface) => {
                  if (localization.language === language) {
                    return {
                      ...localization,
                      audioRequest: audioRequestData,
                    };
                  }
                  return localization;
                });
              }
            });
          }
        } else if (type === ContentTypes.course) {
          return produce(state, (draft) => {
            (draft.course as any)[field].audioLocalizations = (draft.course as any)[field].audioLocalizations.map(
              (localization: LocalizationInterface) => {
                if (localization.language === language) {
                  return {
                    ...localization,
                    audioRequest: audioRequestData,
                  };
                }
                return localization;
              },
            );
          });
        } else {
          return produce(state, (draft) => {
            (draft.selectedGroupsOfParent.parentContents as any)[field].audioLocalizations = (
              draft.selectedGroupsOfParent.parentContents as any
            )[field].audioLocalizations.map((localization: LocalizationInterface) => {
              if (localization.language === language) {
                return {
                  ...localization,
                  audioRequest: audioRequestData,
                };
              }
              return localization;
            });
          });
        }
      }
    } catch (error) {
      Sentry.captureException(error, (scope) => {
        scope.setTag('logosAction', 'CommonActions.SET_AUDIO_VALUE_AFTER_REQUEST_OR_CANCEL_AUDIO');
        scope.setExtras({ payload });

        return scope;
      });
    }
  },
  [CommonActions.SET_TRANSLATOR_CONTEXT]: (
    state: CourseSliceInterface,
    { payload: { description, visitedBranch, type: contentType, row, column, bundleName } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);
    let exerciseType;

    if (contentType === ContentTypes.exercise) {
      exerciseType = clonedState.loadedExercise?.exercise?.type;
    }

    const isHighlighterPhrase =
      exerciseType === ExerciseTypes.highlighter &&
      ['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch);
    if (
      (exerciseType === ExerciseTypes.tip && visitedBranch === 'examples') ||
      isHighlighterPhrase ||
      (exerciseType === ExerciseTypes.dialogue &&
        visitedBranch === contentConstants.DIALOGUE_EXERCISE_SCRIPT_STATE_BRANCH_NAME)
    ) {
      if (exerciseType === ExerciseTypes.tip && visitedBranch === 'examples') {
        clonedState.loadedExercise.exercise.content[visitedBranch][row][column].description = description;
        clonedState.loadedExercise.exercise.content[visitedBranch][row][column].changed = true;
        clonedState.loadedExercise.exercise.content[`${visitedBranch}Changed`] = true;
      }
      if (isHighlighterPhrase) {
        clonedState.loadedExercise.exercise.content.phrases[row].description = description;
        clonedState.loadedExercise.exercise.content.phrases[row].changed = true;
      }

      if (
        exerciseType === ExerciseTypes.dialogue &&
        visitedBranch === contentConstants.DIALOGUE_EXERCISE_SCRIPT_STATE_BRANCH_NAME
      ) {
        clonedState.loadedExercise.exercise.content[visitedBranch][row].line.description = description;
        clonedState.loadedExercise.exercise.content[`${visitedBranch}Changed`] = true;
      }
    } else {
      if (contentType === ContentTypes.exercise) {
        if (bundleName) {
          clonedState.loadedExercise.exercise.content[bundleName][visitedBranch].description = description;
          if (clonedState.loadedExercise.exercise.content[bundleName][visitedBranch]) {
            clonedState.loadedExercise.exercise.content[bundleName][visitedBranch].changed = true;
          } else {
            clonedState.loadedExercise.exercise.content[bundleName][`${visitedBranch}Changed`] = true;
          }
        } else {
          clonedState.loadedExercise.exercise.content[visitedBranch].description = description;
          if (clonedState.loadedExercise.exercise.content[visitedBranch]) {
            clonedState.loadedExercise.exercise.content[visitedBranch].changed = true;
          } else {
            clonedState.loadedExercise.exercise.content[`${visitedBranch}Changed`] = true;
          }
        }
      } else if (contentType === ContentTypes.course) {
        (clonedState.course as any)[visitedBranch].description = description;
        if ((clonedState.course as any)[visitedBranch]) {
          (clonedState.course as any)[visitedBranch].changed = true;
        } else {
          (clonedState.course as any)[`${visitedBranch}Changed`] = true;
        }
      } else if (contentType === ContentType.levelOrLesson) {
        (clonedState.selectedGroupsOfParent.parentContents as any)[visitedBranch].description = description;
        if ((clonedState.selectedGroupsOfParent.parentContents as any)[visitedBranch]) {
          (clonedState.selectedGroupsOfParent.parentContents as any)[visitedBranch].changed = true;
        } else {
          (clonedState.selectedGroupsOfParent.parentContents as any)[`${visitedBranch}Changed`] = true;
        }
      }
    }

    return {
      ...clonedState,
    };
  },
  [CommonActions.REFRESH_IMAGE]: (
    state: CourseSliceInterface,
    { payload: { mediaURL, imageField, bundleName, updatedContent } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);
    let loadedExercise: AnyLoadedExercise = clonedState.loadedExercise as AnyLoadedExercise;

    if (updatedContent !== undefined) {
      if (bundleName) {
        clonedState.loadedExercise.exercise.content[bundleName].image = updatedContent;

        clonedState.loadedExercise.exercise.content[bundleName].image.changed = true;

        return {
          ...clonedState,
          mediaUploading: {
            ...clonedState.mediaUploading,
            imageOrVideoUploadingInProcess: [
              ...clonedState.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== imageField),
            ],
          },
        };
      } else {
        clonedState.loadedExercise.exercise.content[imageField] = updatedContent;

        return {
          ...clonedState,
          mediaUploading: {
            ...clonedState.mediaUploading,
            imageOrVideoUploadingInProcess: [
              ...clonedState.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== imageField),
            ],
          },
        };
      }
    }

    if (imageField === null || imageField === undefined) {
      throw new TypeError(`imageField must contain a value`);
    }

    const contentField = loadedExercise.exercise.content[imageField];

    if (contentField === null) {
      let payload = ExerciseDataModelAssembler.generateBasicImagePayload(mediaURL, DEFAULT_LANGUAGE_V2);
      let returnedPayload = {
        ...ExerciseDataModelAssembler.updateImageField(imageField, state, payload),
        mediaUploading: {
          ...clonedState.mediaUploading,
          imageOrVideoUploadingInProcess: [
            ...clonedState.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== imageField),
          ],
        },
      };

      return returnedPayload;
    }

    const imageLocalizations = contentField.imageLocalizations;

    let searchedLocalizationIndex = imageLocalizations.findIndex(
      (localization: LocalizationInterface) => localization.language === DEFAULT_LANGUAGE_V2,
    );

    if (searchedLocalizationIndex === -1) {
      const payload = ExerciseDataModelAssembler.generateBasicImagePayload(mediaURL, DEFAULT_LANGUAGE_V2);
      const finalPayload = ExerciseDataModelAssembler.updateImageField(imageField, state, payload);

      return {
        ...finalPayload,
        mediaUploading: {
          ...clonedState.mediaUploading,
          imageOrVideoUploadingInProcess: [
            ...clonedState.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== imageField),
          ],
        },
      };
    } else {
      loadedExercise.exercise.content[imageField].imageLocalizations[searchedLocalizationIndex] =
        ExerciseDataModelAssembler.generateBasicImagePayload(mediaURL, DEFAULT_LANGUAGE_V2);

      return {
        ...clonedState,
        loadedExercise,
        mediaUploading: {
          ...clonedState.mediaUploading,
          imageOrVideoUploadingInProcess: [
            ...clonedState.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== imageField),
          ],
        },
      };
    }
  },
  [CommonActions.IMAGE_UPLOADING_STARTED]: (
    state: CourseSliceInterface,
    { payload: { imageField } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return {
      ...state,
      mediaUploading: {
        ...state.mediaUploading,
        imageOrVideoUploadingInProcess: [...state.mediaUploading.imageOrVideoUploadingInProcess, imageField],
        imageOrVideoUploadingFailed: [
          ...state.mediaUploading.imageOrVideoUploadingFailed.filter((item) => item.field !== imageField),
        ],
      },
    };
  },
  [CommonActions.AUDIO_UPLOADING_STARTED]: (
    state: CourseSliceInterface,
    { payload: { audioField, language, row } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return {
      ...state,
      mediaUploading: {
        ...state.mediaUploading,
        audioUploadingInProcess: [
          ...state.mediaUploading.audioUploadingInProcess,
          {
            field: audioField,
            language,
            row,
          },
        ],
        audioUploadingFailed: [
          ...state.mediaUploading.audioUploadingFailed.filter(
            (item) => item.field !== audioField || (item.field === audioField && item.language !== language),
          ),
        ],
      },
    };
  },
  [CommonActions.IMAGE_UPLOADING_FINISHED]: (
    state: CourseSliceInterface,
    { payload: { imageField, details } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return {
      ...state,
      mediaUploading: {
        ...state.mediaUploading,
        imageOrVideoUploadingInProcess: [
          ...state.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== imageField),
        ],
        imageOrVideoUploadingFailed: details
          ? [
              ...state.mediaUploading.imageOrVideoUploadingFailed,
              {
                details,
                field: imageField,
              },
            ]
          : [...state.mediaUploading.imageOrVideoUploadingFailed],
      },
    };
  },
  [CommonActions.AUDIO_UPLOADING_FINISHED]: (
    state: CourseSliceInterface,
    { payload: { audioField, language, row, details } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return {
      ...state,
      mediaUploading: {
        ...state.mediaUploading,
        audioUploadingInProcess: [
          ...state.mediaUploading.audioUploadingInProcess.filter(
            (item) =>
              item.field !== audioField ||
              (item.field === audioField && item.language !== language && item.row !== row),
          ),
        ],
        audioUploadingFailed: [
          ...state.mediaUploading.audioUploadingFailed,
          {
            field: audioField,
            language,
            row: row,
            details,
          },
        ],
      },
    };
  },
  [CommonActions.SET_IMAGE_ENABLED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    clonedCourseSlice.loadedExercise.exercise.content.mainImageEnabled = payload;
    clonedCourseSlice.loadedExercise.exercise.content.mainImageChanged = true;

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.SET_IS_SAVE_LOADING]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    if (!payload.value && payload.updateData) {
      if (clonedCourseSlice.loadedExercise.exercise && clonedCourseSlice.loadedExercise.exercise.content) {
        switch (clonedCourseSlice.loadedExercise.exercise.content.type) {
          case ExerciseTypes.comprehension: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.title) {
              clonedCourseSlice.loadedExercise.exercise.content.title.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.titleChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.titleLanguageChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.providerChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle) {
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.isVocabularyChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phraseChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.isReused = false;
              }
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.imageChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.video) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.video.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.videoChanged = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            break;
          }

          case ExerciseTypes.conversation: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.hint) {
              clonedCourseSlice.loadedExercise.exercise.content.hint.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.hintChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.minimumNumberOfWordsChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.image1) {
              clonedCourseSlice.loadedExercise.exercise.content.image1.changed = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.image2) {
              clonedCourseSlice.loadedExercise.exercise.content.image2.changed = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.image3) {
              clonedCourseSlice.loadedExercise.exercise.content.image3.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.image1Changed = false;
            clonedCourseSlice.loadedExercise.exercise.content.image2Changed = false;
            clonedCourseSlice.loadedExercise.exercise.content.image3Changed = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.dialogue: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.dialogueDescription) {
              clonedCourseSlice.loadedExercise.exercise.content.dialogueDescription.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.dialogueDescriptionChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.dialogueDescriptionLanguageChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.charactersChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.scriptChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.fillgap: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;

            if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle) {
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.isVocabularyChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.isReused = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.imageChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.video) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.video.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.videoChanged = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.distractor1) {
              clonedCourseSlice.loadedExercise.exercise.content.distractor1.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.distractor1.phrase.isReused = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.distractor2) {
              clonedCourseSlice.loadedExercise.exercise.content.distractor2.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.distractor2.phrase.isReused = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.distractor1Changed = false;
            clonedCourseSlice.loadedExercise.exercise.content.distractor2Changed = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.feedback) {
              clonedCourseSlice.loadedExercise.exercise.content.feedback.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.flashcard: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle) {
              clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.isVocabularyChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.image) {
                clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.imageChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.phrase.isReused = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.example) {
                clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.example.changed = false;
                clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.example.isReused = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.learningWordBundle.exampleChanged = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.highlighter: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.feedback) {
              clonedCourseSlice.loadedExercise.exercise.content.feedback.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.phrasesChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.feedbackChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.matchup: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.feedback) {
              clonedCourseSlice.loadedExercise.exercise.content.feedback.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.feedbackChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.fixedItem1) {
              clonedCourseSlice.loadedExercise.exercise.content.fixedItem1.phrase.changed = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.matchingItem1) {
              clonedCourseSlice.loadedExercise.exercise.content.matchingItem1.phrase.changed = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.fixedItem2) {
              clonedCourseSlice.loadedExercise.exercise.content.fixedItem2.phrase.changed = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.matchingItem2) {
              clonedCourseSlice.loadedExercise.exercise.content.matchingItem2.phrase.changed = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.fixedItem3) {
              clonedCourseSlice.loadedExercise.exercise.content.fixedItem3.phrase.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.fixedItem3Changed = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.matchingItem3) {
              clonedCourseSlice.loadedExercise.exercise.content.matchingItem3.phrase.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.matchingItem3Changed = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.multipleChoice: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.choicesImagesEnabledChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.mainImageChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.mainAudioChanged = false;

            if (clonedCourseSlice.loadedExercise.exercise.content.answerBundle) {
              clonedCourseSlice.loadedExercise.exercise.content.answerBundle.isVocabularyChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.answerBundle.image) {
                clonedCourseSlice.loadedExercise.exercise.content.answerBundle.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.answerBundle.imageChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.answerBundle.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.answerBundle.phrase.isReused = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.distractor1) {
              clonedCourseSlice.loadedExercise.exercise.content.distractor1.isVocabularyChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.distractor1.image) {
                clonedCourseSlice.loadedExercise.exercise.content.distractor1.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.distractor1.imageChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.distractor1.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.distractor1.phrase.isReused = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.distractor2) {
              clonedCourseSlice.loadedExercise.exercise.content.distractor2.isVocabularyChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.distractor2.image) {
                clonedCourseSlice.loadedExercise.exercise.content.distractor2.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.distractor2.imageChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.distractor2.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.distractor2.phrase.isReused = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.choicesLanguageChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.phraseBuilder: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle) {
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.isVocabularyChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.isReused = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.feedback) {
              clonedCourseSlice.loadedExercise.exercise.content.feedback.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.feedbackChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.speechRecognition: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle) {
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.isVocabularyChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.imageChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.isReused = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.feedbackOptionChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.feedback) {
              clonedCourseSlice.loadedExercise.exercise.content.feedback.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.feedbackPhonemesChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.spelling: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle) {
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.isVocabularyChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.imageChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.isReused = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.feedback) {
              clonedCourseSlice.loadedExercise.exercise.content.feedback.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.feedbackChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.tip: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.paragraph) {
              clonedCourseSlice.loadedExercise.exercise.content.paragraph.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.paragraphChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.paragraphLanguageChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.examplesLanguageChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.examplesChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.trueFalse: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle) {
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.isVocabularyChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.imageChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.changed = false;
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.isReused = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phraseChanged = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.statement) {
              clonedCourseSlice.loadedExercise.exercise.content.statement.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.answerChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.statementLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.feedback) {
              clonedCourseSlice.loadedExercise.exercise.content.feedback.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.feedbackChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }

          case ExerciseTypes.typing: {
            if (clonedCourseSlice.loadedExercise.exercise.content.instructions) {
              clonedCourseSlice.loadedExercise.exercise.content.instructions.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.instructionsLanguageChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle) {
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.isVocabularyChanged = false;
              if (clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image) {
                clonedCourseSlice.loadedExercise.exercise.content.mainBundle.image.changed = false;
              }
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.imageChanged = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.changed = false;
              clonedCourseSlice.loadedExercise.exercise.content.mainBundle.phrase.isReused = false;
            }
            if (clonedCourseSlice.loadedExercise.exercise.content.hint) {
              clonedCourseSlice.loadedExercise.exercise.content.hint.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.hintChanged = false;
            if (clonedCourseSlice.loadedExercise.exercise.content.feedback) {
              clonedCourseSlice.loadedExercise.exercise.content.feedback.changed = false;
            }
            clonedCourseSlice.loadedExercise.exercise.content.feedbackChanged = false;
            clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = false;
            break;
          }
        }
      }

      if (clonedCourseSlice.selectedGroupsOfParent.parentContents) {
        if (clonedCourseSlice.selectedGroupsOfParent.parentContents.titleWithLocalizations) {
          clonedCourseSlice.selectedGroupsOfParent.parentContents.titleWithLocalizations.changed = false;
        }
        if (clonedCourseSlice.selectedGroupsOfParent.parentContents.descriptionWithLocalizations) {
          clonedCourseSlice.selectedGroupsOfParent.parentContents.descriptionWithLocalizations.changed = false;
        }
      }
      if (clonedCourseSlice.course) {
        if (clonedCourseSlice.course.titleWithLocalizations) {
          clonedCourseSlice.course.titleWithLocalizations.changed = false;
        }
        if (clonedCourseSlice.course.descriptionWithLocalizations) {
          clonedCourseSlice.course.descriptionWithLocalizations.changed = false;
        }
      }
    }

    return {
      ...clonedCourseSlice,
      header: {
        ...clonedCourseSlice.header,
        isSaveButtonEnabled: false,
        isSaveInProgress: payload.value,
      },
    };
  },
  [CommonActions.VALIDATION_IS_LOADING]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return {
      ...clonedCourseSlice,
      header: {
        ...clonedCourseSlice.header,
        validationResultsLoading: payload.value,
      },
    };
  },
  [CommonActions.SET_VALIDATION_RESULT]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    const { type: contentType, data } = payload;

    switch (contentType) {
      case ContentTypes.course: {
        clonedCourseSlice.course.changeStatus = data.changeStatus;
        clonedCourseSlice.course.validation = data.validationStatus;
        clonedCourseSlice.course.thumbnailImageChanged = false;
        clonedCourseSlice.course.paywallImageChanged = false;
        break;
      }
      case ContentTypes.exercise: {
        clonedCourseSlice.loadedExercise.exercise.content.changeStatus = data.changeStatus;
        clonedCourseSlice.loadedExercise.exercise.content.validation = data.validationStatus;

        clonedCourseSlice = updateBundleValidationStatus(clonedCourseSlice, data.childrenStatuses);
        break;
      }

      case ContentTypes.lesson:
      case ContentTypes.activity:
      case ContentTypes.chapter:
      case ContentTypes.level: {
        if (clonedCourseSlice.selectedGroupsOfParent.parentContents) {
          clonedCourseSlice.selectedGroupsOfParent.parentContents.changeStatus = data.changeStatus;
          clonedCourseSlice.selectedGroupsOfParent.parentContents.validation = data.validationStatus;
        }
        break;
      }

      default: {
        console.log('Unknown type');
      }
    }

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.SET_AUDIO_ENABLED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    clonedCourseSlice.loadedExercise.exercise.content.mainAudioEnabled = payload;
    clonedCourseSlice.loadedExercise.exercise.content.mainAudioChanged = true;
    clonedCourseSlice.loadedExercise.exercise.content.displayAudio = payload;
    clonedCourseSlice.loadedExercise.exercise.content.displayAudioChanged = true;

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.TOGGLE_VOCABULARY]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    clonedCourseSlice.loadedExercise.exercise.content[payload].isVocabulary =
      !clonedCourseSlice.loadedExercise.exercise.content[payload].isVocabulary;
    clonedCourseSlice.loadedExercise.exercise.content[payload].isVocabularyChanged = true;

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.SET_COURSE_ALL_VALUES]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    const { values } = payload;
    if (values.titleWithLocalizationsChanged && clonedCourseSlice.course.titleWithLocalizations?.textLocalizations) {
      if (clonedCourseSlice.course.titleWithLocalizations?.isReused) {
        clonedCourseSlice.course.titleWithLocalizations.isReused = false;
      }
      clonedCourseSlice.course.titleWithLocalizations.textLocalizations =
        clonedCourseSlice.course.titleWithLocalizations?.textLocalizations.map((loc) => {
          return {
            ...loc,
            value: values.titleWithLocalizations.find(
              (value: any) => value.language === loc.language && !value.isPhonetic,
            ).value,
            phoneticValue:
              values.titleWithLocalizations.find((value: any) => value.language === loc.language && value.isPhonetic)
                .value || '',
          };
        });
    }
    if (
      values.descriptionWithLocalizationsChanged &&
      clonedCourseSlice.course.descriptionWithLocalizations?.textLocalizations
    ) {
      if (clonedCourseSlice.course.descriptionWithLocalizations?.isReused) {
        clonedCourseSlice.course.descriptionWithLocalizations.isReused = false;
      }
      clonedCourseSlice.course.descriptionWithLocalizations.textLocalizations =
        clonedCourseSlice.course.descriptionWithLocalizations?.textLocalizations.map((loc) => {
          return {
            ...loc,
            value: values.descriptionWithLocalizations.find(
              (value: any) => value.language === loc.language && !value.isPhonetic,
            ).value,
            phoneticValue:
              values.descriptionWithLocalizations.find(
                (value: any) => value.language === loc.language && value.isPhonetic,
              ).value || '',
          };
        });
    }

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.SET_GROUP_ALL_VALUES]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    const { values } = payload;
    if (
      values.titleWithLocalizationsChanged &&
      clonedCourseSlice.selectedGroupsOfParent.parentContents?.titleWithLocalizations?.textLocalizations
    ) {
      if (clonedCourseSlice.selectedGroupsOfParent.parentContents.titleWithLocalizations?.isReused) {
        clonedCourseSlice.selectedGroupsOfParent.parentContents.titleWithLocalizations.isReused = false;
      }
      clonedCourseSlice.selectedGroupsOfParent.parentContents.titleWithLocalizations.textLocalizations =
        clonedCourseSlice.selectedGroupsOfParent.parentContents.titleWithLocalizations?.textLocalizations.map((loc) => {
          return {
            ...loc,
            value: values.titleWithLocalizations.find(
              (value: any) => value.language === loc.language && !value.isPhonetic,
            ).value,
            phoneticValue:
              values.titleWithLocalizations.find((value: any) => value.language === loc.language && value.isPhonetic)
                .value || '',
          };
        });
    }
    if (
      values.descriptionWithLocalizationsChanged &&
      clonedCourseSlice.selectedGroupsOfParent.parentContents?.descriptionWithLocalizations?.textLocalizations
    ) {
      if (clonedCourseSlice.selectedGroupsOfParent.parentContents.descriptionWithLocalizations?.isReused) {
        clonedCourseSlice.selectedGroupsOfParent.parentContents.descriptionWithLocalizations.isReused = false;
      }
      clonedCourseSlice.selectedGroupsOfParent.parentContents.descriptionWithLocalizations.textLocalizations =
        clonedCourseSlice.selectedGroupsOfParent.parentContents.descriptionWithLocalizations?.textLocalizations.map(
          (loc) => {
            return {
              ...loc,
              value: values.descriptionWithLocalizations.find(
                (value: any) => value.language === loc.language && !value.isPhonetic,
              ).value,
              phoneticValue:
                values.descriptionWithLocalizations.find(
                  (value: any) => value.language === loc.language && value.isPhonetic,
                ).value || '',
            };
          },
        );
    }
    if (
      values.learningOutcomesChanged &&
      clonedCourseSlice.selectedGroupsOfParent.parentContents?.learningOutcomes?.textLocalizations
    ) {
      if (clonedCourseSlice.selectedGroupsOfParent.parentContents.learningOutcomes?.isReused) {
        clonedCourseSlice.selectedGroupsOfParent.parentContents.learningOutcomes.isReused = false;
      }
      clonedCourseSlice.selectedGroupsOfParent.parentContents.learningOutcomes.textLocalizations =
        clonedCourseSlice.selectedGroupsOfParent.parentContents.learningOutcomes?.textLocalizations.map((loc) => {
          return {
            ...loc,
            value: values.learningOutcomes.find((value: any) => value.language === loc.language && !value.isPhonetic)
              .value,
            phoneticValue:
              values.learningOutcomes.find((value: any) => value.language === loc.language && value.isPhonetic).value ||
              '',
          };
        });
    }
    if (
      values.completeMessageChanged &&
      clonedCourseSlice.selectedGroupsOfParent.parentContents?.completeMessage?.textLocalizations
    ) {
      if (clonedCourseSlice.selectedGroupsOfParent.parentContents.completeMessage?.isReused) {
        clonedCourseSlice.selectedGroupsOfParent.parentContents.completeMessage.isReused = false;
      }
      clonedCourseSlice.selectedGroupsOfParent.parentContents.completeMessage.textLocalizations =
        clonedCourseSlice.selectedGroupsOfParent.parentContents.completeMessage?.textLocalizations.map((loc) => {
          return {
            ...loc,
            value: values.completeMessage.find((value: any) => value.language === loc.language && !value.isPhonetic)
              .value,
            phoneticValue:
              values.completeMessage.find((value: any) => value.language === loc.language && value.isPhonetic).value ||
              '',
          };
        });
    }

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.SET_EXERCISE_NEW_CONTENT_ID]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    const { field, bundleName, newContentId, index } = payload;

    if (bundleName) {
      clonedCourseSlice.loadedExercise.exercise.content[bundleName][field]._id = newContentId;
      clonedCourseSlice.loadedExercise.exercise.content[bundleName][field].id = newContentId;
    } else {
      if (clonedCourseSlice.loadedExercise.exercise.content.type === ExerciseTypes.dialogue) {
        if (field === 'script') {
          clonedCourseSlice.loadedExercise.exercise.content[field][index].line._id = newContentId;
          clonedCourseSlice.loadedExercise.exercise.content[field][index].line.id = newContentId;
        } else {
          clonedCourseSlice.loadedExercise.exercise.content[field]._id = newContentId;
          clonedCourseSlice.loadedExercise.exercise.content[field].id = newContentId;
        }
      }
      clonedCourseSlice.loadedExercise.exercise.content[field]._id = newContentId;
      clonedCourseSlice.loadedExercise.exercise.content[field].id = newContentId;
    }

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.SET_EXERCISE_NEW_LEXICAL_ITEM]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<SetExerciseNewLexicalItemPayload>,
  ): CourseSliceInterface => {
    const { lexicalItemId, phrase } = payload;

    return produce(state, (draft) => {
      draft.loadedExercise.exercise.content.lexicalItems = [
        ...draft.loadedExercise.exercise.content.lexicalItems,
        { id: lexicalItemId, phrase, ready: false },
      ];
    });
  },
  [CommonActions.SET_COMMON_NEW_CONTENT_ID]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    const { contentType, field, newContentId } = payload;

    if (contentType === ContentTypes.course) {
      (clonedCourseSlice.course as any)[field]._id = newContentId;
      (clonedCourseSlice.course as any)[field].id = newContentId;
    } else {
      (clonedCourseSlice.selectedGroupsOfParent.parentContents as any)[field]._id = newContentId;
      (clonedCourseSlice.selectedGroupsOfParent.parentContents as any)[field].id = newContentId;
    }

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.SET_TEXT_ENABLED]: (state: CourseSliceInterface, { payload }: PayloadAction): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    clonedCourseSlice.loadedExercise.exercise.content.mainTextEnabled = payload;

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.SET_VIDEO_ENABLED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    clonedCourseSlice.loadedExercise.exercise.content.mainVideoEnabled = payload;
    clonedCourseSlice.loadedExercise.exercise.content.mainVideoChanged = true;

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.SET_RECAP_ID]: (state: CourseSliceInterface, { payload }: PayloadAction): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    clonedCourseSlice.loadedExercise.exercise.content.recapExerciseId = payload;
    clonedCourseSlice.loadedExercise.exercise.content.recapExerciseIdChanged = true;

    return {
      ...clonedCourseSlice,
    };
  },
  [CommonActions.REMOVE_BUNDLE]: (
    state: CourseSliceInterface,
    { payload }: { payload: string },
  ): CourseSliceInterface => {
    return produce(state, (draft) => {
      draft.loadedExercise.exercise.content[payload]._id = null;

      let {
        emptyLocalizations,
      }: {
        emptyLocalizations: LocalizationInterface[];
      } = ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['emptyLocalizations']);

      const emptyData = {
        _id: null,
        textLocalizations: [...emptyLocalizations],
        audioLocalizations: [...emptyLocalizations],
        videoLocalizations: [...emptyLocalizations],
        imageLocalizations: [...emptyLocalizations],
      };

      draft.loadedExercise.exercise.content[payload].phrase = emptyData;
      draft.loadedExercise.exercise.content[payload].example = emptyData;
      draft.loadedExercise.exercise.content[payload].image = emptyData;
      draft.loadedExercise.exercise.content[payload].video = emptyData;
      draft.loadedExercise.exercise.content[payload].isVocabulary = false;
      draft.loadedExercise.exercise.content[payload].isVocabularyChanged = true;
      draft.loadedExercise.exercise.content[payload].mappings.count = 1;
      draft.loadedExercise.exercise.content[`${payload}Changed`] = true;
    });
  },
  [CommonActions.SET_DEFAULT_CONTEXT_FOR_TRANSLATORS]: (
    state: CourseSliceInterface,
    {
      payload: { contentType, defaultContextValues },
    }: PayloadAction<{
      contentType: ContentTypesType;
      defaultContextValues: Record<keyof GenericContentInterface, string>;
    }>,
  ) => {
    const isExercise = EXERCISES.includes(contentType as ExerciseType);

    return produce(state, (draft) => {
      if (!isExercise) {
        Object.keys(defaultContextValues).forEach((fieldName) => {
          const targetFieldName = fieldName as keyof GenericContentInterface;

          if (draft.selectedGroupsOfParent.parentContents?.[targetFieldName]) {
            draft.selectedGroupsOfParent.parentContents[targetFieldName].description =
              defaultContextValues[targetFieldName];
          }
        });
      }
    });
  },
  [SearchActions.USE_STRING_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    const {
      newString: notFilledNewString,
      contentType,
      visitedBranch,
      row,
      column,
      isCopied,
      isReuse,
      bundleName,
      isBundleReused,
      newPhraseId,
      newExampleId,
    } = payload;
    const newString = {
      ...notFilledNewString,
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['textLocalizations'],
        notFilledNewString.textLocalizations,
      ),
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['audioLocalizations'],
        notFilledNewString.audioLocalizations,
      ),
    };

    let newCourse = clonedState.course;
    let newExercise = clonedState.loadedExercise;
    let newContent = clonedState.selectedGroupsOfParent;
    if (visitedBranch === 'examples') {
      newExercise = {
        ...newExercise,
        // @ts-ignore
        exercise: {
          ...newExercise.exercise,
          content: {
            ...newExercise.exercise.content,
            examplesChanged: true,
          },
        },
      };
    }

    switch (contentType) {
      case ContentTypes.course: {
        newCourse = {
          ...newCourse,
          [visitedBranch]: {
            ...newString,
            _id: newString.id,
            changed: !isReuse,
            isCopied: !!isCopied,
            isReused: !!isReuse,
          },
        };
        break;
      }
      case ContentTypes.exercise: {
        if (bundleName) {
          newExercise = {
            ...newExercise,
            // @ts-ignore
            exercise: {
              ...newExercise.exercise,
              content: {
                ...newExercise.exercise.content,
                [bundleName]: isBundleReused
                  ? {
                      ...newExercise.exercise.content[bundleName],
                      _id: '',
                      phrase: {
                        ...newExercise.exercise.content[bundleName].phrase,
                        _id: newPhraseId,
                        mappings: { count: 1 },
                      },
                      example: {
                        ...newExercise.exercise.content[bundleName].example,
                        _id: newExampleId,
                        mappings: { count: 1 },
                      },
                      isReused: !!isReuse,
                      mappings: isBundleReused ? { count: 1 } : null,
                    }
                  : {
                      ...newExercise.exercise.content[bundleName],
                      [visitedBranch]: {
                        ...newString,
                        _id: newString.id,
                        changed: !isReuse,
                        mappings: { count: 1 },
                        isCopied: !!isCopied,
                        isReused: !!isReuse,
                      },
                    },
              },
            },
          };
        } else {
          newExercise = {
            ...newExercise,
            // @ts-ignore
            exercise: {
              ...newExercise.exercise,
              content: {
                ...newExercise.exercise.content,
                [visitedBranch]:
                  visitedBranch === 'script'
                    ? newExercise.exercise.content[visitedBranch].map((item: any, index: number) =>
                        index === row
                          ? {
                              ...item,
                              line: {
                                ...newString,
                                _id: newString.id,
                                changed: !isReuse,
                                mappings: { count: 1 },
                                isCopied: !!isCopied,
                                isReused: !!isReuse,
                              },
                            }
                          : { ...item },
                      )
                    : visitedBranch === 'examples'
                      ? [
                          ...newExercise.exercise.content[visitedBranch].map((rowItem: any, index: number) =>
                            index === row
                              ? rowItem.map((columnItem: any, index: number) =>
                                  index === column
                                    ? {
                                        ...newString,
                                        _id: newString.id,
                                        changed: !isReuse,
                                        mappings: { count: 1 },
                                        isCopied: !!isCopied,
                                        isReused: !!isReuse,
                                      }
                                    : { ...columnItem },
                                )
                              : [...rowItem],
                          ),
                        ]
                      : {
                          ...newString,
                          _id: newString.id,
                          mappings: { count: 1 },
                          changed: !isReuse,
                          isCopied: !!isCopied,
                          isReused: !!isReuse,
                        },
              },
            },
          };
        }
        break;
      }
      case ContentType.levelOrLesson: {
        newContent = {
          ...newContent,
          // @ts-ignore
          parentContents: {
            ...newContent.parentContents,
            [visitedBranch]: {
              ...newString,
              _id: newString.id,
              changed: !isReuse,
              isReused: !!isReuse,
            },
          },
        };
        break;
      }
      default: {
        console.log('Incorrect type');
      }
    }

    return {
      ...clonedState,
      course: newCourse,
      selectedGroupsOfParent: newContent,
      loadedExercise: newExercise,
    };
  },
};

export default CommonActionsCourseReducers;
