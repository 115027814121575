import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { RoleplaySliceType } from '@common/interfaces/slices/RoleplaySliceType';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';

export const roleplayInitialContent = {
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  id: '',
  owner: null,
  ready: false,
  type: ContentTypes.roleplay,
  validationStatus: {
    validated: false,
    valid: false,
    errors: [{ field: 'children', message: 'This collection should contain 1 element or more.', isWarning: false }],
    invalidChildEntities: [],
  },
};

export const roleplayCategoryInitialContent = {
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  icon: null,
  iconChanged: false,
  id: '',
  ready: false,
  title: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  titleChanged: false,
  type: ContentTypes.roleplayCategory,
  owner: null,
  validationStatus: {
    validated: false,
    valid: false,
    errors: [{ field: 'children', message: 'This collection should contain 1 element or more.', isWarning: false }],
    invalidChildEntities: [],
  },
};

export const roleplayScenarioInitialContent = {
  attachedLessons: [],
  cefr: null,
  cefrChanged: false,
  chatbotGoal: null,
  chatbotGoalChanged: false,
  chatbotRole: null,
  chatbotRoleChanged: false,
  chatbotScenario: null,
  chatbotScenarioChanged: false,
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  description: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  descriptionChanged: false,
  goal: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  goalChanged: false,
  grammar: null,
  grammarChanged: false,
  id: '',
  image: null,
  imageChanged: false,
  mappings: 0,
  mappingsPath: [],
  messageLimit: 6,
  messageLimitChanged: false,
  prompt: null,
  ready: false,
  triggerMessage1: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  triggerMessage1Changed: false,
  sampleDialogue: null,
  sampleDialogueChanged: false,
  title: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  titleChanged: false,
  type: ContentTypes.roleplayScenario,
  owner: null,
  phraseBookPhrase1: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  phraseBookPhrase2: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  phraseBookPhrase3: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  phraseBookPhrase4: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  phraseBookPhrase5: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  phraseBookPhrase1Changed: false,
  phraseBookPhrase2Changed: false,
  phraseBookPhrase3Changed: false,
  phraseBookPhrase4Changed: false,
  phraseBookPhrase5Changed: false,
  validationStatus: {
    validated: false,
    valid: false,
    errors: [],
    invalidChildEntities: [],
  },
  vocabulary: null,
  vocabularyChanged: false,
  voiceGender: 'male',
  voiceGenderChanged: false,
  voiceAccent: null,
  voiceAccentChanged: false,
};

export const roleplayInitialState: RoleplaySliceType = {
  roleplay: {
    content: roleplayInitialContent,
    categoriesData: {
      categories: [],
      loaded: LoadingStage.notLoaded,
    },
    interfaceLanguages: [],
    loaded: LoadingStage.notLoaded,
  },
  roleplayCategory: {
    content: roleplayCategoryInitialContent,
    loaded: LoadingStage.notLoaded,
    scenariosData: {
      scenarios: [],
      loaded: LoadingStage.notLoaded,
    },
  },
  roleplayScenario: {
    content: roleplayScenarioInitialContent,
    loaded: LoadingStage.notLoaded,
  },
  navigation: {
    structure: [],
    loadingParentId: '',
  },
};
