import { ListenRepeatExerciseActions } from '@actions/ListenRepeatExerciseActions';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import type { LanguageV2 } from '@features/content/languages';
import ListenRepeatExerciseInterface from '@components/Exercises/ListenRepeat/interfaces/ListenRepeatExerciseInterface';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';

export const ListenRepeatExerciseActionsCreator = {
  save: (exercise: ListenRepeatExerciseInterface, learningLanguage: LanguageV2, values: FormikValuesInterface) => ({
    type: ListenRepeatExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: ListenRepeatExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setImage: (exercise: AnyExerciseInterface, image: File, progressHandler: (progress: number) => void) => ({
    type: ListenRepeatExerciseActions.UPLOAD_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ExerciseTypes.listenRepeat,
      progressHandler,
    },
  }),
  removeImage: () => ({
    type: ListenRepeatExerciseActions.REMOVE_IMAGE,
  }),
  removeVideo: () => ({
    type: ListenRepeatExerciseActions.REMOVE_VIDEO,
  }),
};
