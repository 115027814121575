import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { CommonActions } from '@actions/CommonActions';
import { DBId } from '@common/types/DBId';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import type { LanguageV2 } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { DialogueExerciseActions } from '@actions/DialogueExerciseActions';
import { ScriptLine } from '@components/Exercises/Dialogue/types/ScriptLine';
import { Character } from '@components/Exercises/Dialogue/types/Character';
import { clone } from '@helpers/clone';

export const DialogueExerciseActionsCreator = {
  setDialogueDescriptionNeeded: (needed: boolean) => ({
    type: DialogueExerciseActions.SET_DIALOGUE_DESCRIPTION_NEEDED,
    payload: {
      needed,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: DialogueExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setDialogueDescriptionLanguage: (dialogueDescriptionLanguage: DisplayContentToUserModeType) => ({
    type: DialogueExerciseActions.SET_DIALOGUE_DESCRIPTION_LANGUAGE,
    payload: {
      dialogueDescriptionLanguage,
    },
  }),
  switchCharacterForDialogueLine: (dialogueLineIndex: number) => ({
    type: DialogueExerciseActions.SWITCH_CHARACTER_FOR_DIALOGUE_LINE,
    payload: {
      dialogueLineIndex,
    },
  }),
  deleteDialogueLine: (dialogueLine: number) => ({
    type: DialogueExerciseActions.DELETE_DIALOGUE_LINE,
    payload: {
      dialogueLine,
    },
  }),
  updateCharacterName: (characterIndex: number, name: string) => ({
    type: DialogueExerciseActions.UPDATE_CHARACTER_NAME,
    payload: {
      characterIndex,
      name,
    },
  }),
  save: (exercise: AnyExerciseInterface, learningLanguage: LanguageV2, values: FormikValuesInterface) => ({
    type: DialogueExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  nullifyDescription: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'dialogueDescription',
    },
  }),
  showDialogueCharactersSelector: () => ({
    type: DialogueExerciseActions.SHOW_DIALOGUE_CHARACTERS_SELECTOR,
  }),
  addScriptLine: (characterIndex: number, position: number) => ({
    type: DialogueExerciseActions.ADD_SCRIPT_LINE,
    payload: {
      characterIndex,
      position,
    },
  }),
  setSelectedCharacters: (character1: Character, character2: Character | undefined) => {
    let characters: Character[];
    let clonedCharacter1 = clone(character1);
    let clonedCharacter2 = character2 === undefined ? undefined : clone(character2);

    if (clonedCharacter2 === undefined) {
      clonedCharacter1.name = 'Character 1';

      characters = [{ ...clonedCharacter1 }];
    } else {
      clonedCharacter1.name = 'Character 1';
      clonedCharacter2.name = 'Character 2';

      characters = [{ ...clonedCharacter1 }, { ...clonedCharacter2 }];
    }

    return {
      type: DialogueExerciseActions.SET_SELECTED_CHARACTERS_SELECTOR,
      payload: {
        characters,
      },
    };
  },
  showDialogueCharactersSelectorToUpdatePhoto: (characterIndex: number, characterImage: string) => ({
    type: DialogueExerciseActions.SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO,
    payload: {
      characterIndex,
      characterImage,
    },
  }),
  updateSelectedCharacters: (updatedCharacter: Character, characterIndex: number) => ({
    type: DialogueExerciseActions.UPDATE_SELECTED_CHARACTERS,
    payload: {
      updatedCharacter,
      characterIndex,
    },
  }),
  updateScriptLinesOrder: (sortedScript: ScriptLine[]) => ({
    type: DialogueExerciseActions.UPDATE_SCRIPT_LINES_ORDER,
    payload: {
      sortedScript,
    },
  }),
  removeAudioFromScript: (scriptLineId: DBId | undefined, courseLearningLanguage: string) => ({
    type: DialogueExerciseActions.REMOVE_SOUND_FROM_DIALOGUE_LINE,
    payload: {
      scriptLineId,
      courseLearningLanguage,
    },
  }),
  updateDialogueLine: (newText: string, scriptLineIndex: number, courseLearningLanguage: LanguageV2) => ({
    type: DialogueExerciseActions.UPDATE_DIALOGUE_LINE,
    payload: {
      newText,
      scriptLineIndex,
      courseLearningLanguage,
    },
  }),
};
