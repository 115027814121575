import { FormikContextType, useFormikContext } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { DBId } from '@common/types/DBId';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { DEFAULT_LANGUAGE_V2, LANGUAGE_NAMES_V2, type LanguageV2 } from '@features/content/languages';

import { RoleplayCategoryFormikValues } from '../../types';

export const Breadcrumbs = () => {
  const history = useHistory();
  const { languageId } = useParams<{ languageId: LanguageV2; categoryId: DBId }>();
  const formikContext: FormikContextType<RoleplayCategoryFormikValues> = useFormikContext();

  const categoryTitle =
    formikContext?.values.title?.find((loc) => loc.language === DEFAULT_LANGUAGE_V2)?.value || 'Roleplay Category';

  return (
    <BreadcrumbNavigation
      breadcrumbItems={[
        <span onClick={() => history.push(`/roleplay/${languageId}`)}>
          {`${LANGUAGE_NAMES_V2[languageId]} Roleplay`}
        </span>,
        <span>{categoryTitle}</span>,
      ]}
    />
  );
};
