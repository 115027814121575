import styled from 'styled-components';
import type { LanguageV2 } from '@features/content/languages';
import audioIcon from './img/audio.svg';
import audioActiveIcon from './img/audioActive.svg';
import { Flag, FlagTextPositionMode } from '@components/Flag';

const AudioAndTranslationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 5.8rem;
  color: ${({ theme }) => theme.color.brandBlack};
  font-size: 1.4rem;
  padding: 0.8rem 1.6rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.darkBlue15};

  &:hover {
    background: ${({ theme }) => theme.color.quaternaryLight2};
  }
`;

const AudioImage = styled.img`
  margin-right: 1.6rem;
`;

const Translation = styled.div<{ isRTL: boolean }>`
  ${({ isRTL }) =>
    isRTL &&
    `
    direction: rtl;
    width: 100%;
    text-align: right;
  `}
`;

const Message = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.deepBlue65};
`;

const StyledFlag = styled(Flag)`
  margin-right: 0.8rem;
`;

type AudioAndTranslationBlockProps = {
  language: LanguageV2;
  audio: string;
  text: string;
};

export const AudioAndTranslationBlock = ({ language, audio, text }: AudioAndTranslationBlockProps) => (
  <AudioAndTranslationWrapper>
    <AudioImage src={audio ? audioActiveIcon : audioIcon} alt="Audio" />
    <StyledFlag countries={language} mode={FlagTextPositionMode.withoutText} />
    {text ? (
      <>
        {text.trim() ? (
          <Translation isRTL={language === 'ar'}>{text || ''}</Translation>
        ) : (
          <Message>Translation contains only spaces</Message>
        )}
      </>
    ) : (
      <Message>No {audio ? '' : 'audio and '}translation</Message>
    )}
  </AudioAndTranslationWrapper>
);
