import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, select } from 'redux-saga/effects';

import { CommonActions } from '@actions/CommonActions';
import { CoursesActions } from '@actions/CoursesActions';
import { RoleplayActions } from '@actions/RoleplayActions';
import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { MediaTypes } from '@common/enums/MediaTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { DBId } from '@common/types/DBId';
import { addToast } from '@features/app/toast';
import { NavigationItemType } from '@features/content/navigation';
import {
  RoleplayCategoryContentType,
  RoleplayCategoryFieldWithLocalizationNames,
  RoleplayCategoryFormikValues,
  RoleplayCategoryListItemType,
  RoleplayListItemType,
  RoleplayScenarioContentType,
  RoleplayScenarioFormikValues,
  RoleplayService,
} from '@features/content/roleplay';
import { LANGUAGE_NAMES_V2, LanguageV2 } from '@features/content/languages';
import { getNewArray } from '@helpers/newAddedElementHelper';
import { roleplayInitialContent } from '@redux/initialStates/roleplayInitialState';
import { courseSlice } from '@redux/slices/courseSlice';
import { AppDispatch } from '@redux/store';
import {
  selectRoleplayCategories,
  selectRoleplayCategoryContent,
  selectRoleplayCategoryScenarios,
  selectRoleplayContent,
  selectRoleplayNavigationStructure,
  selectRoleplayScenarioContent,
} from '@selectors/roleplaySelectors';
import { selectRoleplayTranslationsPanel } from '@selectors/UiSelectors';
import { UploadMediaResponse } from '@services/HelpersService';
import MediaService from '@services/MediaService';
import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';

const getRoleplayGenericTitle = (language: LanguageV2) => `${LANGUAGE_NAMES_V2[language]} Roleplay`;

export const RoleplaySagas = {
  // Roleplay sagas

  *getRoleplay({ payload }: PayloadAction<LanguageV2>) {
    try {
      yield put({ type: RoleplayActions.ROLEPLAY_LOADING });

      const result: Awaited<ReturnType<typeof RoleplayService.getRoleplay>> = yield call(
        RoleplayService.getRoleplay,
        payload,
      );

      if (result.status === 200) {
        yield put({ type: RoleplayActions.ROLEPLAY_LOADED, payload: result.data.roleplay });
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        yield put({ type: RoleplayActions.ROLEPLAY_LOADED, payload: roleplayInitialContent });

        yield put({
          type: RoleplayActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: '',
              title: getRoleplayGenericTitle(payload),
              type: ContentTypes.roleplay,
              children: false,
              ready: false,
              parentId: null,
              expanded: false,
            },
          ],
        });
      }

      console.error(error);
    }
  },
  *getRoleplayCategories({ payload }: PayloadAction<LanguageV2>) {
    try {
      yield put({ type: RoleplayActions.ROLEPLAY_CATEGORIES_LOADING });

      const result: Awaited<ReturnType<typeof RoleplayService.getRoleplayCategories>> = yield call(
        RoleplayService.getRoleplayCategories,
        payload,
      );

      if (result.status === 200) {
        yield put({ type: RoleplayActions.ROLEPLAY_CATEGORIES_LOADED, payload: result.data.roleplayCategories });
      }
    } catch (error) {
      console.error(error);
    }
  },
  *createRoleplayCategory({
    payload: { language, position },
  }: PayloadAction<{ language: LanguageV2; position: number }>) {
    try {
      const roleplayContent: ReturnType<typeof selectRoleplayContent> = yield select(selectRoleplayContent);
      const categories: ReturnType<typeof selectRoleplayCategories> = yield select(selectRoleplayCategories);

      yield put(courseSlice.actions.setIsCreatingContent(position));

      const createResult: Awaited<ReturnType<typeof RoleplayService.createRoleplayCategory>> = yield call(
        RoleplayService.createRoleplayCategory,
        language,
        position,
      );

      yield put(courseSlice.actions.setIsCreatingContent(undefined));

      const newElementId = createResult.data.id;

      if (createResult.status === 200 && newElementId) {
        const newGroupElement = {
          id: newElementId,
          validationStatus: createResult.data.validationStatus,
          changeStatus: {
            hasPendingChanges: false,
            hasNewChanges: false,
          },
          ready: false,
          type: ContentTypes.roleplayCategory,
        };
        const newCategories = getNewArray(categories, newGroupElement, position);

        yield put({
          type: RoleplayActions.ROLEPLAY_CATEGORIES_LOADED,
          payload: newCategories,
        });

        yield call(RoleplaySagas.updateRoleplayNavigation, { language, roleplayId: roleplayContent.id });

        yield call(RoleplaySagas.getValidationResult, {
          type: ContentTypes.roleplay,
        });
      }

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: { newElementId },
      });

      yield delay(2000);

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: {},
      });
    } catch (error) {
      console.error(error);
    }
  },
  *removeRoleplayCategory({ payload }: PayloadAction<{ language: LanguageV2; categoryId: DBId }>) {
    try {
      const roleplayContent: ReturnType<typeof selectRoleplayContent> = yield select(selectRoleplayContent);
      const categories: ReturnType<typeof selectRoleplayCategories> = yield select(selectRoleplayCategories);
      const { language, categoryId } = payload;

      yield put(courseSlice.actions.setIsDeleteInProgress(true));

      const deleteResult: Awaited<ReturnType<typeof RoleplayService.removeRoleplayCategory>> = yield call(
        RoleplayService.removeRoleplayCategory,
        language,
        categoryId,
      );

      if (deleteResult.status === 204) {
        yield put(courseSlice.actions.setIsDeleteInProgress(false));
        const newCategories = categories.filter((category) => category.id !== categoryId);

        yield put({
          type: RoleplayActions.ROLEPLAY_CATEGORIES_LOADED,
          payload: newCategories,
        });

        yield call(RoleplaySagas.updateRoleplayNavigation, { language: language, roleplayId: roleplayContent.id });

        yield call(RoleplaySagas.getValidationResult, {
          type: ContentTypes.roleplay,
        });
      }
    } catch (error) {
      yield put(courseSlice.actions.setIsDeleteInProgress(false));
      console.error(error);
    }
  },
  *reorderRoleplayCategories({
    payload,
  }: PayloadAction<{
    language: LanguageV2;
    destinationIndex: number;
    orderedItems: RoleplayListItemType[];
  }>) {
    try {
      const { language, destinationIndex, orderedItems } = payload;

      const roleplayContent: ReturnType<typeof selectRoleplayContent> = yield select(selectRoleplayContent);

      yield put({
        type: RoleplayActions.ROLEPLAY_SCENARIOS_LOADED,
        payload: orderedItems,
      });

      const categoryId = orderedItems[destinationIndex].id;

      yield call(RoleplayService.reorderRoleplayCategories, language, categoryId, destinationIndex);

      yield call(RoleplaySagas.updateRoleplayNavigation, { language, categoryId, roleplayId: roleplayContent.id });

      yield call(RoleplaySagas.getValidationResult, {
        language,
        type: ContentTypes.roleplay,
      });
    } catch (error) {
      console.error(error);
    }
  },

  // Roleplay Category sagas

  *getRoleplayCategory({ payload }: PayloadAction<DBId>) {
    try {
      yield put({ type: RoleplayActions.ROLEPLAY_CATEGORY_LOADING });

      const result: Awaited<ReturnType<typeof RoleplayService.getRoleplayCategory>> = yield call(
        RoleplayService.getRoleplayCategory,
        payload,
      );

      if (result.status === 200) {
        yield put({ type: RoleplayActions.ROLEPLAY_CATEGORY_LOADED, payload: result.data.roleplayCategory });
      }
    } catch (error) {
      console.error(error);
    }
  },
  *getRoleplayScenarios({ payload }: PayloadAction<DBId>) {
    try {
      yield put({ type: RoleplayActions.ROLEPLAY_SCENARIOS_LOADING });

      const scenariosResult: Awaited<ReturnType<typeof RoleplayService.getRoleplayScenarios>> = yield call(
        RoleplayService.getRoleplayScenarios,
        payload,
      );

      if (scenariosResult.status === 200) {
        yield put({ type: RoleplayActions.ROLEPLAY_SCENARIOS_LOADED, payload: scenariosResult.data.roleplayScenarios });
      }
    } catch (error) {
      console.error(error);
    }
  },
  *createScenario({ payload: { categoryId, position } }: PayloadAction<{ categoryId: DBId; position: number }>) {
    try {
      const scenarios: ReturnType<typeof selectRoleplayCategoryScenarios> = yield select(
        selectRoleplayCategoryScenarios,
      );

      yield put(courseSlice.actions.setIsCreatingContent(position));

      const createResult: Awaited<ReturnType<typeof RoleplayService.createScenario>> = yield call(
        RoleplayService.createScenario,
        categoryId,
        position,
      );

      yield put(courseSlice.actions.setIsCreatingContent(undefined));

      const newElementId = createResult.data.id;

      if (createResult.status === 200 && newElementId) {
        const newGroupElement = {
          id: newElementId,
          validationStatus: createResult.data.validationStatus,
          changeStatus: {
            hasPendingChanges: false,
            hasNewChanges: false,
          },
          ready: false,
          type: ContentTypes.roleplayScenario,
        };
        const newScenarios = getNewArray(scenarios, newGroupElement, position);

        yield put({
          type: RoleplayActions.ROLEPLAY_SCENARIOS_LOADED,
          payload: newScenarios,
        });

        yield call(RoleplaySagas.updateRoleplayCategoryNavigation, { categoryId });

        yield call(RoleplaySagas.getValidationResult, {
          type: ContentTypes.roleplayCategory,
        });
      }

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: { newElementId },
      });

      yield delay(2000);

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: {},
      });
    } catch (error) {
      console.error(error);
    }
  },
  *removeScenario({ payload }: PayloadAction<{ categoryId: DBId; scenarioId: DBId }>) {
    try {
      const scenarios: ReturnType<typeof selectRoleplayCategoryScenarios> = yield select(
        selectRoleplayCategoryScenarios,
      );
      const { categoryId, scenarioId } = payload;

      yield put(courseSlice.actions.setIsDeleteInProgress(true));

      const deleteResult: Awaited<ReturnType<typeof RoleplayService.removeScenario>> = yield call(
        RoleplayService.removeScenario,
        categoryId,
        scenarioId,
      );

      if (deleteResult.status === 204) {
        yield put(courseSlice.actions.setIsDeleteInProgress(false));
        const newScenarios = scenarios.filter((scenario) => scenario.id !== scenarioId);

        yield put({
          type: RoleplayActions.ROLEPLAY_SCENARIOS_LOADED,
          payload: newScenarios,
        });

        yield call(RoleplaySagas.updateRoleplayCategoryNavigation, { categoryId });

        yield call(RoleplaySagas.getValidationResult, {
          type: ContentTypes.roleplayCategory,
        });
      }
    } catch (error) {
      yield put(courseSlice.actions.setIsDeleteInProgress(false));
      console.error(error);
    }
  },
  *reorderScenarios({
    payload,
  }: PayloadAction<{
    categoryId: DBId;
    destinationIndex: number;
    orderedItems: RoleplayCategoryListItemType[];
  }>) {
    try {
      const { categoryId, destinationIndex, orderedItems } = payload;

      yield put({
        type: RoleplayActions.ROLEPLAY_SCENARIOS_LOADED,
        payload: orderedItems,
      });

      const scenarioId = orderedItems[destinationIndex].id;

      yield call(RoleplayService.reorderScenarios, categoryId, scenarioId, destinationIndex);

      yield call(RoleplaySagas.updateRoleplayCategoryNavigation, { categoryId });

      yield call(RoleplaySagas.getValidationResult, {
        type: ContentTypes.roleplayCategory,
      });
    } catch (error) {
      console.error(error);
    }
  },
  *saveCategory(
    dispatch: AppDispatch,
    {
      payload: { values, language, roleplayId, categoryId },
    }: PayloadAction<{
      values: RoleplayCategoryFormikValues;
      language: LanguageV2;
      roleplayId: DBId;
      categoryId: DBId;
    }>,
  ) {
    try {
      yield put({
        type: RoleplayActions.SET_CATEGORY_ALL_VALUES,
        payload: { values },
      });

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: true },
      });

      const roleplayCategoryContent: RoleplayCategoryContentType = yield select(selectRoleplayCategoryContent);

      const payloadForUpdate: Awaited<ReturnType<typeof RoleplayService.getPayloadForCategoryUpdate>> = yield call(
        RoleplayService.getPayloadForCategoryUpdate,
        roleplayCategoryContent,
        values,
        (contentType, fieldName, contentId) => {
          dispatch(
            RoleplayActionCreators.setContentId({
              contentType,
              fieldName,
              contentId,
            }),
          );
        },
      );

      const saveResult: Awaited<ReturnType<typeof RoleplayService.saveCategory>> = yield call(
        RoleplayService.saveCategory,
        categoryId,
        payloadForUpdate,
      );

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: false },
      });

      yield call(RoleplaySagas.updateRoleplayNavigation, { language, roleplayId, categoryId });

      if (saveResult?.status === 200) {
        addToast({
          type: 'success',
          title: 'Content changes have been saved',
        });

        yield put({
          type: RoleplayActions.SET_CATEGORY_TO_NOT_CHANGED,
        });
      }

      yield call(RoleplaySagas.getValidationResult, {
        type: ContentTypes.roleplayCategory,
      });
    } catch (error) {
      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: false,
          updateData: false,
        },
      });

      console.error(error);
    }
  },

  // Roleplay Scenario sagas
  *getScenario({ payload }: PayloadAction<DBId>) {
    try {
      yield put({ type: RoleplayActions.SCENARIO_LOADING });

      const result: Awaited<ReturnType<typeof RoleplayService.getScenario>> = yield call(
        RoleplayService.getScenario,
        payload,
      );

      if (result.status === 200) {
        yield put({ type: RoleplayActions.SCENARIO_LOADED, payload: result.data.roleplayScenario });
      }
    } catch (error) {
      console.error(error);
    }
  },

  *saveScenario(
    dispatch: AppDispatch,
    {
      payload: { values, language, roleplayId, categoryId, scenarioId },
    }: PayloadAction<{
      values: RoleplayScenarioFormikValues;
      language: LanguageV2;
      roleplayId: DBId;
      categoryId: DBId;
      scenarioId: DBId;
    }>,
  ) {
    try {
      yield put({
        type: RoleplayActions.SET_SCENARIO_ALL_VALUES,
        payload: { values },
      });

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: true },
      });

      const roleplayScenarioContent: RoleplayScenarioContentType = yield select(selectRoleplayScenarioContent);

      const payloadForUpdate: Awaited<ReturnType<typeof RoleplayService.getPayloadForScenarioUpdate>> = yield call(
        RoleplayService.getPayloadForScenarioUpdate,
        roleplayScenarioContent,
        values,
        language,
        (contentType, fieldName, contentId) => {
          dispatch(
            RoleplayActionCreators.setContentId({
              contentType,
              fieldName,
              contentId,
            }),
          );
        },
      );

      const saveResult: Awaited<ReturnType<typeof RoleplayService.saveScenario>> = yield call(
        RoleplayService.saveScenario,
        scenarioId,
        payloadForUpdate,
      );

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: false },
      });

      yield call(RoleplaySagas.updateRoleplayNavigation, { language, roleplayId, categoryId });

      if (saveResult?.status === 200) {
        addToast({
          type: 'success',
          title: 'Content changes have been saved',
        });

        yield put({
          type: RoleplayActions.SET_CATEGORY_TO_NOT_CHANGED,
        });
      }
    } catch (error) {
      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: false,
          updateData: false,
        },
      });

      console.error(error);
    }
  },

  // Roleplay Navigation
  *getRoleplayNavigation({ language, roleplayId }: { language: LanguageV2; roleplayId: DBId }) {
    try {
      yield put({ type: RoleplayActions.SET_LOADING_PARENT_ID, payload: roleplayId });

      const roleplayNavigationResult: Awaited<ReturnType<typeof RoleplayService.getRoleplayNavigation>> = yield call(
        RoleplayService.getRoleplayNavigation,
        language,
      );

      if (roleplayNavigationResult.status === 200) {
        yield put({ type: RoleplayActions.SET_LOADING_PARENT_ID, payload: '' });
        return roleplayNavigationResult.data.roleplayCategories.map((category: NavigationItemType) => ({
          ...category,
          parentId: roleplayId,
          type: ContentTypes.roleplayCategory,
        }));
      } else {
        return [];
      }
    } catch (error) {
      yield put({ type: RoleplayActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(error);
    }
  },
  *getRoleplayCategoryNavigation({ categoryId }: { categoryId: DBId }) {
    try {
      yield put({ type: RoleplayActions.SET_LOADING_PARENT_ID, payload: categoryId });

      const categoryNavigationResult: Awaited<ReturnType<typeof RoleplayService.getRoleplayCategoryNavigation>> =
        yield call(RoleplayService.getRoleplayCategoryNavigation, categoryId);

      if (categoryNavigationResult.status === 200) {
        yield put({ type: RoleplayActions.SET_LOADING_PARENT_ID, payload: '' });
        return categoryNavigationResult.data.roleplayScenarios.map((scenario: NavigationItemType) => ({
          ...scenario,
          parentId: categoryId,
          type: ContentTypes.roleplayScenario,
        }));
      }
    } catch (error) {
      yield put({ type: RoleplayActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(error);
    }
  },
  *getAllNavigation({
    payload: { language, roleplayId, categoryId, scenarioId },
  }: PayloadAction<{ language: LanguageV2; roleplayId?: DBId; categoryId?: DBId; scenarioId?: DBId }>) {
    try {
      const roleplayContent: ReturnType<typeof selectRoleplayContent> = yield select(selectRoleplayContent);
      const navigation: ReturnType<typeof selectRoleplayNavigationStructure> = yield select(
        selectRoleplayNavigationStructure,
      );
      let categories = [];
      let scenarios = [];

      if (roleplayId && !navigation.filter((elem) => elem.parentId === roleplayId).length) {
        categories = yield call(RoleplaySagas.getRoleplayNavigation, { language, roleplayId });
      }

      if (categoryId && !navigation.filter((elem) => elem.parentId === categoryId).length) {
        scenarios = yield call(RoleplaySagas.getRoleplayCategoryNavigation, { categoryId });
      }

      if (categories.length || scenarios.length) {
        yield put({
          type: RoleplayActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: roleplayId,
              title: getRoleplayGenericTitle(language),
              type: ContentTypes.roleplay,
              children: !!categoryId || !!categories.length,
              ready: roleplayContent.ready,
              parentId: null,
              expanded: true,
            },
            ...categories.map((category: NavigationItemType) => ({
              ...category,
              expanded: category.id === categoryId,
            })),
            ...scenarios.map((scenario: NavigationItemType) => ({ ...scenario, expanded: scenario.id === scenarioId })),
          ],
        });
      }
    } catch (error) {}
  },
  *updateRoleplayNavigation({
    language,
    roleplayId,
    categoryId,
  }: {
    language: LanguageV2;
    roleplayId: DBId;
    categoryId?: DBId;
  }) {
    try {
      const roleplayContent: ReturnType<typeof selectRoleplayContent> = yield select(selectRoleplayContent);
      const navigation: ReturnType<typeof selectRoleplayNavigationStructure> = yield select(
        selectRoleplayNavigationStructure,
      );

      let categoriesNavigation = [];

      if (language) {
        categoriesNavigation = yield call(RoleplaySagas.getRoleplayNavigation, { language, roleplayId });
      }

      yield put({
        type: RoleplayActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation.filter((element) => element.parentId !== roleplayId && element.type !== ContentTypes.roleplay),
          {
            id: roleplayId,
            title: getRoleplayGenericTitle(language),
            type: ContentTypes.roleplay,
            children: !!categoriesNavigation.length,
            ready: roleplayContent.ready,
            parentId: null,
            expanded: true,
          },
          ...categoriesNavigation.map((category: NavigationItemType) => ({
            ...category,
            expanded: categoryId === category.id,
          })),
        ],
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  *updateRoleplayCategoryNavigation({ categoryId }: { categoryId: DBId }) {
    try {
      const navigation: ReturnType<typeof selectRoleplayNavigationStructure> = yield select(
        selectRoleplayNavigationStructure,
      );

      let scenariosNavigation = [];

      if (categoryId) {
        scenariosNavigation = yield call(RoleplaySagas.getRoleplayCategoryNavigation, { categoryId });
      }

      yield put({
        type: RoleplayActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation
            .filter((element) => element.parentId !== categoryId)
            .map((element) => ({
              ...element,
              expanded: categoryId === element.id ? scenariosNavigation.length : element.expanded,
              children: categoryId === element.id ? scenariosNavigation.length : element.children,
            })),
          ...scenariosNavigation,
        ],
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  },

  // Translation Panel sagas
  *uploadAudio({
    payload,
  }: PayloadAction<{
    localization: LocalizationInterface;
    uploadedSound: File | null;
    progressHandler?: (progress: number) => void;
  }>) {
    try {
      const { localization, uploadedSound, progressHandler } = payload;
      const roleplayTranslationsPanel: ReturnType<typeof selectRoleplayTranslationsPanel> = yield select(
        selectRoleplayTranslationsPanel,
      );

      const { type, fieldName, visitedContentId } = roleplayTranslationsPanel;

      yield put({
        type: CommonActions.AUDIO_UPLOADING_STARTED,
        payload: { audioField: fieldName, language: localization.language },
      });

      const roleplayCategory: RoleplayCategoryContentType = yield select(selectRoleplayCategoryContent);
      // const scenario: RoleplayScenarioContentType = yield select(selectRoleplayScenarioContent);

      let contentData = roleplayCategory[fieldName];

      /*
      if (type === ContentTypes.roleplayScenario) {
        contentData = scenario[fieldName];
      }
      */

      if (uploadedSound && contentData) {
        const { mediaId, mediaURL }: UploadMediaResponse = yield MediaService.uploadMedia(
          MediaTypes.audio,
          uploadedSound,
          undefined,
          undefined,
          visitedContentId,
          progressHandler,
          contentData,
          localization.language,
        );

        yield put({
          type: RoleplayActions.SET_AUDIO,
          payload: { localization, fieldName, mediaURL, type, mediaId },
        });
      } else {
        yield put({
          type: RoleplayActions.REMOVE_AUDIO,
          payload: { localization, fieldName, type },
        });
      }
    } catch (error: any) {
      const { localization } = payload;
      const roleplayTranslationsPanel: ReturnType<typeof selectRoleplayTranslationsPanel> = yield select(
        selectRoleplayTranslationsPanel,
      );

      const { fieldName } = roleplayTranslationsPanel;

      yield put({
        type: CommonActions.AUDIO_UPLOADING_FINISHED,
        payload: {
          audioField: fieldName,
          language: localization.language,
          details: error?.message,
        },
      });
      console.error(error);
    }
  },

  // Common Roleplay sagas
  *getValidationResult(payload: { type: string; language?: LanguageV2 }) {
    try {
      const roleplayCategoryContent: RoleplayCategoryContentType = yield select(selectRoleplayCategoryContent);
      const roleplayScenarioContent: RoleplayScenarioContentType = yield select(selectRoleplayScenarioContent);

      yield put({
        type: CommonActions.VALIDATION_IS_LOADING,
        payload: {
          value: true,
        },
      });

      let validationResult: Awaited<ReturnType<typeof RoleplayService.getValidationResult>> | null = null;

      switch (payload.type) {
        case ContentTypes.roleplay:
          validationResult = yield call(
            RoleplayService.getValidationResult,
            ContentTypes.roleplay,
            payload.language as LanguageV2,
          );
          break;

        case ContentTypes.roleplayCategory:
          validationResult = yield call(
            RoleplayService.getValidationResult,
            ContentTypes.roleplayCategory,
            roleplayCategoryContent.id,
          );
          break;

        case ContentTypes.roleplayScenario:
          validationResult = yield call(
            RoleplayService.getValidationResult,
            ContentTypes.roleplayScenario,
            roleplayScenarioContent.id,
          );
          break;

        default:
          console.log('Unknown Roleplay type');
          break;
      }

      if (validationResult?.status === 200) {
        yield put({
          type: CommonActions.VALIDATION_IS_LOADING,
          payload: {
            value: false,
          },
        });

        yield put({
          type: RoleplayActions.SET_VALIDATION_RESULT,
          payload: {
            type: payload.type,
            data: validationResult.data,
          },
        });
      }
    } catch (error: any) {
      yield put({
        type: CommonActions.VALIDATION_IS_LOADING,
        payload: {
          value: false,
        },
      });

      console.error(error);
    }
  },
  *removeString({
    payload: { contentType, fieldName },
  }: PayloadAction<{ contentType: ContentTypesType; fieldName: RoleplayCategoryFieldWithLocalizationNames }>) {
    try {
      const roleplayCategory: RoleplayCategoryContentType = yield select(selectRoleplayCategoryContent);
      const roleplayScenario: RoleplayScenarioContentType = yield select(selectRoleplayScenarioContent);

      let stringToRemove: TranslationsPanelContentInterface | null;

      switch (contentType) {
        case ContentTypes.roleplayCategory: {
          stringToRemove = roleplayCategory[fieldName];
          break;
        }
        case ContentTypes.roleplayScenario: {
          stringToRemove = roleplayScenario[fieldName];
          break;
        }
        default: {
          stringToRemove = null;
        }
      }

      if (stringToRemove) {
        const { textLocalizations, audioLocalizations, imageLocalizations, videoLocalizations } = stringToRemove;
        const newTextLocaliz =
          textLocalizations?.map((loc) => ({ ...loc, value: '', phoneticValue: '', _id: '' })) || [];
        const newAudioLocaliz = audioLocalizations?.map((loc) => ({ ...loc, value: '', _id: '' })) || [];
        const newImageLocaliz = imageLocalizations?.map((loc) => ({ ...loc, value: '', _id: '' })) || [];
        const newVideoLocaliz = videoLocalizations?.map((loc) => ({ ...loc, value: '', _id: '' })) || [];

        const newString = {
          _id: '',
          id: '',
          description: '',
          textLocalizations: newTextLocaliz,
          audioLocalizations: newAudioLocaliz,
          imageLocalizations: newImageLocaliz,
          videoLocalizations: newVideoLocaliz,
        };

        yield put({
          type: RoleplayActions.SET_STRING,
          payload: { newString, contentType, fieldName },
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
};
