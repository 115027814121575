import { takeLatest } from 'redux-saga/effects';

import { VocabularyReviewActions } from '@actions/VocabularyReviewActions';
import type { AppDispatch } from '@redux/store';
import { VocabularyReviewSagas } from '@sagas/vocabularyReview/definitions/VocabularyReviewSagasDefinition';

export function* VocabularyReviewRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(VocabularyReviewActions.GET_LEXICAL_ITEM, VocabularyReviewSagas.getLexicalItem);
  yield takeLatest(VocabularyReviewActions.REMOVE_AUDIO, VocabularyReviewSagas.removeAudioFromLexicalItem);
  yield takeLatest(VocabularyReviewActions.REMOVE_STRING, VocabularyReviewSagas.removeStringFromLexicalItem);
  yield takeLatest(VocabularyReviewActions.SAVE_LEXICAL_ITEM, VocabularyReviewSagas.saveLexicalItem, dispatch);
}
