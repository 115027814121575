import { Formik } from 'formik';
import { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DialogueCharactersPanel } from '@components/Exercises/Dialogue/DialogueCharactersPanel';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { DBId } from '@common/types/DBId';
import ExerciseLoader from '@components/ExerciseLoader/ExerciseLoader';
import TranslationsPanel from '@components/TranslationsPanel/TranslationsPanel';
import { ContentTypes } from '@common/enums/ContentTypes';
import { ContentOwner } from '@features/content/contentOwnership';
import { SearchModal } from '@features/search';
import { Loader } from '@features/theme';
import { getInitialValuesForExercise } from '@helpers/formikInitialValuesHelper';

import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { Header } from '../Header';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { Breadcrumbs } from './Breadcrumbs';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse, selectLoadedExercise } from '@selectors/CoursesSelectors';
import { selectGrammarExercise } from '@selectors/GrammarSelectors';
import { selectDialogueCharactersPanelVisible, selectTranslationsPanelVisible } from '@selectors/UiSelectors';

export const GrammarExercise = () => {
  const dispatch = useAppDispatch();
  const { courseId, topicId, exerciseId } = useParams<{
    courseId: DBId;
    topicId: DBId;
    exerciseId: DBId;
  }>();

  const currentCourse = useAppSelector(selectCourse);

  const loadedExercise: AnyLoadedExercise | null = useAppSelector(selectLoadedExercise);

  const grammarExercise = useAppSelector(selectGrammarExercise);
  const dialogueCharactersPanelVisible = useAppSelector(selectDialogueCharactersPanelVisible);
  const translationsPanelVisible = useAppSelector(selectTranslationsPanelVisible);

  useEffect(() => {
    dispatch(GrammarReviewActionCreators.setInitialGrammarExercise());
  }, [dispatch, exerciseId]);

  useEffect(() => {
    if (grammarExercise.loaded === LoadingStage.notLoaded && courseId && topicId && exerciseId) {
      dispatch(CourseEditionActionsCreator.getExercise(courseId, exerciseId, topicId));
    }
  }, [dispatch, courseId, grammarExercise, topicId, exerciseId]);

  const renderExerciseContent = () => {
    return (
      <>
        {translationsPanelVisible && (
          <TranslationsPanel
            content={loadedExercise.exercise.content}
            type={ContentTypes.exercise}
            exerciseType={loadedExercise.exercise.type}
          />
        )}
        {dialogueCharactersPanelVisible && <DialogueCharactersPanel />}

        <div className="course-edition__course-data-composer">
          <SearchModal />
          {currentCourse && (
            <Header
              contentName="Grammar review"
              learningLanguage={currentCourse.learningLanguage}
              interfaceLanguages={currentCourse.interfaceLanguages}
            />
          )}
          {loadedExercise.loaded === LoadingStage.loading ? (
            <Loader size="L" />
          ) : loadedExercise.exercise ? (
            <>
              <BreadcrumbsAndOwnershipWrapper>
                <Breadcrumbs />
                <ContentOwner
                  componentId={exerciseId}
                  contentType={ContentTypes.exercise}
                  dates={loadedExercise.exercise.content.dates}
                  owner={loadedExercise.exercise.content.owner}
                />
              </BreadcrumbsAndOwnershipWrapper>
              <ExerciseLoader loadedExercise={loadedExercise} />
            </>
          ) : null}
        </div>
      </>
    );
  };

  if (loadedExercise.loaded === LoadingStage.loading) {
    return renderExerciseContent();
  }

  return (
    <Formik initialValues={getInitialValuesForExercise(loadedExercise?.exercise)} onSubmit={() => {}}>
      {renderExerciseContent()}
    </Formik>
  );
};

export const GrammarExerciseContent = withRouter(GrammarExercise);
