import styled from 'styled-components/macro';

import { UserInterface } from '@common/interfaces/users/UserInterface';
import { SortableFieldType, orderTriangle } from '@features/dashboard';
import { useUserList } from '@features/users';

import { StyledTableRow } from './TableRow';
import { UserItem } from './UserItem/UserItem';

interface TableProps {
  users: UserInterface[];
  orderByField: SortableFieldType;
  sortOrder: (newFieldOrder: SortableFieldType) => void;
  filteredUsers: UserInterface[];
  visibleUsers: UserInterface[];
  activeUser: UserInterface | null;
}

const StyledTable = styled.div`
  margin-bottom: 6rem;
`;

const StyledTableNoResults = styled.span`
  display: block;
  font-size: 2.4rem;
  padding: 1rem;
  text-align: center;
`;

const StyledTableColumHeader = styled.span<{ onClick?: () => void }>`
  font-weight: 700;

  ${({ onClick }) => onClick && 'cursor: pointer;'}
`;

export const Table = ({ users, sortOrder, orderByField, filteredUsers, visibleUsers, activeUser }: TableProps) => {
  const userIndex = users.reduce((index: Record<string, UserInterface>, user) => {
    index[user.id] = user;

    return index;
  }, {});

  const { userList } = useUserList({ storageKey: 'users-non-viewer', excludedRoles: ['ROLE_VIEWER'] });

  const getUser = (id: string) => userIndex[id] || null;
  const getAvailableManagersForUser = (userId: string) =>
    users.filter((user) => userId === user.id || user.manager !== null);

  return (
    <StyledTable>
      <StyledTableRow>
        <StyledTableColumHeader
          onClick={() => {
            sortOrder({
              field: 'lastName',
              ascending: !orderByField.ascending,
            });
          }}
        >
          User {orderTriangle(orderByField, 'lastName')}
        </StyledTableColumHeader>
        <StyledTableColumHeader
          onClick={() => {
            sortOrder({
              field: 'position',
              ascending: !orderByField.ascending,
            });
          }}
        >
          Position {orderTriangle(orderByField, 'position')}
        </StyledTableColumHeader>
        <StyledTableColumHeader
          onClick={() => {
            sortOrder({
              field: 'manager',
              ascending: !orderByField.ascending,
            });
          }}
        >
          Manager {orderTriangle(orderByField, 'manager')}
        </StyledTableColumHeader>
        <StyledTableColumHeader
          onClick={() => {
            sortOrder({
              field: 'role',
              ascending: !orderByField.ascending,
            });
          }}
        >
          Role {orderTriangle(orderByField, 'role')}
        </StyledTableColumHeader>
        <StyledTableColumHeader>Course language</StyledTableColumHeader>
        <StyledTableColumHeader
          onClick={() => {
            sortOrder({
              field: 'joinedAt',
              fieldType: 'date',
              ascending: !orderByField.ascending,
            });
          }}
        >
          Joined {orderTriangle(orderByField, 'joinedAt')}
        </StyledTableColumHeader>
        <StyledTableColumHeader
          onClick={() => {
            sortOrder({
              field: 'lastLoginAt',
              fieldType: 'date',
              ascending: !orderByField.ascending,
            });
          }}
        >
          Last Login {orderTriangle(orderByField, 'lastLoginAt')}
        </StyledTableColumHeader>
        <span />
        <span />
      </StyledTableRow>
      {filteredUsers.length ? (
        <>
          {visibleUsers.map((user) => (
            <UserItem
              activeUserId={activeUser?.id}
              key={user.id}
              user={user}
              userList={userList}
              getUser={getUser}
              getAvailableManagersForUser={getAvailableManagersForUser}
            />
          ))}
        </>
      ) : (
        <StyledTableNoResults>No results</StyledTableNoResults>
      )}
    </StyledTable>
  );
};
