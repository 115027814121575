import { ChangeEvent, useState } from 'react';

import { LanguageV2 } from '@features/content/languages';
import { Button, Text } from '@features/theme';

import { LEXICAL_ITEM_TMP_ID } from '../../constants';
import {
  ErrorLabelWrapper,
  MatchLexicalItemCreateModalBody,
  MatchLexicalItemModalButtonsArea,
  MatchLexicalItemCreateModalDescription,
  MatchLexicalItemModalWrapper,
  PhraseInput,
  PhraseLabel,
} from '../styles';
import type { MatchLexicalItemCreatorOption } from '../types';
import { ErrorLabel } from '@components/ValidationErrorDisplayer';

type MatchLexicalItemCreateModalProps = {
  language: LanguageV2;
  close: () => void;
  checkDuplicates: (query: string) => Promise<number>;
  onCreate: (value: MatchLexicalItemCreatorOption) => void;
  onSearch: (query: string) => void;
};

export const MatchLexicalItemCreateModal = ({
  checkDuplicates,
  close,
  onCreate,
  onSearch,
}: MatchLexicalItemCreateModalProps) => {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [phrase, setPhrase] = useState('');
  const createButtonLabel = isDuplicate ? 'Create Anyway' : 'Create';

  const cleanUp = () => {
    setIsDuplicate(false);
    setPhrase('');
  };

  const onPhraseInputChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target;

    setPhrase(value);
  };

  const handleOnCreate = async (phrase: string) => {
    // check if the lexical item to be created might be a duplicate on creating first attemp
    if (!isDuplicate) {
      const resultsCount = await checkDuplicates(phrase);

      if (resultsCount > 0) {
        setIsDuplicate(true);
        return;
      }
    }

    onCreate({
      label: phrase,
      value: LEXICAL_ITEM_TMP_ID,
    });
    cleanUp();
    close();
  };

  const handleOnClose = () => {
    cleanUp();
    close();
  };

  const handleOnSearch = (phrase: string) => {
    cleanUp();
    onSearch(phrase);
    close();
  };

  return (
    <MatchLexicalItemModalWrapper>
      <Text variant="header" level={3}>
        Create new lexical item
      </Text>
      <MatchLexicalItemCreateModalDescription variant="body" level={2}>
        Once created, the lexical item will be linked to the current exercise.
      </MatchLexicalItemCreateModalDescription>
      <MatchLexicalItemCreateModalBody>
        <PhraseLabel level={2}>Phrase</PhraseLabel>
        <PhraseInput errored={isDuplicate} value={phrase} onChange={onPhraseInputChange} />
        {isDuplicate && (
          <ErrorLabelWrapper>
            <ErrorLabel text="This Lexical item already exists!" />
          </ErrorLabelWrapper>
        )}
      </MatchLexicalItemCreateModalBody>
      <MatchLexicalItemModalButtonsArea>
        {isDuplicate && (
          <Button disabled={!phrase} variant="text" onClick={() => handleOnSearch(phrase)}>
            Try to match
          </Button>
        )}
        <Button disabled={!phrase} fullWidth onClick={() => handleOnCreate(phrase)}>
          {createButtonLabel}
        </Button>
        <Button fullWidth variant="secondary" onClick={handleOnClose}>
          Cancel
        </Button>
      </MatchLexicalItemModalButtonsArea>
    </MatchLexicalItemModalWrapper>
  );
};
