import { Button } from '@features/theme';

import { VocabularyReviewActionsCreator } from '@actionCreators/VocabularyReviewActionsCreator';
import { useIsEditAvailable } from '@features/content/courses';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsSaveButtonEnabled } from '@selectors/CoursesSelectors';

import { useVocabularyReview } from '../useVocabularyReview';

export const TabFooter = () => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);

  const { onSave } = useVocabularyReview();

  return (
    <footer className="translations-panel-modal__save-cancel">
      <Button variant="text" onClick={() => dispatch(VocabularyReviewActionsCreator.closeTranslationsPanel())}>
        Cancel
      </Button>
      <Button
        disabled={!isEditAvailable || !isSaveButtonEnabled}
        onClick={() => {
          onSave();
          dispatch(VocabularyReviewActionsCreator.closeTranslationsPanel());
        }}
      >
        Save
      </Button>
    </footer>
  );
};
