import styled from 'styled-components';

import { Flag } from '@components/Flag';
import { useIsEditAvailable } from '@features/content/courses';
import { type LanguageV2, LANGUAGE_NAMES_V2 } from '@features/content/languages';

const LanguagesWrapper = styled.div`
  display: flex;
`;

const LearningLanguage = styled.h4`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 0.5rem;
  &:after {
    content: '·';
    margin-left: 0.5rem;
  }
`;

type LanguagesDisplayerProps = {
  learningLanguage: LanguageV2;
  interfaceLanguages: LanguageV2[];
  onInterfaceLanguagesClick?: () => void;
};

export const LanguagesDisplayer = ({
  learningLanguage,
  interfaceLanguages,
  onInterfaceLanguagesClick,
}: LanguagesDisplayerProps) => {
  const { isEditAvailable } = useIsEditAvailable();

  return (
    <LanguagesWrapper>
      <LearningLanguage>{LANGUAGE_NAMES_V2[learningLanguage]}</LearningLanguage>
      <Flag
        countries={interfaceLanguages}
        onClick={() => {
          if (isEditAvailable && onInterfaceLanguagesClick) {
            onInterfaceLanguagesClick();
          }
        }}
      />
    </LanguagesWrapper>
  );
};
