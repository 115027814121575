import { useState } from 'react';

import { FlagTextPositionMode } from '@components/Flag';
import { LanguageSwitchButton, StyledFlag, TranslationsLanguageSwitch } from '@components/TranslationTip/styles';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { useAppSelector } from '@redux/store';
import { selectLearningLanguage } from '@selectors/CoursesSelectors';

type LanguageSwitcherProps = {
  onLanguageSwitchToggled: (switchedToEnglishLanguage: boolean, isPhoneticSelected: boolean) => void;
};

export const LanguageSwitcher = ({ onLanguageSwitchToggled }: LanguageSwitcherProps) => {
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);

  const [switchedToEnglishLanguage, setSwitchedToEnglishLanguage] = useState(true);
  const [isPhonetic, setIsPhonetic] = useState(false);

  const handleChangeLanguage = (toEnglish: boolean, isPhoneticSelected: boolean) => {
    setSwitchedToEnglishLanguage(toEnglish);
    setIsPhonetic(isPhoneticSelected);
    onLanguageSwitchToggled(toEnglish, isPhoneticSelected);
  };

  return (
    <>
      {courseLearningLanguage !== DEFAULT_LANGUAGE_V2 && (
        <TranslationsLanguageSwitch>
          <LanguageSwitchButton
            onClick={() => handleChangeLanguage(true, false)}
            onMouseDown={(evt: MouseEvent) => evt.preventDefault()}
          >
            <StyledFlag
              isActive={switchedToEnglishLanguage}
              mode={FlagTextPositionMode.withoutText}
              countries={[DEFAULT_LANGUAGE_V2]}
            />
          </LanguageSwitchButton>
          <LanguageSwitchButton
            onClick={() => handleChangeLanguage(false, false)}
            onMouseDown={(evt: MouseEvent) => evt.preventDefault()}
          >
            <StyledFlag
              isActive={!switchedToEnglishLanguage && !isPhonetic}
              mode={FlagTextPositionMode.withoutText}
              countries={[courseLearningLanguage]}
            />
          </LanguageSwitchButton>
        </TranslationsLanguageSwitch>
      )}
    </>
  );
};
