import { Logo } from '@features/app/logo';
import type { LanguageV2 } from '@features/content/languages';
import {
  NavigationPanelFooter,
  NavigationPanelContainer,
  LogoAndNavigationContainer,
} from '@features/content/navigation';

import { Navigation } from './Navigation';

export const NavigationPanel = ({ language }: { language: LanguageV2 }) => (
  <NavigationPanelContainer>
    <LogoAndNavigationContainer>
      <Logo size="small" className="course-edition__logo" withLink />
      <Navigation />
    </LogoAndNavigationContainer>
    <NavigationPanelFooter />
  </NavigationPanelContainer>
);
