import { useState } from 'react';

import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import {
  ContentTypes,
  ContentTypesForCreateContent,
  type ContentTypesForCreateContentType,
} from '@common/enums/ContentTypes';
import { LESSON_CHECKPOINT, LESSON_LESSON, LESSON_SPEAKING, LessonType } from '@common/enums/LessonTypes';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';
import { userTracking } from '@features/app/tracking';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse, selectSelectedGroupsOfParent } from '@selectors/CoursesSelectors';
import aiRoleplayLessonIcon from '@static/svg/contentCreatorWidget/roleplayLesson.svg';
import certificateIcon from '@static/svg/contentCreatorWidget/certificate.svg';
import checkpointIcon from '@static/svg/contentCreatorWidget/checkpoint.svg';
import lessonIcon from '@static/svg/contentCreatorWidget/lesson.svg';
import liveLessonIcon from '@static/svg/contentCreatorWidget/liveLesson.svg';
import reviewIcon from '@static/svg/contentCreatorWidget/review.svg';
import speakingLessonIcon from '@static/svg/contentCreatorWidget/speakingLesson.svg';

import { CertificateWarningModal } from './CertificateModal';

type LessonCreatorProps = {
  isCreateInProgress?: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  position: number;
  onCreateContent: (itemType: typeof ContentTypes.lesson, itemCategory: ContentTypesForCreateContentType) => void;
};

const LESSON_CREATOR_ITEMS: ContentCreatorWidgetItemType<LessonType>[] = [
  {
    label: 'Lesson',
    iconSrc: lessonIcon,
    showByDefault: true,
    type: ContentTypes.lesson,
  },
  {
    label: 'Review',
    iconSrc: reviewIcon,
    showByDefault: true,
    type: ContentTypes.review,
  },
  {
    label: 'Checkpoint',
    iconSrc: checkpointIcon,
    showByDefault: !isFeatureEnabled('speakingBites'),
    type: ContentTypes.checkpoint,
  },
  {
    label: 'Certificate',
    iconSrc: certificateIcon,
    showByDefault: !isFeatureEnabled('live-lessons'),
    type: ContentTypes.certificate,
  },
];

if (isFeatureEnabled('speakingBites')) {
  LESSON_CREATOR_ITEMS.splice(2, 0, {
    label: 'Speaking',
    iconSrc: speakingLessonIcon,
    showByDefault: true,
    type: LESSON_SPEAKING,
  });
}

if (isFeatureEnabled('aiRoleplay')) {
  const aiRoleplayLessonItemIndex = isFeatureEnabled('speakingBites') ? 3 : 2;

  LESSON_CREATOR_ITEMS.splice(aiRoleplayLessonItemIndex, 0, {
    label: 'AI Roleplay',
    iconSrc: aiRoleplayLessonIcon,
    showByDefault: true,
    type: ContentTypesForCreateContent.roleplay,
  });
}

if (isFeatureEnabled('live-lessons')) {
  LESSON_CREATOR_ITEMS.splice(3, 0, {
    label: 'Live',
    iconSrc: liveLessonIcon,
    showByDefault: false,
    type: ContentTypes.liveLesson,
  });
}

export const LessonCreator = ({
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
}: LessonCreatorProps) => {
  const dispatch = useAppDispatch();
  const course = useAppSelector(selectCourse);
  const selectedGroupsOfParent = useAppSelector(selectSelectedGroupsOfParent);
  const lessonsList = selectedGroupsOfParent.groups.filter((lesson) => lesson.type === ContentTypes.lesson);
  const areCommonLessonsInList = !!lessonsList.filter((lesson) => lesson.lessonType === LESSON_LESSON).length;
  const isPositionBeforeLessons = !areCommonLessonsInList || position < lessonsList.length;
  const isCheckpointAlreadyInList = lessonsList.some((lesson) => lesson.lessonType === LESSON_CHECKPOINT);

  const [showCertificateWarning, setShowCertificateWarning] = useState(false);

  const isCheckpointCreationBlocked = isPositionBeforeLessons || isCheckpointAlreadyInList;
  const isLessonCreationBlocked = isCheckpointAlreadyInList && position >= lessonsList.length;

  let lessonCreatorItems = LESSON_CREATOR_ITEMS;

  if (isCheckpointCreationBlocked) {
    lessonCreatorItems = lessonCreatorItems.map((item) => {
      if (item.type === ContentTypes.checkpoint)
        return {
          ...item,
          disabled: true,
          title: isCheckpointAlreadyInList
            ? 'A Checkpoint was already created'
            : 'Checkpoints must be placed after all other lessons',
        };

      return item;
    });
  }

  if (isLessonCreationBlocked) {
    lessonCreatorItems = lessonCreatorItems.map((item) => {
      if (item.type === ContentTypes.checkpoint) return item;

      return { ...item, disabled: true };
    });
  }

  const handleOnItemSelected = (itemCategory: ContentTypesForCreateContentType) => {
    if (itemCategory === ContentTypes.certificate && !course.isComplete) {
      setShowCertificateWarning(true);
    } else {
      onCreateContent(ContentTypes.lesson, itemCategory);
    }
  };

  const onSearch = () => {
    dispatch(
      SearchModalActionsCreator.showSearchV2Panel({
        contentType: ContentTypes.lesson,
        forReusing: true,
        predefinedType: 'lesson',
        position,
      }),
    );
    userTracking.logosSearchOpened({
      source: 'content_creator',
    });
  };

  return (
    <>
      <ContentCreatorWidget
        isCreateInProgress={isCreateInProgress}
        isEditAvailable={isEditAvailable}
        isPreEnabled={isPreEnabled}
        items={lessonCreatorItems}
        position={position}
        title="Create a first lesson to get started"
        onItemSelected={(item) => handleOnItemSelected(item.type)}
        onSearch={!isLessonCreationBlocked ? onSearch : undefined}
      />
      <CertificateWarningModal
        opened={showCertificateWarning}
        onConfirm={() => {
          onCreateContent(ContentTypes.lesson, ContentTypes.certificate);
          setShowCertificateWarning(false);
        }}
        onClose={() => setShowCertificateWarning(false)}
      />
    </>
  );
};
