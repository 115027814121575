import { FunctionComponent } from 'react';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { useIsEditAvailable } from '@features/content/courses';
import { Loader } from '@features/theme';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { ContentType, ContentTypes } from '@common/enums/ContentTypes';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { camelCaseSeparateWords } from '@helpers/camel-case-separator';
import { ComponentOtherPlacesDisplayer } from '@components/ComponentOtherPlacesDisplayer';
import { getDescriptionForActivity } from '@helpers/contentCardHelper';
import { DraggableList } from '@components/DraggableList/DraggableList';
import { LoadingStage } from '@common/enums/LoadingStage';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { type LanguageV2, LANGUAGE_NAMES_V2 } from '@features/content/languages';
import { isLegacyActivity } from '@features/content/activities';
import { BulkPublishingWrapper } from '@features/content/exercises';
import {
  selectCourse,
  selectLoadedExercises,
  selectSelectedGroupsOfParentParentContents,
} from '@selectors/CoursesSelectors';
import { arrayMoveImmutable } from 'array-move';
import { clone } from '@helpers/clone';

export const ActivityMainData: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  const exercisesOfParent = useAppSelector(selectLoadedExercises);
  const loadedCourse = useAppSelector(selectCourse);
  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents) as ActivityInterface;

  const childrenValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'children' || error.field === 'exercises',
  );

  const description = getDescriptionForActivity(loadedContent?.activityType, isLegacyActivity(loadedContent))?.replace(
    '[COURSE LANGUAGE]',
    LANGUAGE_NAMES_V2[loadedCourse.learningLanguage as LanguageV2],
  );

  return (
    <div className="course-composer">
      <div className="course-edition__first-top-element">
        <div className="course-edition__publish-status-and-other-places-container">
          <PublishingStatus ready={loadedContent.ready} changeStatus={loadedContent.changeStatus} />
          <ComponentOtherPlacesDisplayer
            mappings={loadedContent?.mappings}
            mappingsPath={loadedContent?.mappingsPath}
          />
        </div>
        <div className="content-common-header__activity-details">
          <IDDisplayer id={loadedContent.id} mode="normal" />
          <div className="content-common-header__content-title content-common-header__content-title--fixed-height">
            {loadedContent?.activityType && (
              <span>
                {isLegacyActivity(loadedContent)
                  ? "Logos doesn't display autogenerated exercises."
                  : camelCaseSeparateWords((loadedContent as ActivityInterface).activityType)}
              </span>
            )}
          </div>
          <span className="content-common-header__description">{description}</span>
        </div>
        {!isEditAvailable && <AccessWarning />}
      </div>
      {exercisesOfParent.loaded !== LoadingStage.loaded ? (
        <Loader size="L" />
      ) : (
        <BulkPublishingWrapper
          items={exercisesOfParent.exercises}
          parentId={loadedContent.id}
          parentType={ContentType.group}
        >
          <DraggableList
            arrayOfChildren={exercisesOfParent.exercises}
            onDragEnd={(result) => {
              result.destination &&
                dispatch(
                  ContentTypesActionsCreator.setOrder(
                    arrayMoveImmutable(
                      clone(exercisesOfParent.exercises),
                      result.source.index,
                      result.destination.index,
                    ),
                    result.source.index,
                    result.destination.index,
                    ContentTypes.activity,
                    loadedContent.id,
                    loadedCourse.id,
                  ),
                );
            }}
            onContentCreate={(contentTypeToCreate, contentCategory, position) => {
              dispatch(
                ContentTypesActionsCreator.onCreateContent(
                  loadedCourse.id,
                  contentTypeToCreate,
                  ContentTypes.activity,
                  loadedContent.id,
                  contentCategory,
                  position,
                ),
              );
            }}
            creatorAcceptableButtons={{
              [ContentTypes.exercise]: true,
            }}
          />
        </BulkPublishingWrapper>
      )}
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(childrenValidationErrors)} />
    </div>
  );
};
