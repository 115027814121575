import { difference, isEqual } from 'lodash';
import { useState } from 'react';
import { useTheme } from 'styled-components/macro';

import { DBId } from '@common/types/DBId';
import { LanguageV2 } from '@features/content/languages';

import { Text } from '@features/theme';
import {
  MatchLexicalItemCreatorOption,
  MatchLexicalItemCreator,
  LexicalItemMatch,
  LEXICAL_ITEM_TMP_ID,
} from '@features/content/vocabularyReview';

import { mapLexicalItemsMatchedToValues } from './_util/mapLexicalItemsMatchedToValues';
import { useLexicalItemsMatched } from './useLexicalItemsMatched';

type MatchLexicalItemsProps = {
  exerciseId: DBId;
  language: LanguageV2;
  values: LexicalItemMatch[];
};

export const LexicalItemsMatched = ({ exerciseId, language, values }: MatchLexicalItemsProps) => {
  const [currentValues, setCurrentValues] = useState(mapLexicalItemsMatchedToValues(values, language));

  const {
    checkLexicalItemsDuplicates,
    createAndMatchLexicalItem,
    matchLexicalItem,
    onLexicalItemClick,
    onSearch,
    unmatchLexicalItem,
  } = useLexicalItemsMatched({
    exerciseId,
    language,
  });

  const theme = useTheme();

  const onChange = (newValues: MatchLexicalItemCreatorOption[]) => {
    if (isEqual(newValues, currentValues)) return;

    let nextCurrentValues = [...newValues];

    if (newValues.length > currentValues.length) {
      // item added
      const [targetLexicalItem] = difference(newValues, currentValues);
      const { label: phrase, value: lexicalItemId } = targetLexicalItem;

      if (!lexicalItemId || lexicalItemId === LEXICAL_ITEM_TMP_ID) {
        // create a new Lexical Item and attach it to the exercise
        createAndMatchLexicalItem({ nextCurrentValues, phrase });
      } else {
        // attach existing Lexical Item to the exercise
        matchLexicalItem({ exerciseId, lexicalItemId, phrase });
      }
    } else {
      // item removed
      const [targetLexicalItem] = difference(currentValues, newValues);
      const { label: phrase, value: lexicalItemId } = targetLexicalItem;

      // dettach it from the exercise
      unmatchLexicalItem({ exerciseId, lexicalItemId, phrase });
    }

    setCurrentValues(nextCurrentValues);
  };

  const handleOnSearch = (query = '') =>
    onSearch(query, (lexicalItemId: DBId, phrase: string) => {
      const newValues = [...currentValues, { label: phrase, value: lexicalItemId }];
      onChange(newValues);
    });

  return (
    <div>
      <Text color={theme.colorV2.textSecondary} level={3} spaceBottom="XS">
        Lexical items matched
      </Text>
      <MatchLexicalItemCreator
        exerciseId={exerciseId}
        language={language}
        values={currentValues}
        checkDuplicates={checkLexicalItemsDuplicates}
        onChange={onChange}
        onClick={onLexicalItemClick}
        onSearch={handleOnSearch}
      />
    </div>
  );
};
