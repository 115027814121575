import { ChangeEvent, useEffect, useState } from 'react';

import { useIsEditAvailable } from '@features/content/courses';
import { DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';
import { useAppSelector } from '@redux/store';
import { selectLearningLanguage } from '@selectors/CoursesSelectors';

import { CharsLimit } from './CharsLimit';
import { InputComponent } from './InputComponent';

export type WritableInputTextProps = {
  bold?: boolean;
  charsLimit?: number;
  className?: string;
  currentLanguage?: string;
  disabled?: boolean;
  errorsShown?: boolean;
  fontSize?: string;
  id?: string;
  isChangeBlocked?: boolean;
  mode?: 'text' | 'area';
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
  withoutBorder?: boolean;
  withGreyColor?: boolean;
  onChange?: (availableText: string, languageForTips?: LanguageV2 | undefined) => void;
  onChangeInstant?: Function;
};

export const WritableInputText = ({
  bold,
  className,
  charsLimit,
  currentLanguage = DEFAULT_LANGUAGE_V2,
  disabled = false,
  errorsShown = false,
  fontSize = '18',
  id = 'input',
  isChangeBlocked = false,
  mode = 'text',
  placeholder,
  readOnly = false,
  value,
  withoutBorder = false,
  withGreyColor = false,
  onChange,
  onChangeInstant,
}: WritableInputTextProps) => {
  const [text, setText] = useState(value);
  const { isEditAvailable } = useIsEditAvailable();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);

  useEffect(() => {
    setText(value);
  }, [value]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isEditAvailable && !disabled) {
      onChangeInstant && onChangeInstant();

      const textLength = text?.length || 0;

      if (!isChangeBlocked) {
        let value = e.target.value;

        if (charsLimit && textLength >= charsLimit) {
          value = value.substring(0, charsLimit);
        }

        setText(value);
        onChange && onChange(value || '');
      }
    }
  };

  return (
    <>
      {!!charsLimit && <CharsLimit max={charsLimit} textLength={text?.length || 0} />}
      <InputComponent
        mode={mode}
        id={id}
        value={text}
        placeholder={placeholder}
        onChange={onChangeHandler}
        readOnly={readOnly}
        withBoldFont={!!bold}
        withRedBorder={errorsShown}
        withBlueBackground={!isEditAvailable || disabled}
        withRightPadding={currentLanguage === 'ar' || courseLearningLanguage !== DEFAULT_LANGUAGE_V2}
        fontSize={fontSize}
        withGreyColor={withGreyColor}
        withoutBorder={withoutBorder}
        className={className}
      />
    </>
  );
};
