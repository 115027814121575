// Vocabulary Review
const SET_INITIAL_VOCABULARY_REVIEW = 'SET_INITIAL_VOCABULARY_REVIEW';
const GET_LEXICAL_ITEMS = 'GET_LEXICAL_ITEMS';
const VOCABULARY_REVIEW_LOADING = 'VOCABULARY_REVIEW_LOADING';
const VOCABULARY_REVIEW_LOADED = 'VOCABULARY_REVIEW_LOADED';

// Lexical Item
const SET_INITIAL_LEXICAL_ITEM = 'SET_INITIAL_LEXICAL_ITEM';
const GET_LEXICAL_ITEM = 'GET_LEXICAL_ITEM';
const LEXICAL_ITEM_LOADING = 'LEXICAL_ITEM_LOADING';
const LEXICAL_ITEM_LOADED = 'LEXICAL_ITEM_LOADED';

const REMOVE_AUDIO = 'LEXICAL_ITEM_REMOVE_AUDIO';
const REMOVE_IMAGE_FROM_LEXICAL_ITEM = 'LEXICAL_ITEM_REMOVE_IMAGE';
const SET_CONTENT_ID = 'SET_LEXICAL_ITEM_CONTENT_ID';
const SET_IMAGE = 'SET_LEXICAL_ITEM_IMAGE';
const SET_MEDIA_VALUE_AFTER_PROCESSING = 'LEXICAL_ITEM_SET_MEDIA_VALUE_AFTER_PROCESSING';
const SET_PHRASE_ALT_VALUES = 'SET_LEXICAL_ITEM_PHRASE_ALT_VALUES';
const UPLOAD_AUDIO_SUCCEEDED = 'UPLOAD_LEXICAL_ITEM_AUDIO_SUCCEEDED';
const UPLOAD_IMAGE_TO_LEXICAL_ITEM = 'UPLOAD_LEXICAL_ITEM_IMAGE';
const SAVE_LEXICAL_ITEM = 'SAVE_LEXICAL_ITEM';

// Translations
const OPEN_TRANSLATIONS_PANEL = 'LEXICAL_ITEM_OPEN_TRANSLATIONS_PANEL';
const CLOSE_TRANSLATIONS_PANEL = 'LEXICAL_ITEM_CLOSE_TRANSLATIONS_PANEL';
const SET_STRING = 'LEXICAL_ITEM_SET_STRING';
const REMOVE_STRING = 'LEXICAL_ITEM_REMOVE_STRING';

export const VocabularyReviewActions = {
  SET_INITIAL_VOCABULARY_REVIEW,
  GET_LEXICAL_ITEMS,
  VOCABULARY_REVIEW_LOADING,
  VOCABULARY_REVIEW_LOADED,
  SET_INITIAL_LEXICAL_ITEM,
  LEXICAL_ITEM_LOADING,
  LEXICAL_ITEM_LOADED,
  GET_LEXICAL_ITEM,
  OPEN_TRANSLATIONS_PANEL,
  CLOSE_TRANSLATIONS_PANEL,
  REMOVE_STRING,
  REMOVE_AUDIO,
  REMOVE_IMAGE_FROM_LEXICAL_ITEM,
  SET_CONTENT_ID,
  SET_IMAGE,
  SET_MEDIA_VALUE_AFTER_PROCESSING,
  SET_PHRASE_ALT_VALUES,
  SET_STRING,
  UPLOAD_AUDIO_SUCCEEDED,
  UPLOAD_IMAGE_TO_LEXICAL_ITEM,
  SAVE_LEXICAL_ITEM,
};
