import { ContentTypes } from './ContentTypes';
import { ExerciseTypes } from './ExerciseTypes';

type Dimensions = {
  width: number;
  height: number;
};

type ImageloadDimensionDescriptorsType = {
  courseImageUploader: { thumbnail: Dimensions; paywall: Dimensions };
  [ExerciseTypes.comprehension]: {
    thumbnail: Dimensions;
    readingImage: Dimensions;
  };
  [ExerciseTypes.conversation]: { image: Dimensions };
  [ExerciseTypes.fillgap]: { image: Dimensions };
  [ExerciseTypes.flashcard]: { image: Dimensions };
  [ExerciseTypes.typing]: { image: Dimensions };
  [ExerciseTypes.trueFalse]: { image: Dimensions };
  [ExerciseTypes.spelling]: { image: Dimensions };
  [ExerciseTypes.multipleChoice]: { image: Dimensions };
  [ExerciseTypes.speechRecognition]: { image: Dimensions };
  [ContentTypes.lexicalItem]: { image: Dimensions };
};

const standardDimensions: Dimensions = {
  width: 700,
  height: 270,
};

const ImageUploadDimensionDescriptors: ImageloadDimensionDescriptorsType = {
  courseImageUploader: {
    thumbnail: {
      width: 500,
      height: 300,
    },
    paywall: {
      width: 1000,
      height: 350,
    },
  },
  [ExerciseTypes.comprehension]: {
    thumbnail: {
      width: 1920,
      height: 1080,
    },
    readingImage: standardDimensions,
  },
  [ExerciseTypes.conversation]: {
    image: standardDimensions,
  },
  [ExerciseTypes.fillgap]: {
    image: standardDimensions,
  },
  [ExerciseTypes.flashcard]: {
    image: standardDimensions,
  },
  [ExerciseTypes.typing]: {
    image: standardDimensions,
  },
  [ExerciseTypes.trueFalse]: {
    image: standardDimensions,
  },
  [ExerciseTypes.spelling]: {
    image: standardDimensions,
  },
  [ExerciseTypes.multipleChoice]: {
    image: standardDimensions,
  },
  [ExerciseTypes.speechRecognition]: {
    image: standardDimensions,
  },
  [ContentTypes.lexicalItem]: {
    image: {
      width: 500,
      height: 300,
    },
  },
};

export default ImageUploadDimensionDescriptors;
