import produce from 'immer';

import {
  grammarTranslationsPanelInitialState,
  lexicalItemTranslationsPanelInitialState,
  roleplayTranslationsPanelInitialState,
} from '@redux/initialStates/uiInitialState';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { UISliceInterface } from '@common/interfaces/slices/UISliceInterface';
import { TranslationsPanelActions } from '@actions/TranslationsPanelActions';
import { GrammarReviewActions } from '@actions/GrammarReviewActions';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { DBId } from '@common/types/DBId';
import { GrammarCategoryEditableFieldNames } from '@features/content/grammar';
import { AudioUploadActions } from '@actions/AudioUploadActions';
import { PayloadAction } from '@reduxjs/toolkit';
import type { ContentTypesType } from '@common/enums/ContentTypes';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { RoleplayActions } from '@actions/RoleplayActions';
import { VocabularyReviewActions } from '@actions/VocabularyReviewActions';
import type { RoleplayCategoryFieldWithLocalizationNames } from '@features/content/roleplay';
import { LexicalItemFieldWithLocalizationNames } from '@features/content/vocabularyReview';

const TranslationsPanelUIReducers = {
  [TranslationsPanelActions.SHOW]: (state: UISliceInterface, { payload }: PayloadAction<any>): UISliceInterface => {
    return {
      ...state,
      panels: {
        ...state.panels,
        translations: {
          ...state.panels.translations,
          visible: true,
          preopenedTab: payload.preopenedTab,
          visitedKey: payload.visitedKey,
          visitedContentId: payload.visitedId,
          visitedBranch: payload.visitedBranch,
          bundleName: payload.bundleName,
          isStringChangeBlocked: payload.isStringChangeBlocked,
          isBundleChangeBlocked: payload.isBundleChangeBlocked,
          translationsPanelTranslationsMode: payload.translationsPanelTranslationsMode,
          localizationIdUserView: payload.localizationIDForLangExpertsToBeDisplayed,
          row: payload.row,
          column: payload.column,
          cells: payload.cells,
          showOtherLocations: payload.showOtherLocations,
          disableLearningLanguage: payload.disableLearningLanguage,
        },
      },
    };
  },
  [TranslationsPanelActions.SHOW_FOR_COURSE]: (
    state: UISliceInterface,
    { payload }: PayloadAction<any>,
  ): UISliceInterface => {
    return {
      ...state,
      panels: {
        ...state.panels,
        translations: {
          ...state.panels.translations,
          visibleForCourse: true,
          preopenedTab: payload.preopenedTab,
          visitedKey: payload.visitedKey,
          visitedContentId: payload.visitedId,
          visitedBranch: payload.visitedBranch,
          isStringChangeBlocked: payload.isStringChangeBlocked,
          translationsPanelTranslationsMode: payload.translationsPanelTranslationsMode,
          localizationIdUserView: payload.localizationIDForLangExpertsToBeDisplayed,
          row: payload.row,
          column: payload.column,
          cells: payload.cells,
          showOtherLocations: payload.showOtherLocations,
          disableLearningLanguage: payload.disableLearningLanguage,
        },
      },
    };
  },
  [TranslationsPanelActions.SHOW_FOR_LEVEL_LESSON]: (
    state: UISliceInterface,
    { payload }: PayloadAction<any>,
  ): UISliceInterface => {
    return {
      ...state,
      panels: {
        ...state.panels,
        translations: {
          ...state.panels.translations,
          visibleForLevelAndLesson: true,
          preopenedTab: payload.preopenedTab,
          visitedKey: payload.visitedKey,
          visitedContentId: payload.visitedId,
          visitedBranch: payload.visitedBranch,
          isStringChangeBlocked: payload.isStringChangeBlocked,
          translationsPanelTranslationsMode: payload.translationsPanelTranslationsMode,
          localizationIdUserView: payload.localizationIDForLangExpertsToBeDisplayed,
          row: payload.row,
          column: payload.column,
          cells: payload.cells,
          showOtherLocations: payload.showOtherLocations,
          disableLearningLanguage: payload.disableLearningLanguage,
          defaultContextForTranslators: payload.defaultContextForTranslators,
        },
      },
    };
  },
  [GrammarReviewActions.OPEN_TRANSLATIONS_PANEL]: (
    state: UISliceInterface,
    {
      payload: {
        type,
        preopenedTab,
        visitedId,
        fieldName,
        isEditDisabled,
        isEditBlocked,
        defaultContextForTranslators,
      },
    }: PayloadAction<{
      type: ContentTypesType;
      preopenedTab: 'translations' | 'audios' | 'other';
      visitedId: DBId;
      fieldName: GrammarCategoryEditableFieldNames;
      isEditDisabled?: boolean;
      isEditBlocked?: boolean;
      defaultContextForTranslators: string;
    }>,
  ): UISliceInterface => {
    return produce(state, (draft) => {
      draft.panels.grammarTranslations = {
        type,
        visible: true,
        preopenedTab: preopenedTab,
        visitedContentId: visitedId,
        fieldName,
        isEditDisabled,
        isEditBlocked,
        defaultContextForTranslators,
      };
    });
  },
  [GrammarReviewActions.SET_STRING]: (
    state: UISliceInterface,
    { payload: { newString } }: PayloadAction<{ newString: TranslationsPanelContentInterface }>,
  ): UISliceInterface => {
    return produce(state, (draft) => {
      draft.panels.grammarTranslations = {
        ...draft.panels.grammarTranslations,
        visitedContentId: newString.id || '',
        isEditBlocked: false,
      };
    });
  },
  [GrammarReviewActions.CLOSE_TRANSLATIONS_PANEL]: (state: UISliceInterface): UISliceInterface => {
    return produce(state, (draft) => {
      draft.panels.grammarTranslations = grammarTranslationsPanelInitialState;
    });
  },
  [RoleplayActions.OPEN_TRANSLATIONS_PANEL]: (
    state: UISliceInterface,
    {
      payload: {
        type,
        preopenedTab,
        visitedId,
        fieldName,
        isEditDisabled,
        isEditBlocked,
        defaultContextForTranslators,
      },
    }: PayloadAction<{
      type: ContentTypesType;
      preopenedTab: 'translations' | 'audios' | 'other';
      visitedId: DBId;
      fieldName: RoleplayCategoryFieldWithLocalizationNames;
      isEditDisabled?: boolean;
      isEditBlocked?: boolean;
      defaultContextForTranslators: string;
    }>,
  ): UISliceInterface => {
    return produce(state, (draft) => {
      draft.panels.roleplayTranslations = {
        type,
        visible: true,
        preopenedTab: preopenedTab,
        visitedContentId: visitedId,
        fieldName,
        isEditDisabled,
        isEditBlocked,
        defaultContextForTranslators,
      };
    });
  },
  [RoleplayActions.CLOSE_TRANSLATIONS_PANEL]: (state: UISliceInterface): UISliceInterface => {
    return produce(state, (draft) => {
      draft.panels.roleplayTranslations = roleplayTranslationsPanelInitialState;
    });
  },
  [VocabularyReviewActions.OPEN_TRANSLATIONS_PANEL]: (
    state: UISliceInterface,
    {
      payload: {
        type,
        preopenedTab,
        visitedId,
        fieldName,
        isEditDisabled,
        isEditBlocked,
        defaultContextForTranslators,
      },
    }: PayloadAction<{
      type: ContentTypesType;
      preopenedTab: 'translations' | 'audios' | 'other';
      visitedId: DBId;
      fieldName: LexicalItemFieldWithLocalizationNames;
      isEditDisabled?: boolean;
      isEditBlocked?: boolean;
      defaultContextForTranslators: string;
    }>,
  ): UISliceInterface => {
    return produce(state, (draft) => {
      draft.panels.lexicalItemTranslations = {
        type,
        visible: true,
        preopenedTab: preopenedTab,
        visitedContentId: visitedId,
        fieldName,
        isEditDisabled,
        isEditBlocked,
        defaultContextForTranslators,
      };
    });
  },
  [VocabularyReviewActions.CLOSE_TRANSLATIONS_PANEL]: (state: UISliceInterface): UISliceInterface => {
    return produce(state, (draft) => {
      draft.panels.lexicalItemTranslations = lexicalItemTranslationsPanelInitialState;
    });
  },
  [TranslationsPanelActions.MAKE_COPY]: (
    state: UISliceInterface,
    { payload }: PayloadAction<any>,
  ): UISliceInterface => {
    const { isBundleChangeBlocked } = payload;
    return {
      ...state,
      panels: {
        ...state.panels,
        translations: {
          ...state.panels.translations,
          visitedContentId: isBundleChangeBlocked ? state.panels.translations.visitedContentId : '',
          isStringChangeBlocked: false,
          isBundleChangeBlocked: isBundleChangeBlocked ? false : state.panels.translations.isBundleChangeBlocked,
        },
        grammarTranslations: {
          ...state.panels.grammarTranslations,
          visitedContentId: '',
          isEditBlocked: false,
        },
      },
    };
  },
  [AudioUploadActions.UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL_SUCCEEDED]: (
    state: UISliceInterface,
    { payload }: ReturnType<typeof AudioUploadActionsCreator.uploadAudioForContentInTranslatorPanelSucceeded>,
  ): UISliceInterface => {
    const { newContentId } = payload;
    return {
      ...state,
      panels: {
        ...state.panels,
        translations: {
          ...state.panels.translations,
          visitedContentId: newContentId || state.panels.translations.visitedContentId || '',
        },
      },
    };
  },
  [ContentTypesActions.EDIT_EVERYWHERE]: (
    state: UISliceInterface,
    { payload }: PayloadAction<any>,
  ): UISliceInterface => {
    const { isBundleChangeBlocked } = payload;
    return {
      ...state,
      panels: {
        ...state.panels,
        translations: {
          ...state.panels.translations,
          isStringChangeBlocked: !isBundleChangeBlocked ? false : state.panels.translations.isStringChangeBlocked,
          isBundleChangeBlocked: isBundleChangeBlocked ? false : state.panels.translations.isBundleChangeBlocked,
        },
        grammarTranslations: {
          ...state.panels.grammarTranslations,
          isEditBlocked: false,
        },
      },
    };
  },
};

export default TranslationsPanelUIReducers;
