import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { ReactComponent as ArrowIcon } from '@components/ContentTypes/BaseContent/img/arrow.svg';
import { ReactComponent as VideoIcon } from '@components/ContentTypes/BaseContent/img/video.svg';
import { Flag } from '@components/Flag';

import { ReactComponent as ExercisesMatchedIcon } from '../_img/connection.svg';

export const TableCell = styled.td<{ align?: string; fontSize?: string }>`
  ${({ align, fontSize, theme }) => css`
    align-items: center;
    border-bottom: solid 0.1rem ${({ theme }) => theme.colorV2.uiDivider};
    display: flex;
    font-size: ${fontSize ?? '1.6rem'};
    height: 8rem;
    justify-content: ${align ?? 'left'};
    padding: ${theme.space.M};

    &:first-child {
      gap: ${theme.space.M};
    }
  `}
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  display: grid;
  grid-template-columns: 40% 15% 15% 15% 15%;
  margin-bottom: ${({ theme }) => theme.space.L};
  min-width: 119rem;

  thead,
  tbody {
    display: contents;
  }
`;

export const TableRow = styled.tr`
  display: contents;

  &:has(> td):hover {
    cursor: pointer;

    ${TableCell} {
      background-color: ${({ theme }) => theme.colorV2.uiBackgroundSecondary};
    }
  }
`;

export const TableHeader = styled.th<{ align?: string }>`
  ${({ align, theme }) => css`
    border-bottom: solid 0.1rem ${({ theme }) => theme.colorV2.uiDivider};
    color: ${theme.colorV2.textTertiary};
    font-size: 1.4rem;
    height: 4rem;
    padding: ${theme.space.S} ${theme.space.M};
    text-align: ${align ?? 'left'};
  `}
`;

export const PhraseWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
`;

export const Ellipsed = styled.div`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const EllipsedPhrase = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ExercisesMatchedCount = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colorV2.accentPrimary};
    border-radius: 1rem;
    color: ${theme.colorV2.uiLightest};
    font-weight: 700;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    width: 2rem;
  `}
`;

export const MediaImage = styled.img`
  display: inline-block;
  border-radius: 0.8rem;
  margin-right: ${({ theme }) => theme.space.S};
  width: 5.5rem;
`;

export const MediaSeparator = styled.div<{ visible: boolean }>`
  margin-right: ${({ theme }) => theme.space.S};
  min-width: 0.1rem;

  ${({ visible, theme }) =>
    visible &&
    `
    background: ${theme.colorV2.textQuaternary};
    height: 1.5rem;
  `}
`;

export const StyledVideoIcon = styled(VideoIcon)`
  min-width: 2.4rem;
`;

export const ExercisesMatchedContainer = styled.div<{ disabled: boolean }>`
  ${({ disabled, theme }) => css`
    cursor: ${disabled ? 'default' : 'pointer'};
    display: flex;
    gap: ${theme.space.S};
    width: 5rem;
  `}
`;

export const StyledExercisesMatchedIcon = styled(ExercisesMatchedIcon)`
  height: 2.2rem;
  width: 2.2rem;

  path {
    fill: ${({ theme }) => theme.colorV2.accentPrimary};
  }
`;

export const MatchedExercisesModalTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorV2.textPrimary};
    font-weight: 800;
    font-size: 2.4rem;
    margin-bottom: ${theme.space.S};
    text-align: center;
  `}
`;

export const MatchedExercisesModalDescription = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorV2.textSecondary};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 150%;
    margin-bottom: ${theme.space.L};
    text-align: center;
  `}
`;

export const MatchedExercisesModalResultsContainer = styled.div`
  height: 32.2rem;
  overflow: scroll;
  margin-bottom: ${({ theme }) => theme.space.L};
`;

export const MatchedExercisesModalLocation = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${({ theme }) => theme.color.deepBlue};
    border-radius: 0.8rem;
    box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.08);
    display: grid;
    font-size: 1.4rem;
    grid-column-gap: 1%;
    grid-template-columns: 20% 15% 15% 20% 10% 10% 4%;
    padding: ${theme.space.S} ${theme.space.M};
    margin: 0.2rem 0.2rem ${theme.space.S} 0.2rem;

    &:hover {
      background: ${theme.colorV2.uiBackgroundSecondary};
    }
  `}
`;

export const MatchedExercisesModalLocationtItem = styled.div`
  align-items: center;
  display: flex;
  height: 5.6rem;
`;

export const MatchedExercisesModalNotCourseImage = styled.img`
  margin-right: 1rem;
`;

export const MatchedExercisesModalNavigate = styled(Link)`
  grid-column: 7;
`;

export const MatchedExercisesModalButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  height: 2.4rem;
  width: 2.4rem;

  ${({ theme }) => css`
    path {
      fill: ${theme.colorV2.accentPrimary};
    }
  `}
`;

export const StyledFlag = styled(Flag)`
  font-size: 1rem;
  margin-right: ${({ theme }) => theme.space.M};

  img {
    height: 2.4rem;
    width: 2.4rem;
    margin: 0;
  }
`;
