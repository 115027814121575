import { useEffect } from 'react';
import { Formik } from 'formik';
import { useParams, withRouter } from 'react-router-dom';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { ContentOwner } from '@features/content/contentOwnership';
import { LANGUAGE_NAMES_V2, type LanguageV2 } from '@features/content/languages';
import { SearchModal } from '@features/search';
import { Loader } from '@features/theme';
import { processLocalizationsForFormikValues } from '@helpers/formikInitialValuesHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectRoleplayCategory } from '@selectors/roleplaySelectors';
import { selectRoleplayTranslationsPanel } from '@selectors/UiSelectors';

import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { Header } from '../Header';
import { TranslationsPanel } from '../TranslationsPanel';
import { Breadcrumbs } from './Breadcrumbs';
import { RoleplayCategoryData } from './RoleplayCategoryData';

const RoleplayCategory = () => {
  const dispatch = useAppDispatch();
  const { languageId, categoryId } = useParams<{ languageId: LanguageV2; categoryId: DBId }>();

  const roleplayCategory = useAppSelector(selectRoleplayCategory);
  const roleplayCategoryContent = roleplayCategory.content;
  const roleplayTranslationPanel = useAppSelector(selectRoleplayTranslationsPanel);

  useEffect(() => {
    dispatch(RoleplayActionCreators.setInitialRoleplayCategory());
  }, [dispatch, categoryId]);

  useEffect(() => {
    if (roleplayCategory.loaded === LoadingStage.notLoaded && categoryId) {
      dispatch(RoleplayActionCreators.getRoleplayCategory(categoryId));
    }
  }, [dispatch, categoryId, roleplayCategory]);

  useEffect(() => {
    if (
      roleplayCategory.loaded === LoadingStage.loaded &&
      roleplayCategory.scenariosData.loaded === LoadingStage.notLoaded &&
      categoryId
    ) {
      dispatch(RoleplayActionCreators.getRoleplayScenarios(categoryId));
    }
  }, [dispatch, categoryId, roleplayCategory]);

  const getInitialValuesForRoleplayCategoryContent = () => {
    return {
      title: processLocalizationsForFormikValues(roleplayCategoryContent?.title?.textLocalizations),
      titleContext: roleplayCategoryContent?.title?.description || '',
      titleChanged: false,
    };
  };

  const contentName = `${LANGUAGE_NAMES_V2[languageId]} Roleplay`;

  if (roleplayCategory.loaded === LoadingStage.loading) {
    return (
      <div className="course-edition__course-data-composer">
        {languageId && <Header contentId={categoryId} contentName={contentName} learningLanguage={languageId} />}
        <Loader size="L" />
      </div>
    );
  }

  if (roleplayCategory.loaded === LoadingStage.loaded) {
    return (
      <Formik initialValues={getInitialValuesForRoleplayCategoryContent()} onSubmit={() => {}}>
        <>
          <div className="course-edition__course-data-composer">
            {roleplayTranslationPanel.visible && (
              <TranslationsPanel content={roleplayCategoryContent} learningLanguage={languageId} />
            )}
            <SearchModal />
            {languageId && <Header contentId={categoryId} contentName={contentName} learningLanguage={languageId} />}
            <BreadcrumbsAndOwnershipWrapper>
              <Breadcrumbs />
              <ContentOwner
                childComponentId={roleplayCategoryContent.id}
                contentType={ContentTypes.roleplayCategory}
                componentId={languageId}
                dates={roleplayCategoryContent.dates}
                owner={roleplayCategoryContent.owner}
              />
            </BreadcrumbsAndOwnershipWrapper>
            <RoleplayCategoryData />
          </div>
        </>
      </Formik>
    );
  }

  return null;
};

export const RoleplayCategoryContent = withRouter(RoleplayCategory);
