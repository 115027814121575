import { MultiValue, StylesConfig } from 'react-select';
import styled, { css, DefaultTheme } from 'styled-components/macro';

import { Button, Text } from '@features/theme';

import type { MatchLexicalItemCreatorOption } from './types';

export const getMatchLexicalItemCreatorSelectorStyles = (
  theme: DefaultTheme,
): StylesConfig<MultiValue<MatchLexicalItemCreatorOption>> => ({
  clearIndicator: (defaultStyles) => ({
    ...defaultStyles,
    display: 'none',
  }),
  control: (defaultStyles, { isDisabled }) => ({
    ...defaultStyles,
    backgroundColor: isDisabled ? theme.colorV2.input.background : 'inherit',
    borderColor: theme.colorV2.input.border.default,
    borderRadius: '0.8rem',
  }),
  dropdownIndicator: (defaultStyles, { isFocused }) => ({
    ...defaultStyles,
    display: 'none',
  }),
  indicatorSeparator: (defaultStyles) => ({
    ...defaultStyles,
    display: 'none',
  }),
  input: (defaultStyles) => ({
    ...defaultStyles,
    color: theme.colorV2.textPrimary,
    fontSize: '1.4rem',
  }),
  menu: (defaultStyles) => ({
    ...defaultStyles,
    display: 'none',
  }),
  multiValue: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: 'transparent',
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: theme.colorV2.textTertiary,
    fontSize: '1.4rem',
  }),
});

export const MatchLexicalItemCreatorWrapper = styled.div`
  position: relative;
`;

export const MatchLexicalItemCreatorButtons = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colorV2.uiBackgroundPrimary};
    background: linear-gradient(270deg, rgba(255, 255, 255) 88%, transparent 100%);
    color: ${theme.colorV2.textSecondary};
    display: flex;
    gap: ${theme.space.XS};
    height: 3.6rem;
    padding-left: ${theme.space.L};
    right: ${theme.space.S};
    top: 0.1rem;
    position: absolute;
    width: fit-content;
    z-index: 1;

    span {
      line-height: 1.4rem;
      margin-bottom: 0;
    }
  `}
`;

export const MatchLexicalItemButton = styled(Button)`
  font-size: 1.4rem;
  padding: 0 0 0 0.2rem;

  svg {
    margin-right: 0.4rem;

    path {
      fill: ${({ theme }) => theme.colorV2.accentPrimary};
    }
  }
`;

export const MatchLexicalItemModalWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const MatchLexicalItemCreateModalDescription = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colorV2.textTertiary};
    margin-bottom: ${theme.space.L};
    text-align: center;
  `}
`;

export const PhraseLabel = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colorV2.textTertiary};
    font-size: 1.4rem;
    margin-bottom: ${theme.space.XS};
  `}
`;

export const PhraseInput = styled.input<{ errored: boolean }>`
  ${({ errored, theme }) => css`
    border-radius: 0.8rem;
    border: solid 0.1rem ${errored ? theme.colorV2.utilityError : theme.colorV2.input.border.default};
    color: ${theme.colorV2.textPrimary};
    font-size: 1.6rem;
    height: 4.4rem;
    padding: 0 ${theme.space.S};
    width: 100%;
  `}
`;

export const MatchLexicalItemCreateModalBody = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.space.L};
  width: 100%;
`;

export const ErrorLabelWrapper = styled.div`
  position: absolute;
  top: -1rem;
  right: 0;
  z-index: 1;
`;

export const MatchLexicalItemModalButtonsArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.M};
  width: 100%;
`;
