import { CanceledError } from 'axios';

import { ContentTypesType } from '@common/enums/ContentTypes';
import { getStringCount, SearchPanelParamsType } from '@features/search';
import type { LanguageV2 } from '@features/content/languages';

const SUGGESTIONS_COUNT_REQUEST_DEBOUNCE = 600; // ms

type DebouncedGetStringCountType = {
  controller: React.MutableRefObject<AbortController | undefined>;
  debounceTimer: React.MutableRefObject<ReturnType<typeof setTimeout> | undefined>;
  filtersPreset: SearchPanelParamsType['filtersPreset'];
  language?: LanguageV2;
  query: string;
  type: ContentTypesType;
  setSuggestionsCount: React.Dispatch<React.SetStateAction<number>>;
  setSuggestionQueryLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const filterActualError = (error: unknown) => {
  // request cancelation is technically an error so we need this check to handle actual errors
  if (!(error instanceof CanceledError)) {
    throw error;
  }
};

export const debouncedGetStringCount = ({
  controller,
  debounceTimer,
  filtersPreset,
  language,
  query,
  type,
  setSuggestionsCount,
  setSuggestionQueryLoading,
}: DebouncedGetStringCountType) => {
  try {
    setSuggestionQueryLoading(true);
    clearTimeout(debounceTimer.current);

    controller.current?.abort();
    controller.current = new AbortController();

    debounceTimer.current = setTimeout(async () => {
      try {
        const suggestionsCountResult = await getStringCount({
          filtersPreset,
          language,
          query,
          signal: (controller.current as AbortController).signal,
          type,
        });

        setSuggestionsCount(suggestionsCountResult);
        setSuggestionQueryLoading(false);
      } catch (error) {
        filterActualError(error);
      }
    }, SUGGESTIONS_COUNT_REQUEST_DEBOUNCE);
  } catch (error) {
    filterActualError(error);
  }
};
