import * as _ from 'lodash';

import { dropdown_defaultCode, DropdownInterface } from '@components/Dropdown';
import { LANGUAGE_NAMES_V2, LANGUAGE_FLAGS, type LanguageV2 } from '@features/content/languages';

export const generateLanguageDropdownOptions = (
  languages: LanguageV2[],
  defaultText?: string,
  termination?: string,
) => {
  const stringTypesDropdownValues: DropdownInterface<LanguageV2>[] = [
    ...languages.map((language: LanguageV2) => {
      return {
        name: (
          <>
            <img src={LANGUAGE_FLAGS[language]} className="dropdown__country-flag" alt="Country flag" />{' '}
            {_.upperFirst(LANGUAGE_NAMES_V2[language])} {termination && termination}
          </>
        ),
        code: language,
      };
    }),
  ];

  if (defaultText !== undefined) {
    stringTypesDropdownValues.unshift({
      name: defaultText,
      code: dropdown_defaultCode,
    });
  }

  return stringTypesDropdownValues;
};
