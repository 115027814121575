/** @deprecated Use DEFAULT_LANGUAGE_V2 type instead */
export const DEFAULT_LANGUAGE = 'EN';

export const DEFAULT_CONTENT_DATES_VALUE = {
  firstPublishedAt: null,
  lastChangedAt: null,
} as const;

export const DIALOGUE_MAX_CHARACTER_PHOTOS = 100;
export const DIALOGUE_CHARACTER_BASIC_PLACEHOLDER = 'Name';
export const DIALOGUE_EXERCISE_SCRIPT_STATE_BRANCH_NAME = 'script';
export const DIALOGUE_MAX_PARTICIPANTS = 2;

// https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/basic-usage#modifiers--special-keys
export const SHORTCUT_KEYS = {
  CONTINUE: 'alt+c',
  KEEP_DRAFT: 'alt+d',
  OPEN_SEARCH: 'mod+shift+s',
  SAVE_CONTENT: 'mod+alt+s',
};

export const MEDIA_PROCESSING_VALIDATION_MESSAGE = {
  AUDIO: 'Audio is not processed for %LANG% yet',
  IMAGE: 'Image is not processed for %LANG% yet',
  VIDEO: 'Video is not processed for %LANG% yet',
};

export const MEDIA_STATUS_REQUEST_INTERVAL = 5000; // ms
export const MEDIA_STATUS_REQUEST_ATTEMPTS = 5;

export const CEFR = {
  a1: 'A1',
  a2: 'A2',
  b1: 'B1',
  b2: 'B2',
  c1: 'C1',
} as const;

export const CEFR_LABEL = {
  a1: 'A1 Beginner',
  a2: 'A2 Elementary',
  b1: 'B1 Intermediate',
  b2: 'B2 Upper Intermediate',
  c1: 'C1 Advanced',
} as const;
