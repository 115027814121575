import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { ResourceData } from '@components/Exercises/Common/ResourceBundle';
import { DEFAULT_LANGUAGE_V2, type LanguageV2 } from '@features/content/languages';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';

export const getResources = (
  loadedExercise: AnyExerciseInterface,
  courseLearningLanguage: LanguageV2,
  bundleName?: string,
  lang?: LanguageV2,
) => {
  let resources: ResourceData[];

  switch (loadedExercise.content.type) {
    case ExerciseTypes.phraseBuilder:
      resources = [
        {
          resource: 'phrase_audio',
          isOpened: !!ImageUploadUtils.getAudioForFileUpload(
            loadedExercise,
            'phrase',
            courseLearningLanguage,
            'mainBundle',
          )?.mediaValue,
          isAvailable: true,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'phrase', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'phrase',
          isOpened: true,
          isAvailable: true,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'phrase',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'image',
          isOpened: !!ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'mainBundle')
            ?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'alternative_value',
          isOpened: false,
          isAvailable: false,
          value: '',
        },
        {
          resource: 'keyphrase',
          isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
          isAvailable: false,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'keyphrase_audio',
          isOpened: !!ImageUploadUtils.getAudioForFileUpload(
            loadedExercise,
            'example',
            courseLearningLanguage,
            'mainBundle',
          )?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'example', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'video',
          isOpened: !!ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
        },
      ];
      break;
    case ExerciseTypes.typing:
      resources = [
        {
          resource: 'phrase',
          isOpened: true,
          isAvailable: true,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'phrase',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'alternative_value',
          isOpened: !!loadedExercise.content.mainBundle?.phrase?.textLocalizations.find(
            (loc: LocalizationInterface) => loc.language === courseLearningLanguage,
          )?.alternativeValues,
          isAvailable: true,
          value: '',
        },
        {
          resource: 'phrase_audio',
          isOpened: loadedExercise.content.mainAudioEnabled,
          isAvailable: true,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'phrase', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'image',
          isOpened: loadedExercise.content.mainImageEnabled,
          isAvailable: true,
          value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'keyphrase',
          isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
          isAvailable: false,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'keyphrase_audio',
          isOpened: !!ImageUploadUtils.getAudioForFileUpload(
            loadedExercise,
            'example',
            courseLearningLanguage,
            'mainBundle',
          )?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'example', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'video',
          isOpened: !!ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
        },
      ];
      break;
    case ExerciseTypes.fillgap:
      switch (bundleName) {
        case 'mainBundle':
          resources = [
            {
              resource: 'phrase',
              isOpened: true,
              isAvailable: true,
              value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'phrase',
                loadedExercise.content,
                courseLearningLanguage,
                'mainBundle',
              ),
            },
            {
              resource: 'image',
              isOpened: loadedExercise.content.mainImageEnabled,
              isAvailable: true,
              value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'mainBundle')
                ?.mediaValue,
            },
            {
              resource: 'phrase_audio',
              isOpened: loadedExercise.content.mainAudioEnabled,
              isAvailable: true,
              value: ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'phrase',
                courseLearningLanguage,
                'mainBundle',
              )?.mediaValue,
            },
            {
              resource: 'alternative_value',
              isOpened: false,
              isAvailable: false,
              value: '',
            },
            {
              resource: 'keyphrase',
              isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'example',
                loadedExercise.content,
                courseLearningLanguage,
                'mainBundle',
              ),
              isAvailable: false,
              value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'example',
                loadedExercise.content,
                courseLearningLanguage,
                'mainBundle',
              ),
            },
            {
              resource: 'keyphrase_audio',
              isOpened: !!ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'example',
                courseLearningLanguage,
                'mainBundle',
              )?.mediaValue,
              isAvailable: false,
              value: ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'example',
                courseLearningLanguage,
                'mainBundle',
              )?.mediaValue,
            },
            {
              resource: 'video',
              isOpened: true,
              isAvailable: false,
              value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
            },
          ];
          break;
        case 'distractor1':
        case 'distractor2':
          resources = [
            {
              resource: 'phrase',
              isOpened: true,
              isAvailable: true,
              value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'phrase',
                loadedExercise.content,
                courseLearningLanguage,
                bundleName,
              ),
            },
            {
              resource: 'image',
              isOpened: true,
              isAvailable: false,
              value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, bundleName)
                ?.mediaValue,
            },
            {
              resource: 'phrase_audio',
              isOpened: true,
              isAvailable: false,
              value: ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'phrase',
                courseLearningLanguage,
                bundleName,
              )?.mediaValue,
            },
            {
              resource: 'alternative_value',
              isOpened: false,
              isAvailable: false,
              value: '',
            },
            {
              resource: 'keyphrase',
              isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'example',
                loadedExercise.content,
                courseLearningLanguage,
                bundleName,
              ),
              isAvailable: false,
              value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'example',
                loadedExercise.content,
                courseLearningLanguage,
                bundleName,
              ),
            },
            {
              resource: 'keyphrase_audio',
              isOpened: !!ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'example',
                courseLearningLanguage,
                bundleName,
              )?.mediaValue,
              isAvailable: false,
              value: ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'example',
                courseLearningLanguage,
                bundleName,
              )?.mediaValue,
            },
            {
              resource: 'video',
              isOpened: true,
              isAvailable: false,
              value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, bundleName)?.mediaValue,
            },
          ];
          break;
        default:
          resources = [];
      }
      break;
    case ExerciseTypes.trueFalse:
      resources = [
        {
          resource: 'image',
          isOpened: true,
          isAvailable: true,
          value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'phrase_audio',
          isOpened: loadedExercise?.content?.mainAudioEnabled,
          isAvailable: true,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'phrase', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'phrase',
          isOpened: !!loadedExercise?.content?.mainBundle?.phrase?._id,
          isAvailable: true,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'phrase',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'alternative_value',
          isOpened: false,
          isAvailable: false,
          value: '',
        },
        {
          resource: 'keyphrase',
          isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
          isAvailable: false,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'keyphrase_audio',
          isOpened: !!ImageUploadUtils.getAudioForFileUpload(
            loadedExercise,
            'example',
            courseLearningLanguage,
            'mainBundle',
          )?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'example', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'video',
          isOpened: true,
          isAvailable: false,
          value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
        },
      ];
      break;
    case ExerciseTypes.comprehension:
      const comprehensionImageResource: ResourceData = {
        resource: 'image',
        isOpened: true,
        isAvailable: true,
        value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'mainBundle')
          ?.mediaValue,
      };

      const comprehensionImageAndVideo: ResourceData[] = [
        {
          ...comprehensionImageResource,
        },
        {
          resource: 'video',
          isOpened: true,
          isAvailable: true,
          value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
        },
      ];

      const getComprehensionImageResource = () => {
        if (loadedExercise.content.mainVideoEnabled) {
          return comprehensionImageAndVideo;
        } else {
          return [
            {
              ...comprehensionImageResource,
            },
          ];
        }
      };

      const getComprehensionVideoResource = () => {
        if (loadedExercise.content.mainVideoEnabled) {
          return [];
        } else {
          const comprehensionVideoResource: ResourceData = {
            resource: 'video',
            isOpened: true,
            isAvailable: false,
            value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
          };
          return [
            {
              ...comprehensionVideoResource,
            },
          ];
        }
      };

      resources = [
        ...getComprehensionImageResource(),
        {
          resource: 'phrase',
          isOpened: !!loadedExercise.content.mainBundle?.phrase?.textLocalizations.filter(
            (loc: LocalizationInterface) => loc.value,
          ).length,
          isAvailable: true,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'phrase',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'alternative_value',
          isOpened: false,
          isAvailable: false,
          value: '',
        },
        {
          resource: 'phrase_audio',
          isOpened: false,
          isAvailable: false,
          value: '',
        },
        {
          resource: 'keyphrase',
          isOpened: false,
          isAvailable: false,
          value: '',
        },
        {
          resource: 'keyphrase_audio',
          isOpened: false,
          isAvailable: false,
          value: '',
        },
        ...getComprehensionVideoResource(),
      ];
      break;
    case ExerciseTypes.flashcard:
      resources = [
        {
          resource: 'image',
          isOpened: true,
          isAvailable: true,
          value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'learningWordBundle')
            ?.mediaValue,
        },
        {
          resource: 'phrase',
          isOpened: true,
          isAvailable: true,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'phrase',
            loadedExercise.content,
            courseLearningLanguage,
            'learningWordBundle',
          ),
        },
        {
          resource: 'alternative_value',
          isOpened: !!loadedExercise.content.learningWordBundle?.phrase?.textLocalizations.find(
            (loc: LocalizationInterface) => loc.language === courseLearningLanguage,
          )?.alternativeValues,
          isAvailable: true,
          value: '',
        },
        {
          resource: 'phrase_audio',
          isOpened: true,
          isAvailable: true,
          value: ImageUploadUtils.getAudioForFileUpload(
            loadedExercise,
            'phrase',
            courseLearningLanguage,
            'learningWordBundle',
          )?.mediaValue,
        },
        {
          resource: 'keyphrase',
          isOpened: !!loadedExercise?.content?.learningWordBundle?.example,
          isAvailable: true,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'learningWordBundle',
          ),
        },
        {
          resource: 'keyphrase_audio',
          isOpened: !!loadedExercise?.content?.learningWordBundle?.example,
          isAvailable: true,
          value: ImageUploadUtils.getAudioForFileUpload(
            loadedExercise,
            'example',
            courseLearningLanguage,
            'learningWordBundle',
          )?.mediaValue,
        },
        {
          resource: 'video',
          isOpened: !!ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'learningWordBundle')?.mediaValue,
          isAvailable: true,
          value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'learningWordBundle')?.mediaValue,
        },
      ];
      break;
    case ExerciseTypes.matchup:
      resources = [
        {
          resource: 'phrase',
          isOpened: true,
          isAvailable: true,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'phrase',
            loadedExercise.content,
            lang || DEFAULT_LANGUAGE_V2,
            bundleName,
          ),
        },
        {
          resource: 'image',
          isOpened: !!ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, bundleName)
            ?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, bundleName)
            ?.mediaValue,
        },
        {
          resource: 'phrase_audio',
          isOpened: loadedExercise?.content?.mainAudioEnabled,
          isAvailable: false,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'phrase', lang, bundleName)?.mediaValue,
        },
        {
          resource: 'alternative_value',
          isOpened: false,
          isAvailable: false,
          value: '',
        },
        {
          resource: 'keyphrase',
          isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            lang || DEFAULT_LANGUAGE_V2,
            bundleName,
          ),
          isAvailable: false,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            lang || DEFAULT_LANGUAGE_V2,
            bundleName,
          ),
        },
        {
          resource: 'keyphrase_audio',
          isOpened: !!ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'example', lang, bundleName)?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'example', lang, bundleName)?.mediaValue,
        },
        {
          resource: 'video',
          isOpened: !!ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, bundleName || '')?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, bundleName || '')?.mediaValue,
        },
      ];
      break;
    case ExerciseTypes.multipleChoice:
      switch (bundleName) {
        case 'answerBundle':
          resources = [
            {
              resource: 'image',
              isOpened: true,
              isAvailable: !!loadedExercise.content.choicesImagesEnabled,
              value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'answerBundle')
                ?.mediaValue,
            },
            {
              resource: 'phrase',
              isOpened: true,
              isAvailable: true,
              value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'phrase',
                loadedExercise.content,
                courseLearningLanguage,
                'answerBundle',
              ),
            },
            {
              resource: 'phrase_audio',
              isOpened: !!ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'phrase',
                courseLearningLanguage,
                'answerBundle',
              )?.mediaValue,
              isAvailable: true,
              value: ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'phrase',
                courseLearningLanguage,
                'answerBundle',
              )?.mediaValue,
            },
            {
              resource: 'alternative_value',
              isOpened: false,
              isAvailable: false,
              value: '',
            },
            {
              resource: 'keyphrase',
              isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'example',
                loadedExercise.content,
                courseLearningLanguage,
                'answerBundle',
              ),
              isAvailable: false,
              value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'example',
                loadedExercise.content,
                courseLearningLanguage,
                'answerBundle',
              ),
            },
            {
              resource: 'keyphrase_audio',
              isOpened: !!ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'example',
                courseLearningLanguage,
                'answerBundle',
              )?.mediaValue,
              isAvailable: false,
              value: ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'example',
                courseLearningLanguage,
                'answerBundle',
              )?.mediaValue,
            },
            {
              resource: 'video',
              isOpened: true,
              isAvailable: false,
              value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'answerBundle')?.mediaValue,
            },
          ];
          break;
        case 'distractor1':
        case 'distractor2':
          resources = [
            {
              resource: 'image',
              isOpened: true,
              isAvailable: !!loadedExercise.content.choicesImagesEnabled,
              value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, bundleName)
                ?.mediaValue,
            },
            {
              resource: 'phrase',
              isOpened: true,
              isAvailable: true,
              value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'phrase',
                loadedExercise.content,
                courseLearningLanguage,
                bundleName,
              ),
            },
            {
              resource: 'phrase_audio',
              isOpened: !!ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'phrase',
                courseLearningLanguage,
                bundleName,
              )?.mediaValue,
              isAvailable: false,
              value: ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'phrase',
                courseLearningLanguage,
                bundleName,
              )?.mediaValue,
            },
            {
              resource: 'alternative_value',
              isOpened: false,
              isAvailable: false,
              value: '',
            },
            {
              resource: 'keyphrase',
              isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'example',
                loadedExercise.content,
                courseLearningLanguage,
                bundleName,
              ),
              isAvailable: false,
              value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
                'example',
                loadedExercise.content,
                courseLearningLanguage,
                bundleName,
              ),
            },
            {
              resource: 'keyphrase_audio',
              isOpened: !!ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'example',
                courseLearningLanguage,
                bundleName,
              )?.mediaValue,
              isAvailable: false,
              value: ImageUploadUtils.getAudioForFileUpload(
                loadedExercise,
                'example',
                courseLearningLanguage,
                bundleName,
              )?.mediaValue,
            },
            {
              resource: 'video',
              isOpened: true,
              isAvailable: false,
              value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, bundleName)?.mediaValue,
            },
          ];
          break;
        default:
          resources = [];
      }
      break;
    case ExerciseTypes.speechRecognition:
      resources = [
        {
          resource: 'image',
          isOpened: true,
          isAvailable: true,
          value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'phrase',
          isOpened: true,
          isAvailable: true,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'phrase',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'phrase_audio',
          isOpened: true,
          isAvailable: true,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'phrase', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'alternative_value',
          isOpened: false,
          isAvailable: false,
          value: '',
        },
        {
          resource: 'keyphrase',
          isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
          isAvailable: false,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'keyphrase_audio',
          isOpened: !!ImageUploadUtils.getAudioForFileUpload(
            loadedExercise,
            'example',
            courseLearningLanguage,
            'mainBundle',
          )?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'example', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'video',
          isOpened: true,
          isAvailable: false,
          value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
        },
      ];
      break;
    case ExerciseTypes.spelling:
      resources = [
        {
          resource: 'phrase',
          isOpened: true,
          isAvailable: true,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'phrase',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'phrase_audio',
          isOpened: true,
          isAvailable: true,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'phrase', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'image',
          isOpened: !!ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'mainBundle')
            ?.mediaValue,
          isAvailable: true,
          value: ImageUploadUtils.getDisplayImageForFileUpload(loadedExercise, 'image', undefined, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'alternative_value',
          isOpened: false,
          isAvailable: false,
          value: '',
        },
        {
          resource: 'keyphrase',
          isOpened: !!ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
          isAvailable: false,
          value: ExerciseDataModelAssembler.findFieldValueInLanguageCommon(
            'example',
            loadedExercise.content,
            courseLearningLanguage,
            'mainBundle',
          ),
        },
        {
          resource: 'keyphrase_audio',
          isOpened: !!ImageUploadUtils.getAudioForFileUpload(
            loadedExercise,
            'example',
            courseLearningLanguage,
            'mainBundle',
          )?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getAudioForFileUpload(loadedExercise, 'example', courseLearningLanguage, 'mainBundle')
            ?.mediaValue,
        },
        {
          resource: 'video',
          isOpened: !!ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
          isAvailable: false,
          value: ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')?.mediaValue,
        },
      ];
      break;
    default:
      resources = [];
  }

  return resources;
};
