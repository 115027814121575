import { type LanguageV2, DEFAULT_LANGUAGE_V2 } from '@features/content/languages';

import { VocabularyReviewViewParams, VocabularyReviewListItem } from '../types';
import type { FiltersState, WordsRangeDTO } from './VocabularyReviewFilters';

/**
 * Maps the wordsRange filter value to an API compatible value
 * @returns WordsRangeDTO
 */
export const mapWordsRangeValueToApiValue = (value: FiltersState['wordsRange']): WordsRangeDTO => {
  const { min, max } = value || {};
  const wordsRangeApiValue: WordsRangeDTO = {};

  if (min) wordsRangeApiValue.fromWordsCount = min;
  if (max) wordsRangeApiValue.toWordsCount = max;

  return wordsRangeApiValue;
};

/**
 * Generates a unique key based on Vocabulary Review language, filters and pagination params to be used in a cache object
 * Ex. "language=en&page=1&fromWordsCount=1&toWordsCount=2"
 * @returns string
 */
export const generateCacheKey = (params: Record<keyof VocabularyReviewViewParams, any>) => {
  let _params = { ...params };

  // prune every filter with a falsy value
  Object.keys(_params.filters).forEach(
    (key) => !_params.filters[key as keyof FiltersState] && delete _params.filters[key as keyof FiltersState],
  );

  if (_params.filters.wordsRange) {
    _params.filters = {
      ..._params.filters,
      ...mapWordsRangeValueToApiValue(_params.filters.wordsRange),
    };

    delete _params.filters.wordsRange;
  }

  if (Object.keys(_params.filters).length) {
    // flatten filter values
    _params = {
      ..._params,
      ..._params.filters,
    };
  }

  delete _params.filters;

  return decodeURIComponent(new URLSearchParams(_params).toString());
};

/**
 * @returns string
 */
export const getPhrasesToDisplay = (phrase: VocabularyReviewListItem['phrase'], learningLanguage: LanguageV2) => {
  const phraseTextLocalizations =
    phrase?.textLocalizations?.reduce((acc: Record<string, string>, { language, value }) => {
      if ([DEFAULT_LANGUAGE_V2, learningLanguage].includes(language)) {
        acc[language] = value;
      }

      return acc;
    }, {}) || {};

  // Return phrase in the learning language / default language if both translation are available (Non EN courses/vocabularies)
  if (
    phraseTextLocalizations[learningLanguage] &&
    phraseTextLocalizations[DEFAULT_LANGUAGE_V2] &&
    phraseTextLocalizations[learningLanguage] !== phraseTextLocalizations[DEFAULT_LANGUAGE_V2]
  ) {
    return `${phraseTextLocalizations[learningLanguage]} / ${phraseTextLocalizations[DEFAULT_LANGUAGE_V2]}`;
  }

  // Return phrase in the learning language only if no default language translation available */
  if (phraseTextLocalizations[learningLanguage]) return phraseTextLocalizations[learningLanguage];
  // Return phrase in default language (EN courses/vocabularies) */
  if (phraseTextLocalizations[DEFAULT_LANGUAGE_V2]) return phraseTextLocalizations[DEFAULT_LANGUAGE_V2];
  // Fall back to an empty string if no translations are available */
  return '';
};
