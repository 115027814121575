import { VocabularyReviewActions } from '@actions/VocabularyReviewActions';
import { ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import type { LanguageV2 } from '@features/content/languages';
import type {
  LexicalItemFieldWithLocalizationNames,
  LexicalItemFormikValues,
  VocabularyReviewViewParams,
} from '@features/content/vocabularyReview';
import type { AlternativeValue } from '@components/Exercises/Common/AlternativeValueCreator/AlternativeValueCreator';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { MediaTypesType } from '@common/enums/MediaTypes';

export const VocabularyReviewActionsCreator = {
  setInitialVocabularyReview: (languageId: LanguageV2) => ({
    type: VocabularyReviewActions.SET_INITIAL_VOCABULARY_REVIEW,
    payload: {
      language: languageId,
    },
  }),
  getLexicalItems: ({ language, page }: VocabularyReviewViewParams) => ({
    type: VocabularyReviewActions.GET_LEXICAL_ITEMS,
    payload: {
      language,
      page,
    },
  }),
  setInitialLexicalItem: (languageId: LanguageV2) => ({
    type: VocabularyReviewActions.SET_INITIAL_LEXICAL_ITEM,
    payload: {
      language: languageId,
    },
  }),
  getLexicalItem: (lexicalItemId: DBId) => ({
    type: VocabularyReviewActions.GET_LEXICAL_ITEM,
    payload: {
      lexicalItemId,
    },
  }),
  openTranslationsPanel: (
    type: ContentTypesType,
    preopenedTab: 'translations' | 'audios' | 'other',
    visitedId: DBId | undefined,
    fieldName: LexicalItemFieldWithLocalizationNames,
    isEditBlocked?: boolean,
    isEditDisabled?: boolean,
    defaultContextForTranslators?: string,
  ) => ({
    type: VocabularyReviewActions.OPEN_TRANSLATIONS_PANEL,
    payload: {
      type,
      preopenedTab,
      visitedId,
      fieldName,
      isEditBlocked,
      isEditDisabled,
      defaultContextForTranslators,
    },
  }),
  closeTranslationsPanel: () => ({
    type: VocabularyReviewActions.CLOSE_TRANSLATIONS_PANEL,
  }),
  removeAudio: ({
    contentId,
    fieldName,
    language,
  }: {
    contentId: DBId | undefined;
    fieldName: LexicalItemFieldWithLocalizationNames;
    language: LanguageV2;
  }) => ({
    type: VocabularyReviewActions.REMOVE_AUDIO,
    payload: { contentId, fieldName, language },
  }),
  removeString: (contentType: ContentTypesType, fieldName: LexicalItemFieldWithLocalizationNames) => ({
    type: VocabularyReviewActions.REMOVE_STRING,
    payload: { contentType, fieldName },
  }),
  setContentId: (payload: {
    contentType: ContentTypesType;
    fieldName: LexicalItemFieldWithLocalizationNames;
    contentId: DBId;
  }) => ({
    type: VocabularyReviewActions.SET_CONTENT_ID,
    payload,
  }),
  setPhraseAltValues: (values: AlternativeValue[], language: LanguageV2) => ({
    type: VocabularyReviewActions.SET_PHRASE_ALT_VALUES,
    payload: { values, language },
  }),
  uploadAudioToLexicalItemSuccedeed: (payload: {
    localization?: LocalizationInterface;
    content: { id: string } & TranslationsPanelContentInterface;
    mediaURL: string;
    visitedBranch: LexicalItemFieldWithLocalizationNames;
  }) => ({ type: VocabularyReviewActions.UPLOAD_AUDIO_SUCCEEDED, payload }),
  uploadImageToLexicalItem: (payload: {
    lexicalItemId: DBId;
    contentIdBeingUpdated: DBId | undefined;
    file: File;
    progressHandler: (progress: number) => void;
  }) => ({ type: VocabularyReviewActions.UPLOAD_IMAGE_TO_LEXICAL_ITEM, payload }),
  removeImageFromLexicalItem: (lexicalItemId: DBId) => ({
    type: VocabularyReviewActions.REMOVE_IMAGE_FROM_LEXICAL_ITEM,
    payload: lexicalItemId,
  }),
  setMediaValueAfterProcessing: (contentType: DBId, mediaType: MediaTypesType, mediaUrl: string) => ({
    type: VocabularyReviewActions.SET_MEDIA_VALUE_AFTER_PROCESSING,
    payload: { contentType, mediaType, mediaUrl },
  }),
  saveLexicalItem: ({
    values,
    languageId,
    lexicalItemId,
  }: {
    values: LexicalItemFormikValues;
    languageId: LanguageV2;
    lexicalItemId: DBId;
  }) => ({
    type: VocabularyReviewActions.SAVE_LEXICAL_ITEM,
    payload: {
      values,
      language: languageId,
      lexicalItemId,
    },
  }),
};
