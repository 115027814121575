import { NoImage } from '../Common/CardImage';
import { ReactComponent as PlaceholderIcon } from '../Common/CardImage/img/placeholder.svg';

import { StyledImage } from './styles';

export const CardImage = ({ image }: { image: string | null }) => (
  <>
    {image ? (
      <StyledImage src={image} />
    ) : (
      <NoImage>
        <PlaceholderIcon />
      </NoImage>
    )}
  </>
);
