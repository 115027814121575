import { trim } from 'lodash';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { DBId } from '@common/types/DBId';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { Button } from '@features/theme';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';
import { selectSearchPanel } from '@selectors/UiSelectors';

import { useSearch } from '../../../SearchContext';
import { SearchResults } from '../../SearchResults';
import { LexicalItemSearchResultItem, type LexicalItemSearchResultType } from '../../SearchResultItem';
import { LexicalItemSearchResultTypeHeader } from '../../SearchResultTypeHeader';
import { searchByLexicalItem } from '../../service';
import {
  DEFAULT_PAGINATION_PAGE,
  SearchResultsWrapper,
  useEnterKeydownHandler,
  useFiltersState,
  useResultsHandler,
} from '../Common';
import { CommonSearchComponentProps } from '../SearchContent';
import { LexicalItemQueryParamsType } from '../../service/types';
import { LexicalItemSearchHeader, LexicalItemSearchTitle, SearchBoxWrapper, StyledSearchBox } from './styles';

const defaultFilters: LexicalItemQueryParamsType = {
  exactMatch: true,
  language: DEFAULT_LANGUAGE_V2,
};

export const LexicalItemSearch = ({ trackSearch }: CommonSearchComponentProps) => {
  const loadedExercise = useAppSelector(selectLoadedExerciseData);
  const { callbacks, filtersPreset, query: initialQuery } = useAppSelector(selectSearchPanel);
  const { search, query, searchLoading, setQuery } = useSearch();
  const { count, results, clearResults, onError, onSuccess } = useResultsHandler<LexicalItemSearchResultType>();
  // Lexical Item search doesn't have actual filters but language is implicit
  const filtersState = useFiltersState(defaultFilters, filtersPreset?.lexicalItem || {});
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGINATION_PAGE);
  const [initialQuerySearched, setInitialQuerySearched] = useState(false);

  const isSearchButtonDisabled = query && trim(query).length ? searchLoading : true;

  useEffect(() => {
    if (Boolean(filtersState.state.language) && initialQuery && !initialQuerySearched) {
      setQuery(initialQuery);

      if (query) {
        onPaginatedSearch(query, 1);
        setInitialQuerySearched(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState.state, initialQuery, initialQuerySearched, query]);

  const onPaginatedSearch = (query: string | null, page: number) => {
    if (query) {
      search(() =>
        searchByLexicalItem(query, {
          exactMatch: true,
          language: filtersState.state.language,
          page,
        }),
      )
        .then(onSuccess)
        .catch(onError);

      trackSearch();
    } else {
      clearResults();
    }
  };

  const onSearch = (query: string | null) => {
    setCurrentPage(DEFAULT_PAGINATION_PAGE);
    onPaginatedSearch(query, DEFAULT_PAGINATION_PAGE);
  };

  const onClear = () => {
    setQuery(null);
    onSearch(null);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    onPaginatedSearch(query, page);
  };

  const onClickResult = (lexicalItemId: DBId) => {
    const url = `/vocabulary-review/${filtersState.state.language}/lexical-item/${lexicalItemId}`;
    window.open(url, '_blank')?.focus();
  };

  const onMatch = (lexicalItemId: DBId, phrase: string) => {
    callbacks?.onMatch && callbacks.onMatch(lexicalItemId, phrase);
    dispatch(SearchModalActionsCreator.hideSearchV2());
  };

  // Handle Enter key press
  useEnterKeydownHandler(() => onSearch(query));

  return (
    <>
      <LexicalItemSearchHeader>
        <IDDisplayer id={loadedExercise.content.id} />
        <LexicalItemSearchTitle>Select the Lexical Items to be matched</LexicalItemSearchTitle>
        <SearchBoxWrapper data-testid="search-box">
          <StyledSearchBox filterTerm={query || ''} placeholder="" onClear={onClear} onSearchChange={setQuery} />
          <Button disabled={isSearchButtonDisabled} size="S" onClick={() => onSearch(query)}>
            Search
          </Button>
        </SearchBoxWrapper>
      </LexicalItemSearchHeader>

      <Modal.Body>
        <SearchResultsWrapper onPageCount={results.length}>
          <SearchResults<LexicalItemSearchResultType>
            results={results}
            count={count}
            currentPage={currentPage}
            setCurrentPage={onPageChange}
            header={<LexicalItemSearchResultTypeHeader forReusing={Boolean(onMatch)} />}
            renderItem={(item) => (
              <LexicalItemSearchResultItem
                content={item}
                language={filtersState.state.language}
                onClick={onClickResult}
                onMatch={(lexicalItemId, phrase) => onMatch && onMatch(lexicalItemId, phrase)}
              />
            )}
          />
        </SearchResultsWrapper>
      </Modal.Body>
    </>
  );
};
